// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 03/09/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Import OpenSankey
import { default_main_sankey_id, default_style_id, default_style_name } from '../deps/OpenSankey+/deps/OpenSankey/types/Utils';
// Import OpenSankey+
import { Class_LinkAttributeOSP, Class_LinkStyleOSP } from '../deps/OpenSankey+/types/LinkOSP';
import { ClassTemplate_ZoneSelectionOSP } from '../deps/OpenSankey+/types/SelectionZoneOSP';
// Local imports
import { Class_MenuConfigMFA } from './MenuConfigMFA';
import { ClassTemplate_ApplicationDataMFA } from './ApplicationDataMFA';
import { ClassTemplate_DrawingAreaMFA } from './DrawingAreaMFA';
import { ClassTemplate_SankeyMFA } from './SankeyMFA';
import { ClassTemplate_NodeElementMFA } from './NodeMFA';
import { ClassTemplate_LinkElementMFA } from './LinkMFA';
import { Class_NodeAttributeOSP, Class_NodeStyleOSP } from '../deps/OpenSankey+/types/NodeOSP';
// STANDARD CLASSES FOR MODEL FLOW ANALYSIS APP AND MORE ********************************
// APPLICATION DATA =====================================================================
export class Class_ApplicationDataMFA extends ClassTemplate_ApplicationDataMFA {
    createNewMenuConfiguration() {
        return new Class_MenuConfigMFA();
    }
    createNewDrawingArea(id) {
        const drawing_area = new Class_DrawingAreaMFA(this, id);
        return drawing_area;
    }
}
// DRAWING AREA =========================================================================
export class Class_DrawingAreaMFA extends ClassTemplate_DrawingAreaMFA {
    createNewSankey(id) {
        const sankey = new Class_SankeyMFA(this, this.application_data.menu_configuration, id);
        return sankey;
    }
    createNewSelectionZone() {
        return new ClassTemplate_ZoneSelectionOSP(this, this.application_data.menu_configuration);
    }
}
// SANKEY ===============================================================================
export class Class_SankeyMFA extends ClassTemplate_SankeyMFA {
    constructor(drawing_area, menu_config, id = default_main_sankey_id) {
        super(drawing_area, menu_config, id);
        this._link_styles = {};
        this._node_styles = {};
        this._link_styles[default_style_id] = this.createNewLinkStyle(default_style_id, default_style_name, false);
        this._node_styles[default_style_id] = this.createNewNodeStyle(default_style_id, default_style_name, false);
    }
    createNewNode(id, name) {
        const node = new Class_NodeElementMFA(id, name, this.drawing_area, this._menu_config);
        return node;
    }
    createNewLink(id, source, target) {
        const link = new Class_LinkElementMFA(id, source, target, this.drawing_area, this._menu_config);
        return link;
    }
    createNewNodeStyle(id, name, is_deletable) {
        return new Class_NodeStyleOSP(id, name, is_deletable);
    }
    createNewLinkStyle(id, name, is_deletable) {
        const style = new Class_LinkStyleOSP(id, name, is_deletable);
        return style;
    }
    get default_link_style() {
        return this._link_styles[default_style_id];
    }
}
// NODE =================================================================================
export class Class_NodeElementMFA extends ClassTemplate_NodeElementMFA {
    constructor(id, name, drawing_area, menu_config) {
        super(id, name, drawing_area, menu_config);
        this._display = {
            drawing_area: drawing_area,
            sankey: this.sankey,
            position: this.display.position,
            style: drawing_area.sankey.default_node_style,
            attributes: new Class_NodeAttributeOSP()
        };
    }
}
// LINK =================================================================================
export class Class_LinkElementMFA extends ClassTemplate_LinkElementMFA {
    constructor(id, source, target, drawing_area, menu_config) {
        super(id, source, target, drawing_area, menu_config);
        // Display
        this._display = {
            drawing_area: drawing_area,
            sankey: drawing_area.sankey,
            displaying_order: drawing_area.addElement(),
            position_starting: {
                type: 'absolute',
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            position_ending: {
                type: 'absolute',
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            style: drawing_area.sankey.default_link_style,
            attributes: new Class_LinkAttributeOSP()
        };
        // Link with style
        this._display.style.addReference(this);
        this.source.addOutputLink(this);
        this.target.addInputLink(this); // Target
        // Instanciate display on svg
        this.computeControlPoints();
        this.draw();
    }
}
