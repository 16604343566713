// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { textwrap } from 'd3-textwrap';
// Local modules imports
import { ClassTemplate_Element } from '../Elements/Element';
import { default_element_color, getBooleanFromJSON, getNumberFromJSON, getStringFromJSON, getStringListFromJSON, getJSONFromJSON, const_default_position_x, } from '../types/Utils';
import { ClassTemplate_Handler } from './Handler';
// CLASS LEGEND *************************************************************************
/**
 * Class that define how we draw legend for a Sankey
 *
 * @export
 * @class ClassTemplate_Legend
 * @extends {ClassTemplate_Element}
 */
export class ClassTemplate_Legend extends ClassTemplate_Element {
    // CONSTRUCTOR ========================================================================
    constructor(drawing_area, menu_config) {
        // Init parent class attributes
        //TODO : rename grp_legend to g_legend when legacy code will be deleted as for now some legacy functions might be tirgered when interactiong with DA and look for g_legend
        super('legend', menu_config, 'grp_legend');
        // PRIVATE ATTRIBUTES =================================================================
        this._pos_from_legacy = false;
        // Legend Class attributes
        this._masked = true;
        this._display_legend_scale = false;
        this._legend_police = 16;
        this._legend_bg_border = false;
        this._legend_bg_color = default_element_color;
        this._legend_bg_opacity = 0;
        this._legend_show_dataTags = false;
        this._node_label_separator = '';
        this._width = 180;
        this._info_link_value_void = false;
        /**
         * Attribute for legend content positionning.
         * Souldn't have getter & setter public because the variable is only use & computed when we draw the legend
         * @private
         * @type {number}
         * @memberof ClassTemplate_Legend
         */
        this._dx = 0;
        /**
         * Attribute for legend content positionning.
         * Souldn't have getter & setter public because the variable is only use & computed when we draw the legend
         * @private
         * @type {number}
         * @memberof ClassTemplate_Legend
         */
        this._dy = 0;
        /**
         * Attribute used for the scale of the legend
         * so the legend can still be visible when we de-zoom DA.
         * The attr is automaticaly updated when we zoom/de-zooom on the DA (see setter)
         *
         * @private
         * @type {number}
         * @memberof ClassTemplate_Legend
         */
        this._scale = 1;
        /**
         * Text wrapper function
         * @private
         * @memberof ClassTemplate_Legend
         */
        this._wrapper = textwrap()
            .bounds({ height: 100, width: this._width })
            .method('tspans');
        // Init other class attributes
        this._display = {
            drawing_area: drawing_area,
            sankey: drawing_area.sankey,
            position: {
                x: const_default_position_x,
                y: drawing_area.getNavBarHeight() + 50,
                u: 0,
                v: 0
            }
        };
        this._drag_handler = {
            left: new ClassTemplate_Handler('legend_left_handle_' + this.id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragLeftHandler(), this.dragHandleEnd(), { class: 'legend_left_handle' }, undefined, 'grp_legend'),
            right: new ClassTemplate_Handler('legend_right_handle_' + this.id, drawing_area, menu_config, this, this.dragHandleStart(), this.dragRightHandler(), this.dragHandleEnd(), { class: 'legend_right_handle' }, undefined, 'grp_legend'),
        };
    }
    // COPY METHODS =======================================================================
    _copyFrom(_) {
        super._copyFrom(_);
        this._masked = _._masked;
        this._dx = _._dx;
        this._dy = _._dy;
        this._scale = _._scale;
        this._width = _._width;
        this._display_legend_scale = _._display_legend_scale;
        this._legend_police = _._legend_police;
        this._legend_bg_border = _._legend_bg_border;
        this._legend_bg_color = _._legend_bg_color;
        this._legend_bg_opacity = _._legend_bg_opacity;
        this._legend_show_dataTags = _._legend_show_dataTags;
        this._node_label_separator = _._node_label_separator;
        this._info_link_value_void = _._info_link_value_void;
        this._pos_from_legacy = _._pos_from_legacy;
    }
    // SAVING METHODS =====================================================================
    _toJSON(json_object, kwargs) {
        // we need to create an entry legend to do this
        super._toJSON(json_object, kwargs);
        json_object['legend'] = {};
        const json_legend = json_object['legend'];
        json_legend['legend_position'] = [String(this.position_x), String(this.position_y)];
        json_legend['mask_legend'] = this._masked;
        json_legend['legend_dx'] = this._dx;
        json_legend['legend_dy'] = this._dy;
        json_legend['legend_scale'] = this._scale;
        json_legend['legend_width'] = this._width;
        json_legend['display_legend_scale'] = this._display_legend_scale;
        json_legend['legend_police'] = this._legend_police;
        json_legend['legend_bg_border'] = this._legend_bg_border;
        json_legend['legend_bg_color'] = this._legend_bg_color;
        json_legend['legend_bg_opacity'] = this._legend_bg_opacity;
        json_legend['legend_show_dataTags'] = this._legend_show_dataTags;
        json_legend['node_label_separator'] = this._node_label_separator;
        json_legend['info_link_value_void'] = this._info_link_value_void;
    }
    _fromJSON(json_object, kwargs) {
        super._fromJSON(json_object, kwargs);
        const json_legend = getJSONFromJSON(json_object, 'legend', {});
        const legend_position = getStringListFromJSON(json_legend, 'legend_position', ['0', String(this.drawing_area.getNavBarHeight())]);
        this._display.position.x = +legend_position[0];
        const tmp_y = +legend_position[1];
        this._display.position.y = (tmp_y < this.drawing_area.getNavBarHeight()) ? this.drawing_area.getNavBarHeight() : tmp_y; //fix legend position by putting it position just under the navbar
        this._masked = getBooleanFromJSON(json_legend, 'mask_legend', this._masked);
        this._dx = getNumberFromJSON(json_legend, 'legend_dx', this._dx);
        this._dy = getNumberFromJSON(json_legend, 'legend_dy', this._dy);
        this._scale = getNumberFromJSON(json_legend, 'legend_scale', this._scale);
        this._width = getNumberFromJSON(json_legend, 'legend_width', this._width);
        this._display_legend_scale = getBooleanFromJSON(json_legend, 'display_legend_scale', this._display_legend_scale);
        this._legend_police = getNumberFromJSON(json_legend, 'legend_police', this._legend_police);
        this._legend_bg_border = getBooleanFromJSON(json_legend, 'legend_bg_border', this._legend_bg_border);
        this._legend_bg_color = getStringFromJSON(json_legend, 'legend_bg_color', this._legend_bg_color);
        this._legend_bg_opacity = getNumberFromJSON(json_legend, 'legend_bg_opacity', this._legend_bg_opacity);
        this._legend_show_dataTags = getBooleanFromJSON(json_legend, 'legend_show_dataTags', this._legend_show_dataTags);
        this._node_label_separator = getStringFromJSON(json_legend, 'node_label_separator', this._node_label_separator);
        this._info_link_value_void = getBooleanFromJSON(json_legend, 'info_link_value_void', this._info_link_value_void);
        // Var only present if json is legacy
        this._pos_from_legacy = getBooleanFromJSON(json_legend, 'legacy_legend', this._pos_from_legacy);
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Function called in _afterFromJSON in ApplicationData,
     * the function correctly place legend as if it was in legacy despite being not anymore relative to DA
     *
     * @memberof ClassTemplate_Legend
     */
    posIfFromLegacy() {
        var _a;
        if (this._pos_from_legacy) {
            let x = 0, y = 0, k = 1;
            const tmp = (_a = this.drawing_area.d3_selection_zoom_area) === null || _a === void 0 ? void 0 : _a.node();
            if (tmp && tmp !== null) {
                x = d3.zoomTransform(tmp).x;
                y = d3.zoomTransform(tmp).y;
                k = d3.zoomTransform(tmp).k;
            }
            //Set pos of legend like it was in legacy (so we have to take into account old pos of legend & scale of DA)
            this.setPosXY((this.display.position.x * k) + x, (this.display.position.y * k) + y);
            this._pos_from_legacy = false;
        }
    }
    /**
     * _drawLegendBg with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    drawLegendBg() {
        this._process_or_bypass(() => this._drawLegendBg());
    }
    /**
     * _drawTagDisplayed with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    drawTagDisplayed() {
        this._process_or_bypass(() => this._drawTagDisplayed());
    }
    /**
     * _drawInfoDataType with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    drawInfoDataType() {
        this._process_or_bypass(() => this._drawInfoDataType());
    }
    /**
     * _drawInfoDashedLink with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    drawInfoDashedLink() {
        this._process_or_bypass(() => this._drawInfoDashedLink());
    }
    /**
     * _drawSankeyScale with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    drawSankeyScale() {
        this._process_or_bypass(() => this._drawSankeyScale());
    }
    /**
       * Function triggered when element is (un)selected
       *
       * @memberof Class_ContainerElement
       */
    drawAsSelected() {
        this.draw();
        this.drawDragHandlers();
    }
    // PROTECTED METHODS ==================================================================
    eventMouseOver(_event) {
        var _a, _b;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.zone_for_dragging').attr('stroke-dasharray', '6,6');
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.zone_for_dragging').attr('stroke', this._legend_bg_color);
    }
    eventMouseOut(_event) {
        var _a, _b;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.zone_for_dragging').attr('stroke-dasharray', this.is_selected ? '6,6' : 'unherit');
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.zone_for_dragging').attr('stroke', (this._legend_bg_border || this.is_selected) ? this._legend_bg_color : 'none');
    }
    eventMouseDragStart(_event) {
    }
    eventMouseDrag(event) {
        this._display.position.x += (event.sourceEvent.movementX);
        if (this._display.position.x < 0)
            this._display.position.x = 0;
        this._display.position.y += (event.sourceEvent.movementY);
        if (this._display.position.y < 0)
            this._display.position.y = 0;
        this.setPosXY(this._display.position.x, this._display.position.y);
        this.drawDragHandlers();
    }
    eventMouseDragEnd(_event) {
        this.draw();
        this.drawDragHandlers();
    }
    _initDraw() {
        const d3_svg = this.drawing_area.d3_selection_zoom_area;
        if (d3_svg !== null) {
            const d3_drawing_area_selection = d3_svg.selectAll(' #' + this._svg_parent_group);
            if (d3_drawing_area_selection.nodes().length > 0) {
                this.d3_selection = d3_drawing_area_selection.append('g');
                this.d3_selection.attr('id', this.svg_group)
                    .attr('transform', 'translate(' + 0 + ',' + this.drawing_area.getNavBarHeight() + ')'); // init drawing area zone with a margin for taking into account the navbar
            }
        }
    }
    _draw() {
        var _a;
        // Heritance of draw function
        super._draw();
        // Update class attributes
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_legend');
        // Draw Background
        this._drawLegendBg();
        // Reset content positionning
        this._dx = 0;
        this._dy = 0;
        // Rebounds text wrapper with width of legend when drawed at this moment
        this._wrapper.bounds({ height: 100, width: this._width });
        // Draw tag color pallette applied to sankey
        this._drawTagDisplayed();
        // Draw explication for data type
        const sankey_has_interval_value = d3.selectAll('.link_value').nodes().filter(lv => d3.select(lv).html().includes('*')).length > 0;
        if (sankey_has_interval_value) {
            this._drawInfoDataType();
        }
        // Draw explication for dashed links
        const sankey_has_dashed_links = this.drawing_area.sankey.links_list.filter(l => l.value.data_value == null).length > 0;
        if (sankey_has_dashed_links && this._info_link_value_void) {
            this._drawInfoDashedLink();
        }
        if (this._display_legend_scale) {
            this._drawSankeyScale();
        }
        this._updateLegendHeight();
    }
    /**
   * Override applyPosition for legend so it take into accound scale transformation
   * @protected
   * @return {*}
   * @memberof Class_Node
   */
    _applyPosition() {
        if (this.d3_selection !== null) {
            this.d3_selection.attr('transform', 'translate(' + this.position_x + ', ' + this.position_y + ')');
        }
    }
    eventMaintainedClick(event) {
        // Apply parent behavior first
        super.eventMaintainedClick(event);
        // Get related drawing area
        const drawing_area = this.drawing_area;
        // EDITION MODE ===========================================================
        if (drawing_area.isInEditionMode()) {
            // Purge selection list
            drawing_area.purgeSelection();
            // Close all menus
            drawing_area.closeAllMenus();
        }
        // SELECTION MODE =========================================================
        else if (drawing_area.isInSelectionMode() && event.button === 0) {
            // if we're here then it's a simple click (no ctrl,alt or shift key pressed) - purge
            // Add node to selection
            drawing_area.addLegendToSelection();
        }
    }
    // PRIVATE METHODS ====================================================================
    /**
     * Activate the control points alignement guide
     *
     * @private
     * @return {*}
     * @memberof Class_ContainerElement
     */
    dragHandleStart() {
        return () => {
        };
    }
    /**
      * Deactivate the control points alignement guide
      * @private
      * @return {*}
      * @memberof Class_ContainerElement
      */
    dragHandleEnd() {
        return () => {
        };
    }
    /**
     * Event when we drag the left handle
     *
     * @private
     * @return {*}
     * @memberof Class_ContainerElement
     */
    dragLeftHandler() {
        return (event) => {
            this._width -= event.dx;
            this.setPosXY(this.position_x + event.dx, this.position_y);
            this.draw();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    /**
     * Event when we drag the right handle
     *
     * @private
     * @return {*}
     * @memberof Class_ContainerElement
     */
    dragRightHandler() {
        return (event) => {
            this._width += event.dx;
            this.draw();
            // Reposition drag handler with updated with & pos of the free label
            this.drawDragHandlers();
        };
    }
    /**
   * Draw all control points
   *
   * @private
   * @memberof Class_ContainerElement
   */
    drawDragHandlers() {
        // Compute positions
        this.computeLeftHandlerPos();
        this.computeRightHandlerPos();
        // Draw
        this._drag_handler.left.draw();
        this._drag_handler.right.draw();
    }
    computeLeftHandlerPos() {
        // left handle pos
        this._drag_handler.left.position_x = this.position_x + 0;
        this._drag_handler.left.position_y = this.position_y + this._dy / 2;
    }
    computeRightHandlerPos() {
        // right handle pos
        this._drag_handler.right.position_x = this.position_x + this._width;
        this._drag_handler.right.position_y = this.position_y + this._dy / 2;
    }
    /**
     * Function that draw the background of the legend, it is also used as draggable
     * element to move the legend
     * @private
     * @memberof ClassTemplate_Legend
     */
    _drawLegendBg() {
        var _a, _b;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.g_drag_zone_leg').remove();
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('g').attr('class', 'g_drag_zone_leg').append('rect').attr('class', 'zone_for_dragging').attr('width', this._width).attr('height', '0px').attr('rx', '2px').attr('ry', '2px').attr('stroke-dasharray', () => '').attr('stroke', (this._legend_bg_border || this.is_selected) ? this._legend_bg_color : 'none').attr('stroke-width', (this._legend_bg_border || this.is_selected) ? 1 : 0).attr('stroke-dasharray', (this.is_selected) ? '6,6' : 'unherit').attr('fill', this._legend_bg_color).attr('fill-opacity', this._legend_bg_opacity / 100);
    }
    /**
     * Function to draw tags in legend that are used in the sankey
     * (when they're activated in the toolbar)
     * @private
     * @memberof ClassTemplate_Legend
     */
    _drawTagDisplayed() {
        const node_taggs = this.drawing_area.sankey.node_taggs_list;
        const flux_taggs = this.drawing_area.sankey.flux_taggs_list;
        const data_taggs = this.drawing_area.sankey.data_taggs_list;
        const flux_list = this.drawing_area.sankey.visible_links_list;
        const node_list = this.drawing_area.sankey.visible_nodes_list;
        // Get all grp tag insind one variable
        const all_tags = [...node_taggs, ...flux_taggs];
        all_tags
            .filter(tag_group => tag_group.show_legend)
            .forEach(tag_group => {
            var _a, _b, _c, _d, _e, _f;
            // Tag froup id can have caracter that 'break' html id selection so we normalize it
            const id_to_use = tag_group.id.replaceAll(' ', '_').replaceAll('\'', '_');
            // Ajout du tagGroup.name
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('text').attr('id', 'GrpTag_title_' + id_to_use).attr('transform', 'translate(' + this._dx + ',' + this._dy + ' )').attr('x', 0).attr('y', this._legend_police).text(tag_group.name).attr('style', 'font-weight:bold;font-size:' + this._legend_police + 'px').call(this._wrapper);
            if (((_b = document.getElementById('GrpTag_title_' + id_to_use)) === null || _b === void 0 ? void 0 : _b.getElementsByTagName('tspan')[0].innerHTML) === '') {
                (_c = document.getElementById('GrpTag_title_' + id_to_use)) === null || _c === void 0 ? void 0 : _c.setAttribute('y', '5');
            }
            this._dy += (((_e = (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.select('#GrpTag_title_' + id_to_use).selectAll('tspan').nodes().length) !== null && _e !== void 0 ? _e : 0) * this.legend_police) + 4;
            const legendElements2 = (_f = this.d3_selection) === null || _f === void 0 ? void 0 : _f.append('g').attr('transform', 'translate(0,' + this._legend_police + ')');
            tag_group.selected_tags_list.filter(tag => {
                // Filter tag that doens't have element visible on the drawing_area
                return node_list.filter(n => n.hasGivenTag(tag)).length !== 0 || flux_list.filter(f => f.hasGivenTag(tag)).length !== 0;
            })
                .forEach((tag) => {
                var _a;
                const tagElement = legendElements2 === null || legendElements2 === void 0 ? void 0 : legendElements2.append('g').attr('id', 'tag_' + tag.name.replaceAll(' ', '__')).attr('transform', () => 'translate(' + this._dx + ',' + (this._dy) + ')').on('mouseover', () => {
                    //Add event on hovering tag in legend that allow to highlight elemnt of the sankey that have the tag we are hovering
                    const nodes_tied_to_link_with_tag_hovered = [];
                    //Get nodes tied to links who have the tag we hovering & get the list of links that have the tag hovered
                    flux_list
                        .filter(l => {
                        var _a;
                        if (l.hasGivenTag(tag)) {
                            nodes_tied_to_link_with_tag_hovered.push(l.source);
                            nodes_tied_to_link_with_tag_hovered.push(l.target);
                            return true;
                        }
                        else if (l.source.hasGivenTag(tag) && l.target.hasGivenTag(tag)) {
                            nodes_tied_to_link_with_tag_hovered.push(l.source);
                            nodes_tied_to_link_with_tag_hovered.push(l.target);
                            return true;
                        }
                        (_a = l.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('opacity', 0.1);
                        return false;
                    });
                    //Reduce opacity of all node that doesn't have the tag hovered or aren't tied to a link that have the tag hovered
                    node_list
                        .forEach(n => {
                        var _a;
                        if (!nodes_tied_to_link_with_tag_hovered.includes(n)) {
                            (_a = n.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('opacity', 0.1);
                        }
                    });
                }).on('mouseout', () => {
                    // Reset opacity of visible element
                    node_list.forEach(node => { var _a; return (_a = node.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('opacity', ''); });
                    flux_list.forEach(flux => { var _a; return (_a = flux.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('opacity', ''); });
                });
                // Ajout du shape
                tagElement === null || tagElement === void 0 ? void 0 : tagElement.append('rect').attr('width', this._legend_police).attr('height', this._legend_police).attr('x', 0).attr('y', -0.75 * this._legend_police).attr('rx', 3).attr('ry', 3).style('fill', () => tag.color).style('fill-opacity', 1);
                // Ajout du label
                tagElement === null || tagElement === void 0 ? void 0 : tagElement.append('text').attr('class', 'name_tag').attr('x', 35).attr('y', 0).attr('font-size', this._legend_police + 'px').text(tag.name).call(this._wrapper);
                this._dy += (((_a = tagElement === null || tagElement === void 0 ? void 0 : tagElement.select('.name_tag').selectAll('tspan').nodes().length) !== null && _a !== void 0 ? _a : 0) * this.legend_police) + 2;
            });
        });
        const show_data = Object.values(data_taggs).filter(d => d.show_legend).length > 0;
        if (this._legend_show_dataTags && show_data) {
            this._dy += this._legend_police;
            Object.entries(data_taggs).forEach(tag_group => {
                var _a, _b, _c;
                // Ajout du tagGroup.name
                (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('text').attr('id', 'leg_dataTag_' + tag_group[0]).attr('transform', 'translate(0,' + this._dy + ' )').attr('x', 0).attr('y', 0).text((tag_group[1].name + ' : ' + tag_group[1].selected_tags_list.map(t => t.name).join(', '))).attr('style', ('font-size:' + this._legend_police + 'px;')).call(this._wrapper);
                this._dy += (((_c = (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('#leg_dataTag_' + tag_group[0]).selectAll('tspan').nodes().length) !== null && _c !== void 0 ? _c : 0) * this.legend_police) + 2;
            });
        }
    }
    /**
     * Add text to describe why there is * in some link value
     * @private
     * @memberof ClassTemplate_Legend
     */
    _drawInfoDataType() {
        var _a;
        // Write information in the legend depending to the diagram representation:
        // - when diagramme type is : data reconciled + indetermined links (values), we explain the meaning of "*" in the link label
        // - when diagramme type is : data collected or data reconciled, we explain the meaning of dashed links
        this._dy += this._legend_police;
        const free_value = (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('g').attr('id', 'gg_legend_free_value').attr('transform', 'translate(0,' + (this._dy) + ')').attr('font-size', this._legend_police + 'px');
        free_value === null || free_value === void 0 ? void 0 : free_value.append('text').text('*').attr('x', '5');
        free_value === null || free_value === void 0 ? void 0 : free_value.append('text').attr('x', '35').text('MEP.show_legend_free_value').call(this._wrapper);
    }
    /**
     * Add text to describe why some link are dashed
     * (because their value are undefined, only appear when data_type
     * is set to anything but structur)
     * @private
     * @memberof ClassTemplate_Legend
     */
    _drawInfoDashedLink() {
        var _a;
        this._dy += this._legend_police;
        // Create info zone
        const dashed_link = (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('g').attr('id', 'gg_legend_dashed_links').attr('transform', 'translate(0,' + this._dy + ')').attr('font-size', this._legend_police + 'px');
        // Create path as exemple
        dashed_link === null || dashed_link === void 0 ? void 0 : dashed_link.append('path').attr('d', 'M 0 0 L 25 0  Z').attr('fill', 'none').attr('stroke-width', '5').attr('stroke', default_element_color).attr('stroke-opacity', 0.85).attr('stroke-dasharray', '3,3');
        // Set explanation text for path as dashed
        dashed_link === null || dashed_link === void 0 ? void 0 : dashed_link.append('text').text(this.drawing_area.application_data.t('MEP.legend_dashed_links')).call(this._wrapper);
        // Correct text position // font size
        dashed_link === null || dashed_link === void 0 ? void 0 : dashed_link.select('text').attr('x', '35').attr('y', this._legend_police / 2);
    }
    /**
     * Add info zone in legend for "Sankey scale"
     * @private
     * @memberof ClassTemplate_Legend
     */
    _drawSankeyScale() {
        var _a;
        // Update vertical offset
        this._dy += this._legend_police + 15; //(50 is the height of the draggable scale)
        // Remove previous info zone for scale
        d3.selectAll(' .opensankey #svg .g_scale').remove();
        // Create info zone for scale
        const g_scale = (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.append('g').attr('class', 'g_scale').attr('transform', 'translate(0,' + (this._dy) + ')');
        // Add explanation text
        g_scale === null || g_scale === void 0 ? void 0 : g_scale.append('text').text(this.drawing_area.application_data.t('scale') + ':').style('font-size', this._legend_police + 'px');
        const g_draggable = g_scale === null || g_scale === void 0 ? void 0 : g_scale.append('g').attr('class', 'g_draggable_scale').style('cursor', 'grab').attr('transform', 'translate(' + (7 * (this._legend_police * 0.75)) + ', -30)');
        g_draggable === null || g_draggable === void 0 ? void 0 : g_draggable.append('rect').attr('width', '3px').attr('height', '50px').attr('fill', 'black');
        g_draggable === null || g_draggable === void 0 ? void 0 : g_draggable.append('text').attr('class', 'measurment_scale').attr('transform', 'translate(5,25)').text(Math.round((this.drawing_area.scale / 2)));
        // Add drag event for the scale representation
        g_draggable === null || g_draggable === void 0 ? void 0 : g_draggable.call(d3.drag()
            .subject(Object)
            .on('drag', function (event) {
            g_draggable.attr('transform', 'translate(' + (event.x) + ',' + (event.y) + ')');
        }));
        this._dy += 20;
    }
    _updateLegendHeight() {
        d3.select('.zone_for_dragging').attr('height', this._dy + 5);
    }
    /**
     * _updateLegendHeight with timeout
     *
     * @private
     * @memberof ClassTemplate_Legend
     */
    updateLegendHeight() {
        this._process_or_bypass(() => this.updateLegendHeight());
    }
    // GETTERS / SETTERS ==================================================================
    get is_visible() {
        return (super.is_visible &&
            (!this._masked));
    }
    get masked() { return this._masked; }
    set masked(value) { this._masked = value; this.draw(); this.drawing_area.checkAndUpdateAreaSize(); }
    get display_legend_scale() { return this._display_legend_scale; }
    set display_legend_scale(value) { this._display_legend_scale = value; this.draw(); }
    get legend_police() { return this._legend_police; }
    set legend_police(value) { this._legend_police = value; this.draw(); }
    get legend_bg_border() { return this._legend_bg_border; }
    set legend_bg_border(value) { this._legend_bg_border = value; this.draw(); }
    get legend_bg_color() { return this._legend_bg_color; }
    set legend_bg_color(value) { this._legend_bg_color = value; this.draw(); }
    get legend_bg_opacity() { return this._legend_bg_opacity; }
    set legend_bg_opacity(value) { this._legend_bg_opacity = value; this.draw(); }
    get legend_show_dataTags() { return this._legend_show_dataTags; }
    set legend_show_dataTags(value) { this._legend_show_dataTags = value; this.draw(); }
    get node_label_separator() { return this._node_label_separator; }
    set node_label_separator(value) { this._node_label_separator = value; this.draw(); }
    get width() { return this._width; }
    set width(value) { this._width = value; this.draw(); }
    get info_link_value_void() { return this._info_link_value_void; }
    set info_link_value_void(value) { this._info_link_value_void = value; this.draw(); }
}
