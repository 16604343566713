// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Local types
import { Class_NodeDimension } from '../Elements/NodeDimension';
import { ClassAbstract_ProtoLevelTag, ClassAbstract_ProtoLevelTagGroup, ClassAbstract_ProtoTag, ClassAbstract_ProtoTagGroup } from '../types/Abstract';
import { default_grey_color, getBooleanFromJSON, getStringFromJSON, getStringListFromJSON, makeId } from '../types/Utils';
import colormap from 'colormap';
// CLASS PROTO TAG ***********************************************************************
/**
 * Class that define a Tag object
 * @class Class_Tag
 */
export class Class_ProtoTag extends ClassAbstract_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ProtoTag.
     * @param {string} name
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_ProtoTag
     */
    constructor(name, sankey, id = undefined) {
        super();
        // Color of tag
        this._color = default_grey_color;
        // Boolean
        this._is_selected = false;
        /**
         * True if tag is currently on a deletion process
         * Avoid cross calls of delete() method
         * @private
         * @memberof Class_Tag
         */
        this._is_currently_deleted = false;
        this._id = id !== null && id !== void 0 ? id : makeId(name);
        this._name = name;
        this._ref_sankey = sankey;
    }
    // CLEANING METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Unref this from tag group
            this.group.removeTag(this);
            // Clean the rest
            this.cleanForDeletion();
            // Garbage collection will do the rest
        }
    }
    // COPY METHODS =======================================================================
    /**
     * Copy given tag
     * @param {Class_ProtoTag} tag_to_copy
     * @memberof Class_ProtoTag
     */
    copyFrom(tag_to_copy) {
        // Get infos
        this._copyFrom(tag_to_copy);
    }
    /**
     * Overridable method to copy a given tag
     * @protected
     * @param {Class_ProtoTag} tag_to_copy
     * @memberof Class_ProtoTag
     */
    _copyFrom(tag_to_copy) {
        this._name = tag_to_copy._name;
        this._color = tag_to_copy._color;
        this._is_selected = tag_to_copy._is_selected;
        // Groups are switched from related group class
    }
    /**
     * Convert element to JSON
     * @param {Type_JSON} [kwargs]
     * @return {*}
     * @memberof Class_ProtoTag
     */
    toJSON(kwargs) {
        // Init output JSON
        const json_object = {};
        // Fill data
        this._toJSON(json_object, kwargs);
        // Return
        return json_object;
    }
    /**
     * Overridable method for JSON conversion
     * @protected
     * @param {Type_JSON} json_object
     * @param {Type_JSON} [_kwargs]
     * @memberof Class_ProtoTag
     */
    _toJSON(json_object, _kwargs) {
        json_object['name'] = this._name;
        json_object['selected'] = this._is_selected;
        json_object['color'] = this._color;
    }
    /**
     *
     *
     * @param {Type_JSON} json_object
     * @param {Type_JSON} [kwargs]
     * @memberof Class_ProtoTag
     */
    fromJSON(json_object, kwargs) {
        // Get infos
        this._fromJSON(json_object, kwargs);
    }
    /**
     * Set Tag value from JSON
     * @protected
     * @param {Type_JSON} json_object
     * @param {Type_JSON} [_kwargs]
     * @memberof Class_ProtoTag
     */
    _fromJSON(json_object, _kwargs) {
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._is_selected = getBooleanFromJSON(json_object, 'selected', false);
        this._color = getStringFromJSON(json_object, 'color', this._color);
    }
    // PUBLIC METHODES ==================================================================
    setSelected(update = true) {
        // Avoid useless update
        if (this._is_selected === false) {
            // Set attributes
            this._is_selected = true;
            // Update this fingerprint
            this.updateFingerprint();
            // Redraw all related elements
            if (update)
                this.update();
        }
    }
    setUnSelected(update = true) {
        // Avoid useless update
        if (this._is_selected === true) {
            // Set attributes
            this._is_selected = false;
            // Update this fingerprint
            this.updateFingerprint();
            // Redraw all related elements
            if (update)
                this.update();
        }
    }
    toogleSelected() {
        // Set attributes
        this._is_selected = !this._is_selected;
        // Redraw all related elements
        this.update();
    }
    // GETTERS / SETTERS ==================================================================
    get id() { return this._id; }
    get name() { return this._name; }
    set name(value) { this._name = value; }
    get color() { return this._color; }
    set color(value) {
        // Avoid useless updates
        if (this._color !== value) {
            // Set attributes
            this._color = value;
            // Redraw all related elements
            this.update();
        }
    }
    // Selection
    get is_selected() { return this._is_selected; }
    set is_selected(_) { this._is_selected = _; }
}
// CLASS TAG ****************************************************************************
/**
 * Class that define a Tag object
 * @class Class_Tag
 */
export class Class_Tag extends Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_Tag.
     * @param {string} name
     * @param {Class_TagGroup} group
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_DataTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._references = {};
        this._group = group;
    }
    // CLEANING METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Unref this tag from all references
        Object.values(this._references)
            .forEach(element => {
            element.removeTag(this);
        });
        this._references = {};
    }
    // PUBLIC METHODS =====================================================================
    update() {
        // Redraw elements
        Object.values(this._references)
            .forEach(element => {
            element.draw();
        });
        // Update legend
        this._ref_sankey.drawing_area.legend.draw();
    }
    hasGivenReference(_) {
        return (this._references[_.id] !== undefined);
    }
    addReference(_) {
        if (!this.hasGivenReference(_)) {
            this._references[_.id] = _;
            _.addTag(this);
        }
    }
    removeReference(_) {
        if (this.hasGivenReference(_)) {
            delete this._references[_.id];
            _.removeTag(this);
        }
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
    get references() { return Object.values(this._references); }
}
// CLASS NODETAG ****************************************************************************
/**
 * Class that define a node tag object
 * @class Class_Tag
 */
export class Class_NodeTag extends Class_Tag {
    // PROTECTED METHODS ==================================================================
    updateFingerprint() {
        this._ref_sankey.nodeTagsUpdated();
    }
}
// CLASS FLUXTAG ****************************************************************************
/**
 * Class that define a node tag object
 * @class Class_Tag
 */
export class Class_FluxTag extends Class_Tag {
    // PROTECTED METHODS ==================================================================
    updateFingerprint() {
        this._ref_sankey.fluxTagsUpdated();
    }
}
// CLASS DATATAG ************************************************************************
export class Class_DataTag extends Class_ProtoTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_DataTag.
     * @param {string} name
     * @param {Class_TagGroup} group
     * @param {ClassAbstract_Sankey} sankey
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_DataTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._references = {};
        this._group = group;
        this._references = sankey.links_dict;
        // Indicate that we will need to recompute visibility
        this._ref_sankey.dataTagsUpdated();
        // Update all links
        Object.values(this._references)
            .forEach(ref => ref.addDataTag(this));
    }
    // PUBLIC METHODS =====================================================================
    update() { } // Does nothing - never called
    setSelected() {
        // Avoid useless update
        if (this.is_selected === false) {
            // Set attributes
            this.is_selected = true;
            // Indicate that we will need to recompute visibility
            this.updateFingerprint();
        }
    }
    setUnSelected() {
        // Avoid useless update
        if (this.is_selected === true) {
            // Set attributes
            this.is_selected = false;
            // Indicate that we will need to recompute visibility
            this.updateFingerprint();
        }
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Update all links
        Object.values(this._references)
            .forEach(link => link.removeDataTag(this));
        // Indicate that we will need to recompute visibility
        this._ref_sankey.dataTagsUpdated();
        // Unref references
        this._references = {};
    }
    // PROTECTED METHODS ==================================================================
    updateFingerprint() {
        this._ref_sankey.dataTagsUpdated();
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
}
// CLASS PROTO LEVEL TAG ****************************************************************
/**
 * Class that define a Tag object
 * @class Class_Tag
 */
export class Class_ProtoLevelTag extends ClassAbstract_ProtoLevelTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ProtoTag.
     * @param {string} name
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_ProtoTag
     */
    constructor(name, sankey, id = undefined) {
        super();
        // Color of tag
        this._color = default_grey_color;
        // Boolean
        this._is_selected = false;
        /**
         * True if tag is currently on a deletion process
         * Avoid cross calls of delete() method
         * @private
         * @memberof Class_Tag
         */
        this._is_currently_deleted = false;
        this._id = id !== null && id !== void 0 ? id : makeId(name);
        this._name = name;
        this._ref_sankey = sankey;
    }
    // CLEANING METHODS ====================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Unref this from tag group
            this.group.removeTag(this);
            // Clean the rest
            this.cleanForDeletion();
            // Garbage collection will do the rest
        }
    }
    // COPY METHODS =====================================================================
    copyFrom(tag_to_copy) {
        // Get infos
        this._copyFrom(tag_to_copy);
    }
    _copyFrom(tag_to_copy) {
        this._name = tag_to_copy._name;
        this._color = tag_to_copy._color;
        this._is_selected = tag_to_copy._is_selected;
        // Groups are switched from related group class
    }
    toJSON(kwargs) {
        const json_object = {};
        this._toJSON(json_object, kwargs);
        return json_object;
    }
    _toJSON(json_object, _kwargs) {
        json_object['name'] = this._name;
        json_object['selected'] = this._is_selected;
        json_object['color'] = this._color;
    }
    fromJSON(json_object, kwargs) {
        // Get infos
        this._fromJSON(json_object, kwargs);
    }
    _fromJSON(json_object, _kwargs) {
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._is_selected = getBooleanFromJSON(json_object, 'selected', false);
        this._color = getStringFromJSON(json_object, 'color', this._color);
    }
    setSelected() {
        // Avoid useless update
        if (this._is_selected === false) {
            // Set attributes
            this._is_selected = true;
            // Redraw all related elements
            this.update();
        }
    }
    setUnSelected() {
        // run it even if this._is_selected is already false
        // as update hides force node
        // Set attributes
        this._is_selected = false;
        // Redraw all related elements
        this.update();
    }
    toogleSelected() {
        // Set attributes
        this._is_selected = !this._is_selected;
        // Redraw all related elements
        this.update();
    }
    // GETTERS / SETTERS ==================================================================
    get id() { return this._id; }
    get name() { return this._name; }
    set name(value) { this._name = value; }
    get color() { return this._color; }
    set color(value) {
        // Avoid useless updates
        if (this._color !== value) {
            // Set attributes
            this._color = value;
            // Redraw all related elements
            this.update();
        }
    }
    // Selection
    get is_selected() { return this._is_selected; }
    set is_selected(_) {
        // Only one level tag per group can be selected
        if (_ == true)
            this._group.tags_list.forEach(tag => tag.is_selected = false);
        this._is_selected = _;
    }
}
// CLASS LEVELTAG ***********************************************************************
export class Class_LevelTag extends Class_ProtoLevelTag {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_LevelTag.
     * @param {string} name
     * @param {Class_LevelTagGroup} group
     * @param {(string | undefined)} [id=undefined]
     * @memberof Class_LevelTag
     */
    constructor(name, group, sankey, id = undefined) {
        super(name, sankey, id);
        // PRIVATE ATTRIBUTES =================================================================
        // List of elements that relates to this tag
        this._dimensions_as_tag_for_parent = {};
        this._dimensions_as_tag_for_children = {};
        this._group = group;
    }
    // CLEANING METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof Class_Tag
     */
    cleanForDeletion() {
        // Need to delete references
        this.dimensions_list_as_tag_for_children
            .forEach(dim => dim.delete());
        this._dimensions_as_tag_for_children = {};
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => dim.delete());
        this._dimensions_as_tag_for_parent = {};
        // Let the garbage collector do the rest
    }
    // COPY METHODS =======================================================================
    // /**
    //  * Copy all attributes from input tags + Set the same refs
    //  *
    //  * @param {Class_DataTag} tag
    //  * @memberof Class_DataTag
    //  */
    // protected _copyFrom(tag_to_copy: Class_LevelTag) {
    //   // Copy herited attributes
    //   super._copyFrom(tag_to_copy)
    //   // Get all existing references ------------------------------------------------------
    //   // Create a dict of all existing node in this related sankey
    //   const all_existing_nodes = this._ref_sankey.nodes_dict
    //   // Create a dict of all existing dimensions in this related sankey
    //   const all_existing_dim: { [_: string]: Class_NodeDimension } = {}
    //   this._ref_sankey.level_taggs_list
    //     .forEach(tagg => {
    //       (tagg as Class_LevelTagGroup).tags_list
    //         .forEach(tag => {
    //           // Check children dimensions
    //           tag.dimensions_list_as_tag_for_children
    //             .filter(dim => !(dim.id in all_existing_dim))
    //             .forEach(dim => all_existing_dim[dim.id] = dim)
    //           // Check parent dimensions
    //           tag.dimensions_list_as_tag_for_parent
    //             .filter(dim => !(dim.id in all_existing_dim))
    //             .forEach(dim => all_existing_dim[dim.id] = dim)
    //         })
    //     })
    //   // Synchro dimensions where tag is for children -------------------------------------
    //   // Add missing but existing dimensions where this is a tag for children
    //   tag_to_copy.dimensions_list_as_tag_for_children
    //     .filter(dim => {
    //       return (
    //         (dim.id in all_existing_dim) &&
    //         !(dim.id in this._dimensions_as_tag_for_children)
    //       )
    //     })
    //     .forEach(dim => {
    //       this.addAsChildrenLevel(all_existing_dim[dim.id])
    //     })
    //   // Add missing and non-existing dimensions where this is a tag for children
    //   tag_to_copy.dimensions_list_as_tag_for_children
    //     .filter(dim => {
    //       // Verify if there is at least one child that exist in related sankey
    //       let at_least_one_match_for_children = false
    //       dim.children
    //         .forEach(child => at_least_one_match_for_children = (
    //           (at_least_one_match_for_children) ||
    //           (child.id in all_existing_nodes)))
    //       // And verify that parent also exists in related sankey
    //       // And that related tag for parent is in the same group
    //       return (
    //         !(dim.id in all_existing_dim) &&
    //         (dim.parent.id in all_existing_nodes) &&
    //         (at_least_one_match_for_children) &&
    //         (dim.parent_level_tag.id in this.group.tags_dict)
    //       )
    //     })
    //     .forEach(dim => {
    //       const parent = all_existing_nodes[dim.parent.id]
    //       const children = dim.children.map(_ => all_existing_nodes[_.id])
    //       const parent_level_tag = this.group.tags_dict[dim.parent_level_tag.id]
    //       const new_dim = new Class_NodeDimension(
    //         parent,
    //         children,
    //         parent_level_tag as ClassAbstract_ProtoLevelTag,
    //         [this] as ClassAbstract_ProtoLevelTag[],
    //         dim.id
    //       )
    //       this.addAsChildrenLevel(new_dim)
    //     })
    //   // Remove existing dimension where this tag is no more
    //   this.dimensions_list_as_tag_for_children
    //     .filter(dim => {
    //       return (
    //         !(dim.id in tag_to_copy._dimensions_as_tag_for_children)
    //       )
    //     })
    //     .forEach(dim => this.removeChildrenLevel(dim))
    //   // Synchro dimensions where tag is for parents --------------------------------------
    //   // Add missing but existing dimensions where this is a tag for parent
    //   tag_to_copy.dimensions_list_as_tag_for_parent
    //     .filter(dim => {
    //       return (
    //         (dim.id in all_existing_dim) &&
    //         !(dim.id in this._dimensions_as_tag_for_parent)
    //       )
    //     })
    //     .forEach(dim => {
    //       this.addAsParentLevel(all_existing_dim[dim.id])
    //     })
    //   // Add missing and non-existing dimensions where this is a tag for parent
    //   tag_to_copy.dimensions_list_as_tag_for_parent
    //     .filter(dim => {
    //       // Verify if there is at least one child that exist in related sankey
    //       let ok_for_children_nodes = false
    //       dim.children
    //         .forEach(child => ok_for_children_nodes = (
    //           (ok_for_children_nodes) ||
    //           (child.id in all_existing_nodes)))
    //       // And that related tag for parent is in the same group
    //       let ok_children_level_tags = false
    //       dim.children_level_tags
    //         .forEach(tag => ok_children_level_tags = (
    //           (ok_children_level_tags) ||
    //           (tag.id in this.group.tags_dict)
    //         ))
    //       // And verify that parent also exists in related sankey
    //       return (
    //         !(dim.id in all_existing_dim) &&
    //         (dim.parent.id in all_existing_nodes) &&
    //         (ok_for_children_nodes) &&
    //         (ok_children_level_tags)
    //       )
    //     })
    //     .forEach(dim => {
    //       const parent = all_existing_nodes[dim.parent.id]
    //       const children = dim.children.map(_ => all_existing_nodes[_.id])
    //       const children_level_tag = dim.children_level_tags
    //         .filter(tag => tag.id in this.group.tags_dict)
    //         .map(tag => this.group.tags_dict[tag.id])
    //       const new_dim = new Class_NodeDimension(
    //         parent,
    //         children,
    //         this as ClassAbstract_ProtoLevelTag,
    //         children_level_tag,
    //         dim.id
    //       )
    //       this.addAsParentLevel(new_dim)
    //     })
    //   // Remove existing dimension where this tag is no more
    //   this.dimensions_list_as_tag_for_parent
    //     .filter(dim => {
    //       return (
    //         !(dim.id in tag_to_copy._dimensions_as_tag_for_parent)
    //       )
    //     })
    //     .forEach(dim => this.removeParentLevel(dim))
    // }
    // PUBLIC METHODS =====================================================================
    setSelected() {
        // Exclude other levels tags from selection and reinit dimension to default dehavior
        this._group.tags_list
            .filter(tag => tag !== this)
            .forEach(tag => tag.setUnSelected());
        // Apply selection
        super.setSelected();
    }
    setUnSelected() {
        // Reinit dimension to default dehavior
        // Apply unselection
        super.setUnSelected();
    }
    update() {
        this.dimensions_list_as_tag_for_children
            .forEach(dim => {
            if (dim.parent_level_tag.group.activated) {
                dim.showAccordingToLevelTags();
            }
        });
        this.dimensions_list_as_tag_for_parent
            .forEach(dim => {
            if (dim.parent_level_tag.group.activated) {
                dim.showAccordingToLevelTags();
            }
        });
    }
    getOrCreateLowerDimension(parent, child, child_tag) {
        // Try to find matching dimension with :
        // - this as parent tag
        // - input child_tag as child tag
        // - parent node as parent
        let dimension_found;
        this.dimensions_list_as_tag_for_parent
            .forEach(dimension => {
            // Match dimension if all these conditions are true
            // - Parent are the same
            // - Parent level tags are the same
            // - child level tag is the same
            if ((dimension.parent_level_tag === this) &&
                (dimension.child_level_tag == child_tag) &&
                (dimension.parent === parent)) {
                dimension_found = dimension;
            }
        });
        // If found - just add child
        if (dimension_found) {
            dimension_found.addNodeAsChild(child);
        }
        // If no dimension has been found, create a new one
        else {
            dimension_found = new Class_NodeDimension(parent, [child], this, child_tag);
        }
        return dimension_found;
    }
    isLevelForChildren(_) {
        return (this._dimensions_as_tag_for_children[_.id] !== undefined);
    }
    isLevelForParent(_) {
        return (this._dimensions_as_tag_for_parent[_.id] !== undefined);
    }
    addAsChildrenLevel(_) {
        if (!this.isLevelForChildren(_)) {
            this._dimensions_as_tag_for_children[_.id] = _;
            _.child_level_tag = this;
        }
    }
    addAsParentLevel(_) {
        if (!this.isLevelForParent(_)) {
            this._dimensions_as_tag_for_parent[_.id] = _;
            _.parent_level_tag = this;
        }
    }
    removeChildrenLevel(_) {
        if (this.isLevelForChildren(_)) {
            delete this._dimensions_as_tag_for_children[_.id];
            _.delete();
        }
    }
    removeParentLevel(_) {
        if (this.isLevelForParent(_)) {
            delete this._dimensions_as_tag_for_parent[_.id];
            _.delete();
        }
    }
    // GETTERS ============================================================================
    get group() { return this._group; }
    get has_upper_dimensions() {
        return (this.dimensions_list_as_tag_for_children.length > 0);
    }
    get has_lower_dimensions() {
        return (this.dimensions_list_as_tag_for_parent.length > 0);
    }
    get dimensions_list_as_tag_for_parent() {
        return Object.values(this._dimensions_as_tag_for_parent);
    }
    get dimensions_list_as_tag_for_children() {
        return Object.values(this._dimensions_as_tag_for_children);
    }
}
// CLASS PROTO TAGGROUP *****************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_ProtoTagGroup extends ClassAbstract_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey) {
        super();
        // List of tags
        this._tag_count = 0;
        // Type of banne
        this._banner = 'one';
        /**
         * True if tag is currently on a deletion process
         * Avoid infinite calls of delete() method
         * @private
         * @memberof Class_TagGroup
         */
        this._is_currently_deleted = false;
        this._id = id;
        this._name = name;
        this._ref_sankey = sankey;
    }
    // CLEANING METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof Class_ProtoTagGroup
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Delete all tags properly
            Object.values(this._tags)
                .forEach(tag => {
                tag.delete();
            });
            this._tags = {};
            // Garbage collection will do the rest ...
        }
    }
    // COPY METHODS =======================================================================
    copyFrom(tagg_to_copy, matching_tags_id = {}) {
        this._copyFrom(tagg_to_copy, matching_tags_id);
    }
    _copyFrom(tagg_to_copy, matching_tags_id = {}) {
        const revert_matching_id = {};
        Object.entries(matching_tags_id).forEach(([k, v]) => revert_matching_id[v] = k);
        // Common attributes
        this._name = tagg_to_copy._name;
        this._banner = tagg_to_copy._banner;
        this._tag_count = tagg_to_copy._tag_count;
        // Synchro current tags
        this.tags_list
            .forEach(tag => {
            var _a, _b;
            // Delete tags not present in new layout but present in curr
            if (!(((_a = matching_tags_id[tag.id]) !== null && _a !== void 0 ? _a : tag.id) in tagg_to_copy.tags_dict))
                this.removeTag(tag);
            // Transfer tags attr present in new layout and in curr
            else
                tag.copyFrom(tagg_to_copy.tags_dict[((_b = matching_tags_id[tag.id]) !== null && _b !== void 0 ? _b : tag.id)]);
        });
        // Add missing tags
        tagg_to_copy.tags_list
            .forEach(tag => {
            var _a;
            if (!(((_a = revert_matching_id[tag.id]) !== null && _a !== void 0 ? _a : tag.id) in this.tags_dict))
                this.addTag(tag.name, tag.id).copyFrom(tag);
        });
    }
    toJSON(kwargs) {
        // Create empty structs
        const json_object = {};
        this._toJSON(json_object, kwargs);
        return json_object;
    }
    _toJSON(json_object, _kwargs) {
        // Fill group attributes
        json_object['name'] = this._name;
        json_object['banner'] = this._banner;
        // Update tags infos
        const json_object_tags = {};
        this.tags_list
            .forEach(tag => {
            json_object_tags[tag.id] = tag.toJSON();
        });
        json_object['tags'] = json_object_tags;
    }
    fromJSON(json_object, kwargs) {
        this._fromJSON(json_object, kwargs);
    }
    _fromJSON(json_object, kwargs) {
        // Read legacy JSON
        this.fromLegacyJSON(json_object);
        // Read group attributes
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._banner = getStringFromJSON(json_object, 'banner', this._banner);
        // Create new tags & read their attributes
        const matching_tags_id = (kwargs && kwargs['matching_tags_id']) ? kwargs['matching_tags_id'] : {};
        Object.entries(json_object['tags'])
            .forEach(([_, tag_json]) => {
            var _a, _b;
            // Get or Create tag
            const tag_id = (_a = matching_tags_id[_]) !== null && _a !== void 0 ? _a : _;
            const tag = (_b = this._tags[_]) !== null && _b !== void 0 ? _b : this.addTag(tag_id, tag_id); // Tag will be renamed in fromJSON method
            // Update tag with json
            tag.fromJSON(tag_json);
        });
    }
    fromLegacyJSON(json_object) {
        this._name = getStringFromJSON(json_object, 'group_name', this._name);
    }
    // PUBLIC METHODS =====================================================================
    addTag(name, id = undefined) {
        const tag = this.createTag(name, id);
        this._tags[tag.id] = tag;
        this._tag_count = this._tag_count + 1;
        return tag;
    }
    addDefaultTag() {
        const n = String(this._tag_count);
        const name = 'Etiquette ' + n;
        this.addTag(name);
    }
    removeTag(_) {
        if (this._tags[_.id] !== undefined) {
            _.delete();
            delete this._tags[_.id];
        }
    }
    selectTagsFromId(id) {
        const _selectTagsFromId = (_) => {
            this.tags_list
                .forEach(tag => {
                if (tag.id === _) {
                    tag.setSelected();
                }
                else {
                    tag.setUnSelected();
                }
            });
            this.updateTagsReferences();
            this._ref_sankey.drawing_area.application_data.menu_configuration.updateAllComponentsRelatedToTags();
        };
        const old_selected = this.selected_tags_list[0].id;
        this._ref_sankey.drawing_area.application_data.history.saveUndo(() => _selectTagsFromId(old_selected));
        this._ref_sankey.drawing_area.application_data.history.saveRedo(() => _selectTagsFromId(id));
        _selectTagsFromId(id);
    }
    selectTagsFromIds(ids) {
        this.tags_list
            .forEach(tag => {
            if (ids.includes(tag.id)) {
                tag.setSelected(false);
            }
            else {
                tag.setUnSelected(false);
            }
        });
        this.updateTagsReferences();
    }
    // GETTERS ============================================================================
    /**
     * Id of tag group
     * @readonly
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get id() { return this._id; }
    /**
     * Name of tag group (!= id)
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get name() { return this._name; }
    /**
     * True if tag group has tags
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_tags() { return this.tags_list.length > 0; }
    /**
     * True if tag group has tags selected
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_selected_tags() { return this.selected_tags_list.length > 0; }
    get first_selected_tags() {
        if (this.has_tags)
            if (this.has_selected_tags)
                return this.selected_tags_list[0];
            else
                return this.tags_list[0];
        else
            return undefined;
    }
    get banner() { return this._banner; }
    // SETTERS ============================================================================
    set name(value) { this._name = value; }
    set banner(value) { this._banner = value; }
}
// CLASS TAGGROUP ***********************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_TagGroup extends Class_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey) {
        super(id, name, sankey);
        // PRIVATE ATTRIBUTES =================================================================
        // Display attributes
        this._show_legend = false;
        // Default banner as multi
        this.banner = 'multi';
    }
    // CLEANING METHODS ==================================================================
    // COPY METHODS =====================================================================
    _toJSON(json_object, kwargs) {
        super._toJSON(json_object, kwargs);
        json_object['show_legend'] = this._show_legend;
    }
    _fromJSON(json_object, kwargs) {
        super._fromJSON(json_object, kwargs);
        this._show_legend = getBooleanFromJSON(json_object, 'show_legend', this._show_legend);
        const nb_tags = Object.values(this._tags).length;
        if (Object.values(this._tags).filter(tag => tag.color != '').length == 0) {
            // if tags has no colors they are generated from the defaut color map
            const colors = colormap({
                colormap: 'jet',
                nshades: Math.max(11, nb_tags),
                format: 'hex',
                alpha: 1
            });
            let step = 1;
            if (nb_tags < 11) {
                // colormap is sampled uniformly between the first index and the last
                step = Math.round(11 / nb_tags);
            }
            Object.values(this._tags).forEach((tag, i) => tag.color = colors[i * step]);
        }
    }
    _copyFrom(tagg_to_copy, matching_tags_id = {}) {
        super._copyFrom(tagg_to_copy, matching_tags_id);
        this._show_legend = tagg_to_copy.show_legend;
    }
    // PUBLIC METHODS =====================================================================
    updateTagsReferences() {
        const ref_updated = [];
        Object.values(this._tags)
            .forEach(tag => {
            tag.references
                .forEach(ref => {
                if (ref_updated.indexOf(ref) < 0) {
                    ref.draw();
                    ref_updated.push(ref);
                }
            });
        });
        this._ref_sankey.drawing_area.checkAndUpdateAreaSize();
    }
    // GETTER =============================================================================
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_Tag }}
     * @memberof Class_TagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_Tag }}
     * @memberof Class_TagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_TagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
    get show_legend() { return this._show_legend; }
    // SETTER =============================================================================
    set show_legend(value) {
        // Avoid useless updates
        if (this._show_legend !== value) {
            this._show_legend = value;
            this.updateTagsReferences();
        }
    }
}
// CLASS NODETAGGROUP *******************************************************************
/**
 * Class that define a Node TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_NodeTagGroup extends Class_TagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey, with_a_tag = true) {
        super(id, name, sankey);
        // Init dict of tags
        this._tags = {};
        // Create a first default tag
        if (with_a_tag)
            this.addTag('Etiquette 0');
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        const tag = new Class_NodeTag(name, this, this._ref_sankey, id);
        tag.setSelected();
        return tag;
    }
}
// CLASS FLUXTAGGROUP *******************************************************************
/**
 * Class that define a Flux TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_FluxTagGroup extends Class_TagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey, with_a_tag = true) {
        super(id, name, sankey);
        // Init dict of tags
        this._tags = {};
        // Create a first default tag
        if (with_a_tag)
            this.addTag('Etiquette 0');
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        const tag = new Class_FluxTag(name, this, this._ref_sankey, id);
        tag.setSelected();
        return tag;
    }
}
// CLASS DATATAGGROUP *******************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_DataTagGroup extends Class_ProtoTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey, with_a_tag = true) {
        super(id, name, sankey);
        // PRIVATE ATTRIBUTES =================================================================
        // Display attributes
        this._show_legend = false;
        this._is_sequence = false;
        // Init dict of tags
        this._tags = {};
        // Create and select a first default tag
        if (with_a_tag) {
            const tag = this.addTag('Etiquette 0');
            tag.setSelected();
        }
    }
    // COPY METHODS =======================================================================
    _copyFrom(tagg_to_copy) {
        super._copyFrom(tagg_to_copy);
        this._show_legend = tagg_to_copy.show_legend;
    }
    _toJSON(json_object, kwargs) {
        super._toJSON(json_object, kwargs);
        json_object['show_legend'] = this._show_legend;
        json_object['is_sequence'] = this._is_sequence;
    }
    _fromJSON(json_object, kwargs) {
        super._fromJSON(json_object, kwargs);
        this._show_legend = getBooleanFromJSON(json_object, 'show_legend', this._show_legend);
        this._is_sequence = getBooleanFromJSON(json_object, 'is_sequence', this._is_sequence);
    }
    // PUBLIC METHODS =====================================================================
    selectTagsFromId(id) {
        const old_selected = this.selected_tags_list[0].id;
        const _selectTagsFromId = (_) => {
            this.tags_list
                .forEach(tag => {
                if (tag.id === _) {
                    tag.setSelected();
                }
                else {
                    tag.setUnSelected();
                }
            });
            this.checkSelectionCoherence();
            this.updateTagsReferences();
            this._ref_sankey.drawing_area.application_data.menu_configuration.updateAllComponentsRelatedToDataTags();
        };
        this._ref_sankey.drawing_area.application_data.history.saveUndo(() => _selectTagsFromId(old_selected));
        this._ref_sankey.drawing_area.application_data.history.saveRedo(() => _selectTagsFromId(id));
        _selectTagsFromId(id);
    }
    selectTagsFromIds(ids) {
        this.tags_list
            .forEach(tag => {
            if (ids.includes(tag.id)) {
                tag.setSelected();
            }
            else {
                tag.setUnSelected();
            }
        });
        this.checkSelectionCoherence();
        this.updateTagsReferences();
    }
    updateTagsReferences() {
        // On datatags update everything is impacted
        this._ref_sankey.drawing_area.draw();
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        return new Class_DataTag(name, this, this._ref_sankey, id);
    }
    // PRIVATE METHODES ===================================================================
    /**
     * Permet d'eviter de désélectionner tous les dataTags ce qui créerait une erreur
     * @private
     * @memberof Class_DataTagGroup
     */
    checkSelectionCoherence() {
        var _a;
        if (this.selected_tags_list.length === 0) {
            (_a = this.tags_list[0]) === null || _a === void 0 ? void 0 : _a.setSelected();
        }
    }
    // GETTER =============================================================================
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_DataTag }}
     * @memberof Class_DataTagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {Class_DataTag[]}
     * @memberof Class_DataTagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_DataTagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
    get show_legend() { return this._show_legend; }
    get is_sequence() { return this._is_sequence; }
    // SETTER ==============================================================================
    set show_legend(value) {
        // Avoid useless updates
        if (this._show_legend !== value) {
            this._show_legend = value;
            this.updateTagsReferences();
        }
    }
    set is_sequence(value) { this._is_sequence = value; }
}
// CLASS PROTO TAGGROUP *****************************************************************
/**
 * Class that define a TagGroup object
 * @export
 * @class Class_TagGroup
 */
export class Class_ProtoLevelTagGroup extends ClassAbstract_ProtoLevelTagGroup {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_TagGroup.
     * @param {string} id
     * @param {string} name
     * @memberof Class_TagGroup
     */
    constructor(id, name, sankey) {
        super();
        // List of tags
        this._tag_count = 0;
        // Type of banne
        this._banner = 'one';
        /**
         * True if tag is currently on a deletion process
         * Avoid infinite calls of delete() method
         * @private
         * @memberof Class_TagGroup
         */
        this._is_currently_deleted = false;
        this._id = id;
        this._name = name;
        this._ref_sankey = sankey;
    }
    // CLEANING METHODS ====================================================================
    /**
     * Define deletion behavior
     * @memberof Class_ProtoTagGroup
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Delete all tags properly
            Object.values(this._tags)
                .forEach(tag => {
                tag.delete();
            });
            this._tags = {};
            // Garbage collection will do the rest ...
        }
    }
    // COPY METHODS ========================================================================
    copyFrom(tagg_to_copy) {
        this._copyFrom(tagg_to_copy);
    }
    _copyFrom(tagg_to_copy) {
        // Common attributes
        this._name = tagg_to_copy._name;
        this._banner = tagg_to_copy._banner;
        this._tag_count = tagg_to_copy._tag_count;
        // Synchronize tags
        // Synchronise current tag group's tag with tag groupto copy
        this.tags_list
            .forEach(tag => {
            // Delete tags not present in new layout but present in curr
            if (!(tag.id in tagg_to_copy.tags_dict))
                this.removeTag(tag);
            // Transfer tags attr present in new layout and in curr
            else
                tag.copyFrom(tagg_to_copy.tags_dict[tag.id]);
        });
        // Add tag present in tagg_to_copy but not this
        tagg_to_copy.tags_list
            .forEach(tag => {
            if (!(tag.id in this.tags_dict))
                this.addTag(tag.name, tag.id).copyFrom(tag);
        });
    }
    toJSON(kwargs) {
        const json_object = {};
        this._toJSON(json_object, kwargs);
        return json_object;
    }
    _toJSON(json_object, _kwargs) {
        // Fill group attributes
        json_object['name'] = this._name;
        json_object['banner'] = this._banner;
        // Update tags infos
        const json_object_tags = {};
        this.tags_list
            .forEach(tag => {
            json_object_tags[tag.id] = tag.toJSON();
        });
        json_object['tags'] = json_object_tags;
    }
    fromJSON(json_object, kwargs) {
        this._fromJSON(json_object, kwargs);
    }
    _fromJSON(json_object, kwargs) {
        // Read legacy JSON
        this.fromLegacyJSON(json_object);
        // Read group attributes
        this._name = getStringFromJSON(json_object, 'name', this._name);
        this._banner = getStringFromJSON(json_object, 'banner', this._banner);
        // Create new tags & read their attributes
        const matching_tags_id = (kwargs && kwargs['matching_tags_id']) ? kwargs['matching_tags_id'] : {};
        Object.entries(json_object['tags'])
            .forEach(([_, tag_json]) => {
            var _a, _b;
            // Get or Create tag
            const tag_id = (_a = matching_tags_id[_]) !== null && _a !== void 0 ? _a : _;
            const tag = (_b = this._tags[_]) !== null && _b !== void 0 ? _b : this.addTag(tag_id, tag_id); // Tag will be renamed in fromJSON method
            // Update tag with json
            tag.fromJSON(tag_json);
        });
    }
    fromLegacyJSON(json_object) {
        this._name = getStringFromJSON(json_object, 'group_name', this._name);
    }
    // PUBLIC METHODS =====================================================================
    addTag(name, id = undefined) {
        const tag = this.createTag(name, id);
        this._tags[tag.id] = tag;
        this._tag_count = this._tag_count + 1;
        return tag;
    }
    addDefaultTag() {
        const n = String(this._tag_count);
        const name = 'Etiquette ' + n;
        return this.addTag(name);
    }
    removeTag(_) {
        if (this._tags[_.id] !== undefined) {
            _.delete();
            delete this._tags[_.id];
        }
    }
    selectTagsFromId(id) {
        // Change selection but do not redraw
        const _selectTagsFromId = (_) => {
            this.tags_list
                .forEach(tag => {
                if (tag.id === _) {
                    tag.setSelected();
                }
                else {
                    tag.setUnSelected();
                }
            });
            this._ref_sankey.drawing_area.application_data.menu_configuration.updateAllComponentsRelatedToLevelTags();
        };
        const old_selected = this.selected_tags_list[0].id;
        this._ref_sankey.drawing_area.application_data.history.saveUndo(() => _selectTagsFromId(old_selected));
        this._ref_sankey.drawing_area.application_data.history.saveRedo(() => _selectTagsFromId(id));
        _selectTagsFromId(id);
    }
    selectTagsFromIds(ids) {
        // Change selection but do not redraw
        this.tags_list
            .forEach(tag => {
            if (ids.includes(tag.id)) {
                tag.setSelected();
            }
            else {
                tag.setUnSelected();
            }
        });
    }
    // GETTERS ============================================================================
    /**
     * Id of tag group
     * @readonly
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get id() { return this._id; }
    /**
     * Name of tag group (!= id)
     * @type {string}
     * @memberof Class_ProtoTagGroup
     */
    get name() { return this._name; }
    /**
     * True if tag group has tags
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_tags() { return this.tags_list.length > 0; }
    /**
     * True if tag group has tags selected
     * @readonly
     * @memberof Class_ProtoTagGroup
     */
    get has_selected_tags() { return this.selected_tags_list.length > 0; }
    get first_selected_tags() {
        if (this.has_tags)
            if (this.has_selected_tags)
                return this.selected_tags_list[0];
            else
                return this.tags_list[0];
        else
            return undefined;
    }
    get banner() { return this._banner; }
    // SETTERS ============================================================================
    set name(value) { this._name = value; }
    set banner(value) { this._banner = value; }
}
// CLASS LEVEL TAGGROUP *****************************************************************
/**
 * Tag group for node level
 * TODO fonctionnement à completer // dimension dans nodes
 * @export
 * @class Class_LevelTagGroup
 * @extends {Class_TagGroup}
 */
export class Class_LevelTagGroup extends Class_ProtoLevelTagGroup {
    constructor() {
        // PROTECTED ATTRIBUTES ===============================================================
        super(...arguments);
        this._tags = {};
        // PRIVATE ATTRIBUTES==================================================================
        this._activated = false;
        this._siblings = [];
        this._antitagged_refs = [];
    }
    // CLEANING METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof Class_ProtoTagGroup
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Super deletion
            super.delete();
            // Unref antitags
            this._antitagged_refs.forEach(ref => this.removeAntiTaggedRef(ref));
            this._antitagged_refs = [];
        }
    }
    // COPY METHODS =======================================================================
    _copyFrom(tagg_to_copy) {
        super._copyFrom(tagg_to_copy);
        this._activated = tagg_to_copy._activated;
        this._siblings = tagg_to_copy._siblings;
    }
    _toJSON(json_object, kwargs) {
        super._toJSON(json_object, kwargs);
        json_object['activated'] = this._activated;
        json_object['siblings'] = this._siblings;
    }
    _fromJSON(json_object, kwargs) {
        super._fromJSON(json_object, kwargs);
        this._activated = getBooleanFromJSON(json_object, 'activated', this._activated);
        this._siblings = getStringListFromJSON(json_object, 'siblings', this._siblings);
    }
    // PUBLIC METHODS =====================================================================
    sibling_activated() {
        return this._siblings.filter(tagg => {
            return this._ref_sankey.level_taggs_dict[tagg].activated;
        }).map(tagg => this._ref_sankey.level_taggs_dict[tagg]);
    }
    addAntiTaggedRef(_) {
        if (!this._antitagged_refs.includes(_)) {
            this._antitagged_refs.push(_);
            _.addAsAntiTagged(this);
        }
    }
    removeAntiTaggedRef(_) {
        if (this._antitagged_refs.includes(_)) {
            const idx = this._antitagged_refs.indexOf(_);
            this._antitagged_refs.splice(idx, 1);
            _.removeAsAntiTagged(this);
        }
    }
    /**
     * Function to add sibling to current group and referenced group,
     * because they mutually interact at some mechanic
     *
     * @param {Class_LevelTagGroup} _
     * @memberof Class_LevelTagGroup
     */
    addSibling(_) {
        // Add antagonist grp id to sibling
        if (!this._siblings.includes(_.id)) {
            this._siblings.push(_.id);
        }
        // Add this grp id to sibling antagonist list
        if (!_._siblings.includes(this.id)) {
            _._siblings.push(this.id);
        }
    }
    /**
     * Function to remove sibling to current group and referenced group,
     * because they mutually interact at some mechanic
     *
     * @param {Class_LevelTagGroup} _
     * @memberof Class_LevelTagGroup
     */
    removeSibling(_) {
        // remove antagonist grp id from sibling
        if (this._siblings.includes(_.id)) {
            const idx = this._siblings.indexOf(_.id);
            this._siblings.splice(idx, 1);
        }
        // remove this grp id from sibling antagonist list
        if (_._siblings.includes(this.id)) {
            const idx = _._siblings.indexOf(this.id);
            _._siblings.splice(idx, 1);
        }
    }
    // PROTECTED METHODS ==================================================================
    createTag(name, id = undefined) {
        const tag = new Class_LevelTag(name, this, this._ref_sankey, id);
        tag.setUnSelected();
        return tag;
    }
    // GETTERS / SETTERS ==================================================================
    get activated() { return this._activated; }
    set activated(value) {
        // Avoid useless updates
        if (this._activated !== value) {
            this._activated = value;
            if (this._activated === true) {
                this._siblings
                    .forEach(sib_tagg_id => {
                    if (this._ref_sankey.level_taggs_dict[sib_tagg_id])
                        this._ref_sankey.level_taggs_dict[sib_tagg_id].activated = false;
                });
            }
            this._ref_sankey.draw();
        }
    }
    get siblings() { return this._siblings; }
    set siblings(value) {
        this._siblings = value;
        this._ref_sankey.draw();
    }
    /**
     * Return dict tag from the current group
     * @type {{ [_: string]: Class_DataTag }}
     * @memberof Class_DataTagGroup
     */
    get tags_dict() { return this._tags; }
    /**
     * Return dict tag from the current group
     * @type {Class_DataTag[]}
     * @memberof Class_DataTagGroup
     */
    get tags_list() { return Object.values(this.tags_dict); }
    /**
     * Return list of selected tag from the current group
     * @readonly
     * @memberof Class_DataTagGroup
     */
    get selected_tags_list() { return this.tags_list.filter(t => t.is_selected); }
    get antitagged_refs() { return this._antitagged_refs; }
}
