// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports =================================================================================
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
// OpenSankey imports ===============================================================================
import OpenSankeyApp from './deps/OpenSankey+/deps/OpenSankey/App';
import { initializeMenuConfiguration } from './deps/OpenSankey+/deps/OpenSankey/Modules';
import { ClickSaveDiagram } from './deps/OpenSankey+/deps/OpenSankey/components/dialogs/SankeyPersistence';
// OpenSankey+ imports ====================================================================================
import { initializeDiagrammSelectorOSP, } from './deps/OpenSankey+/ModulesOSP';
import { ModalWelcomeBuilderOSP } from './deps/OpenSankey+/components/welcome/ModalWelcomeOSP';
import { ThemeOSP } from './deps/OpenSankey+/chakra/ThemeOSP';
// Local imports ====================================================================================
import { initializeAdditionalMenusMFA, initializeApplicationDataMFA, initializeReinitializationMFA, ModuleDialogsAFM } from './ModulesAFM';
export const MFASankeyApp = ({ new_data_app }) => {
    return React.createElement(ChakraProvider, { theme: ThemeOSP },
        React.createElement(OpenSankeyApp, { initializeReinitialization: initializeReinitializationMFA, initializeApplicationData: (initial_data) => {
                return initializeApplicationDataMFA(new_data_app, initial_data);
            }, initializeMenuConfiguration: initializeMenuConfiguration, initializeAdditionalMenus: initializeAdditionalMenusMFA, initializeDiagrammSelector: initializeDiagrammSelectorOSP, moduleDialogs: ModuleDialogsAFM, ModalWelcome: ModalWelcomeBuilderOSP, ClickSaveDiagram: (new_data) => { ClickSaveDiagram(new_data); } }));
};
