// Standard libs
import React, { useState } from 'react';
// OpenSankey libs
import { ModalWelcome, ModalWelcomeContent } from '../../deps/OpenSankey/components/welcome/ModalWelcome';
import { AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
const ShortcutsOSP = (new_data_plus, page_content) => {
    var _a;
    const { t } = new_data_plus;
    page_content['rc'] = React.createElement(React.Fragment, null, (_a = page_content['rc']) !== null && _a !== void 0 ? _a : React.createElement(React.Fragment, null),
        React.createElement(AccordionItem, null,
            React.createElement(AccordionButton, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.rcc_titre_princ')),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Box, { display: 'grid', gridTemplateColumns: '50% 50%', gridColumnGap: '0.25rem', width: '100%', justifySelf: 'center' },
                    React.createElement(Table, { variant: 'table_welcome_buttons' },
                        React.createElement(Thead, null,
                            React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_OSP'))),
                        React.createElement(Tbody, null,
                            React.createElement(Tr, null,
                                React.createElement(Td, null, t('Menu.rcc_osp_cs_bold')),
                                React.createElement(Td, null, t('Menu.rcc_osp_cs'))),
                            React.createElement(Tr, null,
                                React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt_bold')),
                                React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt'))),
                            React.createElement(Tr, null,
                                React.createElement(Td, null, t('Menu.rcc_F7_bold')),
                                React.createElement(Td, null, t('Menu.rcc_F7'))),
                            React.createElement(Tr, null,
                                React.createElement(Td, null, t('Menu.rcc_F8_bold')),
                                React.createElement(Td, null, t('Menu.rcc_F8'))),
                            React.createElement(Tr, null,
                                React.createElement(Td, null, t('Menu.rcc_F9_bold')),
                                React.createElement(Td, null, t('Menu.rcc_F9')))))))));
};
// const LicenceOSP = (
//   new_data_plus: Type_GenericApplicationDataOSP,
//   page_content: { [_: string]: JSX.Element }
// ) => {
//   const { t, logo_sankey_plus } = new_data_plus
//   page_content['licence'] = <React.Fragment>
//     {page_content['licence'] ?? <></>}
//     {/* OpenSankey+ */}
//     <Box layerStyle='welcome_license_row' background='gray.50'>
//       <Box>
//         <img
//           src={logo_sankey_plus}
//           alt='logo_OSP'
//           style={{ 'objectFit': 'contain', 'width': '225px' }}
//         />
//       </Box>
//       <Box layerStyle='welcome_license_desc'>
//         {t('Menu.presentation_OSP')}
//         <Button variant='welcome_button_license_description' onClick={() => {
//           window.open('https://terriflux.com/downloads/open-sankey-plus/')
//         }}>
//           {t('desire_to_know_more')}
//         </Button>
//       </Box>
//     </Box>
//   </React.Fragment>
// }
export const ModalWelcomeBuilderOSP = ({ new_data }) => {
    const [, setCount] = useState(0);
    new_data.menu_configuration.dict_setter_show_dialog.ref_setter_modal_welcome_active_page.current = () => setCount(a => a + 1);
    const [page_links, page_content] = ModalWelcomeContent(new_data);
    ShortcutsOSP(new_data, page_content);
    // if (!new_data.is_static) {
    //   LicenceOSP(
    //     new_data as Type_GenericApplicationDataOSP,
    //     page_content
    //   )
    // }
    return React.createElement(ModalWelcome, { new_data: new_data, external_pagination: page_links, external_content: page_content });
};
