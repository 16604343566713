var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// External imports
import React, { useState } from 'react';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { parseLocaleNumber } from '../../types/Utils';
import { defaultLinkId } from '../../Elements/Link';
// Main SpreadSheet component
export const SpreadSheet = ({ new_data }) => {
    // Extract flux data from the Sankey diagram and prepare it for the spreadsheet
    const getFluxFromSankey = () => {
        const a = new_data.drawing_area.sankey.links_list.map((l) => {
            return {
                source: l.source.name, // Get source node name
                target: l.target.name, // Get target node name
                value: l.data_value, // Get the value of the link
            };
        });
        // Add an empty row for new flux input
        a.push({ source: '', target: '' });
        return a;
    };
    // Map node and link names to their IDs for quick lookups
    const name2id = {};
    new_data.drawing_area.sankey.nodes_list.forEach(n => { name2id[n.name] = n.id; });
    new_data.drawing_area.sankey.links_list.forEach(l => { name2id[defaultLinkId(l.source, l.target)] = l.id; });
    // Define the spreadsheet columns and their properties
    const [columns, setColumns] = useState([
        { columnId: 'source', width: 150, resizable: true },
        { columnId: 'target', width: 150, resizable: true },
        { columnId: 'value', width: 150, resizable: true }
    ]);
    // State to hold the flux data displayed in the spreadsheet
    const [spreadSheetFlux, setSpreadSheetFlux] = useState(getFluxFromSankey());
    // Function to synchronize spreadsheet data with Sankey data
    const synchronizeSpreadSheetWithSankey = () => setSpreadSheetFlux(getFluxFromSankey());
    // Provide a reference to update the spreadsheet externally
    new_data.menu_configuration.ref_to_spreadsheet.current = () => {
        synchronizeSpreadSheetWithSankey();
    };
    // Function to add a new node to the Sankey diagram
    const addNode = (name) => {
        const new_node = new_data.drawing_area.addNewDefaultNodeToSankey();
        new_node.name = name; // Set the name of the new node
        return new_node;
    };
    // Function to add a new link (flux) between two nodes
    const addLink = (cur_flux) => {
        const source_name = cur_flux.source.trim();
        const target_name = cur_flux.target.trim();
        // Skip if source or target is empty
        if (source_name === '' || target_name === '') {
            return;
        }
        // Retrieve or create the source node
        let source_node;
        if (new_data.drawing_area.sankey.nodes_dict[name2id[source_name]]) {
            source_node = new_data.drawing_area.sankey.nodes_dict[name2id[source_name]];
        }
        else {
            source_node = addNode(source_name);
            name2id[source_name] = source_node.id;
        }
        // Retrieve or create the target node
        let target_node;
        if (new_data.drawing_area.sankey.nodes_dict[name2id[target_name]]) {
            target_node = new_data.drawing_area.sankey.nodes_dict[name2id[target_name]];
        }
        else {
            target_node = addNode(target_name);
            name2id[target_name] = target_node.id;
        }
        // Add the link if it doesn't exist
        if (!new_data.drawing_area.sankey.links_dict[defaultLinkId(source_node, target_node)]) {
            const l = new_data.drawing_area.sankey.addNewLink(source_node, target_node);
            // Set the value of the link, if provided
            if (cur_flux.value) {
                l.data_value = +cur_flux.value;
            }
        }
    };
    // Function to get cells from clipboard and parse them into spreadsheet rows
    function getCellsFromClipboardPlainText() {
        return __awaiter(this, void 0, void 0, function* () {
            const text = yield navigator.clipboard.readText().catch(() => {
                throw new Error('Failed to read textual data from clipboard!');
            });
            // Parse the clipboard text into rows and columns
            const lines = text.split('\n');
            const new_lines = lines.map((line) => line.split('\t').map((textValue) => ({
                type: 'text',
                text: textValue,
                value: parseLocaleNumber(textValue),
            })));
            return new_lines;
        });
    }
    // Render the ReactGrid component
    return React.createElement(ReactGrid, { rows: [
            {
                rowId: 'header', // Header row
                cells: [
                    { type: 'header', text: new_data.t('Flux.src') },
                    { type: 'header', text: new_data.t('Flux.trgt') },
                    { type: 'header', text: new_data.t('Flux.value') },
                ]
            },
            ...spreadSheetFlux.map((flux, idx) => ({
                rowId: idx, // Unique ID for each row
                cells: [
                    { type: 'text', text: flux.source },
                    { type: 'text', text: flux.target },
                    { type: 'number', value: flux.value }
                ]
            }))
        ], columns: columns, onCellsChanged: (changes) => {
            let redraw = false;
            let updateTable = false;
            changes.filter(change => change.type === 'number').forEach(change => {
                const fluxIndex = change.rowId;
                const fieldName = change.columnId;
                const l = new_data.drawing_area.sankey.links_list[fluxIndex];
                if (l) {
                    if (isNaN(change.newCell.value)) {
                        l.data_value = null;
                    }
                    else {
                        l.data_value = change.newCell.value;
                    }
                    new_data.drawing_area.updateScaleAtLinkValueSetting();
                }
                spreadSheetFlux[fluxIndex][fieldName] = change.newCell.value;
                updateTable = true;
            });
            // Three possible actions
            // - new link with two new node
            // - new link with two existing nodes
            // - new link with one existing node and the other to create
            // - modifying source or/and target of link
            // - rename node
            // 1. New Flux
            changes.filter(change => change.type === 'text' && change.rowId == spreadSheetFlux.length - 1).forEach(change => {
                const fluxIndex = change.rowId;
                const fieldName = change.columnId;
                spreadSheetFlux[fluxIndex][fieldName] = change.newCell.text;
                const otherfieldName = change.columnId == 'source' ? 'target' : 'source';
                if (spreadSheetFlux[fluxIndex][otherfieldName] != '' && spreadSheetFlux[fluxIndex][fieldName] != '') {
                    addLink(spreadSheetFlux[fluxIndex]);
                    redraw = true;
                }
                else {
                    updateTable = true;
                }
            });
            // 2. Modify flux
            changes.filter(change => change.type === 'text' && change.rowId !== spreadSheetFlux.length - 1 && name2id[change.newCell.text] != undefined).forEach(change => {
                const fluxIndex = change.rowId;
                const fieldName = change.columnId;
                const l = new_data.drawing_area.sankey.links_list[fluxIndex];
                if (fieldName == 'source') {
                    const prevSource = l.source;
                    l.source = new_data.drawing_area.sankey.nodes_dict[name2id[change.newCell.text]];
                    if (!prevSource.hasInputLinks() && !prevSource.hasOutputLinks()) {
                        // Remove lone nodes
                        new_data.drawing_area.deleteNode(prevSource);
                    }
                }
                else {
                    const prevTarget = l.target;
                    l.target = new_data.drawing_area.sankey.nodes_dict[name2id[change.newCell.text]];
                    if (!prevTarget.hasInputLinks() && !prevTarget.hasOutputLinks()) {
                        // Remove lone nodes
                        new_data.drawing_area.deleteNode(prevTarget);
                    }
                }
                synchronizeSpreadSheetWithSankey();
            });
            // 3. Change node name
            changes.filter(change => change.type === 'text' && change.rowId !== spreadSheetFlux.length - 1 && name2id[change.newCell.text] == undefined).forEach(change => {
                const fluxIndex = change.rowId;
                const fieldName = change.columnId;
                const _prev_node_name = change.previousCell.text;
                const new_node_name = change.newCell.text;
                spreadSheetFlux[fluxIndex][fieldName] = new_node_name;
                const l = new_data.drawing_area.sankey.links_list[fluxIndex];
                if (fieldName == 'source') {
                    l.source.name = new_node_name;
                }
                else {
                    l.target.name = new_node_name;
                }
                synchronizeSpreadSheetWithSankey();
            });
            if (redraw) {
                new_data.drawing_area.computeAutoSankey(true);
                new_data.draw();
            }
            if (updateTable) {
                setSpreadSheetFlux([...spreadSheetFlux]);
            }
        }, onColumnResized: (ci, width) => {
            // Allow resizing columns
            setColumns((prevColumns) => {
                const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
                const resizedColumn = prevColumns[columnIndex];
                const updatedColumn = Object.assign(Object.assign({}, resizedColumn), { width });
                prevColumns[columnIndex] = updatedColumn;
                return [...prevColumns];
            });
        }, onContextMenu: (selectedRowIds, selectedColIds, selectionMode, menuOptions, selectedRanges) => {
            return [
                {
                    id: 'paste',
                    label: new_data.t('SpreadSheet.paste'),
                    handler: () => {
                        if (window.navigator.userAgent.includes('Firefox')) {
                            alert('Cette fonctionnalité ne fonctionne pas sur Firefox.');
                            return;
                        }
                        getCellsFromClipboardPlainText().then((rows) => {
                            // Paste and handle clipboard data
                            if (rows.length == 1) {
                                return;
                            }
                            const columnsId = columns.map(c => c.columnId);
                            const current_row = selectedRanges[0][0].rowId;
                            const current_col = columnsId.indexOf(selectedRanges[0][0].columnId);
                            const linksToRemove = [];
                            if (current_col == 0 && current_row < spreadSheetFlux.length - 1) {
                                for (let i = spreadSheetFlux.length - 2; i >= current_row; i--) {
                                    const l = new_data.drawing_area.sankey.links_list[i];
                                    linksToRemove.push(l);
                                }
                            }
                            linksToRemove.forEach(l => new_data.drawing_area.deleteLink(l));
                            new_data.drawing_area.sankey.nodes_list.forEach(n => {
                                if (!n.hasInputLinks() && !n.hasOutputLinks()) {
                                    // Remove lone nodes
                                    new_data.drawing_area.deleteNode(n);
                                }
                            });
                            rows.pop();
                            if (current_row + rows.length > spreadSheetFlux.length) {
                                for (let i = spreadSheetFlux.length; i < current_row + rows.length; i++) {
                                    spreadSheetFlux.push(({ source: '', target: '' }));
                                }
                            }
                            rows.forEach((r, i) => r.forEach((item, j) => {
                                const row_flux = spreadSheetFlux[current_row + i];
                                const fieldName = columnsId[current_col + j];
                                row_flux[fieldName] = item.text.replace('\r', '');
                            }));
                            let redraw = false;
                            let synchronizeSpreadSheet = false;
                            spreadSheetFlux.forEach(flux => {
                                // const source_name = flux.source
                                // const target_name = flux.target
                                if (flux.value && isNaN(flux.value)) {
                                    flux.value = flux.value.replace(' ', '').replace('\r', '');
                                }
                                if (flux.value) {
                                    flux.value = +flux.value;
                                }
                                //if (!name2id[source_name] || !name2id[target_name]) {
                                addLink(flux);
                                redraw = true;
                                //} else {
                                // const sourceNode = new_data.drawing_area.sankey.nodes_dict[name2id[source_name]]
                                // const targetNode = new_data.drawing_area.sankey.nodes_dict[name2id[target_name]]
                                // const l = new_data.drawing_area.sankey.links_dict[name2id[defaultLinkId(sourceNode, targetNode)]]
                                // if (l && flux.value) {
                                //   l.data_value = flux.value
                                // }
                                synchronizeSpreadSheet = true;
                                //}
                            });
                            if (redraw) {
                                new_data.drawing_area.computeAutoSankey(true);
                                new_data.draw();
                            }
                            if (synchronizeSpreadSheet) {
                                synchronizeSpreadSheetWithSankey();
                            }
                        });
                    }
                }
            ];
        } });
};
