// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { useRef } from 'react';
// CLASS MENU CONFIG *******************************************************************/
/**
 * Define shortcut to update menu components
 * @export
 * @class Class_MenuConfig
 */
export class Class_MenuConfig {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_MenuConfig.
     * @memberof Class_MenuConfig
     */
    constructor() {
        // Init button ref ------------------------------------------------------------------
        // PROTECTED  ATTRIBUTES ==============================================================
        /* ========================================
          Configuration menu
          ========================================*/
        /**
         *   List of accordions to show
         * @protected
         * @type {string[]}
         * @memberof Class_MenuConfig
         */
        this._accordions_to_show = [];
        /* ========================================
          Timeout dict
        =========================================== */
        this._waiting_processes = {};
        this._waiting_time_for_processes = 50; // ms
        /* ========================================
         Ref to button on the top menu in the app
         ========================================*/
        this._refs_to_btn_toogle_top_menus = {};
        // Update component SankeySettingsEditionElementTags
        this._ref_to_menu_config_tags_updater = {};
        /* ========================================
          Visible Nodes / Links selectors
        =========================================== */
        this._selector_only_visible_nodes = false;
        this._selector_only_visible_links = false;
        // Ref to style of currently selected node(s)
        this._ref_selected_style_node = useRef('default');
        // Ref to style of currently selected link(s)
        this._ref_selected_style_link = useRef('default');
        // Ref to updater show modal multi aggregate/disaggregate
        this._ref_to_updater_node_disagregate = useRef(() => null);
        this._ref_to_updater_node_agregate = useRef(() => null);
        // Var to hide welcome menu when we relaucnh application
        this._never_see_again = useRef((localStorage.getItem('dontSeeAggainWelcome') === '1'));
        this._ref_to_btn_toogle_menu = useRef(null);
        this._ref_to_btn_accordion_config_elements = useRef(null);
        this._ref_to_btn_accordion_config_node = useRef(null);
        this._ref_to_btn_accordion_config_link = useRef(null);
        // Init menu component updater ------------------------------------------------------
        this._ref_to_menu_updater = useRef(() => null);
        this._ref_to_submenu_updater = useRef(() => null);
        this._ref_to_spreadsheet = useRef(() => null);
        this._ref_to_menu_config_updater = useRef(() => null);
        this._ref_menu_opened = useRef([false, () => null]);
        // Layout
        this._ref_to_menu_config_layout_updater = useRef(() => null);
        this._ref_to_menu_contextual_config_layout_updater = useRef(() => null); //contextual ref updater
        // Nodes
        this._ref_to_menu_config_nodes_selection_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_apparence_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_styles_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_styles_editor_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_tags_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_io_updater = useRef(() => null);
        this._ref_to_menu_config_nodes_tooltips_updater = useRef(() => null);
        // Links
        this._ref_to_menu_config_links_selection_updater = useRef(() => null);
        this._ref_to_menu_config_links_data_updater = useRef(() => null);
        this._ref_to_menu_contextual_config_links_data_updater = useRef(() => null);
        this._ref_to_menu_config_links_apparence_updater = useRef(() => null);
        this._ref_to_menu_config_links_styles_updater = useRef(() => null);
        this._ref_to_menu_config_links_styles_editor_updater = useRef(() => null);
        this._ref_to_menu_config_links_tags_updater = useRef(() => null);
        this._ref_to_menu_config_links_tooltips_updater = useRef(() => null);
        // Tags
        this._ref_to_menu_config_tags_updater['level_taggs'] = useRef(() => null);
        this._ref_to_menu_config_tags_updater['node_taggs'] = useRef(() => null);
        this._ref_to_menu_config_tags_updater['flux_taggs'] = useRef(() => null);
        this._ref_to_menu_config_tags_updater['data_taggs'] = useRef(() => null);
        // Toolbar+
        this._ref_to_save_in_cache_indicator = useRef((_) => null);
        this._ref_to_save_in_cache_indicator_value = useRef(true);
        this._ref_to_toolbar_updater = useRef(() => null);
        // Init context menu components updater ---------------------------------------------
        this._ref_to_menu_context_nodes_updater = useRef(() => null);
        this._ref_to_menu_context_links_updater = useRef(() => null);
        this._ref_to_menu_context_drawing_area_updater = useRef(() => null);
        // Init filtering components updater ------------------------------------------------
        this._ref_to_leveltag_filter_updater = useRef(() => null);
        this._ref_to_nodetag_filter_updater = useRef(() => null);
        this._ref_to_fluxtag_filter_updater = useRef(() => null);
        this._ref_to_datatag_filter_updater = useRef(() => null);
        this._ref_to_datatag_filter_navbar_updater = useRef(() => null);
        // Init save diagram JSON components updater ------------------------------------------------
        this._ref_to_save_diagram_updater = useRef(() => null);
        // Init ApplyLayoutDialog components updater ------------------------------------------------
        this._ref_to_updater_modal_apply_layout = useRef(() => null);
        // Init ModalPreference components updater ------------------------------------------------
        this._ref_to_modal_pref_updater = useRef(() => null);
        // Init dict of setter show dialog -------------------------------------------------
        this._dict_setter_show_dialog = {
            // Config menu - Nodes
            ref_setter_show_menu_node_apparence: useRef(() => null),
            ref_setter_show_menu_node_io: useRef(() => null),
            ref_setter_show_menu_node_tooltip: useRef(() => null),
            ref_setter_show_menu_node_tags: useRef(() => null),
            // Config menu - Links
            ref_setter_show_menu_link_tags: useRef(() => null),
            ref_setter_show_menu_link_data: useRef(() => null),
            ref_setter_show_menu_link_appearence: useRef(() => null),
            ref_setter_show_menu_link_tooltip: useRef(() => null),
            // Config menu - Layout
            ref_setter_show_menu_layout: useRef(() => null),
            // Modal - Welcome
            ref_setter_modal_welcome_active_page: useRef(() => null),
            ref_setter_show_modal_welcome: useRef(() => null),
            ref_setter_show_modal_tuto: useRef(() => null),
            ref_setter_show_modal_support: useRef(() => null),
            // Modal - Saving & Loading
            ref_setter_show_modal_excel_loader: useRef(() => null),
            ref_setter_show_modal_excel_reading_process: useRef(() => null),
            ref_setter_show_modal_json_saver: useRef(() => null),
            ref_setter_show_modal_png_saver: useRef(() => null),
            ref_setter_png_saver_res_h: useRef(() => null),
            ref_setter_png_saver_res_v: useRef(() => null),
            // Modal - Style & Layout
            ref_setter_show_modal_styles_nodes: useRef(() => null),
            ref_setter_show_modal_styles_links: useRef(() => null),
            ref_setter_show_modal_apply_layout: useRef(() => null),
            // Other modals
            ref_setter_show_modal_preference: useRef(() => null),
            ref_setter_show_modal_templates_lib: useRef(() => null),
            ref_setter_show_spreadsheet: useRef(() => null)
        };
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Add accordion to menu config
     * @param {string} _
     * @memberof Class_MenuConfig
     */
    addToAccordionsToShow(_) {
        if (!this.isGivenAccordionShowed(_)) {
            this._accordions_to_show.push(_);
            this.updateAllMenuComponents();
        }
    }
    /**
     * Remove accordion to menu config
     * @param {string} _
     * @memberof Class_MenuConfig
     */
    removeFromAccordionsToShow(_) {
        if (this.isGivenAccordionShowed(_)) {
            this._accordions_to_show = this._accordions_to_show
                .filter(to_show => to_show !== _);
            this.updateAllMenuComponents();
        }
    }
    /**
     * In menu config :
     * If accordion exists - remove it
     * It it does not exist - add it
     * @param {string} _
     * @memberof Class_MenuConfig
     */
    toggleGivenAccordion(_) {
        if (this.isGivenAccordionShowed(_))
            this.removeFromAccordionsToShow(_);
        else
            this.addToAccordionsToShow(_);
    }
    /**
     * Check if given accordion is displayed in menu config
     * @param {string} _
     * @return {*}
     * @memberof Class_MenuConfig
     */
    isGivenAccordionShowed(_) {
        return this._accordions_to_show.includes(_);
    }
    closeAllMenus() {
        // Close config menu
        this.closeConfigMenu();
        // Close all modals
        // -- Welcome
        this._dict_setter_show_dialog.ref_setter_show_modal_welcome.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_tuto.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_support.current(false);
        // -- Saving & Loading
        this._dict_setter_show_dialog.ref_setter_show_modal_excel_loader.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_excel_reading_process.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_json_saver.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_png_saver.current(false);
        // -- Style & Layout
        this._dict_setter_show_dialog.ref_setter_show_modal_styles_nodes.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_styles_links.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_apply_layout.current(false);
        // -- Other modals
        this._dict_setter_show_dialog.ref_setter_show_modal_preference.current(false);
        this._dict_setter_show_dialog.ref_setter_show_modal_templates_lib.current(false);
        this._dict_setter_show_dialog.ref_setter_show_spreadsheet.current(false);
    }
    /**
     * Open menu configuration
     * @memberof Class_MenuConfig
     */
    openConfigMenu() {
        // Check if we linked the ref to the button to toggle the menu
        // and if _ref_to_btn_accordion_config_elements is null it mean the menu is closed(because the accordion is not rendered if the menu is closed)
        if (this._ref_to_btn_toogle_menu &&
            this._ref_to_btn_toogle_menu.current &&
            this._ref_to_btn_accordion_config_elements.current === null) {
            this._ref_to_btn_toogle_menu.current.click();
        }
    }
    /**
     * Open menu configuration
     * @memberof Class_MenuConfig
     */
    closeConfigMenu() {
        // Check if we linked the ref to the button to toggle the menu
        // and if _ref_to_btn_accordion_config_elements is null it mean the menu is closed(because the accordion is not rendered if the menu is closed)
        if (this._ref_to_btn_toogle_menu &&
            this._ref_to_btn_toogle_menu.current &&
            this._ref_to_btn_accordion_config_elements.current !== null) {
            this._ref_to_btn_toogle_menu.current.click();
        }
    }
    /**
     * Check if we linked the ref to the button to open elements accordion
     * and check if the accordion elements is open then click to the button
     * that _ref_to_btn_accordion_config_elements ref to
     *
     * @memberof Class_MenuConfig
     */
    openConfigMenuElements() {
        // Config menu must be opened first
        this.openConfigMenu();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Element accordion
            if (this._ref_to_btn_accordion_config_elements.current &&
                (d3.select(this._ref_to_btn_accordion_config_elements.current).attr('aria-expanded') === 'false')) {
                this._ref_to_btn_accordion_config_elements.current.click();
            }
        }, 500);
    }
    /**
     * Check if we linked the ref to the button to toggle the menu
     * and check if the accordion nodes is open then click to the button
     * _ref_to_btn_accordion_config_node ref to
     *
     * @memberof Class_MenuConfig
     */
    openConfigMenuElementsNodes() {
        // Element config men umust be opened first
        this.openConfigMenuElements();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Node element menu
            if (this._ref_to_btn_accordion_config_node.current &&
                (d3.select(this._ref_to_btn_accordion_config_node.current).attr('aria-expanded') === 'false')) {
                this._ref_to_btn_accordion_config_node.current.click();
            }
        }, 500);
    }
    /**
     * Check if we linked the ref to the button to toggle the menu
     * and check if the accordion nodes is open then click to the button
     * _ref_to_btn_accordion_config_link ref to
     *
     * @memberof Class_MenuConfig
     */
    openConfigMenuElementsLinks() {
        // Element config men umust be opened first
        this.openConfigMenuElements();
        // Leave enough time for menus to open
        setTimeout(() => {
            if (this._ref_to_btn_accordion_config_link.current &&
                d3.select(this._ref_to_btn_accordion_config_link.current).attr('aria-expanded') === 'false') {
                this._ref_to_btn_accordion_config_link.current.click();
            }
        }, 500);
    }
    // Menu config updaters methods -------------------------------------------------------
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLayoutApparence() {
        this._add_waiting_process('updateComponentRelatedToLayoutApparence', (_this) => {
            _this._ref_to_menu_config_layout_updater.current();
            _this._ref_to_menu_contextual_config_layout_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesSelection() {
        this._add_waiting_process('updateComponentRelatedToNodesSelection', (_this) => {
            _this._ref_to_menu_config_nodes_selection_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesApparence() {
        this._add_waiting_process('updateMenuConfigNodeApparence', (_this) => {
            _this._updateComponentRelatedToNodesApparence();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesStyles() {
        this._add_waiting_process('updateComponentRelatedToNodesStyles', (_this) => {
            _this._ref_to_menu_config_nodes_styles_updater.current();
            _this._ref_to_menu_config_nodes_styles_editor_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesTags() {
        this._add_waiting_process('updateComponentRelatedToNodesTags', (_this) => {
            _this._ref_to_menu_config_nodes_tags_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesIO() {
        this._add_waiting_process('updateComponentRelatedToNodesIO', (_this) => {
            _this._ref_to_menu_config_nodes_io_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToNodesTooltips() {
        this._add_waiting_process('updateComponentRelatedToNodesTooltips', (_this) => {
            _this._ref_to_menu_config_nodes_tooltips_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksSelection() {
        this._add_waiting_process('updateComponentRelatedToLinksSelection', (_this) => {
            _this._ref_to_menu_config_links_selection_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksData() {
        this._add_waiting_process('updateComponentRelatedToLinksData', (_this) => {
            _this._ref_to_menu_config_links_data_updater.current();
            _this._ref_to_menu_contextual_config_links_data_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksApparence() {
        this._add_waiting_process('updateComponentRelatedToLinksApparence', (_this) => {
            _this._ref_to_menu_config_links_apparence_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksStyles() {
        this._add_waiting_process('updateComponentRelatedToLinksStyles', (_this) => {
            _this._ref_to_menu_config_links_styles_updater.current();
            _this._ref_to_menu_config_links_styles_editor_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksTags() {
        this._add_waiting_process('updateComponentRelatedToLinksTags', (_this) => {
            _this._ref_to_menu_config_links_tags_updater.current();
        });
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksTooltips() {
        this._add_waiting_process('updateComponentRelatedToLinksTooltips', (_this) => {
            _this._ref_to_menu_config_links_tooltips_updater.current();
        });
    }
    /**
     * Update all menus using related refs to update function
     * @memberof Class_MenuConfig
     */
    updateAllMenuComponents() {
        this._ref_to_menu_updater.current();
        // TDODO : to have an updater in OpenSankeyMenusDictBuilder so if we cahnge language it update language of submenus,
        //  for now OpenSankeyMenusDictBuilder is a function so the updater crash the app because the re-render is out of the correct scope
        // this._ref_to_submenu_updater.current()
        this.updateMenuConfigComponent();
        this.updateComponentRelatedToLayoutApparence();
        this.updateAllComponentsRelatedToNodes();
        this.updateAllComponentsRelatedToLinks();
        this.updateAllComponentsRelatedToToolbar();
        this.updateAllComponentsRelatedToDataTags();
        this.updateAllComponentsRelatedToNodeTags();
        this.updateAllComponentsRelatedToFluxTags();
        this.updateAllComponentsRelatedToLevelTags();
        this.updateComponentPref();
    }
    updateComponentPref() {
        this._ref_to_modal_pref_updater.current();
    }
    updateMenuConfigComponent() {
        this._ref_to_menu_config_updater.current();
    }
    /**
     * Re-render all menus for node config
     * - SankeyNodeEdition
     * - OpenSankeyConfigurationNodesAttributes
     * - OpenSankeyConfigurationNodesTags
     * - SankeyMenuConfigurationNodesIO
     * - SankeyMenuConfigurationNodesTooltip
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToNodes() {
        this.ref_to_spreadsheet.current();
        this.updateComponentRelatedToNodesSelection();
        this.updateAllComponentsRelatedToNodesConfig();
        this.updateComponentRelatedToNodesStyles();
    }
    /**
     * Re-render all submenus for node config
     * - OpenSankeyConfigurationNodesAttributes
     * - OpenSankeyConfigurationNodesTags
     * - SankeyMenuConfigurationNodesIO
     * - SankeyMenuConfigurationNodesTooltip
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToNodesConfig() {
        this.updateComponentRelatedToNodesApparence();
        this.updateComponentRelatedToNodesTags();
        this.updateComponentRelatedToNodesIO();
        this.updateComponentRelatedToNodesTooltips();
    }
    /**
     * Re-render all menus for link config
     * - SankeyMenuConfigurationLinks
     * - MenuConfigurationLinksData
     * - MenuConfigurationLinksAppearence
     * - MenuConfigurationLinksTags
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToLinks() {
        this.ref_to_spreadsheet.current();
        this._ref_to_menu_context_links_updater.current();
        this.updateComponentRelatedToLinksSelection();
        this.updateAllComponentsRelatedToLinksConfig();
        this.updateComponentRelatedToLinksStyles();
    }
    /**
     * Re-render all submenus for link config
     * - MenuConfigurationLinksData
     * - MenuConfigurationLinksAppearence
     * - MenuConfigurationLinksTags
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToLinksConfig() {
        this.updateComponentRelatedToLinksData();
        this.updateComponentRelatedToLinksApparence();
        this.updateComponentRelatedToLinksStyles();
        this.updateComponentRelatedToLinksTags();
        this.updateComponentRelatedToLinksTooltips();
    }
    /**
     * Re-render all submenus for tags config
     * - SankeyMenuConfigurationNodes
     * - OpenSankeyConfigurationNodesTags
     * - SankeyMenuConfigurationLinks
     * - OpenSankeyConfigurationLinksTags
     * - OpenSankeyConfigurationLinksData
     * - ToolbarBuilder
     * @memberof Class_MenuConfig
     */
    updateAllComponentsRelatedToTags() {
        this.updateComponentRelatedToNodesSelection();
        this.updateComponentRelatedToNodesTags();
        this.updateComponentRelatedToLinksSelection();
        this.updateComponentRelatedToLinksTags();
        this.updateComponentRelatedToLinksData();
        this.updateAllComponentsRelatedToToolbar();
        this.updateAllComponentsRelatedToLevelTags();
    }
    updateAllComponentsRelatedToLevelTags() {
        this._ref_to_menu_config_tags_updater['level_taggs'].current();
    }
    updateAllComponentsRelatedToNodeTags() {
        this._ref_to_nodetag_filter_updater.current();
        this._ref_to_leveltag_filter_updater.current();
        this.updateComponentRelatedToNodesTags();
        this._ref_to_menu_config_tags_updater['node_taggs'].current();
    }
    updateAllComponentsRelatedToFluxTags() {
        this._ref_to_fluxtag_filter_updater.current();
        this.updateComponentRelatedToLinksTags();
        this._ref_to_menu_config_tags_updater['flux_taggs'].current();
    }
    updateAllComponentsRelatedToDataTags() {
        this._ref_to_datatag_filter_updater.current();
        this._ref_to_datatag_filter_navbar_updater.current();
        this.updateComponentRelatedToLinksData();
        this.updateComponentRelatedToLinksTags();
        this._ref_to_menu_config_tags_updater['data_taggs'].current();
    }
    updateAllComponentsRelatedToTagsType(type) {
        if (type === 'data_taggs')
            this.updateAllComponentsRelatedToDataTags();
        else if (type === 'flux_taggs')
            this.updateAllComponentsRelatedToFluxTags();
        else if (type === 'node_taggs')
            this.updateAllComponentsRelatedToNodeTags();
        else if (type === 'level_taggs')
            this.updateAllComponentsRelatedToLevelTags();
        else
            this.updateAllComponentsRelatedToLevelTags();
    }
    updateAllComponentsRelatedToToolbar() {
        this._ref_to_toolbar_updater.current();
    }
    toggle_selector_on_visible_nodes() {
        this._selector_only_visible_nodes = !this._selector_only_visible_nodes;
        this.updateAllComponentsRelatedToNodes();
    }
    toggle_selector_on_visible_links() {
        this._selector_only_visible_links = !this._selector_only_visible_links;
        this.updateAllComponentsRelatedToLinks();
    }
    /**
     * Update modal Save diagram JSON
     *
     * @memberof Class_MenuConfig
     */
    updateComponentSaveDiagramJSON() {
        this._ref_to_save_diagram_updater.current();
    }
    /**
     * Function to position horizontally the toolbar, it's position depend if the configuration menu is opened
     * @memberof Class_MenuConfig
     */
    positionToolBar(menu_config_width) {
        d3.select('.sideToolBar').transition().duration(300).style('right', ((this._ref_menu_opened.current[0] ? menu_config_width : 0)) + 'px');
    }
    /**
     * Function to update ApplyLayoutDialog component,
     * can be overrided in submodule if we add subcomponent to ApplyLayoutDialog
     *
     * @memberof Class_MenuConfig
     */
    updateComponentApplyLayout() {
        this._ref_to_updater_modal_apply_layout.current();
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Create a timed out process - Used to avoid multiple reloading of components
     *
     * The process_func is meant to be use by setTimeout(),
     * and inside setTimeOut 'this' keyword has another meaning,
     * so the current object must be passed directly as an argument.
     * see : https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#the_this_problem
     *
     * @protected
     * @param {string} process_id
     * @param {(_: Class_MenuConfig) => void} process_func
     * @memberof Class_MenuConfig
     */
    _add_waiting_process(process_id, process_func) {
        this._cancel_waiting_process(process_id);
        this._waiting_processes[process_id] = setTimeout((_this) => { process_func(_this); }, this._waiting_time_for_processes, this);
    }
    /**
     * Cancel a timed out process - It wont happen
     * @protected
     * @param {string} process_id
     * @memberof Class_MenuConfig
     */
    _cancel_waiting_process(process_id) {
        if (this._waiting_processes[process_id] !== undefined)
            clearTimeout(this._waiting_processes[process_id]);
    }
    _updateComponentRelatedToNodesApparence() {
        this._ref_to_menu_config_nodes_apparence_updater.current();
    }
    // GETTERS / SETTERS ==================================================================
    // Main menu component ----------------------------------------------------------------
    get ref_to_menu_updater() {
        return this._ref_to_menu_updater;
    }
    get ref_to_submenu_updater() {
        return this._ref_to_submenu_updater;
    }
    get ref_to_spreadsheet() {
        return this._ref_to_spreadsheet;
    }
    get ref_menu_opened() {
        return this._ref_menu_opened;
    }
    // Top menu components ----------------------------------------------------------------
    init_refs_to_btn_toogle_top_menus(id) {
        this._refs_to_btn_toogle_top_menus[id] = useRef(null);
    }
    get refs_to_btn_toogle_top_menus() {
        return this._refs_to_btn_toogle_top_menus;
    }
    // Accordion menu openers -------------------------------------------------------------
    get accordions_to_show() {
        return this._accordions_to_show;
    }
    set accordions_to_show(_) {
        this._accordions_to_show = _;
        this.updateAllMenuComponents();
    }
    get ref_to_btn_toogle_menu() {
        return this._ref_to_btn_toogle_menu;
    }
    get ref_to_btn_accordion_config_elements() {
        return this._ref_to_btn_accordion_config_elements;
    }
    get ref_to_btn_accordion_config_node() {
        return this._ref_to_btn_accordion_config_node;
    }
    get ref_to_btn_accordion_config_link() {
        return this._ref_to_btn_accordion_config_link;
    }
    get ref_to_menu_config_updater() {
        return this._ref_to_menu_config_updater;
    }
    // Layout  menus ----------------------------------------------------------------------
    get ref_to_menu_config_layout_updater() {
        return this._ref_to_menu_config_layout_updater;
    }
    get ref_to_menu_contextual_config_layout_updater() {
        return this._ref_to_menu_contextual_config_layout_updater;
    }
    get ref_to_menu_context_drawing_area_updater() {
        return this._ref_to_menu_context_drawing_area_updater;
    }
    // Nodes menus ------------------------------------------------------------------------
    get ref_to_menu_config_nodes_selection_updater() {
        return this._ref_to_menu_config_nodes_selection_updater;
    }
    get ref_to_menu_config_nodes_apparence_updater() {
        return this._ref_to_menu_config_nodes_apparence_updater;
    }
    get ref_to_menu_config_nodes_styles_updater() {
        return this._ref_to_menu_config_nodes_styles_updater;
    }
    get ref_to_menu_config_nodes_styles_editor_updater() {
        return this._ref_to_menu_config_nodes_styles_editor_updater;
    }
    get ref_to_menu_config_nodes_tags_updater() {
        return this._ref_to_menu_config_nodes_tags_updater;
    }
    get ref_to_menu_config_nodes_io_updater() {
        return this._ref_to_menu_config_nodes_io_updater;
    }
    get ref_to_menu_config_nodes_tooltips_updater() {
        return this._ref_to_menu_config_nodes_tooltips_updater;
    }
    // Nodes context menu -----------------------------------------------------------------
    get ref_to_menu_context_nodes_updater() {
        return this._ref_to_menu_context_nodes_updater;
    }
    get ref_to_updater_node_disagregate() {
        return this._ref_to_updater_node_disagregate;
    }
    get ref_to_updater_node_agregate() {
        return this._ref_to_updater_node_agregate;
    }
    // Links menus ------------------------------------------------------------------------
    get ref_to_menu_config_links_selection_updater() {
        return this._ref_to_menu_config_links_selection_updater;
    }
    get ref_to_menu_config_links_data_updater() {
        return this._ref_to_menu_config_links_data_updater;
    }
    get ref_to_menu_contextual_config_links_data_updater() {
        return this._ref_to_menu_contextual_config_links_data_updater;
    }
    get ref_to_menu_config_links_apparence_updater() {
        return this._ref_to_menu_config_links_apparence_updater;
    }
    get ref_to_menu_config_links_styles_updater() {
        return this._ref_to_menu_config_links_styles_updater;
    }
    get ref_to_menu_config_links_styles_editor_updater() {
        return this._ref_to_menu_config_links_styles_editor_updater;
    }
    get ref_to_menu_config_links_tags_updater() {
        return this._ref_to_menu_config_links_tags_updater;
    }
    get ref_to_menu_config_links_tooltips_updater() {
        return this._ref_to_menu_config_links_tooltips_updater;
    }
    // Link context menu
    get ref_to_menu_context_links_updater() {
        return this._ref_to_menu_context_links_updater;
    }
    // Tags menus -------------------------------------------------------------------------
    get ref_to_menu_config_tags_updater() {
        return this._ref_to_menu_config_tags_updater;
    }
    // Toolbar -----------------------------------------------------------------------------
    get ref_to_save_in_cache_indicator() {
        return this._ref_to_save_in_cache_indicator;
    }
    get ref_to_save_in_cache_indicator_value() {
        return this._ref_to_save_in_cache_indicator_value;
    }
    get ref_to_toolbar_updater() {
        return this._ref_to_toolbar_updater;
    }
    // Filtering components ---------------------------------------------------------------
    get ref_to_leveltag_filter_updater() {
        return this._ref_to_leveltag_filter_updater;
    }
    get ref_to_nodetag_filter_updater() {
        return this._ref_to_nodetag_filter_updater;
    }
    get ref_to_fluxtag_filter_updater() {
        return this._ref_to_fluxtag_filter_updater;
    }
    get ref_to_datatag_filter_updater() {
        return this._ref_to_datatag_filter_updater;
    }
    get ref_to_datatag_filter_navbar_updater() {
        return this._ref_to_datatag_filter_navbar_updater;
    }
    // Nodes / links selectors ------------------------------------------------------------
    get is_selector_only_for_visible_nodes() {
        return this._selector_only_visible_nodes;
    }
    get is_selector_only_for_visible_links() {
        return this._selector_only_visible_links;
    }
    // Getter dict of ref setter show dialog
    get dict_setter_show_dialog() {
        return this._dict_setter_show_dialog;
    }
    get ref_selected_style_node() {
        return this._ref_selected_style_node;
    }
    get ref_selected_style_link() {
        return this._ref_selected_style_link;
    }
    get never_see_again() {
        return this._never_see_again;
    }
    // Get ref updater of save diagram JSON
    get ref_to_save_diagram_updater() {
        return this._ref_to_save_diagram_updater;
    }
    // Getter ref updater ApplyLayoutDialog OS component
    get ref_to_updater_modal_apply_layout() {
        return this._ref_to_updater_modal_apply_layout;
    }
    get ref_to_modal_pref_updater() {
        return this._ref_to_modal_pref_updater;
    }
}
