// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
// LOcal constants
import { getBooleanFromJSON, const_default_position_x, const_default_position_y, getStringFromJSON, getNumberFromJSON, getStringOrUndefinedFromJSON, randomId } from '../types/Utils';
// CLASS PROTO ELEMENT ******************************************************************
/**
 * Class that define a meta element to display on drawing area
 *
 * @class ClassTemplate_ProtoElement
 */
export class ClassTemplate_ProtoElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_Element.
     * @param {string} id
     * @param {Type_GenericDrawingArea} drawing_area
     * @param {string} svg_parent_group
     * @memberof ClassTemplate_Element
     */
    constructor(id, menu_config, svg_parent_group) {
        // PUBLIC ATTRIBUTES ==================================================================
        /**
         * D3 selection that contains related svg element
         * @type {(d3.Selection<SVGGElement, ClassTemplate_Element, SVGGElement, unknown> | null)}
         * @memberof ClassTemplate_Element
         */
        this.d3_selection = null;
        /**
         * Is element currently visually selected
         * @protected
         * @type {boolean}
         * @memberof ClassTemplate_Element
         */
        this._is_selected = false;
        /**
         * Is element currently drawn
         * @protected
         * @type {boolean}
         * @memberof ClassTemplate_Element
         */
        this._is_visible = true;
        /**
         * Is mouse cursor over element d3 selection (default=false)
         * @protected
         * @type {boolean}
         * @memberof ClassTemplate_Element
         */
        this._is_mouse_over = false;
        /**
         * Is this element grabbed by mouse (default=false)
         * @protected
         * @type {boolean}
         * @memberof ClassTemplate_Element
         */
        this._is_mouse_grabbed = false;
        /**
         * True if element is currently on a deletion process
         * Avoid cross calls of delete() method
         * @private
         * @memberof ClassTemplate_Element
         */
        this._is_currently_deleted = false;
        // Set values
        this._id = id;
        this._svg_parent_group = svg_parent_group;
        this._menu_config = menu_config;
        // Init visibility id
        this._visibility_fingerprint = randomId();
        // Element created -> set save indicator
        this._menu_config.ref_to_save_in_cache_indicator.current(false);
    }
    // DELETION METHODS ===================================================================
    /**
     * Define deletion behavior
     * @memberof ClassTemplate_Element
     */
    delete() {
        if (this._is_currently_deleted === false) {
            // Set deletion boolean to true
            this._is_currently_deleted = true;
            // Remove from drawing area
            this.unDraw();
            // Abstract method for cleaning relations between elements
            this.cleanForDeletion();
        }
    }
    /**
     * Methods that needs to be overrides to properly clean elements
     * @protected
     * @memberof ClassTemplate_ProtoElement
     */
    cleanForDeletion() {
        // Does nothing here
    }
    // COPY METHODS =======================================================================
    /**
     * Copy only attributes that are not references
     * /!\ Id is not copied
     * @param {ClassTemplate_ProtoElement} element_to_copy
     * @memberof ClassTemplate_ProtoElement
     */
    copyFrom(element_to_copy) {
        // Remove from drawing area
        this.unDraw();
        // Copy intrasect values
        this._copyFrom(element_to_copy);
        // We will need to check all visibility tests after copy
        this.updateVisibilityFingerprint();
    }
    /**
     * Copy only intrasect attributes that are not references
     * Function to override
     * @param {ClassTemplate_ProtoElement} element_to_copy
     * @memberof ClassTemplate_ProtoElement
     */
    _copyFrom(element_to_copy) {
        this._is_visible = element_to_copy._is_visible;
        this._is_selected = element_to_copy._is_selected;
        this._svg_parent_group = element_to_copy._svg_parent_group;
    }
    // SAVING METHODS =====================================================================
    /**
     * Convert element to JSON
     * @return {*}
     * @memberof ClassTemplate_NodeElement
     */
    toJSON(kwargs) {
        // Init output JSON
        const json_object = {};
        // Fill data
        this._toJSON(json_object, kwargs);
        // Return
        return json_object;
    }
    _toJSON(json_object, _kwargs) {
        json_object['id'] = this._id;
        json_object['is_visible'] = this._is_visible;
        json_object['svg_parent_group'] = this._svg_parent_group;
    }
    /**
     * Apply json to element
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_NodeElement
     */
    fromJSON(json_object, kwargs) {
        // Remove from drawing area
        this.unDraw();
        // Get infos
        this._fromJSON(json_object, kwargs);
        // We will need to check all visibility tests after loading
        this.updateVisibilityFingerprint();
    }
    _fromJSON(json_object, _kwargs) {
        this._id = getStringFromJSON(json_object, 'id', this._id);
        this._is_visible = getBooleanFromJSON(json_object, 'is_visible', this._is_visible);
        this._svg_parent_group = getStringFromJSON(json_object, 'svg_parent_group', this._svg_parent_group);
    }
    // PUBLIC METHODS ====================================================================
    /**
     * Set up element on d3 svg area
     * @memberof ClassTemplate_Element
     */
    draw() {
        this._process_or_bypass(() => {
            this.unDraw();
            if (this.is_visible && !this._is_currently_deleted)
                this._draw();
        });
    }
    /**
     * Unset element from d3 svg area
     * @memberof ClassTemplate_Element
     */
    unDraw() {
        if (this.d3_selection !== null) {
            this.d3_selection.remove();
            this.d3_selection = null;
        }
    }
    isRelatedD3SelectionPresentAndSynced() {
        const d3_drawing_area = this.drawing_area.d3_selection;
        if (d3_drawing_area !== null) {
            const d3_drawing_area_selection = d3_drawing_area.selectAll(' #' + this._svg_parent_group);
            if (d3_drawing_area_selection.nodes().length > 0) {
                const d3_selection = d3_drawing_area_selection.selectAll(' #' + this.svg_group);
                if (d3_selection && d3_selection.nodes().length > 0)
                    return true;
            }
        }
        return false;
    }
    /**
     * Set up events related to element d3_element
     * @protected
     * @memberof ClassTemplate_Element
     */
    setEventsListeners() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!this._display.drawing_area.static) {
            // Right mouse button clicks
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.on('click', (event) => this.eventSimpleLMBCLick(event));
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.on('dblclick', (event) => this.eventDoubleLMBCLick(event));
            // Right mouse button maintained
            (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.on('mousedown', (event) => this.eventMaintainedClick(event));
            (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.on('mouseup', (event) => this.eventReleasedClick(event));
            // Mouse cursor goes over this
            (_e = this.d3_selection) === null || _e === void 0 ? void 0 : _e.on('mouseover', (event) => this.eventMouseOver(event));
            (_f = this.d3_selection) === null || _f === void 0 ? void 0 : _f.on('mouseout', (event) => this.eventMouseOut(event));
            // Mouse cursor move
            (_g = this.d3_selection) === null || _g === void 0 ? void 0 : _g.on('mousemove', (event) => this.eventMouseMove(event));
            // Left mouse button click
            (_h = this.d3_selection) === null || _h === void 0 ? void 0 : _h.on('contextmenu', (event) => this.eventSimpleRMBCLick(event));
            // Changed call of drag, we have to use only on time call because otherwise each .call erase the previous .call event
            if (this.drawing_area.isInSelectionMode()) {
                (_j = this.d3_selection) === null || _j === void 0 ? void 0 : _j.call(d3.drag()
                    .on('start', (event) => this.eventMouseDragStart(event))
                    .on('drag', (event) => this.eventMouseDrag(event))
                    .on('end', (event) => this.eventMouseDragEnd(event)));
            }
            // In edition mode we don't use drag event on elements
            else if (this.drawing_area.isInEditionMode()) {
                (_k = this.d3_selection) === null || _k === void 0 ? void 0 : _k.on('mousedown.drag', null); // Remove dag event
            }
        }
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Create a timed out process - Used to avoid multiple reloading of components
     *
     * The process_func is meant to be use by setTimeout(),
     * and inside setTimeOut 'this' keyword has another meaning,
     * so the current object must be passed directly as an argument.
     * see : https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#the_this_problem
     *
     * @protected
     * @param {string} process_id
     * @param {(_: ClassTemplate_ProtoElement) => void} process_func
     * @memberof ClassTemplate_ProtoElement
     */
    _process_or_bypass(process_func) {
        if (this._display.drawing_area.bypass_redraws)
            return;
        process_func();
    }
    _draw() {
        // Set d3 selections
        this._initDraw();
        // Add events listeners
        this.setEventsListeners();
    }
    _initDraw() {
        const d3_drawing_area = this.drawing_area.d3_selection;
        if (d3_drawing_area !== null) {
            const d3_drawing_area_selection = d3_drawing_area.selectAll(' #' + this._svg_parent_group);
            if (d3_drawing_area_selection.nodes().length > 0) {
                this.d3_selection = d3_drawing_area_selection.append('g');
                this.d3_selection.attr('id', this.svg_group);
            }
        }
    }
    /**
     * History saving
     * @param f
     */
    saveUndo(f) {
        this.drawing_area.application_data.history.saveUndo(() => { f(this); });
    }
    /**
    * History saving
    * @param f
    */
    saveRedo(f) {
        this.drawing_area.application_data.history.saveRedo(() => { f(this); });
    }
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventSimpleLMBCLick(_event) {
        // Clear tooltips presents
        d3.selectAll('.sankey-tooltip').remove();
        // TODO do something
    }
    /**
     * Deal with double left Mouse Button (LMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventDoubleLMBCLick(_event) {
        // TODO Ajouter déclemenchement editeur nom de noeud
    }
    /**
     * Deal with simple right Mouse Button (RMB) click on given element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventSimpleRMBCLick(_event) {
        // Clear tooltips presents
        d3.selectAll('.sankey-tooltip').remove();
        // TODO Ajouter ouverture menu contextuel (clic droit) sur noeud
    }
    /**
     * Define maintained left mouse button click for drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMaintainedClick(_event) {
        /* TODO définir clique gauche sur element */
        this._is_mouse_grabbed = true;
    }
    /**
     * Define released left mouse button click for drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventReleasedClick(_event) {
        /* TODO définir clique gauche sur element */
        this._is_mouse_grabbed = false;
    }
    /**
     * Define event when mouse moves over drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseOver(_event) {
        // Update mouse over indicator for element
        this.setMouseOver();
    }
    /**
     * Define event when mouse moves out of drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseOut(_event) {
        // Update mouse left indicator for element
        this.unsetMouseOver();
    }
    /**
     * Define event when mouse moves in drawing area
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseMove(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag starts
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseDragStart(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseDrag(_event) {
        /* TODO définir  */
    }
    /**
     * Define event when mouse drag ends
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseDragEnd(_event) {
        /* TODO définir  */
    }
    // GETTERS / SETTERS ==================================================================
    // DrawingArea
    get drawing_area() { return this._display.drawing_area; }
    // Svg Group
    get svg_parent_group() { return this._svg_parent_group; }
    get svg_group() { return 'gg_' + this._id.replace(/[^a-zA-Z0-9]/g, ''); }
    // Selection
    setSelected() { this._is_selected = true; this.drawAsSelected(); }
    setUnSelected() { this._is_selected = false; this.drawAsSelected(); }
    get is_selected() { return this._is_selected; }
    // Visible
    setVisible() { this._is_visible = true; this.updateVisibilityFingerprint(); this.draw(); }
    setInvisible() { this._is_visible = false; this.updateVisibilityFingerprint(); this.draw(); }
    updateVisibilityFingerprint() { this._visibility_fingerprint = randomId(); }
    get is_visible() {
        return (this.sankey.is_visible && this._is_visible);
    }
    get visibility_fingerprint() { return this._visibility_fingerprint; }
    // Mouse is over element
    isMouseOver() { return this._is_mouse_over; }
    setMouseOver() { this._is_mouse_over = true; }
    unsetMouseOver() { this._is_mouse_over = false; }
    // Unique id
    get id() { return this._id; }
    // Sankey
    get sankey() { return this._display.sankey; }
    // Get application config menu
    get menu_config() { return this._menu_config; }
}
// CLASS ELEMENT ************************************************************************
/**
 * Class that define a meta element to display on drawing area
 * Difference with ClassTemplate_ProtoElement, ClassTemplate_Element set its position
 *
 * @class ClassTemplate_Element
 */
export class ClassTemplate_Element extends ClassTemplate_ProtoElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_Element.
     * @param {string} id
     * @param {Type_GenericDrawingArea} drawing_area
     * @param {string} svg_parent_group
     * @memberof ClassTemplate_Element
     */
    constructor(id, menu_config, svg_parent_group) {
        super(id, menu_config, svg_parent_group);
    }
    // COPY METHODS =======================================================================
    /**
     * Copy only intrasect attributes that are not references
     * Function to override
     * @param {ClassTemplate_ProtoElement} _
     * @memberof ClassTemplate_ProtoElement
     */
    _copyFrom(_) {
        super._copyFrom(_);
        this._display.position.type = _._display.position.type;
        this._display.position.x = _._display.position.x;
        this._display.position.y = _._display.position.y;
        this._display.position.u = _._display.position.u;
        this._display.position.v = _._display.position.v;
        this._display.position.dx = _._display.position.dx;
        this._display.position.dy = _._display.position.dy;
        this._display.position.relative_dx = _._display.position.relative_dx;
        this._display.position.relative_dy = _._display.position.relative_dy;
    }
    // SAVING METHODS =====================================================================
    _toJSON(json_object, kwargs) {
        super._toJSON(json_object, kwargs);
        if (this._display.position.type)
            json_object['position'] = this._display.position.type;
        json_object['x'] = this._display.position.x;
        json_object['y'] = this._display.position.y;
        json_object['u'] = this._display.position.u;
        json_object['v'] = this._display.position.v;
        // We can not handle this field here for now. They are stored in local or in the style
        // and we have not generalised this mechanism to other elements than nodes or links
        // if (this._display.position.dx) json_object['dx'] = this._display.position.dx
        // if (this._display.position.dy) json_object['dy'] = this._display.position.dy
        // if (this._display.position.relative_dx) json_object['relative_dx'] = this._display.position.relative_dx
        // if (this._display.position.relative_dy) json_object['relative_dy'] = this._display.position.relative_dy
    }
    _fromJSON(json_object, kwargs) {
        super._fromJSON(json_object, kwargs);
        this._display.position.type = getStringOrUndefinedFromJSON(json_object, 'position');
        this._display.position.x = getNumberFromJSON(json_object, 'x', this._display.position.x);
        this._display.position.y = getNumberFromJSON(json_object, 'y', this._display.position.y);
        this._display.position.u = getNumberFromJSON(json_object, 'u', this._display.position.u);
        this._display.position.v = getNumberFromJSON(json_object, 'v', this._display.position.v);
        // We can not handle this field here for now. They are stored in local or in the style
        // and we have not generalised this mechanism to other elements than nodes or links
        // this._display.position.dx = getNumberOrUndefinedFromJSON(json_object, 'dx')
        // this._display.position.relative_dx = getNumberOrUndefinedFromJSON(json_object, 'relative_dx')
        // this._display.position.dy = getNumberOrUndefinedFromJSON(json_object, 'dy')
        // this._display.position.relative_dy = getNumberOrUndefinedFromJSON(json_object, 'relative_dy')
    }
    // PUBLIC METHODS =====================================================================
    // Positioning
    setPosXY(x, y) { this._display.position.x = x; this._display.position.y = y; this.applyPosition(); }
    initPosXY(x, y) { this._display.position.x = x; this._display.position.y = y; this.draw(); }
    initDefaultPosXY() { this.initPosXY(const_default_position_x, const_default_position_y); }
    /**
     * Apply node position to it shape in d3
     * @protected
     * @return {*}
     * @memberof Class_Node
     */
    applyPosition() {
        this._process_or_bypass(() => this._applyPosition());
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Set up element on d3 svg area
     * @protected
     * @memberof ClassTemplate_Element
     */
    _draw() {
        // Draw element on D3
        super._draw();
        // Add apply position
        this._applyPosition();
    }
    drawAsSelected() { }
    /**
     * Apply node position to it shape in d3
     * @protected
     * @return {*}
     * @memberof Class_Node
     */
    _applyPosition() {
        var _a;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('transform', 'translate(' + this.position_x + ', ' + this.position_y + ')');
    }
    // GETTERS / SETTERS ==================================================================
    // Position
    get position_x() { return this._display.position.x; }
    set position_x(_) { this._display.position.x = _; /*this.applyPosition()*/ }
    get position_y() { return this._display.position.y; }
    set position_y(_) { this._display.position.y = _; /*this.applyPosition()*/ }
    get position_u() { return this._display.position.u; }
    set position_u(_) { this._display.position.u = _; /*this.applyPosition()*/ }
    get position_v() { return this._display.position.v; }
    set position_v(_) { this._display.position.v = _; /*this.applyPosition()*/ }
    get position_dx() { return this._display.position.dx; }
    set position_dx(_) { this._display.position.dx = _; /*this.applyPosition()*/ }
    get position_relative_dx() { return this._display.position.relative_dx; }
    set position_relative_dx(_) { this._display.position.relative_dx = _; /*this.applyPosition()*/ }
    get position_dy() { return this._display.position.dy; }
    set position_dy(_) { this._display.position.dy = _; /*this.applyPosition()*/ }
    get position_relative_dy() { return this._display.position.relative_dy; }
    set position_relative_dy(_) { this._display.position.relative_dy = _; /*this.applyPosition()*/ }
    get display() { return this._display; }
}
