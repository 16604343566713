// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
import { ClassAbstract_Sankey } from '../types/Abstract';
import { defaultLinkId, sortLinksElementsByDisplayingOrders, sortLinksElementsByIds } from '../Elements/Link';
import { Class_LinkStyle } from '../Elements/LinkAttributes';
import { sortNodesElements } from '../Elements/Node';
import { Class_NodeStyle } from '../Elements/NodeAttributes';
import { Class_NodeTagGroup, Class_FluxTagGroup, Class_DataTagGroup, Class_LevelTagGroup, } from '../types/Tag';
import { getJSONFromJSON, getStringFromJSON, getStringOrUndefinedFromJSON, default_main_sankey_id, default_style_id, randomId } from '../types/Utils';
import { default_save_only_visible_elements, default_save_with_values } from './ApplicationData';
import { DefaultLinkExportStyle, DefaultLinkImportStyle, DefaultNodeExportStyle, DefaultNodeImportStyle, DefaultNodeProductStyle, DefaultNodeSectorStyle } from './Legacy';
// LOCAL FUNCTIONS **********************************************************************
export function get_sync_lists(to_sync, as_ref, matching_id) {
    const revert_matching_id = {};
    Object.entries(matching_id).forEach(([k, v]) => revert_matching_id[v] = k);
    // Transfer node style from new_layout style node  to corresponding style in current
    const to_sync_ids = Object.keys(to_sync);
    const as_ref_ids = Object.keys(as_ref);
    // Styles can be to remove, to add or to update
    const to_remove = to_sync_ids
        .filter(id => { var _a; return !(as_ref_ids.includes((_a = matching_id[id]) !== null && _a !== void 0 ? _a : id)); });
    const to_add = as_ref_ids
        .filter(id => { var _a; return !to_sync_ids.includes((_a = revert_matching_id[id]) !== null && _a !== void 0 ? _a : id); });
    const to_update = to_sync_ids
        .filter(id => { var _a; return as_ref_ids.includes((_a = matching_id[id]) !== null && _a !== void 0 ? _a : id); });
    return [
        to_remove,
        to_add,
        to_update
    ];
}
// CLASS SANKEY *************************************************************************
/**
 * Contains all necessary elements to draw a Sankey
 *
 * @export
 * @class ClassTemplate_Sankey
 */
export class ClassTemplate_Sankey extends ClassAbstract_Sankey {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_Sankey.
     * @param {Type_GenericDrawingArea} drawing_area
     * @memberof ClassTemplate_Sankey
     */
    constructor(drawing_area, menu_config, id = default_main_sankey_id) {
        super();
        /**
         * Nodes
         *
         * @protected
         * @type {{ [_: string]: Type_GenericNodeElement }}
         * @memberof ClassTemplate_Sankey
         */
        this._nodes = {};
        // Links
        this._links = {};
        // Tags
        this._node_taggs = {};
        this._flux_taggs = {};
        this._data_taggs = {};
        this._level_taggs = {};
        this.drawing_area = drawing_area;
        this._menu_config = menu_config;
        this._id = id;
        // Init updating keys
        this._node_tags_fingerprint = randomId();
        this._flux_tags_fingerprint = randomId();
        this._data_tags_fingerprint = randomId();
    }
    // CLEANING METHODS ===================================================================
    delete() {
        // Properly delete all nodes & link
        this.nodes_list.forEach(n => {
            n.delete(); // Will also trigger delete() on links
        });
        this._nodes = {};
        this._links = {};
        // Properly delete all node styles
        this.node_styles_list.forEach(sn => {
            sn.delete();
        });
        this._node_styles = {};
        // Properly delete all link styles
        this.link_styles_list.forEach(sl => {
            sl.delete();
        });
        this._link_styles = {};
        // Properly delete all tags groups -> will delete related tags also
        this.node_taggs_list.forEach(grp => grp.delete());
        this.flux_taggs_list.forEach(grp => grp.delete());
        this.data_taggs_list.forEach(grp => grp.delete());
        this.level_taggs_list.forEach(grp => grp.delete());
        this._node_taggs = {};
        this._flux_taggs = {};
        this._data_taggs = {};
        this._level_taggs = {};
    }
    // COPY METHODS =======================================================================
    /**
     * Copy everything from input sankey to copy
     * @param {ClassTemplate_Sankey<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>} sankey_to_copy
     * @memberof ClassTemplate_Sankey
     */
    copyFrom(sankey_to_copy) {
        // First clean everything
        this.delete();
        // Then copy tags
        Object.entries(sankey_to_copy._node_taggs)
            .forEach(([idx, node_tagg_to_copy]) => {
            this.addNodeTagGroup(idx, node_tagg_to_copy.name)
                .copyFrom(node_tagg_to_copy);
        });
        Object.entries(sankey_to_copy._flux_taggs)
            .forEach(([idx, flux_tagg_to_copy]) => {
            this.addFluxTagGroup(idx, flux_tagg_to_copy.name)
                .copyFrom(flux_tagg_to_copy);
        });
        Object.entries(sankey_to_copy._level_taggs)
            .forEach(([idx, level_tagg_to_copy]) => {
            this.addLevelTagGroup(idx, level_tagg_to_copy.name)
                .copyFrom(level_tagg_to_copy);
        });
        Object.entries(sankey_to_copy._data_taggs)
            .forEach(([idx, data_tagg_to_copy]) => {
            this.addDataTagGroup(idx, data_tagg_to_copy.name)
                .copyFrom(data_tagg_to_copy);
        });
        // Then copy styles
        Object.entries(sankey_to_copy._node_styles)
            .forEach(([idx, node_style_to_copy]) => {
            this.addNewNodeStyle(idx, node_style_to_copy.name)
                .copyFrom(node_style_to_copy);
        });
        Object.entries(sankey_to_copy._link_styles)
            .forEach(([idx, link_style_to_copy]) => {
            this.addNewLinkStyle(idx, link_style_to_copy.name)
                .copyFrom(link_style_to_copy);
        });
        // Then copy links
        Object.entries(sankey_to_copy._links)
            .forEach(([idx, link_to_copy]) => {
            var _a, _b;
            this.addNewLinkWithId(idx, (_a = this._nodes[link_to_copy.source.id]) !== null && _a !== void 0 ? _a : this.addNewNode(link_to_copy.source.id, link_to_copy.source.name), // Get or create source
            (_b = this._nodes[link_to_copy.target.id]) !== null && _b !== void 0 ? _b : this.addNewNode(link_to_copy.target.id, link_to_copy.target.name) // Get or create target
            )
                .copyFrom(link_to_copy);
        });
        // Then copy nodes
        Object.entries(sankey_to_copy._nodes)
            .forEach(([idx, node_to_copy]) => {
            var _a;
            const node = ((_a = this._nodes[idx]) !== null && _a !== void 0 ? _a : this.addNewNode(idx, node_to_copy.name));
            node.copyFrom(node_to_copy);
            node.keepLinkOrderingFrom(node_to_copy, {}); // Same ordering
        });
    }
    /**
     * Copy some of all the other sankey attributes to this sankey
     * Modes list can contains all these options :
     * - 'attrDrawingArea' - Copy Attributes related to display on drawing area (ie styles)
     * - 'tagLevel' - Copy level tags
     * - 'tagNode' - Copy node tags
     * - 'tagFlux' - Copy flux tags
     * - 'tagData' - Copy data tags
     * @param {ClassTemplate_Sankey<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>} other_sankey
     * @param {string[]} mode
     * @memberof ClassTemplate_Sankey
     */
    updateFrom(other_sankey, mode) {
        const matching_taggs_id = {};
        const matching_tags_id = {};
        const matching_nodes_id = {};
        const matching_links_id = {};
        other_sankey.matchAndModifyJSONIds(this.toJSON(), matching_taggs_id, matching_tags_id, matching_nodes_id, matching_links_id);
        const revert_matching_links_id = {};
        Object.entries(matching_links_id).forEach(([k, v]) => revert_matching_links_id[v] = k);
        // Local variables to avoid recomputations ------------------------------------------
        const all = mode.includes('*');
        // Transfer DA attribut from other sankey to current (+ nodes/links style)------------
        if (mode.includes('attrDrawingArea') || all) {
            // Nodes styles can be to remove, to add or to update
            const [ns_to_remove, ns_to_add, ns_to_update] = get_sync_lists(this._node_styles, other_sankey._node_styles, {});
            // Update styles
            ns_to_remove
                .forEach(id => {
                this._node_styles[id].delete();
            });
            ns_to_add
                .forEach(id => {
                const ns = other_sankey._node_styles[id];
                this.addNewNodeStyle(ns.id, ns.name);
                this._node_styles[ns.id].copyFrom(ns);
            });
            ns_to_update
                .forEach(id => {
                this._node_styles[id].copyFrom(other_sankey._node_styles[id]);
            });
            // Link styles can be to remove, to add or to update
            const [ls_to_remove, ls_to_add, ls_to_update] = get_sync_lists(this._link_styles, other_sankey._link_styles, {});
            // Update styles
            ls_to_remove
                .forEach(id => {
                this._link_styles[id].delete();
            });
            ls_to_add
                .forEach(id => {
                const ls = other_sankey._link_styles[id];
                this.addNewLinkStyle(ls.id, ls.name);
                this._link_styles[ls.id].copyFrom(ls);
            });
            ls_to_update
                .forEach(id => {
                this._link_styles[id].copyFrom(other_sankey._link_styles[id]);
            });
        }
        // Update level_tag_dict ------------------------------------------------------------
        if (mode.includes('tagLevel') || all) {
            // Finds the corresponding tag group by ids
            const [to_remove, to_add, to_update] = get_sync_lists(this._level_taggs, other_sankey._level_taggs, matching_taggs_id['levelTags']);
            // Update taggs
            to_remove
                .forEach(id => {
                this.removeTagGroupWithId('level_taggs', id);
            });
            to_add
                .forEach(id => {
                var _a;
                const ltagg = other_sankey._level_taggs[(_a = matching_taggs_id['levelTags'][id]) !== null && _a !== void 0 ? _a : id];
                this.addLevelTagGroup(ltagg.id, ltagg.name);
                this._level_taggs[id].copyFrom(ltagg);
            });
            to_update
                .forEach(id => {
                var _a;
                this._level_taggs[id].copyFrom(other_sankey._level_taggs[(_a = matching_taggs_id['levelTags'][id]) !== null && _a !== void 0 ? _a : id]);
            });
        }
        // Update node_tag_dict ------------------------------------------------------------
        if (mode.includes('tagNode') || all) {
            // Finds the corresponding tag group by ids
            const [to_remove, to_add, to_update] = get_sync_lists(this._node_taggs, other_sankey._node_taggs, matching_taggs_id['nodeTags']);
            // Update taggs
            to_remove
                .forEach(id => {
                this.removeTagGroupWithId('node_taggs', id);
            });
            to_add
                .forEach(id => {
                var _a;
                const ntagg = other_sankey._node_taggs[(_a = matching_taggs_id['nodeTags'][id]) !== null && _a !== void 0 ? _a : id];
                this.addNodeTagGroup(ntagg.id, ntagg.name);
                this._node_taggs[id].copyFrom(ntagg);
            });
            to_update
                .forEach(id => {
                var _a;
                this._node_taggs[id].copyFrom(other_sankey._node_taggs[(_a = matching_taggs_id['nodeTags'][id]) !== null && _a !== void 0 ? _a : id], matching_tags_id['nodeTags'][id]);
            });
        }
        // Update flux_tag_dict ------------------------------------------------------------
        if (mode.includes('tagFlux') || all) {
            // Finds the corresponding tag group by ids
            const [to_remove, to_add, to_update] = get_sync_lists(this._flux_taggs, other_sankey._flux_taggs, matching_taggs_id['fluxTags']);
            // Update taggs
            to_remove
                .forEach(id => {
                this.removeTagGroupWithId('flux_taggs', id);
            });
            to_add
                .forEach(id => {
                var _a;
                const ftagg = other_sankey._flux_taggs[(_a = matching_taggs_id['fluxTags'][id]) !== null && _a !== void 0 ? _a : id];
                this.addFluxTagGroup(ftagg.id, ftagg.name);
                this._flux_taggs[id].copyFrom(ftagg);
            });
            to_update
                .forEach(id => {
                var _a;
                this._flux_taggs[id].copyFrom(other_sankey._flux_taggs[(_a = matching_taggs_id['fluxTags'][id]) !== null && _a !== void 0 ? _a : id], matching_tags_id['fluxTags'][id]);
            });
        }
        // Update data_tag_dict ------------------------------------------------------------
        if (mode.includes('tagData') || all) {
            // Finds the corresponding tag group by ids
            const [to_remove, to_add, to_update] = get_sync_lists(this._data_taggs, other_sankey._data_taggs, matching_taggs_id['dataTags']);
            // Update taggs
            to_remove
                .forEach(id => {
                this.removeTagGroupWithId('data_taggs', id);
            });
            to_add
                .forEach(id => {
                var _a;
                const dtagg = other_sankey._data_taggs[(_a = matching_taggs_id['dataTags'][id]) !== null && _a !== void 0 ? _a : id];
                this.addDataTagGroup(dtagg.id, dtagg.name);
                this._data_taggs[id].copyFrom(dtagg);
            });
            to_update
                .forEach(id => {
                var _a;
                this._data_taggs[id].copyFrom(other_sankey._data_taggs[(_a = matching_taggs_id['dataTags'][id]) !== null && _a !== void 0 ? _a : id], matching_tags_id['dataTags'][id]);
            });
        }
        // Nodes  ---------------------------------------------------------------------------
        const add_nodes = mode.includes('addNode');
        const remove_nodes = mode.includes('removeNodes');
        const sync_nodes_tags = mode.includes('tagNode');
        const sync_nodes_positions = mode.includes('posNode');
        const sync_nodes_attr = mode.includes('attrNode');
        if (add_nodes ||
            remove_nodes ||
            sync_nodes_tags ||
            sync_nodes_positions ||
            sync_nodes_attr ||
            all) {
            const [to_remove, to_add, to_update] = get_sync_lists(this._nodes, other_sankey._nodes, matching_nodes_id);
            // Add nodes that are in other sankey but not in this sankey
            if (add_nodes || all) {
                to_add
                    .forEach(id => {
                    var _a;
                    const n = other_sankey._nodes[(_a = matching_nodes_id[id]) !== null && _a !== void 0 ? _a : id];
                    this.addNewNode(n.id, n.name);
                    this._nodes[id].copyFrom(n);
                    return id;
                });
            }
            // Delete nodes that are in other sankey but not in this sankey
            if (remove_nodes || all) {
                to_remove
                    .forEach(id => {
                    this.drawing_area.deleteNode(this._nodes[id]);
                });
            }
            // With attrNode we transfer node attr
            if (sync_nodes_attr || all) {
                // Transfer node attr from new_layout node to correspondinf node in current
                to_update
                    .forEach(id => {
                    var _a;
                    const n = this._nodes[id];
                    const pn = structuredClone(n.display.position); // Save position
                    const on = other_sankey._nodes[(_a = matching_nodes_id[id]) !== null && _a !== void 0 ? _a : id];
                    n.copyAttrFrom(on); // Copy attributes
                    n.display.position = pn; // Reapply position
                    return id;
                });
            }
            // Update nodes ref to node_taggs
            if ((sync_nodes_tags) || all) {
                to_update
                    .forEach(id => {
                    var _a;
                    this._nodes[id].copyTagsReferencingFrom(other_sankey._nodes[(_a = matching_nodes_id[id]) !== null && _a !== void 0 ? _a : id], matching_taggs_id['nodeTags'], matching_tags_id['nodeTags']);
                });
                // Update nodes ref to node added
                if ((add_nodes) || all) {
                    to_add
                        .forEach(id => {
                        var _a;
                        this._nodes[id].copyTagsReferencingFrom(other_sankey._nodes[(_a = matching_nodes_id[id]) !== null && _a !== void 0 ? _a : id], matching_taggs_id['nodeTags'], matching_tags_id['nodeTags']);
                    });
                }
            }
            // Update node position from other sankey
            if (sync_nodes_positions || all) {
                to_update
                    .forEach(id => {
                    var _a;
                    const n = other_sankey._nodes[(_a = matching_nodes_id[id]) !== null && _a !== void 0 ? _a : id];
                    this._nodes[id].setPosXY(n.position_x, n.position_y);
                });
            }
        }
        // Links -------------------------------------------------------------------------
        const add_flux = mode.includes('addFlux');
        const remove_flux = mode.includes('removeFlux');
        const pos_flux = mode.includes('posFlux');
        const sync_flux_tags = mode.includes('tagFlux');
        const sync_flux_values = mode.includes('Values');
        const sync_flux_attr = mode.includes('attrFlux');
        if (add_flux ||
            remove_flux ||
            sync_flux_tags ||
            sync_flux_values ||
            sync_flux_attr ||
            all) {
            const [to_remove, to_add, to_update] = get_sync_lists(this._links, other_sankey._links, matching_links_id);
            // Add link in new that are not in current then add them
            if (add_flux || all) {
                to_add
                    .forEach(id => {
                    var _a;
                    const link = other_sankey._links[(_a = matching_links_id[id]) !== null && _a !== void 0 ? _a : id];
                    const similar_src_curr = this._nodes[link.source.id];
                    const similar_trgt_curr = this._nodes[link.target.id];
                    if (similar_src_curr && similar_trgt_curr) {
                        // Copy with exactly the same atributs, source, targets, id, ...
                        this.addNewLinkWithId(id, similar_src_curr, similar_trgt_curr);
                        this._links[id].copyFrom(link);
                    }
                });
            }
            // Remove link in current that are not in new then delete them
            if (remove_flux || all) {
                to_remove
                    .forEach(id => {
                    this.drawing_area.deleteLink(this._links[id]);
                });
            }
            if (pos_flux || all) {
                to_update
                    .forEach(id => {
                    var _a, _b;
                    const link = this._links[id];
                    // Source node
                    const source = this._nodes[link.source.id];
                    const other_source = other_sankey._nodes[other_sankey._links[(_a = matching_links_id[id]) !== null && _a !== void 0 ? _a : id].source.id];
                    source.keepLinkOrderingFrom(other_source, revert_matching_links_id);
                    // Target node
                    const target = this._nodes[link.target.id];
                    const other_target = other_sankey._nodes[other_sankey._links[(_b = matching_links_id[id]) !== null && _b !== void 0 ? _b : id].target.id];
                    target.keepLinkOrderingFrom(other_target, revert_matching_links_id);
                });
            }
            // With attrFlux we transfer link attr
            if (sync_flux_attr || all) {
                to_update
                    .forEach(id => {
                    var _a;
                    const link = this._links[id];
                    // Save positions
                    const sp = structuredClone(link.source.display.position);
                    const tp = structuredClone(link.target.display.position);
                    // Copy all attributes
                    link.copyAttrFrom(other_sankey._links[(_a = matching_links_id[id]) !== null && _a !== void 0 ? _a : id]);
                    // Keep positions
                    link.source.display.position = sp;
                    link.target.display.position = tp;
                });
            }
            if (add_flux || remove_flux || all) {
                const list_link_post_update = this.links_list.map(l => l.id);
                // Update links ordering
                const to_update_reorder = Object.assign([], to_update);
                if (add_flux || all)
                    to_update_reorder.concat(to_add);
                to_update_reorder
                    .filter(id => list_link_post_update.includes(id)) // only keep link really added
                    .forEach(id => {
                    var _a, _b;
                    // Source node
                    const source = this._nodes[this._links[id].source.id];
                    const other_source = other_sankey._nodes[other_sankey._links[(_a = matching_links_id[id]) !== null && _a !== void 0 ? _a : id].source.id];
                    source.keepLinkOrderingFrom(other_source, revert_matching_links_id);
                    // Target node
                    const target = this._nodes[this._links[id].target.id];
                    const other_target = other_sankey._nodes[other_sankey._links[(_b = matching_links_id[id]) !== null && _b !== void 0 ? _b : id].target.id];
                    target.keepLinkOrderingFrom(other_target, revert_matching_links_id);
                });
            }
            // Values  ------------------------------------------------------------------------
            let to_update_for_values = Object.assign([], to_update);
            if (all || add_flux)
                to_update_for_values = to_update_for_values.concat(to_add);
            // /!\ other sankey must but an ancient version of the current sankey because each link value has an unique id
            if (((sync_flux_tags || sync_flux_values)) || all) {
                // To speed up matching process between values ids (that are random)
                // We compute corresp value ids for sync_flux_tags & sync_flux_values
                const values_corresp_ids = {};
                to_update_for_values
                    .forEach(id_flux => {
                    var _a;
                    // avoid recomputation
                    const values = this._links[id_flux].getAllValues();
                    const other_values = other_sankey._links[(_a = matching_links_id[id_flux]) !== null && _a !== void 0 ? _a : id_flux].getAllValues();
                    // Init corresps list
                    values_corresp_ids[id_flux] = {};
                    if (Object.keys(values).length > 0) {
                        // Case 1 : No datatags - only one value per flux
                        if (Object.values(values)[1] === undefined) {
                            values_corresp_ids[id_flux][Object.keys(values)[0]] = Object.keys(other_values)[0];
                        }
                        // Case 2 : Datatags are present
                        else {
                            Object.entries(values)
                                .forEach(([id_value, [, dtags]]) => {
                                if (dtags !== undefined) { // Should never be the case
                                    // Find values match based on datatags ids
                                    const dtags_id = dtags.map(dtag => dtag.id);
                                    Object.entries(other_values)
                                        .filter(([, [, other_dtags]]) => {
                                        if (other_dtags !== undefined)
                                            return (JSON.stringify(dtags_id) ===
                                                JSON.stringify(other_dtags.map(other_dtag => other_dtag.id)));
                                        else
                                            return false; // Should never be the case
                                    })
                                        .forEach(([id_other_value,]) => {
                                        values_corresp_ids[id_flux][id_value] = id_other_value;
                                    });
                                }
                            });
                        }
                    }
                });
                // Update refs between values and flux_tags
                if ((sync_flux_tags && (add_flux || remove_flux)) || all) {
                    to_update_for_values
                        .forEach(id_flux => {
                        var _a;
                        // Avid recomputation
                        const link = this._links[id_flux];
                        const values = link.getAllValues();
                        const other_link = other_sankey._links[(_a = matching_links_id[id_flux]) !== null && _a !== void 0 ? _a : id_flux];
                        const other_values = other_link.getAllValues();
                        // Loop on all current values for given flux id_flux
                        Object.entries(values)
                            .forEach(([id_value, [value,]]) => {
                            // Remove all tags for all current fluxs
                            value.flux_tags_list
                                .forEach(tag => {
                                value.removeTag(tag);
                            });
                            // Get corresponding value to copy
                            const id_other_value = values_corresp_ids[id_flux][id_value];
                            if (id_other_value !== undefined) {
                                const other_value = other_values[id_other_value][0];
                                // Apply same flux-tag relationship from new_layout to current sankey's fluxs
                                other_value.flux_tags_list
                                    .filter(tag => tag.group.id in this._flux_taggs)
                                    .filter(tag => tag.id in this._flux_taggs[tag.group.id].tags_dict)
                                    .forEach(tag => value.addTag(tag));
                            }
                        });
                    });
                }
                // Apply links values from other sankey to current links
                if (sync_flux_values || all) {
                    to_update_for_values
                        .forEach(id_flux => {
                        var _a;
                        // Avid recomputation
                        const link = this._links[id_flux];
                        const values = link.getAllValues();
                        const other_link = other_sankey._links[(_a = matching_links_id[id_flux]) !== null && _a !== void 0 ? _a : id_flux];
                        const other_values = other_link.getAllValues();
                        // Loop on all current values for given flux id_flux
                        Object.entries(values)
                            .forEach(([id_value, [value,]]) => {
                            // Get corresponding value to copy
                            const id_other_value = values_corresp_ids[id_flux][id_value];
                            if (id_other_value !== undefined) {
                                value.copyFrom(other_values[id_other_value][0]);
                            }
                        });
                    });
                }
            }
        }
    }
    /**
     * Extract sankey as a JSON struct
     *
     * @param {boolean} [only_visible_elements=false]
     * @param {boolean} [with_values=true]
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    toJSON(only_visible_elements = default_save_only_visible_elements, with_values = default_save_with_values) {
        // Create json struct
        const json_object = {};
        const json_object_levelTags = {};
        const json_object_nodeTags = {};
        const json_object_fluxTags = {};
        const json_object_dataTags = {};
        const json_object_styles_nodes = {};
        const json_object_styles_links = {};
        const json_object_nodes = {};
        const json_object_links = {};
        // Id
        json_object['id'] = this._id;
        // Add tag groups
        json_object['levelTags'] = json_object_levelTags;
        this.level_taggs_list.forEach(tagg => {
            json_object_levelTags[tagg.id] = tagg.toJSON();
        });
        json_object['nodeTags'] = json_object_nodeTags;
        this.node_taggs_list.forEach(tagg => {
            json_object_nodeTags[tagg.id] = tagg.toJSON();
        });
        json_object['fluxTags'] = json_object_fluxTags;
        this.flux_taggs_list.forEach(tagg => {
            json_object_fluxTags[tagg.id] = tagg.toJSON();
        });
        json_object['dataTags'] = json_object_dataTags;
        this.data_taggs_list.forEach(tagg => {
            json_object_dataTags[tagg.id] = tagg.toJSON();
        });
        // Add Styles
        json_object['style_node'] = json_object_styles_nodes;
        this.node_styles_list.forEach(style => {
            json_object_styles_nodes[style.id] = style.toJSON();
            json_object_styles_nodes[style.id]['name'] = style.name;
        });
        json_object['style_link'] = json_object_styles_links;
        this.link_styles_list.forEach(style => {
            json_object_styles_links[style.id] = style.toJSON();
            json_object_styles_links[style.id]['name'] = style.name;
        });
        // Add nodes
        json_object['nodes'] = json_object_nodes;
        const nodes_list = (only_visible_elements ? this.visible_nodes_list : this.nodes_list);
        nodes_list
            .forEach(node => {
            json_object_nodes[node.id] = node.toJSON();
        });
        // Add links
        json_object['links'] = json_object_links;
        const links_list = (only_visible_elements ? this.visible_links_list : this.links_list);
        links_list
            .sort((a, b) => sortLinksElementsByDisplayingOrders(a, b))
            .forEach(link => {
            json_object_links[link.id] = link.toJSON({ 'with_values': with_values });
        });
        // Out
        return json_object;
    }
    /**
     * Setting value of sankey and substructur from JSON
     *
     * @param {{[_:string]:any} json_object
     * @memberof ClassTemplate_Legend
    */
    fromJSON(json_object, match_and_update = false) {
        // Id
        this._id = getStringFromJSON(json_object, 'id', this._id);
        // If we use json object only for updateing layout,
        // we need to find correspondances for tags, nodes and links ids
        // from input JSON to this Sankey
        const matching_taggs_id = {};
        const matching_tags_id = {};
        const matching_nodes_id = {};
        const matching_links_id = {};
        if (match_and_update) {
            this.matchAndModifyJSONIds(json_object, matching_taggs_id, matching_tags_id, matching_nodes_id, matching_links_id);
        }
        // First read styles
        if (json_object['style_node'] !== undefined) {
            // Set node styles from json data
            Object.entries(json_object['style_node'])
                .forEach(([style_id, style_json]) => {
                var _a;
                // Create a node style
                const new_style = (_a = this._node_styles[style_id]) !== null && _a !== void 0 ? _a : this.createNewNodeStyle(style_id, style_id, true);
                // Set node style value to node from JSON
                new_style.fromJSON(style_json);
                new_style.name = getStringFromJSON(style_json, 'name', new_style.id);
                // Add node style to sankey
                this._node_styles[style_id] = new_style;
            });
        }
        if (json_object['style_link'] !== undefined) {
            // Set link styles from json data
            Object.entries(json_object['style_link'])
                .forEach(([style_id, style_json]) => {
                var _a;
                // Create a link style
                const new_style = (_a = this._link_styles[style_id]) !== null && _a !== void 0 ? _a : this.createNewLinkStyle(style_id, style_id, true);
                // Set link style value to link style from JSON
                new_style.fromJSON(style_json);
                new_style.name = getStringFromJSON(style_json, 'name', new_style.id);
                // Add link style to sankey
                this._link_styles[style_id] = new_style;
            });
        }
        // Then read tag groups
        let json_entry = 'levelTags';
        if (json_object[json_entry] !== undefined) {
            // Set level tag & tag group from json data
            Object.entries(json_object[json_entry])
                .forEach(([_, tagg_json]) => {
                var _a, _b, _c;
                // Get or create a level tag group
                const tagg_id = (_a = matching_taggs_id[json_entry][_]) !== null && _a !== void 0 ? _a : _;
                const tagg = (_b = this._level_taggs[tagg_id]) !== null && _b !== void 0 ? _b : this.addLevelTagGroup(tagg_id, tagg_id); // Will be renamed in fromJSON()
                // Set level tag group value from JSON
                tagg.fromJSON(tagg_json, (_c = matching_tags_id[json_entry][_]) !== null && _c !== void 0 ? _c : {});
            });
        }
        json_entry = 'nodeTags';
        if (json_object[json_entry] !== undefined) {
            // Set node tag & tag group from json data
            Object.entries(json_object[json_entry])
                .forEach(([_, tagg_json]) => {
                var _a, _b, _c;
                // Get or Create a node tag group
                const tagg_id = (_a = matching_taggs_id[json_entry][_]) !== null && _a !== void 0 ? _a : _;
                const tagg = (_b = this._node_taggs[tagg_id]) !== null && _b !== void 0 ? _b : this.addNodeTagGroup(tagg_id, tagg_id, false); // Will be renamed in fromJSON()
                // Set node tag group value from JSON
                tagg.fromJSON(tagg_json, (_c = matching_tags_id[json_entry][_]) !== null && _c !== void 0 ? _c : {});
            });
            // Create default style for 'Type de noeud' if they don't exist
            if (Object.keys(json_object[json_entry]).includes('type de noeud')) {
                Object.entries({
                    'NodeProductStyle': DefaultNodeProductStyle,
                    'NodeSectorStyle': DefaultNodeSectorStyle,
                    'NodeImportStyle': DefaultNodeImportStyle,
                    'NodeExportStyle': DefaultNodeExportStyle
                })
                    .forEach(([style_id, fn]) => {
                    if (this._node_styles[style_id]) {
                        // if style already exists do nothings
                        return;
                    }
                    const json_style = fn();
                    const new_style = this.createNewNodeStyle(style_id, json_style.name, true);
                    // Set node style value to node from JSON
                    new_style.fromJSON(json_style);
                    // Add node style to sankey
                    this._node_styles[style_id] = new_style;
                });
                Object.entries({
                    'LinkImportStyle': DefaultLinkImportStyle,
                    'LinkExportStyle': DefaultLinkExportStyle
                })
                    .forEach(([style_id, fn]) => {
                    if (this._link_styles[style_id]) {
                        // if style already exists do nothing
                        return;
                    }
                    const json_style = fn();
                    const new_style = this.createNewLinkStyle(style_id, json_style.name, true);
                    // Set node style value to node from JSON
                    new_style.fromJSON(fn());
                    // Add node style to sankey
                    this._link_styles[style_id] = new_style;
                });
            }
        }
        json_entry = 'fluxTags';
        if (json_object[json_entry] !== undefined) {
            // Set flux tag & tag group from json data
            Object.entries(json_object[json_entry])
                .forEach(([_, tagg_json]) => {
                var _a, _b, _c;
                // Get or Create a flux tag group
                const tagg_id = (_a = matching_taggs_id[json_entry][_]) !== null && _a !== void 0 ? _a : _;
                const tagg = (_b = this._flux_taggs[tagg_id]) !== null && _b !== void 0 ? _b : this.addFluxTagGroup(tagg_id, tagg_id, false); // Will be renamed in fromJSON()
                // Set flux tag group value from JSON
                tagg.fromJSON(tagg_json, (_c = matching_tags_id[json_entry][_]) !== null && _c !== void 0 ? _c : {});
            });
        }
        json_entry = 'dataTags';
        if (json_object[json_entry] !== undefined) {
            // Set data tag & tag group from json data
            Object.entries(json_object[json_entry])
                .forEach(([_, tagg_json]) => {
                var _a, _b, _c;
                // Get or Create a flux tag group
                const tagg_id = (_a = matching_taggs_id[json_entry][_]) !== null && _a !== void 0 ? _a : _;
                const tagg = (_b = this._data_taggs[tagg_id]) !== null && _b !== void 0 ? _b : this.addDataTagGroup(tagg_id, tagg_id, false); // Will be renamed in fromJSON()
                // Set flux tag group value from JSON
                tagg.fromJSON(tagg_json, (_c = matching_tags_id[json_entry][_]) !== null && _c !== void 0 ? _c : {});
            });
        }
        // Then read links
        const json_link_object = getJSONFromJSON(json_object, 'links', {});
        Object.entries(json_link_object)
            .forEach(([_, link_json]) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            // Get related nodes id
            let source_node_id = getStringOrUndefinedFromJSON(link_json, 'idSource');
            let target_node_id = getStringOrUndefinedFromJSON(link_json, 'idTarget');
            if (source_node_id && target_node_id) {
                // Get or create related nodes
                source_node_id = (_a = matching_nodes_id[source_node_id]) !== null && _a !== void 0 ? _a : source_node_id;
                const source = (_b = this._nodes[source_node_id]) !== null && _b !== void 0 ? _b : this.addNewNode(source_node_id, source_node_id);
                target_node_id = (_c = matching_nodes_id[target_node_id]) !== null && _c !== void 0 ? _c : target_node_id;
                const target = (_d = this._nodes[target_node_id]) !== null && _d !== void 0 ? _d : this.addNewNode(target_node_id, target_node_id);
                // Get or create link
                const link_id = (_e = matching_links_id[_]) !== null && _e !== void 0 ? _e : _;
                const link = (_f = this._links[link_id]) !== null && _f !== void 0 ? _f : this.addNewLinkWithId(link_id, source, target);
                // Set link value to link from JSON
                link.fromJSON(link_json, {
                    'matching_taggs_id': (_g = matching_taggs_id['fluxTags']) !== null && _g !== void 0 ? _g : {},
                    'matching_tags_id': (_h = matching_tags_id['fluxTags']) !== null && _h !== void 0 ? _h : {}
                });
            }
        });
        // Then read nodes
        const json_node_object = getJSONFromJSON(json_object, 'nodes', {});
        Object.entries(json_node_object)
            .forEach(([_, node_json]) => {
            var _a, _b, _c, _d;
            // Get or Create a node
            const node_id = (_a = matching_nodes_id[_]) !== null && _a !== void 0 ? _a : _;
            const node = (_b = this._nodes[node_id]) !== null && _b !== void 0 ? _b : this.addNewNode(node_id, node_id);
            // Set node value to node from JSON
            node.fromJSON(node_json, {
                'matching_taggs_id': Object.assign(Object.assign({}, matching_taggs_id['nodeTags']), matching_taggs_id['levelTags']),
                'matching_tags_id': Object.assign(Object.assign({}, matching_tags_id['nodeTags']), matching_tags_id['levelTags'])
            });
            // Order links io position in each nodes
            node.linksFromJSON(getJSONFromJSON(json_node_object, node.id, {}), matching_links_id);
            // Set dimensions
            node.dimensionsFromJSON(node_json, matching_nodes_id, (_c = matching_taggs_id['levelTags']) !== null && _c !== void 0 ? _c : {}, (_d = matching_tags_id['levelTags']) !== null && _d !== void 0 ? _d : {});
        });
        //if (Object.keys(json_object[json_entry]).includes('type de noeud')) {
        // Change style if node has default style & 'Type de noeud' tags
        this.nodes_list.forEach(n => {
            const tagg = this.node_taggs_dict['type de noeud'];
            if (!tagg) {
                return;
            }
            const product_tag = tagg.tags_dict['produit'];
            const sector_tag = tagg.tags_dict['secteur'];
            if (n.hasGivenTag(product_tag) && n.style.id === 'default') {
                n.style = this.node_styles_dict['NodeProductStyle'];
            }
            else if (n.hasGivenTag(sector_tag) && n.style.id === 'default') {
                n.style = this.node_styles_dict['NodeSectorStyle'];
            }
        });
        //}
    }
    matchAndModifyJSONIds(json_object, matching_taggs_id = {}, matching_tags_id = {}, matching_nodes_id = {}, matching_links_id = {}) {
        // Loop on every tag group entries in JSON if there is data -------------------------
        const loop_taggs = {
            'levelTags': this._level_taggs,
            'nodeTags': this._node_taggs,
            'fluxTags': this._flux_taggs,
            'dataTags': this._data_taggs,
        };
        Object.entries(loop_taggs)
            .forEach(([tagg_type, tagg_dict]) => {
            if (json_object[tagg_type] !== undefined) {
                // Variable to save matching ids : old -> new
                const curr_matching_taggs_id = {};
                const curr_matching_tags_id = {};
                // Cast type for linter
                const json = json_object[tagg_type];
                // Loop on all entries to find tag group and then tags matchs
                Object.entries(json)
                    .forEach(([tagg_id, _]) => {
                    // Cast type
                    const tagg_json = _;
                    // Match tag groups between sankey and JSON that have the same name but different id
                    const matching_taggs = Object.values(tagg_dict)
                        .filter(tagg => {
                        return ((tagg.name === getStringOrUndefinedFromJSON(tagg_json, 'name')) &&
                            (tagg.id !== tagg_id));
                    });
                    // We need to find a unique matching entry in JSON
                    if (matching_taggs.length === 1) {
                        curr_matching_taggs_id[tagg_id] = matching_taggs[0].id;
                    }
                    // Then match tags using the same methode
                    curr_matching_tags_id[tagg_id] = {};
                    Object.entries(tagg_json.tags)
                        .forEach(([tag_id, __]) => {
                        var _a, _b;
                        // Get related tag group
                        const new_tagg_id = (_a = curr_matching_taggs_id[tagg_id]) !== null && _a !== void 0 ? _a : tagg_id;
                        const tagg = (_b = tagg_dict[new_tagg_id]) !== null && _b !== void 0 ? _b : undefined;
                        if (tagg) {
                            // Casting type
                            const tag_json = __;
                            // Match tag group in json data with theses in sankey data using name
                            const matching_tags = tagg.tags_list
                                .filter(tag => {
                                return ((tag.name === getStringOrUndefinedFromJSON(tag_json, 'name')) &&
                                    (tag.id !== tag_id));
                            });
                            // We need to find a unique matching entry in JSON
                            if (matching_tags.length === 1) {
                                curr_matching_tags_id[tagg_id][tag_id] = matching_tags[0].id;
                            }
                        }
                    });
                });
                // Save results
                matching_taggs_id[tagg_type] = curr_matching_taggs_id;
                matching_tags_id[tagg_type] = curr_matching_tags_id;
            }
        });
        // Loop on all nodes ------------------------------------------------------------
        // Cast type for linter
        const nodes_json = json_object['nodes'];
        Object.entries(nodes_json)
            .forEach(([node_id, _]) => {
            // Cast type for linter
            const node_json = _;
            // Loop on all existing node and try to find match based on names
            const matching_nodes = this.nodes_list
                .filter(node => {
                return ((node.name === getStringOrUndefinedFromJSON(node_json, 'name')) &&
                    (node.id !== node_id));
            });
            // There must be only one matching node
            if (matching_nodes.length === 1) {
                matching_nodes_id[node_id] = matching_nodes[0].id;
            }
        });
        // Loop on all links ------------------------------------------------------------
        // Cast type for linter
        const links_json = json_object['links'];
        Object.entries(links_json)
            .forEach(([link_id, _]) => {
            // Cast type for linter
            const link_json = _;
            // Loop on all existing link and try to find match based on names
            const matching_links = this.links_list
                .filter(link => {
                var _a, _b;
                let source_id = getStringFromJSON(link_json, 'idSource', '');
                source_id = (_a = matching_nodes_id[source_id]) !== null && _a !== void 0 ? _a : source_id;
                let target_id = getStringFromJSON(link_json, 'idTarget', '');
                target_id = (_b = matching_nodes_id[target_id]) !== null && _b !== void 0 ? _b : target_id;
                return ((link.source.id === source_id) &&
                    (link.target.id === target_id) &&
                    (link.id !== link_id));
            });
            // There must be only one matching link
            if (matching_links.length === 1) {
                matching_links_id[link_id] = matching_links[0].id;
            }
        });
    }
    // PUBLIC METHODS =====================================================================
    // All --------------------------------------------------------------------------------
    draw() {
        // // Draw links
        // this.links_list.forEach(link => link.draw())
        // Draw nodes
        this.nodes_list.forEach(node => node.draw());
    }
    // Nodes related ----------------------------------------------------------------------
    /**
     * Create and add a node for this Sankey
     * @param {string} id
     * @param {string} name
     * @return {Class_Node}
     * @memberof ClassTemplate_Sankey
     */
    addNewNode(id, name) {
        if (!this._nodes[id]) {
            // Create node
            const node = this.createNewNode(id, name);
            // Set node to default position
            node.initDefaultPosXY();
            // Update registry of nodes
            this._addNode(node);
            return node;
        }
        else {
            return this.addNewNode(id + '_0', name + '_0');
        }
    }
    /**
     * Create and add a node for this Sankey with default name
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    addNewDefaultNode() {
        const n = String(Object.values(this._nodes).length);
        const id = 'node' + n;
        const name = 'Node ' + n;
        return this.addNewNode(id, name);
    }
    /**
    * Get a specific node from this Sankey
    * @param {string} id
    * @return {*}
    * @memberof ClassTemplate_Sankey
    */
    getNode(id) {
        if (id in this._nodes) {
            return this._nodes[id];
        }
        return null;
    }
    /**
     * Delete a given node from Sankey -> node may still exist somewhere
     * @param {Class_Node} node
     * @memberof ClassTemplate_Sankey
     */
    deleteNode(node) {
        if (this._nodes[node.id] !== undefined) {
            // if we remove a node we also have to remove it link attached to it
            node.input_links_list.forEach(l => this.drawing_area.deleteLink(l));
            node.output_links_list.forEach(l => this.drawing_area.deleteLink(l));
            // Delete node in sankey
            const _ = this._nodes[node.id];
            delete this._nodes[node.id];
            _.delete();
        }
    }
    // Links related ----------------------------------------------------------------------
    /**
     * Create a new link from source to target
     *
     * @param {Type_GenericNodeElement} source
     * @param {Type_GenericNodeElement} target
     * @return {*}  {Type_GenericLinkElement}
     * @memberof ClassTemplate_Sankey
     */
    addNewLink(source, target) {
        return this.addNewLinkWithId(defaultLinkId(source, target), source, target);
    }
    /**
     * Create a new link from source to target.
     * Check that we always have unique id
     *
     * @private
     * @param {string} id
     * @param {Type_GenericNodeElement} source
     * @param {Type_GenericNodeElement} target
     * @return {*}  {Type_GenericLinkElement}
     * @memberof ClassTemplate_Sankey
     */
    addNewLinkWithId(id, source, target) {
        if (!this._links[id]) {
            const link = this.createNewLink(id, source, target);
            this._addLink(link);
            return link;
        }
        else {
            return this.addNewLinkWithId(id + ' (dup)', source, target);
        }
    }
    /**
     * Create a new default link : select a default source and default target
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    addNewDefaultLink() {
        let source;
        let target;
        if (this.nodes_list.length > 2) {
            source = this.nodes_list[0];
            target = this.nodes_list[1];
        }
        else if (this.nodes_list.length == 1) {
            source = this.nodes_list[0];
            target = this.addNewDefaultNode();
            target.setPosXY(source.position_x + 100, source.position_y + 100);
        }
        else {
            source = this.addNewDefaultNode(); // Set with default position
            target = this.addNewDefaultNode();
            target.setPosXY(source.position_x + 100, source.position_y + 100);
        }
        return this.addNewLink(source, target);
    }
    /**
     * Get link object by its id
     *
     * @param {string} id
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    getLink(id) {
        if (id in this._links) {
            return this._links[id];
        }
        return null;
    }
    /**
     * Remove a given link from sankey
     * @param {Type_GenericLinkElement} link
     * @memberof ClassTemplate_Sankey
     */
    removeLink(link) {
        delete this._links[link.id];
    }
    // Style related -----------------------------------------------------------------------
    /**
     * Create a new default style for node
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    addNewDefaultNodeStyle() {
        const _ = String(this.node_styles_list.length);
        return this.addNewNodeStyle('style_node_' + _, 'Style ' + _);
    }
    /**
     * Create a new style for node
     * @param {string} id
     * @param {string} name
     * @return {*}  {Class_NodeStyle}
     * @memberof ClassTemplate_Sankey
     */
    addNewNodeStyle(id, name) {
        if (!this._node_styles[id]) {
            const style = new Class_NodeStyle(id, name, true);
            this._node_styles[id] = style;
            return style;
        }
        else {
            return this.addNewNodeStyle(id + ' (dup)', name);
        }
    }
    /**
     * Delete a given style
     * @param {Class_NodeStyle} style
     * @memberof ClassTemplate_Sankey
     */
    deleteNodeStyle(style) {
        if (this._node_styles[style.id] !== undefined) {
            this._node_styles[style.id].delete();
            delete this._node_styles[style.id];
        }
    }
    /**
     * Create a new default style for link
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    addNewDefaultLinkStyle() {
        const _ = String(this.link_styles_list.length);
        return this.addNewLinkStyle('style_link_' + _, 'Style ' + _);
    }
    /**
     * Create a new style for link
     * @param {string} id
     * @param {string} name
     * @return {*}  {LinkAttributes}
     * @memberof ClassTemplate_Sankey
     */
    addNewLinkStyle(id, name) {
        if (!this._link_styles[id]) {
            const style = new Class_LinkStyle(id, name, true);
            this._link_styles[id] = style;
            return style;
        }
        else {
            return this.addNewLinkStyle(id + ' (dup)', name);
        }
    }
    /**
     * Delete a given style
     * @param {Class_NodeStyle} style
     * @memberof ClassTemplate_Sankey
     */
    deleteLinkStyle(style) {
        if (this._link_styles[style.id] !== undefined) {
            this._link_styles[style.id].delete();
            delete this._link_styles[style.id];
        }
    }
    // Tags related ------------------------------------------------------------------------
    triggerPrimaryLevelTagging() {
        // TODO deal with siblings tags
        if ('Primaire' in this._level_taggs) {
            if (this.level_taggs_list.length > 1) {
                this._level_taggs['Primaire'].activated = false;
            }
            else {
                this._level_taggs['Primaire'].activated = true;
            }
        }
    }
    addLevelTagGroup(id, name) {
        if (!this._level_taggs[id]) {
            // Create
            const tag_group = new Class_LevelTagGroup(id, name, this);
            // Update
            this._level_taggs[id] = tag_group;
            // Return
            return tag_group;
        }
        // Recursive to avoid id duplicates
        else {
            return this.addLevelTagGroup(id + '_0', name + '_0');
        }
    }
    addNodeTagGroup(id, name, with_a_tag = true) {
        if (!this._node_taggs[id]) {
            // Create
            const tag_group = new Class_NodeTagGroup(id, name, this, with_a_tag);
            // Update
            this._node_taggs[id] = tag_group;
            // Return
            return tag_group;
        }
        // Recursive to avoid id duplicates
        else {
            return this.addNodeTagGroup(id + '_0', name + '_0', with_a_tag);
        }
    }
    addFluxTagGroup(id, name, with_a_tag = true) {
        if (!this._flux_taggs[id]) {
            // Create
            const tag_group = new Class_FluxTagGroup(id, name, this, with_a_tag);
            // Update
            this._flux_taggs[id] = tag_group;
            // Return
            return tag_group;
        }
        // Recursive to avoid id duplicates
        else {
            return this.addFluxTagGroup(id + '_0', name + '_0', with_a_tag);
        }
    }
    addDataTagGroup(id, name, with_a_tag = true) {
        if (!this._data_taggs[id]) {
            // Create
            const tag_group = new Class_DataTagGroup(id, name, this, with_a_tag);
            // Update value tree
            this.links_list.forEach(link => link.addDataTagGroup(tag_group));
            // Update
            this._data_taggs[id] = tag_group;
            // Return
            return tag_group;
        }
        // Recursive to avoid id duplicates
        else {
            return this.addDataTagGroup(id + '_0', name + '_0', with_a_tag);
        }
    }
    /**
     * Create a TagGroup and add it to to specified group
     *
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    createTagGroup(type_group) {
        // Get a new id
        const n = Object.values(this.getTagGroupsAsDict(type_group)).length;
        const id = type_group + n;
        const name = 'Tag Group ' + n;
        // Create
        if (type_group === 'level_taggs') {
            return this.addLevelTagGroup(id, name);
        }
        else if (type_group === 'node_taggs') {
            return this.addNodeTagGroup(id, name);
        }
        else if (type_group === 'flux_taggs') {
            return this.addFluxTagGroup(id, name);
        }
        else {
            return this.addDataTagGroup(id, name);
        }
    }
    /**
     * Properly remove tag group related to given id
     * @param {Type_MacroTagGroup} type_group
     * @param {string} id
     * @memberof ClassTemplate_Sankey
     */
    removeTagGroupWithId(type_group, id) {
        const macro_tag_group = this.getTagGroupsAsDict(type_group);
        if (macro_tag_group[id] !== undefined) {
            // Get Tag group
            const tag_group = macro_tag_group[id];
            // Prune value tree for data tags
            if (tag_group instanceof Class_DataTagGroup)
                this.links_list.forEach(link => link.removeDataTagGroup(tag_group));
            // Delete tag groupe properly
            tag_group.delete();
            // Remove reference to tag group
            delete macro_tag_group[id];
        }
    }
    /**
     * Properly remove tag group
     * @param {Type_MacroTagGroup} type_group
     * @param {Class_NodeTagGroup | Class_FluxTagGroup | Class_LevelTagGroup | Class_DataTagGroup} tagg
     * @memberof ClassTemplate_Sankey
     */
    removeTagGroup(type_group, tagg) {
        this.removeTagGroupWithId(type_group, tagg.id);
    }
    /**
     * Return list of group tag from specified group type
     * @param {Type_MacroTagGroup} type_group
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    getTagGroupsAsList(type_group) {
        return Object.values(this.getTagGroupsAsDict(type_group));
    }
    /**
     * Return dict of group tag from specified group type
     * @param {Type_MacroTagGroup} type_group
     * @return {*}
     * @memberof ClassTemplate_Sankey
     */
    getTagGroupsAsDict(type_group) {
        if (type_group === 'node_taggs') {
            return this._node_taggs;
        }
        else if (type_group === 'flux_taggs') {
            return this._flux_taggs;
        }
        else if (type_group === 'data_taggs') {
            return this._data_taggs;
        }
        else {
            return this._level_taggs;
        }
    }
    /**
     * Update data tags random key to ensure that element's visibilty will be recalculated
     * @memberof ClassTemplate_Sankey
     */
    nodeTagsUpdated() {
        this._node_tags_fingerprint = randomId();
    }
    /**
     * Update data tags random key to ensure that element's visibilty will be recalculated
     * @memberof ClassTemplate_Sankey
     */
    fluxTagsUpdated() {
        this._flux_tags_fingerprint = randomId();
    }
    /**
     * Update data tags random key to ensure that element's visibilty will be recalculated
     * @memberof ClassTemplate_Sankey
     */
    dataTagsUpdated() {
        this._data_tags_fingerprint = randomId();
    }
    // PRIVATE METHODS ====================================================================
    // Nodes related ----------------------------------------------------------------------
    /**
     * Add a given node to Sankey
     * @param {Class_Node} node
     * @memberof ClassTemplate_Sankey
     */
    _addNode(node) { this._nodes[node.id] = node; }
    // Links related ----------------------------------------------------------------------
    /**
     * Add a given link to Sankey
     * @param {Type_GenericLinkElement} link
     * @memberof ClassTemplate_Sankey
     */
    _addLink(link) {
        this._links[link.id] = link;
    }
    // GETTERS / SETTERS ==================================================================
    get id() { return this._id; }
    get is_visible() { return true; } // Visibility always true for base sankey
    // Nodes related ----------------------------------------------------------------------
    /**
     * Get all nodes as dict
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get nodes_dict() {
        return this._nodes;
    }
    /**
     * Sorts nodes from lower v coordinates to higher v
     * @memberof ClassTemplate_Sankey
     */
    sortNodes() {
        const echangeTag = this.node_taggs_dict['type de noeud'] ? this.node_taggs_dict['type de noeud'].tags_dict['echange'] : undefined;
        const sorted_nodes = this.nodes_list.filter(n => !echangeTag || !n.hasGivenTag(echangeTag));
        sorted_nodes.sort((n1, n2) => {
            if (n1.position_v >= 0 || n2.position_v >= 0) {
                return n1.position_v - n2.position_v;
            }
            else {
                return n2.position_v - n1.position_v;
            }
        });
        const import_nodes = this.nodes_list.filter(n => echangeTag && n.hasGivenTag(echangeTag) && n.output_links_list.length > 0);
        import_nodes.sort((n1, n2) => {
            if (n1.position_v >= 0 || n2.position_v >= 0) {
                return n1.position_v - n2.position_v;
            }
            else {
                return n2.position_v - n1.position_v;
            }
        });
        const export_nodes = this.nodes_list.filter(n => echangeTag && n.hasGivenTag(echangeTag) && n.input_links_list.length > 0);
        export_nodes.sort((n1, n2) => {
            if (n1.position_v >= 0 || n2.position_v >= 0) {
                return n1.position_v - n2.position_v;
            }
            else {
                return n2.position_v - n1.position_v;
            }
        });
        const all_nodes = [...sorted_nodes, ...import_nodes, ...export_nodes];
        this._nodes = Object.assign({}, ...all_nodes.map((n) => ({ [n.id]: n })));
    }
    /**
     * Get all nodes as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get nodes_list() {
        return Object.values(this._nodes);
    }
    /**
     * Get all nodes sorted by their names as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get nodes_list_sorted() {
        return this.nodes_list
            .sort((a, b) => sortNodesElements(a, b));
    }
    /**
     * Get all visible nodes as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get visible_nodes_list() {
        return Object.values(this._nodes)
            .filter(node => node.is_visible);
    }
    /**
     * Get all nodes sorted by their names as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get visible_nodes_list_sorted() {
        return this.visible_nodes_list
            .sort((a, b) => sortNodesElements(a, b));
    }
    // Links related ----------------------------------------------------------------------
    /**
     * Return a dict with all the links of the sankey
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get links_dict() {
        return this._links;
    }
    /**
     * Return a list with all the links of the sankey
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get links_list() {
        return Object.values(this._links);
    }
    /**
     * Get all nodes sorted by their names as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get links_list_sorted() {
        return this.links_list
            .sort((a, b) => sortLinksElementsByIds(a, b));
    }
    /**
     * Get all visible links as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get visible_links_list() {
        return Object.values(this._links)
            .filter(node => node.is_visible);
    }
    /**
     * Get all links sorted by their names as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get visible_links_list_sorted() {
        return this.visible_links_list
            .sort((a, b) => sortLinksElementsByIds(a, b));
    }
    // Styles related ---------------------------------------------------------------------
    /**
     * Return the object containing all the style
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get node_styles_dict() {
        return this._node_styles;
    }
    /**
     * Return default style for nodes
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get default_node_style() {
        return this._node_styles[default_style_id];
    }
    /**
     * Return all the style as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get node_styles_list() {
        return Object.values(this._node_styles);
    }
    /**
     * Return all the style as a sorted list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get node_styles_list_sorted() {
        return this.node_styles_list
            .sort((a, b) => sortNodesElements(a, b));
    }
    /**
     * Return the object containing all the style
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get link_styles_dict() {
        return this._link_styles;
    }
    /**
     * Return all the style as a list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get link_styles_list() {
        return Object.values(this._link_styles);
    }
    /**
     * Return all the style as a sorted list
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get link_styles_list_sorted() {
        return this.link_styles_list
            .sort((a, b) => sortLinksElementsByIds(a, b));
    }
    // Tags related -----------------------------------------------------------------------
    get node_taggs_dict() {
        return this._node_taggs;
    }
    get node_taggs_list() {
        return Object.values(this._node_taggs);
    }
    get node_tags_fingerprint() {
        return this._node_tags_fingerprint;
    }
    get flux_taggs_dict() {
        return this._flux_taggs;
    }
    get flux_taggs_list() {
        return Object.values(this._flux_taggs);
    }
    get flux_tags_fingerprint() {
        return this._flux_tags_fingerprint;
    }
    get data_taggs_dict() {
        return this._data_taggs;
    }
    get data_taggs_list() {
        return Object.values(this._data_taggs);
    }
    get data_taggs_entries() {
        return Object.entries(this._data_taggs);
    }
    get data_tags_fingerprint() {
        return this._data_tags_fingerprint;
    }
    /**
     * Return an array  of id of tag selected of that data_taggs
     *
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get selected_data_tags_list() {
        const data_tags = [];
        this.data_taggs_list.forEach(data_tagg => {
            data_tags.push(...data_tagg.selected_tags_list);
        });
        return data_tags;
    }
    /**
     * Return an object wherekey are data_taggs id ,
     * and value an array of id of tag selected of that data_taggs
     *
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get selected_data_tags_entries() {
        const obj_data_tags_selected = {};
        this.data_taggs_list.forEach(data_tagg => {
            obj_data_tags_selected[data_tagg.id] = data_tagg.selected_tags_list[0];
        });
        return obj_data_tags_selected;
    }
    /**
     * Return an array of possible path to link value,
     * it use the combinitation of all tags from different data_taggs
     *
     * Exemple :
     * [
     *
     * [grp1_key1,grp2_key1],
     *
     * [grp1_key1,grp2_key2],
     *
     * [grp1_key2,grp2_key1],
     *
     * [grp1_key2,grp2_key2],
     * ...
     * ]
     * *
     * @readonly
     * @memberof ClassTemplate_Sankey
     */
    get list_combinatorial_data_taggs_path() {
        const list_tag_by_grp = [];
        this.data_taggs_list.forEach(data_tagg => {
            list_tag_by_grp.push(data_tagg.tags_list.map(tag => tag.id));
        });
        return list_tag_by_grp;
    }
    get level_taggs_dict() {
        return this._level_taggs;
    }
    get level_taggs_list() {
        return Object.values(this._level_taggs);
    }
}
