// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 03/09/2024
// All rights reserved for TerriFlux SARL
//
// This file is used to avoid cycling dependancies inside each Class definition files.
// ==================================================================================================
// OpenSankey+ imports
import { ClassTemplate_ApplicationDataOSP } from '../deps/OpenSankey+/types/ApplicationDataOSP';
import { ClassTemplate_DrawingAreaOSP } from '../deps/OpenSankey+/types/DrawingAreaOSP';
import { ClassTemplate_SankeyOSP } from '../deps/OpenSankey+/types/SankeyOSP';
import { ClassTemplate_NodeElementOSP } from '../deps/OpenSankey+/types/NodeOSP';
import { ClassTemplate_LinkElementOSP } from '../deps/OpenSankey+/types/LinkOSP';
export class ClassAbstract_ApplicationDataMFA extends ClassTemplate_ApplicationDataOSP {
}
export class ClassAbstract_DrawingAreaMFA extends ClassTemplate_DrawingAreaOSP {
}
export class ClassAbstract_SankeyMFA extends ClassTemplate_SankeyOSP {
}
export class ClassAbstract_NodeElementMFA extends ClassTemplate_NodeElementOSP {
}
export class ClassAbstract_LinkElementMFA extends ClassTemplate_LinkElementOSP {
}
