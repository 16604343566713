import React, { useState, } from 'react';
import { FaCheck } from 'react-icons/fa';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, Button, NumberInput, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInputField, NumberInputStepper, TabList, Tab, Tabs, TabPanels, TabPanel, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '@chakra-ui/react';
import { default_style_id, OSTooltip } from '../../types/Utils';
import { isPositionOverloaded } from '../../Elements/Node';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
/**
 *
 * @param {FCType_ApplyLayoutDialog} { ref_setter_show_modal_apply_layout, set_show_apply_layout, sankey_data, set_sankey_data }
 * @returns {*}
 */
export const ApplyLayoutDialog = ({ applicationData, diagramSelector, apply_transformation_additional_elements }) => {
    const { data_var_to_update, t, menu_configuration } = applicationData;
    const { node_styles_dict, link_styles_dict } = applicationData.drawing_area.sankey;
    const { ref_to_updater_modal_apply_layout } = menu_configuration;
    const [forceUpdate, setForceUpdate] = useState(true);
    const [mode_trans, set_mode_trans] = useState('simple');
    const [parametric, set_parametric] = useState(node_styles_dict[default_style_id].position.type == 'parametric');
    let trade_close = true;
    if ('NodeImportStyle' in node_styles_dict) {
        trade_close = node_styles_dict['NodeImportStyle'].position.type == 'relative';
    }
    //const [trade_close, set_trade_close] = useState(true)
    ref_to_updater_modal_apply_layout.current = () => setForceUpdate(b => !b);
    if (parametric !== (node_styles_dict[default_style_id].position.type == 'parametric')) {
        set_parametric(node_styles_dict[default_style_id].position.type == 'parametric');
    }
    const simple_element_to_transform = [
        'posNode',
        'attrNode', 'attrFlux',
        'attrDrawingArea'
    ];
    const default_element_to_transform = [
        'posNode',
        'attrNode', 'attrFlux',
        'attrDrawingArea'
    ];
    const setTrade = (trade_close) => {
        applicationData.drawing_area.bypass_redraws = true;
        if (trade_close) {
            // nodes of type
            node_styles_dict['NodeImportStyle'].position.type = 'relative';
            node_styles_dict['NodeImportStyle'].shape_visible = false;
            node_styles_dict['NodeImportStyle'].shape_min_height = 40;
            node_styles_dict['NodeImportStyle'].name_label_visible = false;
            node_styles_dict['NodeImportStyle'].value_label_is_visible = false;
            // node_styles_dict['NodeImportStyle'].value_label_horiz = 'middle'
            // node_styles_dict['NodeImportStyle'].value_label_vert = 'top'
            node_styles_dict['NodeExportStyle'].position.type = 'relative';
            node_styles_dict['NodeExportStyle'].shape_visible = false;
            node_styles_dict['NodeExportStyle'].shape_min_height = 40;
            node_styles_dict['NodeExportStyle'].name_label_visible = false;
            node_styles_dict['NodeExportStyle'].value_label_is_visible = false;
            // node_styles_dict['NodeExportStyle'].value_label_horiz = 'middle'
            // node_styles_dict['NodeExportStyle'].value_label_vert = 'bottom'
            link_styles_dict['LinkImportStyle'].shape_orientation = 'vh';
            link_styles_dict['LinkExportStyle'].shape_orientation = 'hv';
        }
        else {
            node_styles_dict['NodeImportStyle'].position.type = 'parametric';
            node_styles_dict['NodeImportStyle'].shape_visible = false;
            node_styles_dict['NodeImportStyle'].shape_min_height = 1;
            node_styles_dict['NodeImportStyle'].name_label_visible = true;
            node_styles_dict['NodeImportStyle'].name_label_horiz = 'left';
            node_styles_dict['NodeImportStyle'].name_label_horiz_shift = -200;
            node_styles_dict['NodeImportStyle'].value_label_is_visible = true;
            node_styles_dict['NodeImportStyle'].value_label_horiz = 'left';
            node_styles_dict['NodeImportStyle'].value_label_vert = 'middle';
            node_styles_dict['NodeImportStyle'].value_label_horiz_shift = -10;
            node_styles_dict['NodeExportStyle'].position.type = 'parametric';
            node_styles_dict['NodeExportStyle'].shape_visible = false;
            node_styles_dict['NodeExportStyle'].shape_min_height = 1;
            node_styles_dict['NodeExportStyle'].name_label_visible = true;
            node_styles_dict['NodeExportStyle'].name_label_horiz = 'right';
            node_styles_dict['NodeExportStyle'].name_label_horiz_shift = 200;
            node_styles_dict['NodeExportStyle'].value_label_is_visible = true;
            node_styles_dict['NodeExportStyle'].value_label_horiz = 'right';
            node_styles_dict['NodeExportStyle'].value_label_vert = 'middle';
            node_styles_dict['NodeExportStyle'].value_label_horiz_shift = 10;
            link_styles_dict['LinkImportStyle'].shape_orientation = 'hh';
            link_styles_dict['LinkImportStyle'].value_label_is_visible = false;
            link_styles_dict['LinkExportStyle'].shape_orientation = 'hh';
            link_styles_dict['LinkExportStyle'].value_label_is_visible = false;
        }
    };
    const content_modal_layout = React.createElement(Tabs, null,
        React.createElement(TabList, null,
            React.createElement(Box, { layerStyle: 'submenuconfig_tab' },
                React.createElement(Tab, null, t('Menu.Transformation.amp_import'))),
            React.createElement(Box, { layerStyle: 'submenuconfig_tab' },
                React.createElement(Tab, null, t('Menu.Transformation.amp_manuelle')))),
        React.createElement(TabPanels, null,
            React.createElement(TabPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    diagramSelector(applicationData),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.choseTransforDifficulty')),
                        React.createElement(Box, { layerStyle: 'options_3cols' },
                            React.createElement(Button, { variant: mode_trans == 'simple' ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => { set_mode_trans('simple'); applicationData.menu_configuration.ref_to_menu_updater.current(); } }, "Basiques"),
                            React.createElement(Button, { variant: mode_trans == 'expert' ? 'menuconfigpanel_option_button_tertiary_activated' : 'menuconfigpanel_option_button_tertiary', onClick: () => { set_mode_trans('expert'); applicationData.menu_configuration.ref_to_menu_updater.current(); } }, "Tous"))),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Shortcuts') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Shortcuts')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        data_var_to_update.current.length = 0;
                                        menu_configuration.updateComponentApplyLayout();
                                    } }, t('Menu.Transformation.unSelectAll')),
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        data_var_to_update.current.length = 0;
                                        if (mode_trans === 'simple') {
                                            simple_element_to_transform.forEach(el => data_var_to_update.current.push(el));
                                        }
                                        else {
                                            applicationData.transform_layout_all_attr.forEach(el => data_var_to_update.current.push(el));
                                        }
                                        menu_configuration.updateComponentApplyLayout();
                                    } }, t('Menu.Transformation.selectAll')),
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        data_var_to_update.current.length = 0;
                                        default_element_to_transform.forEach(el => data_var_to_update.current.push(el));
                                        menu_configuration.updateComponentApplyLayout();
                                    } }, t('Menu.Transformation.selectDefault'))))),
                    mode_trans != 'simple' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Topology') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Topology')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('addNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('addNode')) {
                                                data_var_to_update.current.push('addNode');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('addNode'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.addNode')),
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('removeNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('removeNode')) {
                                                data_var_to_update.current.push('removeNode');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('removeNode'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.removeNode')),
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('addFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('addFlux')) {
                                                data_var_to_update.current.push('addFlux');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('addFlux'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.addFlux')),
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('removeFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('removeFlux')) {
                                                data_var_to_update.current.push('removeFlux');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('removeFlux'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.removeFlux'))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Geometry') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Geometry')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: data_var_to_update.current.includes('posNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!data_var_to_update.current.includes('posNode')) {
                                            data_var_to_update.current.push('posNode');
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                        else {
                                            data_var_to_update.current.splice(data_var_to_update.current.indexOf('posNode'), 1);
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                    } }, t('Menu.Transformation.PosNoeud')),
                                React.createElement(Button, { variant: data_var_to_update.current.includes('posFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!data_var_to_update.current.includes('posFlux')) {
                                            data_var_to_update.current.push('posFlux');
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                        else {
                                            data_var_to_update.current.splice(data_var_to_update.current.indexOf('posFlux'), 1);
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                    } },
                                    " ",
                                    t('Menu.Transformation.posFlux'))))),
                    mode_trans != 'simple' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Values') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Values')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('Values') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('Values')) {
                                                data_var_to_update.current.push('Values');
                                                // Also need dataTags because we can't only import values without the structur of dataTags
                                                // (but we can import dataTags without values)
                                                if (!data_var_to_update.current.includes('tagData')) {
                                                    data_var_to_update.current.push('tagData');
                                                }
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('Values'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, data_var_to_update.current.includes('Values') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Attribut') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Attribut')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: data_var_to_update.current.includes('attrNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!data_var_to_update.current.includes('attrNode')) {
                                            data_var_to_update.current.push('attrNode');
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                        else {
                                            data_var_to_update.current.splice(data_var_to_update.current.indexOf('attrNode'), 1);
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                    } }, t('Menu.Transformation.attrNode')),
                                React.createElement(Button, { variant: data_var_to_update.current.includes('attrFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!data_var_to_update.current.includes('attrFlux')) {
                                            data_var_to_update.current.push('attrFlux');
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                        else {
                                            data_var_to_update.current.splice(data_var_to_update.current.indexOf('attrFlux'), 1);
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                    } }, t('Menu.Transformation.attrFlux'))))),
                    mode_trans == 'expert' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Tags') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Tags')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('tagNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('tagNode')) {
                                                data_var_to_update.current.push('tagNode');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('tagNode'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.tagNode')),
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('tagFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('tagFlux')) {
                                                data_var_to_update.current.push('tagFlux');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('tagFlux'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.tagFlux')),
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('tagData') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('tagData')) {
                                                data_var_to_update.current.push('tagData');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else if (!data_var_to_update.current.includes('Values')) {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('tagData'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, t('Menu.Transformation.tagData'))))) : React.createElement(React.Fragment, null),
                    mode_trans == 'expert' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.tagLevel') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.tagLevel')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: data_var_to_update.current.includes('tagLevel') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!data_var_to_update.current.includes('tagLevel')) {
                                                data_var_to_update.current.push('tagLevel');
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                            else {
                                                data_var_to_update.current.splice(data_var_to_update.current.indexOf('tagLevel'), 1);
                                                menu_configuration.updateComponentApplyLayout();
                                            }
                                        } }, data_var_to_update.current.includes('tagLevel') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.attrDrawingArea') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.attrGeneral')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: data_var_to_update.current.includes('attrDrawingArea') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!data_var_to_update.current.includes('attrDrawingArea')) {
                                            data_var_to_update.current.push('attrDrawingArea');
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                        else {
                                            data_var_to_update.current.splice(data_var_to_update.current.indexOf('attrDrawingArea'), 1);
                                            menu_configuration.updateComponentApplyLayout();
                                        }
                                    } }, data_var_to_update.current.includes('attrDrawingArea') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))),
                    mode_trans == 'expert' ? apply_transformation_additional_elements.map((c, i) => {
                        return React.createElement(React.Fragment, { key: i }, c);
                    }) : React.createElement(React.Fragment, null))),
            React.createElement(TabPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement("h5", null,
                        React.createElement("center", null, t('MEP.columnsParameters'))),
                    React.createElement(OSTooltip, { label: t('MEP.tooltips.EEN_h') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Horizontal')),
                            React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', step: 1, min: 0, allowMouseWheel: true, value: applicationData.drawing_area.horizontal_spacing, onChange: evt => {
                                    applicationData.drawing_area.horizontal_spacing = +evt;
                                    menu_configuration.updateComponentApplyLayout();
                                } },
                                React.createElement(NumberInputField, null),
                                React.createElement(NumberInputStepper, null,
                                    React.createElement(NumberIncrementStepper, null),
                                    React.createElement(NumberDecrementStepper, null))))),
                    React.createElement(OSTooltip, { label: t('MEP.tooltips.EEN_v') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Vertical')),
                            React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', step: 1, min: 0, allowMouseWheel: true, value: applicationData.drawing_area.vertical_spacing, onChange: (evt) => {
                                    applicationData.drawing_area.vertical_spacing = +evt;
                                    node_styles_dict['default'].position.dy = +evt;
                                    if (node_styles_dict['NodeSectorStyle']) {
                                        node_styles_dict['NodeSectorStyle'].position.dy = +evt;
                                    }
                                    if (node_styles_dict['NodeProductStyle']) {
                                        node_styles_dict['NodeProductStyle'].position.dy = +evt;
                                    }
                                    menu_configuration.updateComponentApplyLayout();
                                } },
                                React.createElement(NumberInputField, null),
                                React.createElement(NumberInputStepper, null,
                                    React.createElement(NumberIncrementStepper, null),
                                    React.createElement(NumberDecrementStepper, null)))))),
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement("h5", null,
                        React.createElement("center", null, t('MEP.positioningMode'))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_4cols' },
                        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: parametric, onChange: (evt) => {
                                node_styles_dict['default'].position.type = evt.target.checked ? 'parametric' : 'absolute';
                                set_parametric(evt.target.checked);
                                if (evt.target.checked) {
                                    Object.values(node_styles_dict)
                                        .filter(style => style.id !== 'NodeExportStyle' && style.id !== 'NodeImportStyle')
                                        .forEach(style => style.position.type = 'parametric');
                                    applicationData.drawing_area.sankey.nodes_list.forEach(n => n.position_v = -1);
                                    applicationData.drawing_area.computeParametrization();
                                }
                                else {
                                    Object.values(node_styles_dict)
                                        .filter(style => style.id !== 'NodeExportStyle' && style.id !== 'NodeImportStyle')
                                        .forEach(style => style.position.type = 'absolute');
                                }
                            } },
                            React.createElement(OSTooltip, { label: t('MEP.tooltips.parametricMode') }, t('MEP.parametricMode'))),
                        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: !parametric, onChange: (evt) => {
                                set_parametric(!evt.target.checked);
                                if (!evt.target.checked) {
                                    Object.values(node_styles_dict)
                                        .filter(style => style.id !== 'NodeExportStyle' && style.id !== 'NodeImportStyle')
                                        .forEach(style => style.position.type = 'parametric');
                                    applicationData.drawing_area.computeParametrization();
                                }
                                else {
                                    Object.values(node_styles_dict)
                                        .filter(style => style.id !== 'NodeExportStyle' && style.id !== 'NodeImportStyle')
                                        .forEach(style => style.position.type = 'absolute');
                                }
                            } },
                            React.createElement(OSTooltip, { label: t('MEP.tooltips.absoluteMode') }, t('MEP.absoluteMode'))),
                        parametric ? React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                if (parametric) {
                                    Object.values(applicationData.drawing_area.sankey.nodes_dict)
                                        .filter(node => node.display.position.type !== 'relative')
                                        .forEach(node => {
                                        if (isPositionOverloaded([node], 'dy')) {
                                            node.resetPositionAttribute('dy');
                                            node.applyPosition();
                                        }
                                    });
                                }
                                applicationData.draw();
                            } }, t('MEP.reInitDY')) : React.createElement(React.Fragment, null)),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                        React.createElement("h5", null,
                            React.createElement("center", null, t('MEP.importExport'))),
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_3cols' },
                            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: trade_close, onChange: (evt) => {
                                    setTrade(evt.target.checked);
                                    applicationData.drawing_area.arrangeTrade(true);
                                    applicationData.draw();
                                    setForceUpdate(!forceUpdate);
                                } },
                                React.createElement(OSTooltip, { label: t('MEP.tooltips.importExportClose') }, t('MEP.importExportClose'))),
                            React.createElement(Checkbox, { isDisabled: !parametric, variant: 'menuconfigpanel_option_checkbox', isChecked: !trade_close, onChange: (evt) => {
                                    setTrade(!evt.target.checked);
                                    applicationData.drawing_area.arrangeTrade(true);
                                    applicationData.draw();
                                    setForceUpdate(!forceUpdate);
                                } },
                                React.createElement(OSTooltip, { label: t('MEP.tooltips.importExportAboveBelow') }, t('MEP.importExportAboveBelow'))))),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(OSTooltip, { label: t('MEP.tooltips.PA') },
                            React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                    applicationData.drawing_area.computeAutoSankey(false);
                                } }, t('MEP.PA'))),
                        React.createElement(OSTooltip, { label: t('MEP.tooltips.AN') },
                            React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                    // arrangeNodes(data)
                                    // set_data({ ...data })
                                } }, t('MEP.AN'))))))));
    const dragLayout = React.createElement(MenuDraggable, { dict_hook_ref_setter_show_dialog_components: applicationData.menu_configuration.dict_setter_show_dialog, dialog_name: 'ref_setter_show_modal_apply_layout', content: content_modal_layout, title: t('Menu.Transformation.title') });
    return dragLayout;
};
/**
 *
 * @param {FCType_ApplySaveJSONDialog}
 * @returns {*}
 */
export const ApplySaveJSONDialog = ({ new_data, additional_file_save_json_option, ClickSaveDiagram }) => {
    const { t } = new_data;
    const [show_save_json_modal, set_show_save_json_modal] = useState(false);
    const [, setCount] = useState(0);
    new_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_json_saver.current = set_show_save_json_modal;
    // Set ref of update of ApplySaveJSONDialog components
    new_data.menu_configuration.ref_to_save_diagram_updater.current = () => setCount(a => a + 1);
    return React.createElement(Modal, { isOpen: show_save_json_modal, onClose: () => set_show_save_json_modal(false), variant: 'modal_dialog' },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('Menu.SaveJSON')),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.options_save_json.mode_save, onChange: (evt) => { new_data.options_save_json.mode_save = evt.target.checked; setCount(a => a + 1); } }, t('Menu.SaveValue')),
                    React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: new_data.options_save_json.mode_visible_element, onChange: (evt) => { new_data.options_save_json.mode_visible_element = evt.target.checked; setCount(a => a + 1); } }, t('Menu.VisibleElement')),
                    additional_file_save_json_option.map(el => React.createElement(React.Fragment, { key: 'add_save_' }, el)))),
            React.createElement(ModalFooter, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'options_2cols' },
                        React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                ClickSaveDiagram(new_data);
                            } }, t('Menu.SaveJSON')),
                        React.createElement(Button, { variant: 'menuconfigpanel_del_button', onClick: () => {
                                set_show_save_json_modal(false);
                            } }, t('Menu.close')))))));
};
/**
 * Return the modal when we try to open an excel file
 *
 * @param {{ uploadExcelImpl: any; handleCloseDialog: any; set_data: any; data: any; set_show_excel_dialog: any; url_prefix: any; postProcessLoadExcel: any; launch: any; }} { uploadExcelImpl, handleCloseDialog, set_data, data, set_show_excel_dialog,url_prefix,postProcessLoadExcel,launch }
 * @returns
 */
export const ExcelModal = ({ new_data, uploadExcelImpl, launch, Reinitialization }) => {
    const { t, url_prefix } = new_data;
    const [input_file_name, set_input_file_name] = useState(undefined);
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, null,
            t('Menu.input_file_excel'),
            React.createElement(Input, { type: "file", accept: '.xlsx', onChange: (evt) => {
                    set_input_file_name(evt.target.files[0]);
                } })),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, null),
            React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary", onClick: () => {
                    Reinitialization();
                    launch(input_file_name.name);
                    uploadExcelImpl(new_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_excel_loader.current, input_file_name, url_prefix);
                } }, t('Menu.ouvrir'))));
    return React.createElement(MenuDraggable, { dict_hook_ref_setter_show_dialog_components: new_data.menu_configuration.dict_setter_show_dialog, dialog_name: 'ref_setter_show_modal_excel_loader', content: content, title: t('Menu.open_excel_file') });
};
export const OpenSankeyDiagramSelector = (new_data) => {
    const { t, data_var_to_update } = new_data;
    const [file_layout, set_file_layout] = useState(undefined);
    return React.createElement(Box, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.Transformation.fmep')),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Input, { type: "file", "aria-label": '', onChange: (evt) => set_file_layout(evt.target.files) }),
            React.createElement(Box, { layerStyle: 'options_2cols' },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        if (file_layout === undefined) {
                            return;
                        }
                        const reader = new FileReader();
                        reader.onload = (() => {
                            return ((e) => {
                                let result = e.target.result;
                                if (result) {
                                    // TODO verifier fonctionnement de ce qui suit
                                    result = String(result);
                                    const new_layout = JSON.parse(result);
                                    const tmp_DA = new_data.createNewDrawingArea();
                                    tmp_DA.fromJSON(new_layout);
                                    new_data.drawing_area.updateFrom(tmp_DA, data_var_to_update.current);
                                    new_data.drawing_area.drawElements();
                                    new_data.drawing_area.areaAutoFit();
                                    new_data.menu_configuration.updateAllMenuComponents();
                                }
                            });
                        })();
                        reader.readAsText(file_layout[0]);
                    } }, t('Menu.Transformation.ad')),
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        // set_sankey_data(JSON.parse(JSON.stringify(prev_sankey_data)))
                    } }, t('Menu.Transformation.undo')))));
};
// export const PopoverSelectorDetailNodes:FunctionComponent<popoverSelectorDetailNodesFType>=({
//   applicationContext,
//   applicationData,
//   applicationDraw,
//   node_function,
//   link_function
// }
// )=>{
//   const redrawAllNodes=()=>node_function.RedrawNodes(Object.values(applicationData.display_nodes))
//   const redrawAllLinks=()=>link_function.RedrawLinks(Object.values(applicationData.display_links))
//   return <Popover id='popover-details-level' style={{maxWidth:'100%'}}>
//     <Popover.Header as="h3">{applicationContext.t('Banner.ndd')}</Popover.Header>
//     <Popover.Body style={{  marginLeft: '5px', width: '350px' }}>
//       <>{(Object.entries(applicationData.data.levelTags).length > 0) ? (<>
//         {addSimpleLevelDropDown(
//           applicationData,applicationDraw.reDrawLegend,redrawAllNodes,redrawAllLinks,node_function.recomputeDisplayedElement
//         )}</>
//       ) : (<>
//         <Form.Control placeholder="Pas de filtrage" style={{ opacity: !new_data.is_static ? '0.3' : '0', color: '#6c757d' }} disabled /></>)}</>
//     </Popover.Body>
//   </Popover>
// }
// export default PopoverSelectorDetailNodesetailNodes
