// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Local imports
import { ClassAbstract_LinkElementMFA } from './AbstractMFA';
// CLASS Link ELEMENT PLUS **************************************************************
/**
 * Override OpenSankey's class to take in account specifities of OpenSankey+ app
 * @export
 * @class ClassTemplate_LinkElementMFA
 * @extends {Class_LinkElement}
 */
export class ClassTemplate_LinkElementMFA extends ClassAbstract_LinkElementMFA {
    // PRIVATE ATTRIBUTES =================================================================
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_LinkElementMFA.
     * @param {string} id
     * @param {Type_GenericNodeElement} source
     * @param {Type_GenericNodeElement} target
     * @param {Type_GenericDrawingArea} drawing_area
     * @param {Class_MenuConfigMFA} menu_config
     * @memberof ClassTemplate_LinkElementMFA
     */
    constructor(id, source, target, drawing_area, menu_config) {
        // Heritance
        super(id, source, target, drawing_area, menu_config);
        // Override menu config
        this._menu_config = menu_config;
    }
    // GETTERS / SETTERS ==================================================================
    // Get application config menu
    get menu_config() { return this._menu_config; }
}
