// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
//
// This file is used to avoid cycling dependancies inside each Class definition files.
// ==================================================================================================
import { ClassTemplate_ProtoElement } from '../Elements/Element';
export class ClassAbstract_LinkElement extends ClassTemplate_ProtoElement {
}
export class ClassAbstract_LinkValue {
}
export class ClassAbstract_LinkStyle {
}
