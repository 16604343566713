export const resources_template = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      templates:{
        ids: {
          commercial_pipe_monthly_report: 'Business pipe monthly report',
          personal_budget_with_tags: 'Tagged personal expenses',
          cocoa_logistics: 'Cocoa logistics',
          tolkien: 'Wood production - Lord of the Rings',
          flux_verticaux: 'Vertical diagram',
          flux_de_matiere_economie: 'Economic sector',
          filiere_vegetale: 'Vegetable industry',
          filiere_bois: 'Wood industry',
          exemple_gradients: 'Color gradients',
          bilan_matiere_huilerie: 'Vegetable oil production',
          cocoa_logistics_illustrated: 'Cocoa logistics illustrated'
        }
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      templates:{
        ids: {
          commercial_pipe_monthly_report: 'Pipe commercial mensualisé',
          personal_budget_with_tags: 'Dépenses personnelles étiquetées',
          cocoa_logistics: 'Logistique du cacao',
          tolkien: 'Production de bois - Seigneur des Anneaux',
          flux_verticaux: 'Diagramme vertical',
          flux_de_matiere_economie: 'Filière économique',
          filiere_vegetale: 'Filière économique du végétal',
          filiere_bois: 'Filière économique du bois',
          exemple_gradients: 'Gradients de couleurs',
          bilan_matiere_huilerie: 'Production d\'huile végétale',
          cocoa_logistics_illustrated: 'Logistique du cacao illustrée'
        },
      },
    }
  }
}
