// Standard libs
import React, { useRef, useState, } from 'react';
// Imported libs
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, InputRightAddon, InputGroup, Input, } from '@chakra-ui/react';
// Local libs
import SankeyNodeEdition from './SankeyMenuConfigurationNodes';
import SankeyMenuConfigurationLinks from './SankeyMenuConfigurationLinks';
// COMPONENTS ===========================================================================
/**
 *  Define configuration menu
  *
  * @param {*} {
  *     new_data,
  *     menu_configuration_layout,
  *     menu_configuration_node_tags,
  *     menu_configuration_link_tags,
  *     menu_configuration_data_tags,
  *     menu_configuration_nodes_attributes,
  *     menu_config_link_data,
  *     menu_config_link_attr,
  *     additional_primary_accordion_elements,
  *   }
  * @return {*}
  */
export const OpenSankeyConfigurationsMenus = ({ new_data, menu_configuration_layout, menu_configuration_nodes_attributes, menu_config_link_data, menu_config_link_attr, additional_menus, }) => {
    // Data -------------------------------------------------------------------------------
    const { t } = new_data;
    const config_object = new_data.menu_configuration;
    // Component updater ------------------------------------------------------------------
    const [, setCount] = useState(0);
    config_object.ref_to_menu_config_updater.current = () => setCount(a => a + 1);
    // JSX Component ----------------------------------------------------------------------
    const menu_items = [
        React.createElement(AccordionItem, null,
            React.createElement(AccordionButton, { onClick: () => {
                    const scroll_x = window.scrollX;
                    const scroll_y = window.scrollY;
                    setTimeout(() => {
                        var _a;
                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                    }, 50);
                } },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.MEP')),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_configuration_layout))),
        React.createElement(AccordionItem, null,
            React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_elements },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.Elements')),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Accordion, { allowToggle: true, variant: 'accordion_sublevel_style' },
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_node },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdN')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyNodeEdition, { new_data: new_data, menu_configuration_nodes_attributes: menu_configuration_nodes_attributes, additionalMenus: additional_menus }))),
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: config_object.ref_to_btn_accordion_config_link },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdF')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyMenuConfigurationLinks, { new_data: new_data, menu_config_link_data: menu_config_link_data, menu_config_link_attr: menu_config_link_attr, additionalMenus: additional_menus }))),
                    additional_menus.additional_configuration_menus_edition_elements))),
        //Add extra accordionItem (for example views with OpenSankey+ submodule)
        React.createElement(React.Fragment, null, additional_menus.additional_configuration_menus_primary_accordion_elements.map((c, i) => {
            return React.createElement(React.Fragment, { key: i }, c);
        }))
    ];
    return (React.createElement(Accordion, { allowToggle: true }, menu_items.map((c, i) => {
        return React.createElement(React.Fragment, { key: i }, c);
    })));
};
export const SankeyConfigurationMenu = ({ configuration_menus }) => {
    return (React.createElement(Accordion, { allowToggle: true }, configuration_menus.map((c, i) => {
        return React.createElement(React.Fragment, { key: i }, c);
    })));
};
/**
 * Component developped for number input of the config menu
  * @param {*} {
  *   ref_to_set_value,
  *   function_on_blur,
  *   menu_for_style = false,
  *   minimum_value = Number.MIN_SAFE_INTEGER,
  *   maximum_value = Number.MAX_SAFE_INTEGER,
  *   stepper = false,
  *   step = 1,
  *   unit_text = undefined,
  * }
  * @return {*}
  */
export const ConfigMenuNumberInput = ({ default_value, ref_to_set_value, function_on_blur, menu_for_style = false, minimum_value = Number.MIN_SAFE_INTEGER, maximum_value = Number.MAX_SAFE_INTEGER, stepper = false, step = 1, unit_text = undefined, fixed_dec = 2 }) => {
    const ref_input = useRef(null);
    const is_modifying = useRef();
    const variant = unit_text ? 'menuconfigpanel_option_numberinput_with_right_addon' : 'menuconfigpanel_option_numberinput';
    const getFixedVal = (_) => {
        const number_val = Number(_);
        // if val has decimal & we want a fixed number of decimal & the number is not an Integer then fix value decimal else return value(Integer or null)
        const new_fixed_value = (fixed_dec !== 0 && number_val !== null && number_val !== undefined && Math.trunc(number_val) != number_val) ? (number_val === null || number_val === void 0 ? void 0 : number_val.toFixed(fixed_dec)) : number_val;
        return (String(new_fixed_value));
    };
    const fixed_value = getFixedVal(default_value);
    const [value, setValue] = useState(default_value ? String(fixed_value) : '');
    ref_to_set_value.current = (val) => setValue(getFixedVal(val));
    // Add stepper addon if specified
    const stepperBtn = stepper ? React.createElement(NumberInputStepper, null,
        React.createElement(NumberIncrementStepper, null),
        React.createElement(NumberDecrementStepper, null)) : React.createElement(React.Fragment, null);
    // Add unit addon if specified
    const input_unit = unit_text ? React.createElement(InputRightAddon, null, unit_text) : React.createElement(React.Fragment, null);
    return React.createElement(InputGroup, null,
        React.createElement(NumberInput, { allowMouseWheel: true, variant: variant, min: minimum_value !== null && minimum_value !== void 0 ? minimum_value : undefined, max: maximum_value, step: step, value: value !== null && value !== void 0 ? value : '', onChange: (value_as_string) => {
                // Launch/reset timeout before the input auto blur (and update the value in data)
                if (!menu_for_style) {
                    // reset timeout if exist
                    if (is_modifying.current) {
                        clearTimeout(is_modifying.current);
                    }
                    // launch timeout that automatically blur the input
                    is_modifying.current = setTimeout(() => {
                        var _a;
                        (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, 3000);
                }
                // Update displayed value_as_number
                setValue((value_as_string !== '') ? value_as_string : null);
            }, onKeyDown: e => {
                var _a;
                if (e.key === 'Enter') {
                    e.preventDefault();
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
            } },
            React.createElement(NumberInputField, { ref: ref_input, onBlur: () => {
                    if (!menu_for_style) {
                        clearTimeout(is_modifying.current);
                    }
                    // Update selected elements value
                    // Use functionOnBlur with either value null or value casted as number
                    let new_value = value === null ? value : Number(value);
                    if (fixed_dec > 0 && new_value !== null) {
                        new_value = +(new_value === null || new_value === void 0 ? void 0 : new_value.toFixed(2));
                    }
                    function_on_blur(new_value);
                } }),
            stepperBtn),
        input_unit);
};
export const ConfigMenuNumberOrUndefinedInput = ({ default_value, ref_to_set_value, function_on_blur, menu_for_style = false, minimum_value = Number.MIN_SAFE_INTEGER, maximum_value = Number.MAX_SAFE_INTEGER, stepper = false, step = 1, unit_text = undefined, }) => {
    const ref_input = useRef(null);
    const is_modifying = useRef();
    const variant = unit_text ? 'menuconfigpanel_option_numberinput_with_right_addon' : 'menuconfigpanel_option_numberinput';
    const [value, setValue] = useState(default_value);
    ref_to_set_value.current = setValue;
    // Add stepper addon if specified
    const stepperBtn = stepper ? React.createElement(NumberInputStepper, null,
        React.createElement(NumberIncrementStepper, null),
        React.createElement(NumberDecrementStepper, null)) : React.createElement(React.Fragment, null);
    // Add unit addon if specified
    const input_unit = unit_text ? React.createElement(InputRightAddon, null, unit_text) : React.createElement(React.Fragment, null);
    return React.createElement(InputGroup, null,
        React.createElement(NumberInput, { allowMouseWheel: true, variant: variant, min: minimum_value, max: maximum_value, step: step, value: value !== null && value !== void 0 ? value : '', onChange: (_, value_as_number) => {
                // Launch/reset timeout before the input auto blur (and update the value in data)
                if (!menu_for_style) {
                    // reset timeout if exist
                    if (is_modifying.current) {
                        clearTimeout(is_modifying.current);
                    }
                    // launch timeout that automatically blur the input
                    is_modifying.current = setTimeout(() => {
                        var _a;
                        (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, 3000);
                }
                // Update displayed value_as_number
                setValue(isNaN(value_as_number) ? undefined : value_as_number);
            }, onKeyDown: e => {
                var _a;
                if (e.key === 'Enter') {
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
            } },
            React.createElement(NumberInputField, { ref: ref_input, onBlur: () => {
                    if (!menu_for_style) {
                        clearTimeout(is_modifying.current);
                    }
                    // Update selected elements value
                    function_on_blur(value);
                } }),
            stepperBtn),
        input_unit);
};
/**
 * Component developped for text input of the config menu
 * @param {*} {
 *   default_value,
 *   function_onChange,
 *   function_onBlur,
 *   menu_for_style = false
 * }
 * @return {*}
 */
export const ConfigMenuTextInput = ({ ref_to_set_value, function_get_value, function_on_blur, menu_for_style = false, disabled = false }) => {
    const ref_input = useRef(null);
    const is_modifying = useRef();
    const [value, setValue] = useState(function_get_value());
    ref_to_set_value.current = setValue;
    return React.createElement(InputGroup, null,
        React.createElement(Input, { isDisabled: disabled, ref: ref_input, variant: 'menuconfigpanel_option_input', value: value !== null && value !== void 0 ? value : '', onChange: evt => {
                const updated_value = evt.target.value;
                // Launch/reset timeout before the input auto blur (and update the updated_value in data)
                if (!menu_for_style) {
                    // reset timeout if exist
                    if (is_modifying.current) {
                        clearTimeout(is_modifying.current);
                    }
                    // launch timeout that automatically blur the input
                    is_modifying.current = setTimeout(() => {
                        var _a;
                        (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, 2000);
                }
                // Update displayed updated_value
                setValue((updated_value !== '') ? updated_value : null);
            }, onKeyDown: e => {
                var _a;
                if (e.key === 'Enter') {
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }
            }, onBlur: () => {
                if (!menu_for_style) {
                    clearTimeout(is_modifying.current);
                }
                // Update selected elements value
                function_on_blur(value);
            } }));
};
