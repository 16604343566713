export const resources_app_elements = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      Menu: {
        unit: 'Sankey Unitaire',
        afm: 'AFM',
        afm_tools: 'Outils',
        xl_check: 'Excel checks',
        featureBeta: 'Beta-test',
        ter_gen: 'Gen. Supply-Use table',
        afm_reconcil: 'Reconciliation',
        afm_reconcil_json: 'Reconciling actual sankey diagram',
        afm_reconcil_excel: 'Reconciling an excel file',
      },
      ModalAFM: {
        title: 'Reconciliation',
        success_status_optim: 'Download results',
        success_status_check_excel: 'Verification finished',
        success_status_create_ter: 'Creation finished',
        fail_status_optim: 'Fail to reconcile',
        fail_status_check_excel: 'Fail to verify',
        fail_status_create_ter: 'fail to create the TER',
        launch: 'Launch',
        processing: 'Processing...',
        open_file: 'Open the reconcilied file',
        reset: 'Reset',
        success: 'Success',
        infos: 'Infos',
        err: 'Errors',
        debug: 'Debug',
        input_parameter: 'Parameter and input data',
        input_excel: 'Entering excel file',
        input_layout: 'Layout file',
        check_scale_geo: 'Scale\'s descent',
        input_scale_geo: 'MFA file from supperior geographic level',
        check_analyse_uncert: 'Uncertainty analysis',
        input_analyse_uncert: 'Number of realisation',
        waiting_file: 'Choose an entering excel file'
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      Menu: {
        unit: 'Unitary Sankey',
        afm: 'AFM',
        afm_tools: 'Tools',
        xl_check: 'Vérif. excel',
        featureBeta: 'Experimental',
        ter_gen: 'Génération T.E.R',
        afm_reconcil: 'Réconciliation',
        afm_reconcil_json: 'Réconcilier le diagramme de Sankey actuel',
        afm_reconcil_excel: 'Réconcilier un fichier Excel',
      },
      ModalAFM: {
        title: 'Réconciliation',
        success_status_optim: 'Télécharger les résultats',
        success_status_check_excel: 'Vérification terminée',
        success_status_create_ter: 'Création Terminée',
        fail_status_optim: 'Echec de la réconciliation',
        fail_status_check_excel: 'Echec de la vérification',
        fail_status_create_ter: 'Echec de la création',
        launch: 'Lancer',
        processing: 'En traitement...',
        open_file: 'Ouvrir fichier reconcilié',
        reset: 'Réinitialiser',
        success: 'Succès',
        infos: 'Infos',
        err: 'Erreurs',
        debug: 'Debug',
        input_parameter: 'Paramètres et données d\'entrée',
        input_excel: 'Fichier d\'entrée excel',
        input_layout: 'Diagramme de mise en page',
        check_scale_geo: 'Descente d\'échelle',
        input_scale_geo: 'Fichier MFA du niveau géographique supérieur',
        check_analyse_uncert: 'Analyse d\'incertitude',
        input_analyse_uncert: 'Nombre de réalisations',
        waiting_file: 'Veuillez choisir un fichier d\'entrée excel'
      }
    }
  }
}
