import { defineStyle } from '@chakra-ui/react';
// Style for the drawer containing the configuration menu
export const drawer_sequence = defineStyle({
    dialog: {
        maxWidth: 'inherit'
    },
    dialogContainer: {
        width: 'inherit',
        zIndex: 1
    },
    body: {
        padding: '0'
    }
});
