// External libs
import React, { useRef, useState } from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaLock, FaLockOpen, FaChevronDown, FaUndo } from 'react-icons/fa';
import { Box, Button, Checkbox, Input, Menu, MenuButton, MenuItem, MenuList, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, TabPanel, } from '@chakra-ui/react';
import { isAttributeOverloaded, } from '../../Elements/Node';
import { default_node_name_label_box_width, default_node_name_label_visible, default_shape_arrow_angle_direction, default_shape_arrow_angle_factor, default_shape_color, default_shape_color_sustainable, default_shape_min_height, default_shape_min_width, default_shape_type, default_shape_visible, default_dy, default_position_type, default_relative_dx, default_relative_dy, default_node_value_label_is_visible, default_node_name_label_background, default_node_value_label_background, default_node_value_label_horiz_shift, default_node_value_label_vert_shift, default_node_name_label_horiz_shift, default_node_name_label_vert_shift } from '../../Elements/NodeAttributes';
import { CustomFaEyeCheckIcon } from '../../types/Utils';
// Local functions
import { CutName, OSTooltip, TooltipValueSurcharge, } from '../../types/Utils';
import { default_style_id } from '../../types/Utils';
import { ConfigMenuNumberInput } from './SankeyMenuConfiguration';
import { SankeyMenuLabelComponent, SankeyMenuValueLabelComponent } from './SankeyMenuComponents';
export const svg_label_top = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,0H4.5c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5h7.247c-.143,.042-.278,.12-.391,.234l-5.087,5.191c-.574,.581-.167,1.575,.644,1.575h3.587v12.5c0,.829,.671,1.5,1.5,1.5s1.5-.671,1.5-1.5V10h3.587c.811,0,1.218-.994,.644-1.575L12.644,3.234c-.113-.114-.248-.192-.391-.234h7.247c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
export const svg_label_bottom = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,21h-7.247c.143-.042,.278-.12,.391-.234l5.087-5.191c.574-.581,.167-1.575-.644-1.575h-3.587V1.5c0-.829-.672-1.5-1.5-1.5s-1.5,.671-1.5,1.5V14h-3.587c-.811,0-1.218,.994-.644,1.575l5.087,5.191c.113,.114,.248,.192,.391,.234H4.5c-.828,0-1.5,.671-1.5,1.5s.672,1.5,1.5,1.5h15c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
export const svg_label_center = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M24,12c0,.553-.448,1-1,1H1c-.552,0-1-.447-1-1s.448-1,1-1H23c.552,0,1,.447,1,1Zm-13.414-3.586c.39,.39,.902,.585,1.414,.585s1.024-.195,1.414-.585l3.293-3.293c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.293,2.293V1c0-.553-.448-1-1-1s-1,.447-1,1V6l-2.293-2.293c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l3.293,3.293Zm2.828,7.172c-.779-.779-2.049-.779-2.828,0l-3.293,3.293c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l2.293-2.293v5c0,.553,.448,1,1,1s1-.447,1-1v-5l2.293,2.293c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-3.293-3.293Z" }));
export const svg_label_upper = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", width: "12", height: "12" },
    React.createElement("g", null,
        React.createElement("path", { d: "M22,8V9.026A4.948,4.948,0,0,0,19,8a5,5,0,0,0,0,10,4.948,4.948,0,0,0,3-1.026V18h2V8Zm-3,8a3,3,0,1,1,3-3A3,3,0,0,1,19,16Z" }),
        React.createElement("path", { d: "M12,18h2.236L7.118,3.764,0,18H2.236l2-4H10ZM5.236,12,7.118,8.236,9,12Z" })));
/*************************************************************************************************/
/**
 * Define the menu that allows to modifiy appararence for nodes / properties for a node style
 *
 * @param {*} {
 *   applicationContext,
 *   new_data,
 *   applicationState,
 *   menu_for_style,
 *   ref_selected_style_node,
 *   advanced_appearence_content,
 *   advanced_label_content,
 *   advanced_label_value_content,
 *   node_function
 * }
 * @return {*}
 */
export const OpenSankeyConfigurationNodesAttributes = ({ new_data, menu_for_style, additional_menus, }) => {
    // Datas ------------------------------------------------------------------------------
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29;
    // Get traduction function
    const { t } = new_data;
    // Elements on which this menu applies ------------------------------------------------
    let selected_nodes;
    if (!new_data.menu_configuration.is_selector_only_for_visible_nodes) {
        // All availables nodes
        selected_nodes = new_data.drawing_area.selected_nodes_list_sorted;
    }
    else {
        // Only visible nodes
        selected_nodes = new_data.drawing_area.visible_and_selected_nodes_list_sorted;
    }
    // Elements on which menu modification applies
    let elements;
    if (menu_for_style) {
        elements = [new_data.drawing_area.sankey.node_styles_dict[new_data.menu_configuration.ref_selected_style_node.current]];
    }
    else {
        elements = selected_nodes;
    }
    // Elements attributes ----------------------------------------------------------------
    /**
     *
     * function that go throught all Type_GenericNodeElement of an array & check if they're all equals
     * (to the first )
     *
     * @param {Type_GenericNodeElement} curr
     * @return {*}
     */
    const check_indeterminate = (curr) => {
        return (selected_nodes[0].isEqual(curr));
    };
    const is_indeterminated = !selected_nodes.every(check_indeterminate);
    // Get values or default values
    const shape_visible = ((_b = (_a = elements[0]) === null || _a === void 0 ? void 0 : _a.shape_visible) !== null && _b !== void 0 ? _b : default_shape_visible);
    const name_label_visible = ((_d = (_c = elements[0]) === null || _c === void 0 ? void 0 : _c.name_label_visible) !== null && _d !== void 0 ? _d : default_node_name_label_visible);
    const shape_min_width = ((_f = (_e = elements[0]) === null || _e === void 0 ? void 0 : _e.shape_min_width) !== null && _f !== void 0 ? _f : default_shape_min_width);
    const shape_min_height = ((_h = (_g = elements[0]) === null || _g === void 0 ? void 0 : _g.shape_min_height) !== null && _h !== void 0 ? _h : default_shape_min_height);
    const shape_color = ((_k = (_j = elements[0]) === null || _j === void 0 ? void 0 : _j.shape_color) !== null && _k !== void 0 ? _k : default_shape_color);
    const shape_type = ((_m = (_l = elements[0]) === null || _l === void 0 ? void 0 : _l.shape_type) !== null && _m !== void 0 ? _m : default_shape_type);
    const shape_arrow_angle_factor = ((_p = (_o = elements[0]) === null || _o === void 0 ? void 0 : _o.shape_arrow_angle_factor) !== null && _p !== void 0 ? _p : default_shape_arrow_angle_factor);
    const shape_arrow_angle_direction = ((_r = (_q = elements[0]) === null || _q === void 0 ? void 0 : _q.shape_arrow_angle_direction) !== null && _r !== void 0 ? _r : default_shape_arrow_angle_direction);
    const shape_color_sustainable = ((_t = (_s = elements[0]) === null || _s === void 0 ? void 0 : _s.shape_color_sustainable) !== null && _t !== void 0 ? _t : default_shape_color_sustainable);
    const name_label_box_width = ((_v = (_u = elements[0]) === null || _u === void 0 ? void 0 : _u.name_label_box_width) !== null && _v !== void 0 ? _v : default_node_name_label_box_width);
    const value_label_visible = ((_x = (_w = elements[0]) === null || _w === void 0 ? void 0 : _w.value_label_is_visible) !== null && _x !== void 0 ? _x : default_node_value_label_is_visible);
    const name_label_background = ((_z = (_y = elements[0]) === null || _y === void 0 ? void 0 : _y.name_label_background) !== null && _z !== void 0 ? _z : default_node_name_label_background);
    const value_label_background = ((_1 = (_0 = elements[0]) === null || _0 === void 0 ? void 0 : _0.value_label_background) !== null && _1 !== void 0 ? _1 : default_node_value_label_background);
    const value_label_horiz_shift = ((_3 = (_2 = elements[0]) === null || _2 === void 0 ? void 0 : _2.value_label_horiz_shift) !== null && _3 !== void 0 ? _3 : default_node_value_label_horiz_shift);
    const value_label_vert_shift = ((_5 = (_4 = elements[0]) === null || _4 === void 0 ? void 0 : _4.value_label_vert_shift) !== null && _5 !== void 0 ? _5 : default_node_value_label_vert_shift);
    const name_label_horiz_shift = ((_7 = (_6 = elements[0]) === null || _6 === void 0 ? void 0 : _6.name_label_horiz_shift) !== null && _7 !== void 0 ? _7 : default_node_name_label_horiz_shift);
    const name_label_vert_shift = ((_9 = (_8 = elements[0]) === null || _8 === void 0 ? void 0 : _8.name_label_vert_shift) !== null && _9 !== void 0 ? _9 : default_node_name_label_vert_shift);
    const position_type = menu_for_style ?
        ((_11 = (_10 = elements[0]) === null || _10 === void 0 ? void 0 : _10.position.type) !== null && _11 !== void 0 ? _11 : default_position_type) :
        ((_13 = (_12 = elements[0]) === null || _12 === void 0 ? void 0 : _12.position_type) !== null && _13 !== void 0 ? _13 : default_position_type);
    const position_u = menu_for_style ?
        ((_15 = (_14 = elements[0]) === null || _14 === void 0 ? void 0 : _14.position.dx) !== null && _15 !== void 0 ? _15 : 0) :
        ((_17 = (_16 = elements[0]) === null || _16 === void 0 ? void 0 : _16.display.position.u) !== null && _17 !== void 0 ? _17 : 0);
    const position_dy = menu_for_style ?
        ((_19 = (_18 = elements[0]) === null || _18 === void 0 ? void 0 : _18.position.dy) !== null && _19 !== void 0 ? _19 : default_dy) :
        ((_21 = (_20 = elements[0]) === null || _20 === void 0 ? void 0 : _20.display.position.dy) !== null && _21 !== void 0 ? _21 : default_dy);
    const position_relative_dx = menu_for_style ?
        ((_23 = (_22 = elements[0]) === null || _22 === void 0 ? void 0 : _22.position.relative_dx) !== null && _23 !== void 0 ? _23 : default_relative_dx) :
        ((_25 = (_24 = elements[0]) === null || _24 === void 0 ? void 0 : _24.display.position.relative_dx) !== null && _25 !== void 0 ? _25 : default_relative_dx);
    const position_relative_dy = menu_for_style ?
        ((_27 = (_26 = elements[0]) === null || _26 === void 0 ? void 0 : _26.position.relative_dy) !== null && _27 !== void 0 ? _27 : default_relative_dy) :
        ((_29 = (_28 = elements[0]) === null || _28 === void 0 ? void 0 : _28.display.position.relative_dy) !== null && _29 !== void 0 ? _29 : default_relative_dy);
    /**
     * Get style name to display for style selector
     * @return {*}
     */
    const style_of_selected_nodes = () => {
        if (selected_nodes.length !== 0) {
            const style = selected_nodes[0].style;
            let inchangee = true;
            selected_nodes.forEach(node => {
                inchangee = (node.style.id === style.id) ? inchangee : false;
            });
            return (inchangee) ?
                CutName(style.name, 20) :
                t('Noeud.multi_style');
        }
        else {
            return default_style_id;
        }
    };
    // Components updaters ----------------------------------------------------------------
    // Boolean used to force this component to reload
    const [, setCount] = useState(0);
    const [, setCountStyle] = useState(0);
    // Link this menu's update function
    if (!menu_for_style) {
        new_data.menu_configuration.ref_to_menu_config_nodes_apparence_updater.current = () => setCount(a => a + 1);
    }
    else {
        new_data.menu_configuration.ref_to_menu_config_nodes_styles_updater.current = () => setCountStyle(a => a + 1);
    }
    /**
     * Function used to reset menu UI
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Whatever is done, set saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        if (menu_for_style) {
            // Update menus for node's apparence in case we use this for style
            new_data.menu_configuration.updateComponentRelatedToNodesStyles();
            // Redraw all visible nodes if we modifie node style
            new_data.drawing_area.sankey.visible_nodes_list.forEach(n => n.draw());
        }
        // And update this menu also
        new_data.menu_configuration.updateComponentRelatedToNodesApparence();
    };
    // Node to ConfigMenuNumberInput state variable
    const number_of_input = 11;
    const ref_set_number_inputs = [];
    for (let i = 0; i < number_of_input; i++)
        ref_set_number_inputs.push(useRef((_) => null));
    // Be sure that values are updated in inputs when refreshing this component
    ref_set_number_inputs[0].current(String(shape_min_height));
    ref_set_number_inputs[1].current(String(shape_min_width));
    ref_set_number_inputs[2].current(String(name_label_box_width));
    ref_set_number_inputs[3].current(String(position_u));
    ref_set_number_inputs[4].current(String(position_dy));
    ref_set_number_inputs[5].current(String(position_relative_dx));
    ref_set_number_inputs[6].current(String(position_relative_dy));
    ref_set_number_inputs[7].current(String(name_label_horiz_shift));
    ref_set_number_inputs[8].current(String(name_label_vert_shift));
    ref_set_number_inputs[9].current(String(value_label_horiz_shift));
    ref_set_number_inputs[10].current(String(value_label_vert_shift));
    // JSX menu components ---------------------------------------------------------------
    // Check if the 1st selected node has a tag selected from the group tag 'type de noeud' so we can disable the selection of the node shape
    const content_appearence = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: React.createElement(CustomFaEyeCheckIcon, null), isChecked: shape_visible, isIndeterminate: is_indeterminated, onChange: (evt) => {
                    elements.forEach(element => (element.shape_visible = evt.target.checked));
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.Visibilité') }, t('Noeud.apparence.Visibilité')),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'shape_visible') ?
                    TooltipValueSurcharge('node_var', t) :
                    React.createElement(React.Fragment, null))),
        additional_menus.advanced_appearence_content.splice(1, 1),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.edition')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Noeud.apparence.Couleur'),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'shape_color') ? (React.createElement(React.Fragment, null, TooltipValueSurcharge('node_var_', t))) : (React.createElement(React.Fragment, null))),
            React.createElement(Box, { layerStyle: 'option_with_activation' },
                React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.Couleur') },
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: shape_color, onChange: evt => {
                            elements.forEach(element => element.shape_color = evt.target.value);
                            refreshThisAndUpdateRelatedComponents();
                        } })),
                React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.CouleurPérenne') },
                    React.createElement(Button
                    //Si la valeur est a true alors la couleur des noeuds reste celle sélectionné loreque que l'on affiche les flux celon leur étiquettes
                    , { 
                        //Si la valeur est a true alors la couleur des noeuds reste celle sélectionné loreque que l'on affiche les flux celon leur étiquettes
                        variant: shape_color_sustainable ?
                            'menuconfigpanel_option_button_activated' :
                            'menuconfigpanel_option_button', onClick: () => {
                            elements.forEach(element => element.shape_color_sustainable = !shape_color_sustainable);
                            refreshThisAndUpdateRelatedComponents();
                        } }, shape_color_sustainable ? React.createElement(FaLock, null) : React.createElement(FaLockOpen, null))))),
        React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.Forme') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                    t('Noeud.apparence.Forme'),
                    ((!menu_for_style) &&
                        isAttributeOverloaded(selected_nodes, 'shape_type') ?
                        React.createElement(React.Fragment, null, TooltipValueSurcharge('node_var_', t)) :
                        React.createElement(React.Fragment, null))),
                React.createElement(Box, { layerStyle: 'options_3cols' },
                    React.createElement(Button, { value: "ellipse", variant: shape_type === 'ellipse' ?
                            'menuconfigpanel_option_button_activated' :
                            'menuconfigpanel_option_button', onClick: () => {
                            elements.forEach(element => element.shape_type = 'ellipse');
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: '#78C2AD', stroke: 'currentColor', viewBox: '0 0 17 17', width: "1.5rem", height: "1.5rem" },
                            React.createElement("path", { d: "M 16.440445,8.4666672 A 7.9737778,7.9737773 0 0 1 8.4666672,16.440444 7.9737778,7.9737773 0 0 1 0.4928894,8.4666672 7.9737778,7.9737773 0 0 1 8.4666672,0.49288988 7.9737778,7.9737773 0 0 1 16.440445,8.4666672 Z" }))),
                    React.createElement(Button, { variant: shape_type === 'rect' ?
                            'menuconfigpanel_option_button_activated' :
                            'menuconfigpanel_option_button', onClick: () => {
                            elements.forEach(element => element.shape_type = 'rect');
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: '#78C2AD', stroke: 'currentColor', viewBox: '0 0 17 17', width: "1.5rem", height: "1.5rem" },
                            React.createElement("path", { d: "M 0.385555,0.385555 H 16.547779 V 16.547779 H 0.385555 Z" }))),
                    React.createElement(Button, { variant: shape_type === 'arrow' ?
                            'menuconfigpanel_option_button_activated' :
                            'menuconfigpanel_option_button', onClick: () => {
                            elements.forEach(element => element.shape_type = 'arrow');
                            refreshThisAndUpdateRelatedComponents();
                        } },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: '#78C2AD', stroke: 'currentColor', viewBox: '0 0 17 17', width: "1.5rem", height: "1.5rem" },
                            React.createElement("path", { d: "M 0.11499051,0.11500028 H 10.015883 L 16.844087,8.5149428 10.015883,16.818334 H 0.11499051 L 6.601784,8.5149428 Z" })))))),
        /* Change the angle of the arrow shaped node */
        shape_type === 'arrow' ?
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.arrow_angle') },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                            t('Noeud.apparence.arrow_angle'),
                            ((!menu_for_style) &&
                                isAttributeOverloaded(selected_nodes, 'shape_arrow_angle_factor') ?
                                React.createElement(React.Fragment, null, TooltipValueSurcharge('node_var_', t)) :
                                React.createElement(React.Fragment, null))),
                        React.createElement(Slider, { min: 0, max: 45, step: 5, value: shape_arrow_angle_factor, onChange: (value) => {
                                elements.forEach(element => element.shape_arrow_angle_factor = value);
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            React.createElement(SliderMark, { value: shape_arrow_angle_factor },
                                shape_arrow_angle_factor,
                                "\u00B0"),
                            React.createElement(SliderTrack, null,
                                React.createElement(SliderFilledTrack, null)),
                            React.createElement(SliderThumb, null)))),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.angle_orientation')),
                    React.createElement(Box, { layerStyle: 'options_4cols' },
                        React.createElement(Button, { variant: shape_arrow_angle_direction === 'left' ?
                                'menuconfigpanel_option_button_activated' :
                                'menuconfigpanel_option_button', minWidth: 0, onClick: () => {
                                elements.forEach(element => element.shape_arrow_angle_direction = 'left');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            React.createElement(FaArrowLeft, null)),
                        React.createElement(Button, { variant: shape_arrow_angle_direction === 'right' ?
                                'menuconfigpanel_option_button_activated' :
                                'menuconfigpanel_option_button', minWidth: 0, onClick: () => {
                                elements.forEach(element => element.shape_arrow_angle_direction = 'right');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            React.createElement(FaArrowRight, null)),
                        React.createElement(Button, { variant: shape_arrow_angle_direction === 'top' ?
                                'menuconfigpanel_option_button_activated' :
                                'menuconfigpanel_option_button', minWidth: 0, onClick: () => {
                                elements.forEach(element => element.shape_arrow_angle_direction = 'top');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            React.createElement(FaArrowUp, null)),
                        React.createElement(Button, { variant: shape_arrow_angle_direction === 'bottom' ?
                                'menuconfigpanel_option_button_activated' :
                                'menuconfigpanel_option_button', minWidth: 0, onClick: () => {
                                elements.forEach(element => element.shape_arrow_angle_direction = 'bottom');
                                refreshThisAndUpdateRelatedComponents();
                            } },
                            React.createElement(FaArrowDown, null))))) :
            React.createElement(React.Fragment, null),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Noeud.size')),
        React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.TML') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.TML')),
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[1], default_value: shape_min_width, function_on_blur: (value) => {
                        elements.forEach(element => element.shape_min_width = (value !== null && value !== void 0 ? value : undefined));
                        refreshThisAndUpdateRelatedComponents();
                    }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' }))),
        React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.TMH') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.TMH')),
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[0], default_value: shape_min_height, function_on_blur: (value) => {
                        elements.forEach(element => element.shape_min_height = (value !== null && value !== void 0 ? value : undefined));
                        refreshThisAndUpdateRelatedComponents();
                    }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' }))),
        !menu_for_style && position_type == 'parametric' ? React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Noeud.position')) : React.createElement(React.Fragment, null),
        !menu_for_style && position_type == 'parametric' ? React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.geometry_u') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.geometry_u')),
                React.createElement(ConfigMenuNumberInput, { default_value: position_u, ref_to_set_value: ref_set_number_inputs[3], menu_for_style: menu_for_style, function_on_blur: () => {
                        new_data.drawing_area.computeParametricV();
                        refreshThisAndUpdateRelatedComponents();
                    }, stepper: true, minimum_value: 1, unit_text: 'pixels' }))) : React.createElement(React.Fragment, null),
        menu_for_style && position_type == 'parametric' ? React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.geometry_dy') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.geometry_dy')),
                React.createElement(ConfigMenuNumberInput, { default_value: position_dy, ref_to_set_value: ref_set_number_inputs[4], menu_for_style: menu_for_style, function_on_blur: val => {
                        elements.forEach(element => element.position.dy = val);
                        refreshThisAndUpdateRelatedComponents();
                    }, stepper: true, unit_text: 'pixels' }))) : React.createElement(React.Fragment, null),
        menu_for_style && position_type == 'relative' ? React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.geometry_relative_dx') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.geometry_relative_dx')),
                React.createElement(ConfigMenuNumberInput, { default_value: position_relative_dx, ref_to_set_value: ref_set_number_inputs[5], menu_for_style: menu_for_style, function_on_blur: () => {
                        refreshThisAndUpdateRelatedComponents();
                    }, stepper: true, unit_text: 'pixels' }))) : React.createElement(React.Fragment, null),
        menu_for_style && position_type == 'relative' ? React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.geometry_relative_dy') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.geometry_relative_dy')),
                React.createElement(ConfigMenuNumberInput, { default_value: position_relative_dy, ref_to_set_value: ref_set_number_inputs[6], menu_for_style: menu_for_style, function_on_blur: () => {
                        refreshThisAndUpdateRelatedComponents();
                    }, stepper: true, unit_text: 'pixels' }))) : React.createElement(React.Fragment, null),
        additional_menus.advanced_appearence_content);
    const content_label = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: React.createElement(CustomFaEyeCheckIcon, null), isIndeterminate: is_indeterminated, isChecked: name_label_visible, onChange: (evt) => {
                    elements.forEach(element => element.name_label_visible = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.vdb') }, t('Noeud.labels.vdb')),
                ((!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'name_label_visible') ?
                    TooltipValueSurcharge('node_var', t) :
                    React.createElement(React.Fragment, null)))),
        name_label_visible ? React.createElement(React.Fragment, null,
            React.createElement(SankeyMenuLabelComponent, { new_data: new_data, elements: elements, selectedElements: selected_nodes, refreshParentComponent: refreshThisAndUpdateRelatedComponents, dict_decorator_name: {
                    label_horiz: 'name_label_horiz',
                    label_vert: 'name_label_vert',
                    label_font_size: 'name_label_font_size',
                    label_color: 'name_label_color',
                    label_bold: 'name_label_bold',
                    label_uppercase: 'name_label_uppercase',
                    label_italic: 'name_label_italic',
                    label_font_family: 'name_label_font_family',
                } }),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminated, isChecked: name_label_background, onChange: (evt) => {
                    elements.forEach(element => element.name_label_background = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.l_bg') }, t('Noeud.labels.l_bg')),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'name_label_background') ?
                    TooltipValueSurcharge('node_var', t) :
                    React.createElement(React.Fragment, null)),
            React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.cl') },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Menu.larg'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_nodes, 'name_label_box_width') ?
                            React.createElement(React.Fragment, null, TooltipValueSurcharge('node_var_', t)) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[2], default_value: name_label_box_width, function_on_blur: (value) => {
                            elements.forEach(element => element.name_label_box_width = (value !== null && value !== void 0 ? value : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' }))),
            React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.anchor_dx') },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Noeud.labels.anchor_dx'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_nodes, 'name_label_horiz_shift') ?
                            TooltipValueSurcharge('node_var', t) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[7], default_value: name_label_horiz_shift, function_on_blur: (value) => {
                            elements.forEach(element => element.name_label_horiz_shift = (value !== null && value !== void 0 ? value : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' }))),
            React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.anchor_dy') },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Noeud.labels.anchor_dy'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_nodes, 'name_label_vert_shift') ?
                            TooltipValueSurcharge('node_var', t) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[8], default_value: name_label_vert_shift, function_on_blur: (value) => {
                            elements.forEach(element => element.name_label_vert_shift = (value !== null && value !== void 0 ? value : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' })))) : React.createElement(React.Fragment, null));
    const content_label_value = React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: React.createElement(CustomFaEyeCheckIcon, null), isIndeterminate: is_indeterminated, isChecked: value_label_visible, onChange: (evt) => {
                    elements.forEach(element => element.value_label_is_visible = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.label') }, t('Flux.label.vdb') + ' '),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'value_label_is_visible') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null))),
        value_label_visible ? React.createElement(React.Fragment, null,
            React.createElement(SankeyMenuValueLabelComponent, { new_data: new_data, elements: elements, selectedElements: selected_nodes, refreshParentComponent: refreshThisAndUpdateRelatedComponents, dict_decorator_name: {
                    label_horiz: 'value_label_horiz',
                    label_vert: 'value_label_vert',
                    label_font_size: 'value_label_font_size',
                    label_color: 'value_label_color',
                    label_font_family: 'value_label_font_family',
                    label_unit_visible: 'value_label_unit_visible',
                    label_unit: 'value_label_unit',
                    label_bold: 'value_label_bold',
                    label_uppercase: 'value_label_uppercase',
                    label_italic: 'value_label_italic',
                    label_unit_factor: 'value_label_unit_factor',
                    label_custom_digit: 'value_label_custom_digit',
                    label_nb_digit: 'value_label_nb_digit',
                } }),
            " ",
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminated, isChecked: value_label_background, onChange: (evt) => {
                    elements.forEach(element => element.value_label_background = evt.target.checked);
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.l_bg') }, t('Noeud.labels.l_bg')),
                (!menu_for_style) &&
                    isAttributeOverloaded(selected_nodes, 'value_label_background') ?
                    TooltipValueSurcharge('node_var', t) :
                    React.createElement(React.Fragment, null)),
            React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.anchor_dx') },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Noeud.labels.anchor_dx'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_nodes, 'value_label_horiz_shift') ?
                            TooltipValueSurcharge('node_var', t) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[9], default_value: value_label_horiz_shift, function_on_blur: (value) => {
                            elements.forEach(element => element.value_label_horiz_shift = (value !== null && value !== void 0 ? value : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' }))),
            React.createElement(OSTooltip, { label: t('Noeud.labels.tooltips.anchor_dy') },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Noeud.labels.anchor_dy'),
                        (!menu_for_style) &&
                            isAttributeOverloaded(selected_nodes, 'value_label_vert_shift') ?
                            TooltipValueSurcharge('node_var', t) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[10], default_value: value_label_vert_shift, function_on_blur: (value) => {
                            elements.forEach(element => element.value_label_vert_shift = (value !== null && value !== void 0 ? value : undefined));
                            refreshThisAndUpdateRelatedComponents();
                        }, menu_for_style: menu_for_style, minimum_value: 0, step: 1, stepper: true, unit_text: 'pixels' })))) : React.createElement(React.Fragment, null));
    const style_node = !menu_for_style ? React.createElement(Box, { layerStyle: 'menuconfigpanel_row_stylechoice' },
        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('Noeud.Style')),
        React.createElement(Menu, null,
            React.createElement(MenuButton, { as: Button, variant: 'menuconfigpanel_option_button', rightIcon: React.createElement(FaChevronDown, null) }, style_of_selected_nodes()),
            React.createElement(MenuList, null, new_data.drawing_area.sankey.node_styles_list_sorted
                .map(style => {
                return (React.createElement(React.Fragment, { key: style.id },
                    React.createElement(MenuItem, { key: style.id, onClick: () => {
                            new_data.menu_configuration.ref_selected_style_node.current = style.id;
                            selected_nodes.forEach(node => {
                                node.style = style;
                            });
                            refreshThisAndUpdateRelatedComponents();
                        } }, style.name)));
            }))),
        React.createElement(OSTooltip, { label: t('Noeud.tooltips.AS') },
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                    selected_nodes.forEach(node => node.resetAttributes());
                    refreshThisAndUpdateRelatedComponents();
                } },
                React.createElement(FaUndo, null)))) : React.createElement(React.Fragment, null);
    // Tableau d'elements de sous-menu attribut de noeuds
    return React.createElement(React.Fragment, null,
        React.createElement(React.Fragment, { key: 'style_node' }, style_node),
        React.createElement(React.Fragment, { key: 'sep_1' },
            React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } })),
        React.createElement(React.Fragment, { key: 'app' }, content_appearence),
        React.createElement(React.Fragment, { key: 'sep_2' },
            React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } })),
        React.createElement(React.Fragment, { key: 'lab' }, content_label),
        React.createElement(React.Fragment, { key: 'sep_3' },
            React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } })),
        React.createElement(React.Fragment, { key: 'lab' }, content_label_value),
        additional_menus.additional_node_apparence_content.map((content) => content(menu_for_style) /*<React.Fragment key={idx}>{content}</React.Fragment>*/));
};
export const SankeyMenuConfigurationNodesAttributes = (t, menu_configuration_nodes_attributes, for_modal = false) => {
    //Function that check if all selected nodes have the same value for some parameter
    return for_modal ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_configuration_nodes_attributes) :
        React.createElement(TabPanel, { id: 'nodes_desc' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_configuration_nodes_attributes));
};
export const SankeyWrapperConfigInModalOrMenu = ({ menu_to_wrap, for_modal, idTab = '' }) => {
    //Function that check if all selected nodes have the same value for some parameter
    return for_modal ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_to_wrap) :
        React.createElement(TabPanel, { id: idTab },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_to_wrap));
};
