// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Local modules imports
import { ClassTemplate_Element } from '../Elements/Element';
import { default_element_position } from '../types/Utils';
// CLASS HANDLER ************************************************************************
/**
 * Class that define a handler used to manipulate a element
 * @export
 * @class ClassTemplate_Handler
 * @extends {ClassTemplate_Element}
 */
export class ClassTemplate_Handler extends ClassTemplate_Element {
    // CONSTRUCTOR ========================================================================
    /**
    * Creates an instance of ClassTemplate_Handler.
    * @param {string} id
    * @param {Type_GenericDrawingArea} drawing_area
    * @param {Class_MenuConfig} menu_config
    * @param {ClassTemplate_ProtoElement} ref
    * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragStart_function
    * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} drag_function
    * @param {(event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => void} dragEnd_function
    * @param {{class?:string, size?: number, color?: string, filled?: boolean }} [options]
    * @param {ClassTemplate_ProtoElement} [ref_optional]
    * @memberof ClassTemplate_Handler
    */
    constructor(id, drawing_area, menu_config, ref, dragStart_function, drag_function, dragEnd_function, options, ref_optional, custom_parent_grp) {
        // Init parent class attributes
        super(id, menu_config, custom_parent_grp ? custom_parent_grp : 'g_handlers');
        // PRIVATE ATTRIBUTES =================================================================
        this._size = 5;
        this._color = 'black';
        this._filled = true;
        this._ref_element = ref;
        this._ref_element_optional = ref_optional;
        this._custom_html_grp = custom_parent_grp !== undefined;
        // Init other class attributes
        this._display = {
            drawing_area: drawing_area,
            sankey: drawing_area.sankey,
            position: structuredClone(default_element_position),
        };
        // Drag handling functions -> defined by parent element
        this.eventMouseDragStart = dragStart_function;
        this.eventMouseDrag = drag_function;
        this.eventMouseDragEnd = dragEnd_function;
        // Set optional variable value
        if (options) {
            if (options.size !== undefined) {
                this._size = options.size;
            }
            if (options.color !== undefined) {
                this._color = options.color;
            }
            if (options.filled !== undefined) {
                this._filled = options.filled;
            }
            if (options.class !== undefined) {
                this._custom_class = options.class;
            }
        }
    }
    // COPY METHODS =======================================================================
    _copyFrom(element) {
        super._copyFrom(element);
        this._size = element._size;
        this._color = element._color;
        this._filled = element._filled;
        this._custom_class = element._custom_class;
    }
    // PUBLIC METHODS =====================================================================
    drawElements() {
        this._process_or_bypass(() => this._drawElement());
    }
    _drawElement() {
        var _a, _b, _c;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_handler');
        if (this._custom_class !== undefined) {
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.attr('class', this._custom_class);
        }
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.append('rect').attr('x', -this._size / 2).attr('y', -this._size / 2).attr('width', this._size).attr('height', this._size).attr('stroke', this._color).attr('stroke-width', 1).attr('fill', this._color).attr('fill-opacity', this._filled ? 1 : 0);
    }
    // PROTECTED METHODS =====================================================================
    _draw() {
        super._draw();
        this._drawElement();
    }
    /**
     * Override initDraw to allow the creation of html grp outside the DA
     *
     * @memberof Class_Handler
     */
    _initDraw() {
        // If the parent id is referenced in the constructor we allow the creation of the new group outside the DA
        // (orginally this override was created to allow the creation of legend handler outside the DA)
        if (this._custom_html_grp) {
            const d3_svg = this.drawing_area.d3_selection_zoom_area;
            if (d3_svg !== null) {
                const d3_drawing_area_selection = d3_svg.selectAll(' #' + this._svg_parent_group);
                if (d3_drawing_area_selection.nodes().length > 0) {
                    this.d3_selection = d3_drawing_area_selection.append('g');
                    this.d3_selection.attr('id', this.svg_group)
                        .attr('transform', 'translate(' + 0 + ',' + this.drawing_area.getNavBarHeight() + ')'); // init drawing area zone with a margin for taking into account the navbar
                }
            }
        }
        else {
            // Normal _initDraw
            super._initDraw();
        }
    }
    // GETTERS / SETTERS ==================================================================
    /**
       * Getter used to display or not the handler (called in draw of ClassTemplate_Element)
       *
       * @readonly
       * @memberof ClassTemplate_Handler
       */
    get is_visible() {
        var _a, _b;
        return (super.is_visible &&
            this._ref_element.is_visible &&
            this._ref_element.is_selected &&
            ((_b = (_a = this._ref_element_optional) === null || _a === void 0 ? void 0 : _a.is_visible) !== null && _b !== void 0 ? _b : true));
    }
    get ref_element() {
        return this._ref_element;
    }
    get ref_element_optional() {
        return this._ref_element_optional;
    }
}
