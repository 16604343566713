// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 03/09/2024
// All rights reserved for TerriFlux SARL
//
// This file is used to avoid cycling dependancies inside each Class definition files.
// ==================================================================================================
import { ClassTemplate_ApplicationData } from '../deps/OpenSankey/types/ApplicationData';
import { ClassTemplate_DrawingArea } from '../deps/OpenSankey/types/DrawingArea';
import { ClassTemplate_Element } from '../deps/OpenSankey/Elements/Element';
import { ClassTemplate_LinkElement } from '../deps/OpenSankey/Elements/Link';
import { ClassTemplate_NodeElement } from '../deps/OpenSankey/Elements/Node';
import { ClassTemplate_Sankey } from '../deps/OpenSankey/types/Sankey';
export class ClassAbstract_ApplicationDataOSP extends ClassTemplate_ApplicationData {
}
export class ClassAbstract_DrawingAreaOSP extends ClassTemplate_DrawingArea {
}
export class ClassAbstract_SankeyOSP extends ClassTemplate_Sankey {
}
export class ClassAbstract_NodeElementOSP extends ClassTemplate_NodeElement {
}
export class ClassAbstract_LinkElementOSP extends ClassTemplate_LinkElement {
}
export class ClassAbstract_ContainerElement extends ClassTemplate_Element {
}
