// Standard lib
import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
// Imported libs
import { Box, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, Tab, Tabs, Table, TabList, TabPanel, TabPanels, Tbody, Td, Text, Th, Thead, Tr, ModalOverlay, ModalHeader } from '@chakra-ui/react';
import resources from './resources.json';
export const ModalWelcome = ({ new_data, external_pagination, external_content }) => {
    const { t } = new_data;
    const { never_see_again } = new_data.menu_configuration;
    const [show_welcome, set_show_welcome] = useState(false);
    const [current_header, setCurrentHeader] = useState(Object.keys(external_pagination)[0]);
    new_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_welcome.current = set_show_welcome;
    const content_rc_static = React.createElement(React.Fragment, null,
        React.createElement(Heading, { variant: 'heading_welcome_style' }, t('Menu.rcc_titre_princ')),
        React.createElement("p", null,
            React.createElement("b", null, t('Menu.rcc_cdn_bold')),
            t('Menu.rcc_cdn')),
        React.createElement("p", null,
            React.createElement("b", null, t('Menu.rcc_ctrl_scrll_bold')),
            t('Menu.rcc_ctrl_scrll')),
        React.createElement("p", null,
            React.createElement("b", null, t('Menu.rcc_F7_bold')),
            t('Menu.rcc_F7')),
        React.createElement("p", null,
            React.createElement("b", null, t('Menu.rcc_F8_bold')),
            t('Menu.rcc_F8')),
        React.createElement("p", null,
            React.createElement("b", null, t('Menu.rcc_F9_bold')),
            t('Menu.rcc_F9')));
    external_content['rc'] = new_data.is_static ? content_rc_static : external_content['rc'];
    const content = React.createElement(Modal, { isOpen: show_welcome && !never_see_again.current, onClose: () => set_show_welcome(false) },
        React.createElement(ModalOverlay, null),
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('welcome.' + current_header)),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null,
                React.createElement(Tabs, { orientation: 'vertical', align: 'start', variant: 'tabs_variant_template', height: '100%' },
                    React.createElement(TabList, null, Object.entries(external_pagination)
                        .map(([key, content]) => {
                        return React.createElement(Tab, { key: key, onClick: () => setCurrentHeader(key) },
                            " ",
                            content,
                            " ");
                    })),
                    React.createElement(TabPanels, null, Object.values(external_content)
                        .map((content, idx) => {
                        return React.createElement(TabPanel, { key: idx }, content);
                    }))))));
    return content;
};
export const ModalWelcomeBuilder = ({ new_data }) => {
    const [, setCount] = useState(0);
    new_data.menu_configuration.dict_setter_show_dialog.ref_setter_modal_welcome_active_page.current = () => setCount(a => a + 1);
    const [page_links, page_content] = ModalWelcomeContent(new_data);
    return React.createElement(ModalWelcome, { new_data: new_data, external_pagination: page_links, external_content: page_content });
};
export const ModalWelcomeContent = (new_data) => {
    var _a, _b;
    const { t, static_path } = new_data;
    const welcome_text = (_b = (_a = new_data.options) === null || _a === void 0 ? void 0 : _a.welcome_text) !== null && _b !== void 0 ? _b : '';
    const has_welcome_text = welcome_text.length > 0;
    const images_paths = resources['images_carousel_paths'].map(image_path => {
        const path = window.location.href;
        if (!path.includes('localhost')) {
            image_path = image_path.replace('static/', static_path);
        }
        return image_path;
    });
    const src_intro_static = 'welcome/intro_static.png';
    const page_links = {};
    const page_content = {};
    // Welcom text
    if (has_welcome_text) {
        page_links['read_me'] = React.createElement(React.Fragment, null, t('welcome.breadcrumbs.read_me'));
        page_content['read_me'] = welcome_text ? React.createElement(React.Fragment, null, " parse(welcome_text) ") : React.createElement(React.Fragment, null);
    }
    // Introduction to application
    page_links['intro'] = React.createElement(React.Fragment, null,
        " ",
        t('welcome.breadcrumbs.intro'));
    page_content['intro'] = React.createElement(Box, { display: "block", height: '100%' }, new_data.is_static ?
        React.createElement("img", { src: src_intro_static, alt: 'intro carousel', style: { 'objectFit': 'contain', 'width': '100%' } }) :
        React.createElement(Carousel, { variant: 'dark', style: { 'height': '100%' } }, images_paths.map((_, idx) => {
            let title = _.split('/').pop();
            title = title.split('.').splice(0, 1).join('');
            return (React.createElement(Carousel.Item, { key: idx, style: { 'height': '100%' } },
                React.createElement(Box, { display: 'grid', width: '100%', height: '100%' },
                    React.createElement(Text, { textStyle: 'h2', padding: '2rem', justifySelf: 'center', alignSelf: 'bottom', textAlign: 'center' }, t('welcome.caroussel.' + title)),
                    React.createElement("img", { alt: title, src: _, style: {
                            'objectFit': 'scale-down',
                            'justifySelf': 'center',
                            'alignSelf': 'center',
                            'maxWidth': '100%',
                            'height': '100%'
                        } }),
                    React.createElement(Text, { textStyle: 'h4', padding: '2rem', justifySelf: 'center', alignSelf: 'bottom', textAlign: 'center' }, t('welcome.caroussel.descr.' + title)))));
        })));
    // Shortcuts
    page_links['rc'] = React.createElement(React.Fragment, null, t('welcome.breadcrumbs.rc'));
    page_content['rc'] = React.createElement(Box, { display: "block", overflowY: 'scroll', overflowX: 'hidden', height: '100%', width: '100%' },
        React.createElement(Box, { display: 'grid', gridTemplateColumns: '50% 50%', gridColumnGap: '0.25rem', width: '100%', height: '100%', justifySelf: 'center' },
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_select'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_shift_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_shift_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_shift_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_shift_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cs_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cs'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag_bold')),
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cdn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cdn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ad_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ad'))))),
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_edi'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_ds_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_ds'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_dn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_dn')))),
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_autre'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_s_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_s'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_fc_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_fc'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_dbm_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_dbm'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_ech_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_ech'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll')))))));
    return [
        page_links,
        page_content
    ];
};
