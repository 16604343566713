// External imports
import React, { useState } from 'react';
import { Box, Checkbox, Select, TabPanel, } from '@chakra-ui/react';
import { OSTooltip } from '../../deps/OpenSankey/types/Utils';
/*************************************************************************************************/
export const MenuConfigurationLinksTags = ({ new_data, menu_for_modal, }) => {
    // Data -------------------------------------------------------------------------------
    const { t } = new_data;
    // Tags -------------------------------------------------------------------------------
    // Flux tag groups
    const list_flux_taggs = new_data.drawing_area.sankey.flux_taggs_list;
    const has_flux_taggs = list_flux_taggs.length > 0;
    const [flux_tagg_entry_index, setFluxTaggEntryIndex] = useState(0);
    const flux_tagg_entry = list_flux_taggs[flux_tagg_entry_index];
    // Data tag groups
    const data_taggs = new_data.drawing_area.sankey.data_taggs_list;
    // Selected links ---------------------------------------------------------------------
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    // Menu updaters ----------------------------------------------------------------------
    const [, setCount] = useState(0);
    const updateThis = () => {
        // Can just use simple refresh if flux_tagg entry exists
        if (new_data.drawing_area.sankey.flux_taggs_list[flux_tagg_entry_index])
            setCount(a => a + 1);
        // If not, reset entry
        else
            setFluxTaggEntryIndex(0);
    };
    new_data.menu_configuration.ref_to_menu_config_links_tags_updater.current = updateThis;
    /**
     * Function used to reset menu UI
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Whatever is done, set saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        new_data.menu_configuration.updateAllComponentsRelatedToFluxTags();
    };
    // Utils functions --------------------------------------------------------------------
    /**
     * Check if all selected nodes are related to the given tag
     * @param {Class_Tag} tag
     * @return [allTrue: boolean, allFalse: boolean]
     */
    const haveAllSelectedLinksGivenTag = (tag) => {
        let allTrue = true;
        let allFalse = true;
        selected_links
            .forEach(link => {
            const test = link.hasGivenTag(tag);
            allTrue = allTrue && (test === true);
            allFalse = allFalse && (test === false);
        });
        return [allTrue, allFalse];
    };
    // JSX content ------------------------------------------------------------------------
    const content = React.createElement(React.Fragment, null, (selected_links.length !== 0) ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.EF')),
            React.createElement(Select, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_option_select', onChange: (evt) => {
                    setFluxTaggEntryIndex(Number(evt.target.value));
                }, value: flux_tagg_entry_index }, list_flux_taggs.map((flux_tagg, flux_tagg_index) => React.createElement("option", { key: flux_tagg.id, value: flux_tagg_index }, flux_tagg.name))),
            data_taggs
                .filter(data_tagg => data_tagg.has_tags) // Secu
                .map(data_tagg => {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, data_tagg.name),
                    React.createElement(Select, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_option_select', name: data_tagg.id, value: (_b = (_a = data_tagg.first_selected_tags) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '-', onChange: (evt) => {
                            data_tagg.selectTagsFromId(evt.target.value);
                            // Update only this menu
                            new_data.menu_configuration.updateAllComponentsRelatedToDataTags();
                        } }, data_tagg.tags_list
                        .map(data_tag => {
                        return (React.createElement("option", { key: data_tag.id, value: data_tag.id }, data_tag.name));
                    }))));
            }),
            has_flux_taggs ?
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, flux_tagg_entry.tags_list
                    .map(flux_tag => {
                    const [allTrue, allFalse] = haveAllSelectedLinksGivenTag(flux_tag);
                    return (React.createElement(Checkbox, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_option_checkbox', isChecked: allTrue, isIndeterminate: !allTrue && !allFalse, onChange: (evt) => {
                            const visible = evt.target.checked;
                            new_data.drawing_area.updateSelectedLinksTagAssignation(visible, flux_tag);
                        } }, flux_tag.name));
                })) : React.createElement(React.Fragment, null))
        :
            React.createElement(React.Fragment, null));
    return React.createElement(OSTooltip, { label: new_data.has_sankey_plus ? '' : t('Menu.sankeyOSPDisabled') }, menu_for_modal ?
        content :
        React.createElement(TabPanel, null, content));
};
