// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
// Local types
import { GetRandomInt, default_background_color, default_black_color, default_grid_color, default_main_sankey_id, getBooleanFromJSON, getNumberFromJSON, getNumberOrUndefinedFromJSON, getStringFromJSON, getStringOrUndefinedFromJSON, list_palette_color } from '../types/Utils';
import { ClassTemplate_NodeElement, sortNodesElements } from '../Elements/Node';
import { ClassTemplate_GhostLinkElement, ClassTemplate_LinkElement, sortLinksElementsByDisplayingOrders, sortLinksElementsByIds } from '../Elements/Link';
import { ClassTemplate_Legend } from '../Elements/Legend';
import { convert_data_legacy, convert_pre_v_0_91 } from './Legacy';
import { ClassAbstract_DrawingArea, } from '../types/Abstract';
// CONSTANTS ****************************************************************************
const initial_show_structure = 'reconciled';
const default_grid_size = 50;
const default_grid_visible = true;
const default_horizontal_spacing = 200;
const default_vertical_spacing = 50;
const default_scale = 50;
const default_DA_marging = 50;
// CLASS DRAWING AREA *******************************************************************
/**
 * Class to deal with drawing area properties and display
 *
 * @class ClassTemplate_DrawingArea
 */
export class ClassTemplate_DrawingArea extends ClassAbstract_DrawingArea {
    getZoomScale() {
        var _a;
        const tmp = (_a = this.d3_selection_zoom_area) === null || _a === void 0 ? void 0 : _a.node();
        if (tmp && tmp !== null)
            return d3.zoomTransform(tmp).k;
        else
            return 1;
    }
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_DrawingArea.
     * @param {ClassAbstract_ApplicationData} application_data
     * @memberof ClassTemplate_DrawingArea
     */
    constructor(application_data, id = default_main_sankey_id) {
        super();
        /**
       * d3 svg element containing all sub svg elements, it is also wher we can zoom with scroll wheel
       * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
       * @memberof ClassTemplate_DrawingArea
       */
        this.d3_selection_zoom_area = null;
        /**
         * d3 svg groups for drawing area
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection = null;
        /**
         * d3 selection of svg group that contains drawing area background
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_bg_group = null;
        /**
       * d3 selection of svg group that contains drawing area background
       * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
       * @memberof ClassTemplate_DrawingArea
       */
        this.d3_selection_bg = null;
        /**
         * d3 selection of svg group that contains drawing area grid
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_grid = null;
        /**
         * d3 selection of svg group that contains drawing area elements
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_elements_group = null;
        /**
         * d3 selection of svg group that contains drawing area nodes
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_nodes = null;
        /**
         * d3 selection of svg group that contains drawing area links
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_links = null;
        /**
         * d3 selection of svg group that contains drawing area legend elements
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_legend = null;
        /**
         * d3 selection of svg group that contains drawing area legend elements
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_handlers = null;
        /**
         * d3 selection of svg group that contains drawing area zone of selection element
         * @type {(d3.Selection<SVGGElement, unknown, HTMLElement, unknown> | null)}
         * @memberof ClassTemplate_DrawingArea
         */
        this.d3_selection_zone_select = null;
        /**
         * Is drawing area in publish _mode or not. If so, blocks all interactions with it
         * @type {boolean}
         * @memberof ClassTemplate_DrawingArea
         */
        this.static = window.SankeyToolsStatic;
        this.bypass_redraws = false;
        // Color
        this._color = default_background_color;
        // Grid
        this._grid_color = default_grid_color;
        this._grid_visible = default_grid_visible;
        this._grid_size = default_grid_size;
        // PRIVATE ATTRIBUTES =================================================================
        // Attributes that describe drawing area ----------------------------------------------
        /**
         * Distance to keep between drawing area & external windows sides (up, down, left & right)
         * when calling window fit
         * @private
         * @type {number}
         * @memberof ClassTemplate_DrawingArea
         */
        this._fit_margin = 10;
        /**
         * Scaling factor as value = scale * value in pixel
         * @private
         * @type {number}
         * @memberof ClassTemplate_DrawingArea
         */
        this._scale = default_scale;
        /**
         * _scaleValueToPx transform a value to a proportional size in px according to data scale
         *
         * @private
         * @memberof ClassTemplate_DrawingArea
         */
        this._scaleValueToPx = d3.scaleLinear()
            .domain([0, this._scale])
            .range([0, 100]);
        // Positionning
        this._horizontal_spacing = default_horizontal_spacing;
        this._vertical_spacing = default_vertical_spacing;
        // Shifting of d3 elements
        this._elements_d3_groups_shift_x = 0;
        this._elements_d3_groups_shift_y = 0;
        this._background_d3_groups_shift_x = 0;
        this._background_d3_groups_shift_y = 0;
        // Filter out link inferior to this value (when filter value is at 0 doesn't filter link even null)
        this._filter_link_value = 0;
        // Filter out link label inferior to this value (null is considered as 0)
        this._filter_label = 0;
        // Display
        this._show_structure = initial_show_structure;
        this._number_of_elements = 0;
        // Context attributes for drawing area ------------------------------------------------
        /**
         * Interaction mode with drawing area
         * @private
         * @type {('edition' | 'selection')}
         * @memberof ClassTemplate_DrawingArea
         */
        this._mode = 'edition';
        /**
         * Boolean to know if we are creating a link & another node at the release of the LMB
         *
         * @private
         * @type {boolean}
         * @memberof ClassTemplate_DrawingArea
         */
        this._ghost_link = null;
        /**
         *Elements that are selected in this area
         *
         * @protected
         * @type {{ [id: string]: ClassTemplate_ProtoElement }}
         * @memberof ClassTemplate_DrawingArea
         */
        this._selection = {};
        // Context menu
        this._pointer_pos = [0, 0];
        this._node_contextualied = undefined;
        this._link_contextualied = undefined;
        this._is_drawing_area_contextualised = false;
        /**
         * Zoom & positioning of drawing_area
         * if we want to move manually the drawing_area, we should use this variable
         * (see areaFitHorizontally && areaFitVertically)
         * @private
         * @memberof ClassTemplate_DrawingArea
         */
        this.zoomListener = d3.zoom()
            // only trigger zoom event when we scroll (which == 0) &&
            // and drag mouse middle button (which == 2)
            .filter(evt => (evt.which === 2 || evt.which === 0))
            // Change cursor in teh beginning to 'move' to show we can shift drawing area
            .on('start', () => { var _a; return (_a = this.d3_selection_zoom_area) === null || _a === void 0 ? void 0 : _a.attr('cursor', 'move'); })
            .on('zoom', (event) => this.eventZoom(event))
            // Reset cursor in the end
            .on('end', () => { var _a; return (_a = this.d3_selection_zoom_area) === null || _a === void 0 ? void 0 : _a.attr('cursor', ''); });
        /**
         *Inverse selected links and save undoing
         *
         * @memberof ClassTemplate_DrawingArea
         */
        this.inverseSelectedLinks = () => {
            const _inverseSelectedLinks = () => {
                // Inverse link source & target
                this.selected_links_list.forEach(link => link.inverse());
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
            };
            // Save undo/redo in data history
            this.application_data.history.saveUndo(_inverseSelectedLinks);
            this.application_data.history.saveRedo(_inverseSelectedLinks);
            // Execute original attr mutation
            _inverseSelectedLinks();
        };
        /**
         * Update tag selected for selected links and save it undoing
         *
         * @param {boolean} val
         * @param {Class_Tag} flux_tag
         */
        this.updateSelectedLinksTagAssignation = (val, flux_tag) => {
            const visible = val;
            const dict_old_val = {};
            this.selected_links_list.forEach(l => {
                dict_old_val[l.id] = l.hasGivenTag(flux_tag);
            });
            const _updateSelectedLinksTagAssignation = () => {
                this.selected_links_list.forEach(link => {
                    if (visible) {
                        link.addTag(flux_tag);
                    }
                    else {
                        link.removeTag(flux_tag);
                    }
                });
                // Full update
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
            };
            const inv_updateSelectedLinksTagAssignation = () => {
                this.selected_links_list.forEach(link => {
                    if (dict_old_val[link.id]) {
                        link.addTag(flux_tag);
                    }
                    else {
                        link.removeTag(flux_tag);
                    }
                });
                // Full update
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
            };
            this.application_data.history.saveUndo(inv_updateSelectedLinksTagAssignation);
            this.application_data.history.saveRedo(_updateSelectedLinksTagAssignation);
            _updateSelectedLinksTagAssignation();
        };
        this.application_data = application_data;
        // Init attributes
        this._height = this.window_fitting_height;
        this._width = this.window_fitting_width;
        this._sankey = this.createNewSankey(id);
        this._legend = new ClassTemplate_Legend(this, this.application_data.menu_configuration);
        this._selection_zone = this.createNewSelectionZone();
    }
    // CLEANING METHODS ===================================================================
    delete() {
        var _a;
        // Empty selection
        this.purgeSelection();
        // Clean ghost link
        (_a = this._ghost_link) === null || _a === void 0 ? void 0 : _a.delete();
        this._ghost_link = null;
        // Unref contextualized elements -> will be deleted later
        this._link_contextualied = undefined;
        this._node_contextualied = undefined;
        // Clean Elements
        // this._sankey.delete() TODO Trop lourd + bug suppression vues
        this._legend.delete();
        this._selection_zone.delete();
        // Clean drawing area
        this.unDraw();
    }
    // COPY METHODS ======================================================================
    copyFrom(drawing_area_to_copy) {
        // Delete everything
        this.delete();
        // Copy All attributes
        this._copyAttrFrom(drawing_area_to_copy);
        // Copy Sankey
        this._sankey.copyFrom(drawing_area_to_copy._sankey);
        //create new ClassTemplate_Legend after deleting previous in 'this.delete()'
        this._legend = new ClassTemplate_Legend(this, this.application_data.menu_configuration);
        // Copy Legend
        this._legend.copyFrom(drawing_area_to_copy._legend);
        //create new selection zone after deleting previous in 'this.delete()'
        this._selection_zone = this.createNewSelectionZone();
    }
    _copyAttrFrom(drawing_area_to_copy) {
        // Copy All attributes
        this.static = drawing_area_to_copy.static;
        this._color = drawing_area_to_copy._color;
        this._filter_label = drawing_area_to_copy._filter_label;
        this._filter_link_value = drawing_area_to_copy._filter_link_value;
        this._fit_margin = drawing_area_to_copy._fit_margin;
        this._grid_color = drawing_area_to_copy._grid_color;
        this._grid_size = drawing_area_to_copy._grid_size;
        this._grid_visible = drawing_area_to_copy._grid_visible;
        this._height = drawing_area_to_copy._height;
        this._horizontal_spacing = drawing_area_to_copy._horizontal_spacing;
        this._maximum_flux = drawing_area_to_copy._maximum_flux;
        this._minimum_flux = drawing_area_to_copy._minimum_flux;
        this._number_of_elements = drawing_area_to_copy._number_of_elements;
        this._scale = drawing_area_to_copy._scale;
        this._scaleValueToPx.domain([0, this._scale]);
        this._show_structure = drawing_area_to_copy._show_structure;
        this._vertical_spacing = drawing_area_to_copy._vertical_spacing;
        this._width = drawing_area_to_copy._width;
    }
    updateFrom(other_drawing_area, mode) {
        // Transfert all attributes = Copy everything from other drawing area
        const all = mode.includes('*');
        // Transfer DA attributs
        if (mode.includes('attrDrawingArea') || all) {
            this._copyAttrFrom(other_drawing_area);
            this._legend.copyFrom(other_drawing_area._legend);
        }
        // Transfert Sankey Attributes
        this.sankey.updateFrom(other_drawing_area.sankey, mode);
    }
    // SAVING METHODS =====================================================================
    /**
     * Convert current drawing area & all substructure as JSON data
     * @param {boolean} [only_visible_elements=false]
     * @param {boolean} [with_values=true]
     * @return {*}
     * @memberof ClassTemplate_DrawingArea
     */
    toJSON(only_visible_elements = false, with_values = true) {
        // Create json struct
        const json_object = {};
        // Add current version of app
        json_object['version'] = this.application_data.version;
        // Dump DA attributes
        json_object['height'] = this._height;
        json_object['width'] = this._width;
        json_object['grid_visible'] = this._grid_visible;
        json_object['grid_square_size'] = this._grid_size;
        json_object['h_space'] = this._horizontal_spacing;
        json_object['v_space'] = this._vertical_spacing;
        json_object['user_scale'] = this._scale;
        json_object['couleur_fond_sankey'] = this._color;
        if (this._maximum_flux)
            json_object['maximum_flux'] = this._maximum_flux;
        if (this._minimum_flux)
            json_object['minimum_flux'] = this._minimum_flux;
        json_object['filter_label'] = this._filter_label;
        json_object['filter_link_value'] = this._filter_link_value;
        json_object['show_structure'] = this._show_structure;
        json_object['number_of_elements'] = this._number_of_elements;
        // Dump with json of contained elements
        const out = Object.assign(Object.assign(Object.assign({}, json_object), this._legend.toJSON()), this._sankey.toJSON(only_visible_elements, with_values));
        return out;
    }
    /**
     * Export current drawing area & its contents as json struct
     *
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_DrawingArea
     */
    fromJSON(json_object, match_and_update = true) {
        const version = getStringOrUndefinedFromJSON(json_object, 'version');
        // Only legacy convert old sankey
        if ((version === undefined) ||
            (Number(version) < 0.9)) {
            convert_data_legacy(json_object); // FIXME
        }
        if ((version !== undefined) &&
            (Number(version) < 0.91)) {
            convert_pre_v_0_91(json_object);
        }
        // Set node label separator attribute from json
        this.application_data.node_label_separator = getStringFromJSON(json_object, 'node_label_separator', ' - ');
        this.application_data.node_label_separator_part = getStringFromJSON(json_object, 'node_label_separator_part', 'before');
        this.application_data.language = getStringOrUndefinedFromJSON(json_object, 'language');
        // Update direct attributes
        this._color = getStringFromJSON(json_object, 'couleur_fond_sankey', this._color);
        this._filter_label = getNumberFromJSON(json_object, 'filter_label', 0);
        this._filter_link_value = getNumberFromJSON(json_object, 'filter_link_value', 0);
        this._grid_size = getNumberFromJSON(json_object, 'grid_square_size', this._grid_size);
        this._grid_visible = getBooleanFromJSON(json_object, 'grid_visible', this._grid_visible);
        this._height = getNumberFromJSON(json_object, 'height', this._height);
        this._horizontal_spacing = getNumberFromJSON(json_object, 'h_space', this._horizontal_spacing);
        this._maximum_flux = getNumberOrUndefinedFromJSON(json_object, 'maximum_flux');
        this._minimum_flux = getNumberOrUndefinedFromJSON(json_object, 'minimum_flux');
        this._number_of_elements = getNumberFromJSON(json_object, 'number_of_elements', this._number_of_elements);
        this._scale = getNumberFromJSON(json_object, 'user_scale', this._scale);
        this._scaleValueToPx.domain([0, this._scale]);
        this._show_structure = getStringFromJSON(json_object, 'show_structure', this._show_structure);
        this._vertical_spacing = getNumberFromJSON(json_object, 'v_space', this._vertical_spacing);
        this._width = getNumberFromJSON(json_object, 'width', this._width);
        // Update legend
        this._legend.fromJSON(json_object);
        // Update Sankey
        this.sankey.fromJSON(json_object, match_and_update);
    }
    // PUBLIC METHODS ====================================================================
    /**
     * Reset drawing area & add waiting toast
     * @memberof ClassTemplate_DrawingArea
     */
    draw() {
        // This function calls explictly for a redraw
        this.bypass_redraws = false;
        // Clean drawing area
        this.unDraw();
        // Reinit d3 selections
        this._initDraw();
        // Draw Everything
        this.drawElements();
        // Fit area
        this.areaAutoFit();
        // Added events listeners
        this.setEventsListeners();
        // Unset saving indicator
        this.application_data.menu_configuration.ref_to_save_in_cache_indicator.current(true);
    }
    /**
     * Reinit d3 selections
     * @protected
     * @memberof ClassTemplate_DrawingArea
     */
    _initDraw() {
        // Add zoom zone where we can scroll to zoom or drag with mouse middle button
        this.d3_selection_zoom_area = d3.select('#sankey_app')
            .append('svg')
            .attr('id', 'draw_zoom')
            .attr('width', window.innerWidth)
            .attr('height', window.innerHeight)
            .attr('transform', 'translate(0, 0)'); // Avoid NaN when Zooming
        // Init drawing area
        const x = this._fit_margin / 2;
        const y = this._fit_margin / 2 + this.getNavBarHeight(); // init drawing area zone with a margin for taking into account the navbar
        this.d3_selection = this.d3_selection_zoom_area
            .append('g')
            .attr('id', 'g_drawing')
            .attr('transform', 'translate(' + x + ',' + y + ')');
        // Add specific groups for drawing background
        this.d3_selection_bg_group = this.d3_selection.append('g').attr('id', 'g_background');
        this.d3_selection_bg = this.d3_selection_bg_group.append('g').attr('id', 'g_color_bg');
        this.d3_selection_grid = this.d3_selection_bg_group.append('g').attr('id', 'g_grid');
        // Since legend can't be affected by zoom, it outside g_drawing
        this.d3_selection_legend = this.d3_selection_zoom_area.append('g').attr('id', 'grp_legend');
        // Add specific groups for nodes, link and others
        this.d3_selection_elements_group = this.d3_selection.append('g').attr('id', 'g_elements');
        this.d3_selection_links = this.d3_selection_elements_group.append('g').attr('id', 'g_links');
        this.d3_selection_nodes = this.d3_selection_elements_group.append('g').attr('id', 'g_nodes');
        this.d3_selection_handlers = this.d3_selection_elements_group.append('g').attr('id', 'g_handlers');
        this.d3_selection_zone_select = this.d3_selection_elements_group.append('g').attr('id', 'g_select_zone');
    }
    /**
     * Draw grid for drawing area
     * @public
     * @memberof ClassTemplate_DrawingArea
     */
    drawGrid() {
        var _a, _b, _c, _d;
        // Clean if needed
        (_a = this.d3_selection_grid) === null || _a === void 0 ? void 0 : _a.selectAll('.line').remove();
        // Draw only if asked OR outside publishing mode
        if (this.grid_visible && !this.static) {
            // Draw horizontal lines
            const number_of_horizontal_lines = this.height / this.grid_size;
            for (let row = 0; row < number_of_horizontal_lines; row++) {
                (_b = this.d3_selection_grid) === null || _b === void 0 ? void 0 : _b.append('line').attr('class', 'line line-horiz').attr('id', 'line_horiz_drawing_area_' + String(row)).attr('x1', '0').attr('x2', this.width).attr('y1', row * this.grid_size).attr('y2', row * this.grid_size).style('stroke', this.grid_color).style('stroke-dasharray', 4);
            }
            // Draw vertical lines
            const number_of_vertical_lines = this.width / this.grid_size;
            for (let column = 0; column < number_of_vertical_lines; column++) {
                (_c = this.d3_selection_grid) === null || _c === void 0 ? void 0 : _c.append('line').attr('class', 'line line-vert').attr('id', 'line_horiz_drawing_area_' + String(column)).attr('x1', column * this.grid_size).attr('x2', column * this.grid_size).attr('y1', 0).attr('y2', this.height).style('stroke-dasharray', 4).style('stroke', this.grid_color);
            }
            (_d = this.d3_selection_grid) === null || _d === void 0 ? void 0 : _d.raise();
        }
    }
    /**
     * Draw all elements inside drawing area
     * @memberof ClassTemplate_DrawingArea
     */
    drawElements() {
        // Draw grid
        this.drawGrid();
        // for parametric mode nodes need to be draw in a certain order
        // so that the nodes at the top of the columns are drawn first
        this._sankey.sortNodes();
        // Draw all nodes
        this._sankey.draw();
        // Draw legend
        this._legend.draw();
    }
    drawSelected() {
        // Draw links selected
        this.selected_links_list.forEach(link => link.draw());
        // Draw nodes selected
        this.selected_nodes_list.forEach(node => node.draw());
    }
    closeAllMenus() {
        this.application_data.menu_configuration.closeAllMenus();
        this.closeAllContextMenus();
    }
    /**
     * Context menus are directly diplayed in drawing area when deal with them directly here
     * @memberof ClassTemplate_DrawingArea
     */
    closeAllContextMenus() {
        // Reset contextulised elements
        this.node_contextualised = undefined;
        this.link_contextualised = undefined;
        this.is_drawing_area_contextualised = false;
        // Update components
        this.application_data.menu_configuration.ref_to_menu_context_nodes_updater.current();
        this.application_data.menu_configuration.ref_to_menu_context_links_updater.current();
        this.application_data.menu_configuration.ref_to_menu_context_drawing_area_updater.current();
    }
    addElement() {
        // We increase by two, in order to easyly swap elements
        // ie : element0 order = 0, element1 order = 2, element3 order = 4
        // to increase element 0 order, juste add 3
        // then : element0 order = 3, element1 order = 2, element3 order = 4
        // then orderElement() method will display elements as wanted + update their order value
        // ie : element1 order = 0, element0 order = 2, element3 order = 4
        this._number_of_elements = this._number_of_elements + 2;
        return this._number_of_elements;
    }
    orderElements() {
        // Sort links
        let new_order = 0;
        this.sankey.links_list
            .sort((a, b) => sortLinksElementsByDisplayingOrders(a, b))
            .forEach(link => {
            var _a;
            if (link.is_visible) {
                (_a = link.d3_selection) === null || _a === void 0 ? void 0 : _a.raise();
            }
            // Re-update display order as consecutive
            link.displaying_order = new_order;
            new_order = new_order + 2;
        });
        // Sort nodes
        // TODO if necessary
        // Update number of elements
        this._number_of_elements = new_order;
    }
    /**
     * Checks if it is possible to directly deal with events
     * @return {boolean}
     * @memberof ClassTemplate_DrawingArea
     */
    eventsEnabled() {
        // Deal with node events in priority
        const mouse_over_nodes = this.isMouseOverAnExistingNode();
        if (mouse_over_nodes === true) {
            return false;
        }
        // Deal with link events
        for (const link_id in this.sankey.links_dict) {
            if (this.sankey.links_dict[link_id].isMouseOver())
                return false;
        }
        if (this._legend.isMouseOver()) {
            return false;
        }
        // Ok event
        return true;
    }
    /**
     * Add a new default node to drawing area sankey
     * @return {ClassTemplate_NodeElement}
     * @memberof ClassTemplate_DrawingArea
     */
    addNewDefaultNodeToSankey() {
        return this.sankey.addNewDefaultNode();
    }
    /**
     * Retrieve node by id from sankey struct
     * @param {string} id
     * @return {ClassTemplate_NodeElement | null}
     * @memberof ClassTemplate_DrawingArea
     */
    getNodeFromSankey(id) {
        return this.sankey.getNode(id);
    }
    /**
     * Delete a given node -> node will not exist anymore
     * @param {ClassTemplate_NodeElement} node
     * @memberof ClassTemplate_DrawingArea
     */
    deleteNode(node) {
        // Remove from selection if necessary
        this.removeNodeFromSelection(node);
        // Remove node from sankey
        this.sankey.deleteNode(node);
        // Self delete node
        node.delete();
        // Update related menus
        this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
    }
    /**
     * Add a new default link to drawing area sankey
     * @return {Type_GenericLinkElement}
     * @memberof ClassTemplate_DrawingArea
     */
    addNewDefaultLinkToSankey() {
        return this.sankey.addNewDefaultLink();
    }
    /**
     * Retrieve node by id from sankey struct
     * @param {string} id
     * @return {Type_GenericLinkElement | null}
     * @memberof ClassTemplate_DrawingArea
     */
    getLinkFromSankey(id) { return this.sankey.getLink(id); }
    /**
     * Delete a given link -> link will not exist anymore
     * @param {ClassTemplate_NodeElement} node
     * @memberof ClassTemplate_DrawingArea
     */
    deleteLink(link) {
        // Remove link from selection if necessary
        this.removeLinkFromSelection(link);
        // Remove link from sankey
        this.sankey.removeLink(link);
        // Self delete node
        link.delete();
        // Update related menus
        this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
    }
    /**
     * Add a node to selection set
     * Update menu accordingly
     * @param {ClassTemplate_NodeElement} node
     * @memberof ClassTemplate_DrawingArea
     */
    addNodeToSelection(node) {
        // Update selection list
        this._selection[node.id] = node;
        // Update selection attribute on given node
        node.setSelected();
        // Update related menus
        this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
    }
    addLegendToSelection() {
        // Update selection list
        this._selection['legend'] = this._legend;
        this._legend.setSelected();
    }
    /**
     * Add all nodes to selection set
     * Update menu accordingly
     * @memberof ClassTemplate_DrawingArea
     */
    addAllVisibleNodesToSelection() {
        this.sankey.visible_nodes_list
            .forEach(node => this.addNodeToSelection(node));
    }
    /**
     * remove a node from a selection set
     * Update menu accordingly
     * @param {Type_GenericNodeElement} node
     * @memberof ClassTemplate_DrawingArea
     */
    removeNodeFromSelection(node) {
        if (this._selection[node.id] !== undefined) {
            // Update selection list
            delete this._selection[node.id];
            // Update selection attribute on given node
            node.setUnSelected();
            // Update related menus
            this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
        }
    }
    /**
     * remove a legend from a selection set
     * @param {Type_GenericNodeElement} node
     * @memberof Class_DrawingArea
     */
    removeLegendFromSelection() {
        if (this._selection['legend'] !== undefined) {
            // Update selection list
            delete this._selection['legend'];
            // Update selection attribute on legend
            this._legend.setUnSelected();
        }
    }
    /**
     * Permanently delete selected nodes
     * Update menu accordingly
     * @memberof ClassTemplate_DrawingArea
     */
    deleteSelectedNodes() {
        // Get copy of selected nodes
        const selected_nodes = this.selected_nodes_list;
        // Delete each one of them
        selected_nodes.forEach(node => { this.deleteNode(node); });
        // Then let garbage collector do the rest...
    }
    /**
     * Add a link to selection set
     * Update menu accordingly
     * @param {Type_GenericLinkElement} link
     * @memberof ClassTemplate_DrawingArea
     */
    addLinkToSelection(link) {
        // Update selection list
        this._selection[link.id] = link;
        // Update selection attribute on given link
        link.setSelected();
        // Update related menus
        this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
    }
    /**
     * Add all links to selection set
     * Update menu accordingly
     * @memberof ClassTemplate_DrawingArea
     */
    addAllVisibleLinksToSelection() {
        this.sankey.visible_links_list
            .forEach(link => this.addLinkToSelection(link));
    }
    /**
     * Remove given link from selection set
     * @param {ClassTemplate_LinkElement} link
     * @memberof ClassTemplate_DrawingArea
     */
    removeLinkFromSelection(link) {
        if (this._selection[link.id] !== undefined) {
            // Update selection list
            delete this._selection[link.id];
            // Update selection attribute on given link
            link.setUnSelected();
            // Update related menus
            this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
        }
    }
    /**
     * Remove all link selected
     * @memberof ClassTemplate_DrawingArea
     */
    purgeSelectionOfLinks(reset = true) {
        // Unselect elements
        this.selected_links_list
            .forEach(link => {
            this.removeLinkFromSelection(link);
        });
        // Reset config menu
        // Sometime this function is used then updateAllComponentsRelatedToLinks is also called,
        //  this mean that the hook referenced go from true -> false -> true before the rerender
        // & since it doesn't see a changement of value it doesn't trigger the redraw of the component
        if (reset)
            this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
    }
    /**
   * Remove all node selected
   * @memberof ClassTemplate_DrawingArea
   */
    purgeSelectionOfNode(reset = true) {
        // Unselect elements
        this.selected_nodes_list
            .forEach(node => {
            this.removeNodeFromSelection(node);
        });
        // Reset config menu
        // Sometime this function is used then updateAllComponentsRelatedToNodes is also called,
        //  this mean that the hook referenced go from true -> false -> true before the rerender
        // & since it doesn't see a changement of value it doesn't trigger the redraw of the component
        if (reset)
            this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
    }
    /**
     * Delete all selected links -> link will not exist anymore
     *
     * @memberof ClassTemplate_DrawingArea
     */
    deleteSelectedLinks() {
        // dict containing  [link order in source node,link order in target node  ]
        const dict_old_val = {};
        const list_old_link = [...this.selected_links_list].map(l => {
            const order_o_s = l.source.output_links_list.map(l => l.id);
            const order_i_t = l.target.input_links_list.map(l => l.id);
            dict_old_val[l.id] = [order_o_s, order_i_t];
            return l;
        });
        const selected_links = list_old_link;
        const _deleteSelectedLinks = () => {
            // Delete each one of them
            selected_links.forEach(link => { this.deleteLink(link); });
            // Then let garbage collector do the rest...
        };
        const inv_deleteSelectedLinks = () => {
            // Reintroduce deleted link + order them correctly in source/target
            list_old_link.forEach(l => {
                const new_l = this.sankey.addNewLinkWithId(l.id, l.source, l.target);
                new_l.source.reorganizeIOFromListIds(dict_old_val[l.id][0]);
                new_l.target.reorganizeIOFromListIds(dict_old_val[l.id][1]);
                new_l.copyFrom(l);
                l.drawWithNodes();
            });
        };
        // Save undo/redo in data history
        this.application_data.history.saveUndo(inv_deleteSelectedLinks);
        this.application_data.history.saveRedo(_deleteSelectedLinks);
        // Execute original attr mutation
        _deleteSelectedLinks();
    }
    /**
     * Clean selection set of sankey elements
     * @memberof ClassTemplate_DrawingArea
     */
    purgeSelection() {
        // Unselect everything
        Object.values(this._selection)
            .forEach((element) => element.setUnSelected());
        // TODO Unselect other things
        // Reset selection
        // TODO reset config menu
        this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
        this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
        // Clean selection dict
        this._selection = {};
    }
    /**
     * Delete all selected elements
     *
     * @memberof ClassTemplate_DrawingArea
     */
    deleteSelection() {
        // Save undo --------------------------------------
        // --- Init
        const json_hist_nodes = {};
        const json_hist_links = {};
        // --- Selected nodes
        this.selected_nodes_list
            .forEach(node => {
            json_hist_nodes[node.id] = node.toJSON();
            node.input_links_list.forEach(link => json_hist_links[link.id] = link.toJSON());
            node.output_links_list.forEach(link => json_hist_links[link.id] = link.toJSON());
        });
        // --- Selected links
        this.selected_links_list.forEach(link => json_hist_links[link.id] = link.toJSON());
        // --- Undo function
        function undo(_) {
            const json_hist = {
                'nodes': json_hist_nodes,
                'links': json_hist_links
            };
            _.sankey.fromJSON(json_hist);
            _.sankey.draw();
        }
        this.saveUndo(undo);
        // End Save undo -----------------------------------
        this.deleteSelectedNodes();
        this.deleteSelectedLinks();
        // Save Redo ---------------------------------------
        // -- Redo function
        function redo(_) {
            Object.keys(json_hist_links)
                .forEach(link_id => _.deleteLink(_.sankey.links_dict[link_id]));
            Object.keys(json_hist_nodes)
                .forEach(node_id => _.deleteNode(_.sankey.nodes_dict[node_id]));
        }
        this.saveRedo(redo);
        // End Save Redo -----------------------------------
    }
    /**
     * Function to use after setting link value, it check if there is only 1 link and if so update DA scale
     *
     * @memberof ClassTemplate_DrawingArea
     */
    updateScaleAtLinkValueSetting() {
        // Update scaling if only one link
        const links = this.sankey.links_list.filter(l => l.value != null && l.value.data_value != null && l.value.data_value != 0);
        if (links.length == 1) {
            this.scale = links[0].value.data_value; // will redraw everything // will redraw everything
        }
    }
    /**
     * Function to check if element are near drawing area border & update it size in consequence
     *
     * @memberof ClassTemplate_DrawingArea
     */
    checkAndUpdateAreaSize(recenter = false) {
        var _a, _b, _c, _d, _e;
        // Get bounding box for all elements
        const bbox = (_c = (_b = (_a = this.d3_selection_elements_group) === null || _a === void 0 ? void 0 : _a.node()) === null || _b === void 0 ? void 0 : _b.getBBox()) !== null && _c !== void 0 ? _c : undefined;
        // No bounding box -> return
        if (bbox == undefined)
            return;
        // Bounding box with no element -> default dims
        if ((bbox.width == 0) && (bbox.height == 0)) {
            // Set to fitting windows
            this.width = this.window_fitting_width;
            this.height = this.window_fitting_height;
            // And redraw
            this.drawBackground();
            return;
        }
        // Get fitting dimensions for drawing area - depends on screen size
        const fitting_width = this.window_fitting_width; // acces speeding computation
        const fitting_height = this.window_fitting_height; // acces speeding computation
        // Get current drawing zone dimensions
        let width = this.width;
        let height = this.height;
        let x0 = this._background_d3_groups_shift_x;
        let y0 = this._background_d3_groups_shift_y;
        // Check horizontal fitting
        const new_x0 = Math.min(0, bbox.x - default_DA_marging);
        const new_x1 = Math.max(fitting_width, bbox.x + bbox.width + default_DA_marging);
        width = new_x1 - new_x0;
        x0 = new_x0;
        // Check vertical fitting
        const new_y0 = Math.min(0, bbox.y - default_DA_marging);
        const new_y1 = Math.max(fitting_height, bbox.y + bbox.height + default_DA_marging);
        height = new_y1 - new_y0;
        y0 = new_y0;
        // Recenter elements
        if (recenter) {
            this._elements_d3_groups_shift_x = -(bbox.x + bbox.width / 2) + (x0 + width / 2);
            this._elements_d3_groups_shift_y = -(bbox.y + bbox.height / 2) + (y0 + height / 2);
            (_d = this.d3_selection_elements_group) === null || _d === void 0 ? void 0 : _d.attr('transform', 'translate(' + this._elements_d3_groups_shift_x + ', ' + this._elements_d3_groups_shift_y + ')');
        }
        // Save new dimensions
        this._width = width;
        this._height = height;
        this._background_d3_groups_shift_x = x0;
        this._background_d3_groups_shift_y = y0;
        // And redraw
        (_e = this.d3_selection_bg_group) === null || _e === void 0 ? void 0 : _e.attr('transform', 'translate(' + this._background_d3_groups_shift_x + ', ' + this._background_d3_groups_shift_y + ')');
        this.drawBackground();
        this.drawGrid();
    }
    /**
     * Recenter drawing area & make it fit the screen horizontally,
     *
     * (not recommended for vertical sankey)
     *
     * @memberof ClassTemplate_DrawingArea
     */
    areaFitHorizontally() {
        this.checkAndUpdateAreaSize(true);
        if (this.d3_selection_zoom_area) {
            // window_fitting_width correspond to minimal width of drawing_area (when there is no elements pushing it boundaries)
            const k = this.window_fitting_width / this.width;
            const x0 = this._fit_margin / 2 - this._background_d3_groups_shift_x * k;
            const y0 = Math.max(this._fit_margin / 2, (this.window_fitting_height - this.height * k) / 2) + this.getNavBarHeight() - this._background_d3_groups_shift_y * k;
            this.zoomListener.scaleTo(this.d3_selection_zoom_area, k);
            this.zoomListener.translateTo(this.d3_selection_zoom_area, 0, 0, [x0, y0]);
        }
    }
    /**
     * Recenter drawing area & make it fit the screen vertically,
     *
     * (not recommended for horizontal sankey)
     *
     * @memberof ClassTemplate_DrawingArea
     */
    areaFitVertically() {
        this.checkAndUpdateAreaSize(true);
        if (this.d3_selection_zoom_area) {
            // window.innerHeight-50 correspond to minimal height of drawing_area (when there is no elements pushing it boundaries)
            const k = this.window_fitting_height / this.height;
            const x0 = Math.max(this._fit_margin / 2, (this.window_fitting_width - k * this.width) / 2) - this._background_d3_groups_shift_x * k;
            const y0 = this._fit_margin / 2 + this.getNavBarHeight() - this._background_d3_groups_shift_y * k;
            this.zoomListener.scaleTo(this.d3_selection_zoom_area, k);
            this.zoomListener.translateTo(this.d3_selection_zoom_area, 0, 0, [x0, y0]);
        }
    }
    /**
     * Explore all node's branches to compute all their nodes horizontal index
     *
     * @param {SankeyNode} node Node to start exploring from
     * @param {number} starting_index
     * @param {string[]} visited_nodes_ids List of nodes (by their id) that have been visited. Helps to find recycling flux
     * @param {string[]} recycling_links_ids Links (by their id) that are detected as recycling link
     * @param {object} horizontal_indexes_per_nodes_ids Current horizontal index for given node id
     */
    computeHorizontalIndex(node, nodes_to_process, starting_index, visited_nodes_ids, recycling_links_ids, horizontal_indexes_per_nodes_ids) {
        // Update node index
        if (!horizontal_indexes_per_nodes_ids[node.id]) {
            horizontal_indexes_per_nodes_ids[node.id] = starting_index;
        }
        else {
            if (starting_index > horizontal_indexes_per_nodes_ids[node.id]) {
                horizontal_indexes_per_nodes_ids[node.id] = starting_index;
            }
        }
        // From current node, use output links to
        // recurse on following node
        node
            .output_links_list
            .filter(link => 
        // Computes only for link to visible nodes
        // and not for nodes related to recyling flux
        (nodes_to_process.includes(this.sankey.links_dict[link.id].target) &&
            !recycling_links_ids.includes(link.id)))
            .forEach(link => {
            // Next node to recurse on
            const next_node = this.sankey.nodes_dict[this.sankey.links_dict[link.id].target.id];
            // But first we check if next node has not been already visited
            if (!visited_nodes_ids.includes(next_node.id)) {
                // Recursive calling
                this.computeHorizontalIndex(next_node, nodes_to_process, starting_index + 1, [...visited_nodes_ids, node.id], recycling_links_ids, horizontal_indexes_per_nodes_ids);
            }
            else {
                // If next node has already been visited then this means
                // that link between current node and next node
                // is a recycling flux
                //
                // To illustrate :
                // -> This example count as recycling flux :
                //    N0 - N11 - N21 - N3
                //       \ N12 - N22 -
                //          |         |
                //           ---------
                // -> But not this one :
                //    N0 - N11 - N21 - N3
                //       \ N12 - N22 \
                //          |         |
                //           ---------
                recycling_links_ids.push(link.id);
            }
        });
    }
    /**
     * Recompte index for link taggued as recyling links
     * We need to recompute positionning of next_node,
     * because of recycling link, its position can be all wrong
     * -> exemple
     *
     *     N0 - N11 - N21 - N3
     *       \     \
     *        N12 - N22 \
     *         |         |
     *          ---------
     *
     *    So we got N0->N11->N22->N12->N22 stop
     *               0   1    2    3
     *    And the link N12->N22 will be considered as
     *    recycled link and we will get
     *
     *      N0 - N11 - N21 - N3
     *        \      \
     *         \       N22
     *          \    /
     *           \   -------------
     *            \              |
     *             --------- N12 -
     *    So we need to recompute N12 index
     *
     * @param {SankeyLink} link Link that has been previoulsy taggued ass possible recyling link
     * @param {string[]} visible_nodes_ids List of nodes (by their id) that are currently visible on Sankey diagram
     * @param {string[]} recycling_links_ids Links (by their id) that are detected as recycling link
     * @param {object} horizontal_indexes_per_nodes_ids Current index for given node id
     * @param {object} links
     * @param {object} nodes
     */
    computeRecyclingHorizontalIndex(nodes_to_process, link, recycling_links_ids, horizontal_indexes_per_nodes_ids) {
        // Get id for source and target
        const target_node_id = link.id;
        const source_node_id = link.id;
        // Compute only if horizontal indexes for source >= horizontal index for target
        // which can not be the case if these nodes' indexes have been reprocessed
        // by this same function
        if (horizontal_indexes_per_nodes_ids[source_node_id] >=
            horizontal_indexes_per_nodes_ids[target_node_id]) {
            // For source node, check if there is a gap
            // between its horizontal index and all the horizontal
            // indexes of nodes that are sources of its own inputs links
            const indexes_before_source_node = [];
            let min_index = -1;
            this.sankey.nodes_dict[source_node_id]
                .input_links_list
                .forEach(input_link => {
                const index = horizontal_indexes_per_nodes_ids[this.sankey.links_dict[input_link.id].id];
                if (min_index >= 0) {
                    if (index < min_index) {
                        min_index = index;
                    }
                }
                else {
                    min_index = index;
                }
                indexes_before_source_node.push(index);
            });
            // If there is a gap, we recompute source node horizontal indexing
            const horizontal_index_of_source_node = horizontal_indexes_per_nodes_ids[source_node_id]; // memorize value for loop
            for (let index = min_index + 1; index < horizontal_index_of_source_node; index++) {
                // Gap check here
                if (!indexes_before_source_node.includes(index)) {
                    horizontal_indexes_per_nodes_ids[source_node_id] = index;
                    // TODO faut un forçage des indexs à suivre.
                    this.computeHorizontalIndex(this.sankey.nodes_dict[source_node_id], nodes_to_process, index, [], recycling_links_ids, horizontal_indexes_per_nodes_ids);
                    break;
                }
            }
        }
    }
    /**
     * Initially there is only one node per type of exchanges.
     * it must be splitted to have one import and one export per product
     * International will be split to give InternationalProduct1Importation InternationalProduc1Exportation
     *
     * @memberof ClassTemplate_DrawingArea
     */
    splitTrade() {
        if (!this.sankey.node_taggs_dict['type de noeud']) {
            return;
        }
        let trade_nodes = this.sankey.nodes_list.filter(n => n.hasGivenTag(this.sankey.node_taggs_dict['type de noeud'].tags_dict['echange']));
        // first split the nodes
        trade_nodes.forEach(node => {
            if (node.output_links_list.length > 0) {
                node.SplitIOrE(true);
            }
            if (node.input_links_list.length > 0) {
                node.SplitIOrE(false);
            }
            this.sankey.deleteNode(node);
        });
        trade_nodes = this.sankey.nodes_list.filter(n => n.hasGivenTag(this.sankey.node_taggs_dict['type de noeud'].tags_dict['echange']));
        // the set dimensions. It must be done after each trade node has been split
        trade_nodes.forEach(node => {
            node.setTradeDimensions(true);
            node.setTradeDimensions(false);
        });
    }
    /**
     * Compute the position of the nodes which are not trade nodes
     *
     * /!\ Add to waiting spinner queue
     *
     * @memberof ClassTemplate_DrawingArea
     */
    computeAutoSankey(launched_from_process) {
        this.application_data.sendWaitingToast(() => {
            // Compute auto pos of nodes
            this._computeAutoSankey(launched_from_process);
            this.computeParametrization();
            if (launched_from_process) {
                // Initially there is only one node per type of exchanges.
                // it must be splitted to have one import and one export per product
                // International will be split to give InternationalProduct1Importation InternationalProduc1Exportation
                this.splitTrade();
                // Computes u v,x and initial y for trade nodes
                this.arrangeTrade(true);
            }
            // Defaut color + auto reorg of links
            const color_selected = list_palette_color[GetRandomInt(list_palette_color.length)];
            this.sankey.visible_nodes_list.forEach((n, i, a) => {
                var _a;
                n.reorganizeIOLinks();
                if (launched_from_process) {
                    this.sankey.nodes_list[i].shape_color = (_a = d3.color(color_selected(+i / a.length))) === null || _a === void 0 ? void 0 : _a.formatHex();
                }
            });
            if (launched_from_process) {
                // Update defaut data on recycling mode
                this.sankey.links_list.forEach(l => {
                    if (l.shape_is_recycling) {
                        l.shape_starting_tangeant = 0.01;
                        l.shape_ending_tangeant = 0.01;
                    }
                });
            }
            // Update area
            this.areaAutoFit();
            // Toggle saving indicator
            this.application_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        }, {
            success: {
                title: this.application_data.t('toast.compute_auto_sankey.success.title')
            },
            loading: {
                title: this.application_data.t('toast.compute_auto_sankey.loading.title')
            }
        });
    }
    /**
     * Compute the position of the nodes which are not trade nodes
     * @protected
     * @param {boolean} launched_from_process
     * @memberof ClassTemplate_DrawingArea
     */
    _computeAutoSankey(launched_from_process) {
        // Calcul de la valeur max des flux
        let linksMaxValue = 0;
        this.sankey.links_list.forEach(link => {
            // We use a function to max value for each link because
            // each link can have multiple values
            const linkMaxValue = link.getMaxValue();
            linksMaxValue = Math.max(linksMaxValue, linkMaxValue ? linkMaxValue : 0);
        });
        linksMaxValue += 1; // Protection if all values are at 0
        // Get scale from max value
        if (launched_from_process) {
            this.scale = this._maximum_flux ? Math.max(this._maximum_flux, linksMaxValue) : linksMaxValue;
        }
        const echangeTag = this.sankey.node_taggs_dict['type de noeud'] ? this.sankey.node_taggs_dict['type de noeud'].tags_dict['echange'] : undefined;
        const nodes_to_process = this.sankey.visible_nodes_list.filter(n => !echangeTag || !n.hasGivenTag(echangeTag));
        // Compute positionning indexes
        const horizontal_indexes_per_nodes_ids = {};
        const possible_recycling_links_ids = [];
        nodes_to_process
            .forEach(node => {
            //     const node = data.nodes[node_id]
            if (!node.hasInputLinks() && node.hasOutputLinks()) {
                // get current node horizontal index (eg longest branch length)
                const starting_index = 0;
                this.computeHorizontalIndex(node, nodes_to_process, starting_index, [], possible_recycling_links_ids, horizontal_indexes_per_nodes_ids);
            }
            else {
                // Lone node case
                if (!node.hasInputLinks() && !node.hasOutputLinks()) {
                    horizontal_indexes_per_nodes_ids[node.id] = 0;
                }
            }
        });
        // Double check recycling links
        const checked_recycling_links_ids = [];
        Object.values(possible_recycling_links_ids)
            .forEach(link_id => this.computeRecyclingHorizontalIndex(nodes_to_process, this.sankey.links_dict[link_id], checked_recycling_links_ids, horizontal_indexes_per_nodes_ids));
        // Use results from previous index computing
        // TODO : maybe possible to speed up here overall computing with getting
        //        max_horizontal_index and nodes_per_horizontal_indexes from another loop
        let max_horizontal_index = 0;
        const nodes_per_horizontal_indexes = {};
        this.sankey.visible_nodes_list.forEach(node => {
            // Previously computed index for given node
            const node_index = horizontal_indexes_per_nodes_ids[node.id];
            // Update reversed dict index-> nodes
            if (!nodes_per_horizontal_indexes[node_index]) {
                nodes_per_horizontal_indexes[node_index] = [];
            }
            nodes_per_horizontal_indexes[node_index].push(this.sankey.nodes_dict[node.id]);
            // Update max horizontal index
            if (node_index > max_horizontal_index) {
                max_horizontal_index = node_index;
            }
            // Set recycling links
            Object.values(this.sankey.nodes_dict[node.id].output_links_list)
                .forEach(link => {
                // Get id for source and target
                const target_node_id = this.sankey.links_dict[link.id].target.id;
                // Compute only if indexes for source >= index for target
                // which can not be the case if these nodes have been reprocessed
                // by this same function
                if (node_index >= horizontal_indexes_per_nodes_ids[target_node_id]) {
                    this.sankey.links_dict[link.id].shape_is_recycling = true;
                }
                else {
                    this.sankey.links_dict[link.id].shape_is_recycling = false;
                }
            });
        });
        // for the node which have no input links they should stick to the next output node and
        // have an horizontal index equal to output node horizontal index minus one
        for (let horizontal_index = 0; horizontal_index <= max_horizontal_index; horizontal_index++) {
            // Pass if no nodes for this horizontal_index
            // TODO : if it is the case -> something was wrong before
            if (!nodes_per_horizontal_indexes[horizontal_index]) {
                continue;
            }
            const to_splice = [];
            nodes_per_horizontal_indexes[horizontal_index].forEach(node => {
                if (!node.hasInputLinks()) {
                    let min_next_horizontal_index = max_horizontal_index + 1;
                    node.output_links_list.forEach((link) => {
                        if (this.sankey.nodes_dict[this.sankey.links_dict[link.id].source.id].is_visible &&
                            this.sankey.nodes_dict[this.sankey.links_dict[link.id].target.id].is_visible) {
                            const target_node = this.sankey.nodes_dict[this.sankey.links_dict[link.id].target.id];
                            if (target_node === undefined) {
                                return;
                            }
                            if (horizontal_indexes_per_nodes_ids[target_node.id] < horizontal_indexes_per_nodes_ids[node.id]) {
                                return;
                            }
                            if (horizontal_indexes_per_nodes_ids[target_node.id] < min_next_horizontal_index) {
                                min_next_horizontal_index = horizontal_indexes_per_nodes_ids[target_node.id];
                            }
                        }
                    });
                    if (horizontal_indexes_per_nodes_ids[node.id] < min_next_horizontal_index - 1) {
                        to_splice.push(node);
                        // Il semblerait que dans certains cas nodes2horizontal_indices de certains noeuds peuvent devenir négatif
                        // ce qui lors de l'affectation difference'une position x, ceux-ci sont négatif
                        horizontal_indexes_per_nodes_ids[node.id] = min_next_horizontal_index - 1;
                        if (!nodes_per_horizontal_indexes[min_next_horizontal_index - 1]) {
                            nodes_per_horizontal_indexes[min_next_horizontal_index - 1] = [];
                        }
                        nodes_per_horizontal_indexes[min_next_horizontal_index - 1].push(node);
                    }
                }
            });
            to_splice.forEach(node => nodes_per_horizontal_indexes[horizontal_index].splice(nodes_per_horizontal_indexes[horizontal_index].indexOf(node), 1));
        }
        // Loop on all index "columns"
        let h_left_margin = this._horizontal_spacing;
        let h_right_margin = this._horizontal_spacing;
        const height_cumul_per_indexes = [];
        const height_per_nodes_ids = {};
        const node_id_per_hxv_indexes = [];
        let max_height_cumul = 0;
        for (let h_index = 0; h_index <= max_horizontal_index; h_index++) {
            // Pass if no nodes for this index
            if (!nodes_per_horizontal_indexes[h_index]) {
                continue;
            }
            // Loop on nodes from computed horizontal index
            let height_cumul_for_index = 0;
            let max_vertical_index = 0;
            const sortcoef_per_nodes_ids = {};
            const vertical_indexes_per_node_id = {};
            const nodes_ids_per_vertical_index = [];
            nodes_per_horizontal_indexes[h_index]
                .forEach(node => {
                // Node height
                const node_height = node.getShapeHeightToUse();
                // Coef to verticaly sort nodes - highest coef is upper
                // - Empirique : prend en considération taille du neoud et taille du noeud normalisée
                const node_sortcoef = node_height * (0.8 + 0.2 / (node.output_links_list.length + node.input_links_list.length));
                // Verticaly sort nodes accordingly to their height
                height_per_nodes_ids[node.id] = node_height;
                sortcoef_per_nodes_ids[node.id] = node_sortcoef;
                vertical_indexes_per_node_id[node.id] = max_vertical_index;
                nodes_ids_per_vertical_index.push(node.id);
                if (max_vertical_index > 0) {
                    // Bubble sort algo
                    for (let v_index = max_vertical_index; v_index > 0; v_index--) {
                        // Prev node infos
                        const prev_v_index = v_index - 1;
                        const prev_node_id = nodes_ids_per_vertical_index[prev_v_index];
                        const prev_node_sortcoef = sortcoef_per_nodes_ids[prev_node_id];
                        if (prev_node_sortcoef < node_sortcoef) {
                            // Update referencing for bubble node
                            vertical_indexes_per_node_id[node.id] = prev_v_index;
                            nodes_ids_per_vertical_index[prev_v_index] = node.id;
                            // Update referencing for prev node
                            vertical_indexes_per_node_id[prev_node_id] = v_index;
                            nodes_ids_per_vertical_index[v_index] = prev_node_id;
                        }
                        else {
                            break;
                        }
                    }
                }
                max_vertical_index += 1;
                // Compute cumulative height for given index
                height_cumul_for_index += node_height;
                // Compute left horizontal margin
                if (h_index == 0) {
                    const node_label_width = this.sankey.node_styles_dict[node.style.id].name_label_box_width;
                    const needed_margin = this.grid_size + node_label_width;
                    if (needed_margin > h_left_margin) {
                        h_left_margin = needed_margin;
                    }
                }
                // Compute right horizontal margin
                // if (h_index == (max_horizontal_index - cumul_shifting_value)) {
                if (h_index == max_horizontal_index) {
                    const node_label_width = this.sankey.node_styles_dict[node.style.id].name_label_box_width;
                    const needed_margin = this.grid_size + node_label_width;
                    if (needed_margin > h_right_margin) {
                        h_right_margin = needed_margin;
                    }
                }
                // If we launched the function from process example
                // then we assume we need to place node label according to some parameters
                if (launched_from_process) {
                    // Place labels accordingly
                    // If node is lone, source, sink or in the middle
                    if (!node.hasInputLinks() && !node.hasOutputLinks()) {
                        // Node is lone node
                        node.name_label_horiz = 'middle';
                        node.name_label_vert = 'middle';
                        // node.name_label_background = true
                    }
                    else if (node.input_links_list.length === 0) {
                        // Node is a source : no input link
                        node.name_label_horiz = 'left';
                        node.name_label_vert = 'middle';
                    }
                    else if (node.output_links_list.length === 0) {
                        // Node is a sink : no output link
                        node.name_label_horiz = 'right';
                        node.name_label_vert = 'middle';
                    }
                    else {
                        // Node is in the middle of the sankey
                        node.name_label_horiz = 'left';
                        node.name_label_vert = 'middle';
                        // node.name_label_background = true
                    }
                }
            });
            // Get horizontal index that need the most of vertical space
            // with vertical spacing between nodes in account
            height_cumul_for_index += (nodes_per_horizontal_indexes[h_index].length - 1) * (this.vertical_spacing);
            if (height_cumul_for_index > max_height_cumul) {
                max_height_cumul = height_cumul_for_index;
            }
            height_cumul_per_indexes.push(height_cumul_for_index);
            // Update global indexing table
            node_id_per_hxv_indexes.push(nodes_ids_per_vertical_index);
        }
        max_horizontal_index = (node_id_per_hxv_indexes.length - 1);
        // Update horizontal and vertical position of nodes
        // compute total height of nodes that belong to the same column,
        // then compute the spaces between them and their positions.
        const v_margin = this.vertical_spacing;
        for (let horizontal_index = 0; horizontal_index <= max_horizontal_index; horizontal_index++) {
            // Pass if no nodes for this horizontal_index
            // TODO : if it is the case -> something was wrong before
            if (!node_id_per_hxv_indexes[horizontal_index]) {
                continue;
            }
            // Loop on horizontal_index node
            const center_biggest_nodes = (node_id_per_hxv_indexes[horizontal_index].length > 2) && true; // TODO put function arg instead of true
            const h_position_for_index = h_left_margin + horizontal_index * this.horizontal_spacing;
            const v_margin_for_index = v_margin + (max_height_cumul - height_cumul_per_indexes[horizontal_index]) / 2;
            let upper_node_height_and_margin = v_margin_for_index;
            if (center_biggest_nodes === true) {
                // From the bottom to the top : plot node every two index
                let last_index = (node_id_per_hxv_indexes[horizontal_index].length - 1);
                for (let index = last_index; index >= 0; index -= 2) {
                    const node_id = node_id_per_hxv_indexes[horizontal_index][index];
                    // Node position
                    this.sankey.nodes_dict[node_id].position_x = h_position_for_index;
                    this.sankey.nodes_dict[node_id].position_y = upper_node_height_and_margin;
                    // Update upper margin for next node
                    const node_height = height_per_nodes_ids[node_id];
                    upper_node_height_and_margin += node_height + v_margin;
                    // Update last index
                    last_index = index;
                }
                // From the top to the bottom : remaining index
                if (last_index == 0)
                    last_index = 1;
                else
                    last_index = 0;
                for (let index = last_index; index < node_id_per_hxv_indexes[horizontal_index].length; index += 2) {
                    const node_id = node_id_per_hxv_indexes[horizontal_index][index];
                    // Node position
                    this.sankey.nodes_dict[node_id].position_x = h_position_for_index;
                    this.sankey.nodes_dict[node_id].position_y = upper_node_height_and_margin;
                    // Update upper margin for next node
                    const node_height = height_per_nodes_ids[node_id];
                    upper_node_height_and_margin += node_height + v_margin;
                }
            }
            else {
                node_id_per_hxv_indexes[horizontal_index]
                    .forEach(node_id => {
                    // Node position
                    this.sankey.nodes_dict[node_id].position_x = h_position_for_index;
                    this.sankey.nodes_dict[node_id].position_y = upper_node_height_and_margin;
                    // Update upper margin for next node
                    const node_height = height_per_nodes_ids[node_id];
                    upper_node_height_and_margin += node_height + v_margin;
                });
            }
        }
        const possible_witdh = (h_left_margin + max_horizontal_index * this.horizontal_spacing + h_right_margin);
        const possible_height = (v_margin * 2 + max_height_cumul);
        this.width = (this.window_fitting_width < possible_witdh) ? possible_witdh : this.window_fitting_width;
        this.height = (this.window_fitting_height < possible_height) ? possible_height : this.window_fitting_height;
    }
    /**
     * Computes u,v,x and initial y for trade nodes
     *
     * @memberof ClassTemplate_DrawingArea
     */
    arrangeTrade(compute_xy) {
        if (!this.sankey.node_taggs_dict['type de noeud']) {
            return;
        }
        const process_nodes = this.sankey.nodes_list;
        const echangeTag = this.sankey.node_taggs_dict['type de noeud'].tags_dict['echange'];
        const import_nodes = process_nodes.filter(n => n.hasGivenTag(echangeTag) && n.output_links_list.length > 0);
        const export_nodes = process_nodes.filter(n => n.hasGivenTag(echangeTag) && n.input_links_list.length > 0);
        const other_nodes = process_nodes.filter(n => !n.hasGivenTag(echangeTag));
        let max_vertical_offset = 0;
        other_nodes.forEach(n => max_vertical_offset = Math.max(n.position_y + n.getShapeHeightToUse(), max_vertical_offset));
        max_vertical_offset = max_vertical_offset + 200;
        // const firstNonEchangeNodeBelow = other_nodes.sort((n1,n2)=> n1.position_y-n2.position_y)[0]
        import_nodes.forEach(node => {
            const output_link = node.output_links_list[0];
            const target_node = output_link.target;
            node.position_u = target_node.position_u;
            node.position_v = target_node.position_v;
            if (compute_xy) {
                const x = target_node.position_x - this.horizontal_spacing;
                node.position_x = x;
                node.position_y = 50;
            }
        });
        export_nodes.forEach(node => {
            const input_link = node.input_links_list[0];
            const source_node = input_link.source;
            node.position_u = source_node.position_u;
            node.position_v = source_node.position_v;
            if (node.position_x < source_node.position_x) {
                node.position_x = source_node.position_x + 1;
            }
            if (compute_xy) {
                const x = source_node.position_x + this.horizontal_spacing;
                node.position_x = x;
                node.position_y = max_vertical_offset;
            }
        });
    }
    /**
     * Computes u,v for nodes in the drawing area
     *
     * @memberof ClassTemplate_DrawingArea
     */
    computeParametrization() {
        let smaller_x;
        this.sankey.visible_nodes_list.forEach(n => {
            if (smaller_x === undefined) {
                smaller_x = n.position_x;
            }
            if (n.position_x < smaller_x) {
                smaller_x = n.position_x;
            }
        });
        this.sankey.visible_nodes_list.forEach(n => {
            if (n.position_type === 'relative') {
                return;
            }
            n.display.position.u = Math.floor((n.position_x - smaller_x / 3) / this.sankey.default_node_style.position.dx);
        });
        this.computeParametricV();
    }
    /**
     * Computes v for nodes in the drawing area
     *
     * @memberof ClassTemplate_DrawingArea
     */
    computeParametricV() {
        const columns = {};
        this.sankey.visible_nodes_list.forEach(n => {
            if (n.position_type === 'relative') {
                return;
            }
            if (!(n.position_u in columns)) {
                columns[n.position_u] = [n];
            }
            else {
                columns[n.position_u].push(n);
            }
        });
        Object.values(columns).forEach(column => {
            column.sort((n1, n2) => n1.position_y - n2.position_y);
            column.forEach((n, i) => {
                if (i == 0) {
                    return;
                }
                const dy = n.position_y - column[i - 1].position_y - column[i - 1].getShapeHeightToUse();
                if (dy !== 0) {
                    n.position_dy = dy;
                }
                else {
                    //delete n.local!.dy
                }
            });
        });
        Object.values(columns).forEach(column => {
            column.sort((n1, n2) => n1.position_y - n2.position_y);
            let current_v = 0;
            column.forEach(n => current_v = this.apply_v_desagregate(n, current_v));
        });
        Object.values(columns).forEach(column => {
            column.forEach(n => this.apply_v_agregate(n));
        });
        this.sankey.sortNodes();
    }
    /**
     * Computes v for nodes in the drawing area
     *
     * @memberof ClassTemplate_DrawingArea
     */
    apply_v_agregate(node) {
        let agregated_nodes = [];
        this.sankey.level_taggs_list.forEach(tagGroup => {
            const nodeDimParent = node.nodeDimensionAsChild(tagGroup);
            if (!nodeDimParent) {
                return;
            }
            if (nodeDimParent.parent.display.position.v != -1) {
                // v is computed at the first path
                return;
            }
            agregated_nodes = [...agregated_nodes, nodeDimParent.parent];
            agregated_nodes = [...new Set(agregated_nodes)];
        });
        agregated_nodes.forEach(nn => {
            nn.display.position.x = node.position_x;
            nn.display.position.y = node.position_y;
            nn.display.position.u = node.position_u;
            nn.display.position.v = node.position_v;
            this.apply_v_agregate(nn);
        });
    }
    /**
     * Computes v for nodes in the drawing area
     *
     * @memberof ClassTemplate_DrawingArea
     */
    apply_v_desagregate(node, current_v) {
        if (node.display.position.v == -1) {
            // v is computed at the first path
            node.display.position.v = current_v;
        }
        let new_current_v = current_v;
        let desagregated_nodes = [];
        this.sankey.level_taggs_list.forEach(tagGroup => {
            const nodeDimParent = node.nodeDimensionAsParent(tagGroup);
            if (!nodeDimParent) {
                return;
            }
            desagregated_nodes = [...desagregated_nodes, ...nodeDimParent.children];
            desagregated_nodes = [...new Set(desagregated_nodes)];
        });
        let current_y = node.position_y;
        desagregated_nodes.forEach(nn => {
            nn.display.position.x = node.position_x;
            nn.display.position.u = node.position_u;
            nn.display.position.y = current_y;
            current_y += 20;
            new_current_v = this.apply_v_desagregate(nn, new_current_v);
        });
        return new_current_v + 1;
    }
    /**
     * Reposition visible nodes so that their left/top side is close to a grid line
     *
     * @memberof ClassTemplate_DrawingArea
     */
    arrangeNodesToGrid() {
        this._sankey.visible_nodes_list.forEach(node => {
            const shift_x = node.position_x - (node.position_x % this.grid_size); // get position so that the node position_x is set to previous horizontal grid line
            const shift_y = node.position_y - (node.position_y % this.grid_size); // get position so that the node position_y is set to previous vertical grid line
            node.setPosXY(shift_x, shift_y);
        });
    }
    /**
     * Function to delete attr _minimum_flux
     *
     * @memberof ClassTemplate_DrawingArea
     */
    removeMinimumLinkThickness() {
        delete this._minimum_flux;
    }
    /**
     * Function to delete attr _maximum_flux
     *
     * @memberof ClassTemplate_DrawingArea
     */
    removeMaximumLinkThickness() {
        delete this._maximum_flux;
    }
    /**
     * Create a timed out process - Used to avoid multiple reloading of components
     *
     * The process_func is meant to be use by setTimeout(),
     * and inside setTimeOut 'this' keyword has another meaning,
     * so the current object must be passed directly as an argument.
     * see : https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#the_this_problem
     *
     * @protected
     * @param {string} process_id
     * @param {(_: ClassTemplate_ProtoElement) => void} process_func
     * @memberof ClassTemplate_ProtoElement
     */
    _process_or_bypass(process_func) {
        if (this.bypass_redraws)
            return;
        process_func();
    }
    /**
     * Function that fit DA in screen, it determine if it have to fit it vertically or horizontally by processing ratio
     *
     * Function generally use at opening of file to automatically fit sankey on screen
     *
     * @memberof ClassTemplate_DrawingArea
     */
    areaAutoFit() {
        this._process_or_bypass(() => {
            // Ratios
            const ratio_v = this._height / this.window_fitting_height; // get ratio of sankey height / screen height
            const ratio_h = this._width / this.window_fitting_width; // get ratio of sankey width / screen width
            // Fit from ratio
            if (ratio_h > ratio_v) { // if sankey is wider than taller then fit horizontally
                this.areaFitHorizontally();
            }
            else if (ratio_h <= ratio_v) { // if sankey is taller than wider then fit vertically
                this.areaFitVertically();
            }
        });
    }
    // PRIVATE METHODS ==================================================================
    /**
     * Delete html element SVG containing drawing area
     * @private
     * @memberof ClassTemplate_DrawingArea
     */
    unDraw() {
        if (this.d3_selection_zoom_area) {
            this.d3_selection_zoom_area.remove();
            this.d3_selection_zoom_area = null;
        }
    }
    /**
     * Draw background for drawing area
     *
     * @param {*} drawing_area
     */
    drawBackground() {
        var _a, _b;
        // Clean if needed
        (_a = this.d3_selection_bg) === null || _a === void 0 ? void 0 : _a.selectAll('.bg').remove();
        // Draw background
        (_b = this.d3_selection_bg) === null || _b === void 0 ? void 0 : _b.append('rect').attr('class', 'bg').attr('id', 'bg_drawing_area').attr('fill', this.color).attr('width', this.width).attr('height', this.height).style('stroke-width', 5).style('stroke', default_black_color);
        this.drawCursor();
    }
    /**
     * History saving
     * @param f
     */
    saveUndo(f) {
        this.application_data.history.saveUndo(() => { f(this); });
    }
    /**
    * History saving
    * @param f
    */
    saveRedo(f) {
        this.application_data.history.saveRedo(() => { f(this); });
    }
    /**
     * Test if mouse is over some node
     *
     * @private
     * @return {*}
     * @memberof ClassTemplate_DrawingArea
     */
    isMouseOverAnExistingNode() {
        let node_id;
        for (node_id in this.sankey.nodes_dict) {
            if (this.sankey.nodes_dict[node_id].isMouseOver())
                return true;
        }
        return false;
    }
    /**
     * Set up events related to element d3_element
     * @private
     * @memberof ClassTemplate_DrawingArea
     */
    setEventsListeners() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (!this.static &&
            (this.d3_selection !== null)) {
            // Right mouse button clicks
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.on('click', (event) => this.eventSimpleLMBCLick(event));
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.on('dblclick', (event) => this.eventDoubleLMBCLick(event));
            // Right mouse button maintained
            (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.on('mousedown', (event) => this.eventMaintainedClick(event));
            (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.on('mouseup', (event) => this.eventReleasedClick(event));
            // Mouse cursor goes over this
            (_e = this.d3_selection) === null || _e === void 0 ? void 0 : _e.on('mouseover', (event) => this.eventMouseOver(event));
            (_f = this.d3_selection) === null || _f === void 0 ? void 0 : _f.on('mouseout', (event) => this.eventMouseOut(event));
            // Mouse cursor move
            (_g = this.d3_selection) === null || _g === void 0 ? void 0 : _g.on('mousemove', (event) => this.eventMouseMove(event));
            // Left mouse button click
            (_h = this.d3_selection) === null || _h === void 0 ? void 0 : _h.on('contextmenu', (event) => this.eventSimpleRMBCLick(event));
            // Zoom behavior(but can also drag drawing area in scroll zone)
            (_j = this.d3_selection_zoom_area) === null || _j === void 0 ? void 0 : _j.call(this.zoomListener).on('dblclick.zoom', null).on('wheel.zoom', (event) => {
                event.preventDefault();
                this.eventMouseScroll(event);
            });
        }
    }
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventSimpleLMBCLick(event) {
        event.preventDefault();
        if (this.eventsEnabled()) {
            // Clear tooltips presents
            d3.selectAll('.sankey-tooltip').remove();
        }
    }
    /**
     * Deal with double left Mouse Button (LMB) click on given element
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventDoubleLMBCLick(_event) {
        // TODO Ajouter déclemenchement editeur nom de noeud
    }
    /**
     * Deal with simple right Mouse Button (RMB) click on given element
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventSimpleRMBCLick(event) {
        event.preventDefault();
        if (this.eventsEnabled()) {
            // Clear tooltips presents
            d3.selectAll('.sankey-tooltip').remove();
            // SELECTION MODE ===========================================================
            if (this.isInSelectionMode()) {
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
                this.is_drawing_area_contextualised = true;
                this.application_data.menu_configuration.ref_to_menu_context_drawing_area_updater.current();
            }
        }
    }
    /**
     * Define maintained left mouse button click for drawing area
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventMaintainedClick(event) {
        var _a, _b;
        event.preventDefault();
        // Clear tooltips presents
        d3.selectAll('.sankey-tooltip').remove();
        // EDITION MODE =============================================================
        // event.button==0 check if we use LMB
        if (this.isInEditionMode() && event.button == 0 && this.eventsEnabled()) {
            // No more elements must be in selection
            this.purgeSelection();
            // Close all menus
            this.closeAllMenus();
            if (this._ghost_link == null) { // Start creating  a node & a ghost_link + ghost node
                // Get relative mouse position
                const mouse_position = d3.pointer(event);
                mouse_position[0] = mouse_position[0] - this._elements_d3_groups_shift_x;
                mouse_position[1] = mouse_position[1] - this._elements_d3_groups_shift_y;
                // Create default source node
                const source = this.sankey.addNewDefaultNode();
                // Position center of source node to pointer pos
                source.setPosXY(mouse_position[0] - (source.getShapeWidthToUse() / 2), mouse_position[1] - (source.getShapeHeightToUse() / 2));
                // Create default target node
                const target = this.sankey.addNewDefaultNode();
                target.setPosXY(mouse_position[0] + 2, mouse_position[1] + 2);
                // Make target a 'ghost' node
                target.setInvisible();
                // Ref newly created link this var to be used in other mouse event
                this._ghost_link = new ClassTemplate_GhostLinkElement('ghost_link', source, target, this, this.application_data.menu_configuration);
                this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
            }
            else {
                // If by any means we have already a ghost link but we start clicking the DA
                // (It can occur when we relase the mouse out of DA while we have a ghost link & restart clicking in DA
                // Mouse released on source node
                if (this._ghost_link.source.isMouseOver()) {
                    // If we release the mouse on the source of the link
                    // then delete the link & target to keep only the source
                    // So we only created 1 node
                    this.deleteNode(this._ghost_link.target);
                }
                else if (this.isMouseOverAnExistingNode() === true) {
                    let node_id = (_a = this._ghost_link) === null || _a === void 0 ? void 0 : _a.source.id; //in case the loop don't find the hovered node we take the source as default
                    for (node_id in this.sankey.nodes_dict) {
                        if (this.sankey.nodes_dict[node_id].isMouseOver())
                            break; //stop the loop when we fint the node hovered
                    }
                    // Create new link
                    this.sankey.addNewLink(this._ghost_link.source, this.sankey.nodes_dict[node_id]);
                    this.purgeSelectionOfLinks(false);
                    this.addLinkToSelection(this.sankey.links_list[this.sankey.links_list.length - 1]);
                    this.application_data.menu_configuration.openConfigMenuElementsLinks();
                    // Delete old target node
                    this.deleteNode((_b = this._ghost_link) === null || _b === void 0 ? void 0 : _b.target);
                }
                else {
                    // Make ghost target visible
                    this._ghost_link.target.setVisible();
                    // Create new link
                    this.sankey.addNewLink(this._ghost_link.source, this._ghost_link.target);
                    this.purgeSelectionOfLinks(false);
                    this.addLinkToSelection(this.sankey.links_list[this.sankey.links_list.length - 1]);
                    this.application_data.menu_configuration.openConfigMenuElementsLinks();
                }
                // In case we get there still deref ghost link
                this._ghost_link.delete();
                this._ghost_link = null;
                this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
            }
        }
        // SELECTION MODE ===========================================================
        else if (this.isInSelectionMode()) {
            if (event.button === 0) {
                // Close context menus
                this.closeAllContextMenus();
                // Get relative mouse position
                const mouse_position = d3.pointer(event);
                mouse_position[0] = mouse_position[0] - this._elements_d3_groups_shift_x;
                mouse_position[1] = mouse_position[1] - this._elements_d3_groups_shift_y;
                // Display the selection zone & set it starting position
                this._selection_zone.setVisible();
                this._selection_zone.starting_x_point = mouse_position[0];
                this._selection_zone.starting_y_point = mouse_position[1];
                this._selection_zone.draw();
            }
        }
    }
    /**
     * Define released left mouse button click for drawing area
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventReleasedClick(event) {
        var _a, _b;
        // EDITION MODE =============================================================
        if (this.isInEditionMode()) {
            // When we are creating a link with LMB
            if (this._ghost_link !== null) {
                // Mouse released on source node
                if (this._ghost_link.source.isMouseOver()) {
                    // If we release the mouse on the source of the link
                    // then delete the link & target to keep only the source
                    // So we only created 1 node
                    this.deleteNode(this._ghost_link.target);
                }
                else if (this.isMouseOverAnExistingNode() === true) {
                    let node_id = (_a = this._ghost_link) === null || _a === void 0 ? void 0 : _a.source.id; //in case the loop don't find the hovered node we take the source as default
                    for (node_id in this.sankey.nodes_dict) {
                        if (this.sankey.nodes_dict[node_id].isMouseOver())
                            break; //stop the loop when we fint the node hovered
                    }
                    // Create new link
                    this.sankey.addNewLink(this._ghost_link.source, this.sankey.nodes_dict[node_id]);
                    this.purgeSelectionOfLinks(false);
                    this.addLinkToSelection(this.sankey.links_list[this.sankey.links_list.length - 1]);
                    this.application_data.menu_configuration.openConfigMenuElementsLinks();
                    // Delete old target node
                    this.deleteNode((_b = this._ghost_link) === null || _b === void 0 ? void 0 : _b.target);
                }
                else {
                    // Make ghost target visible
                    this._ghost_link.target.setVisible();
                    // Create new link
                    this.sankey.addNewLink(this._ghost_link.source, this._ghost_link.target);
                    this.purgeSelectionOfLinks(false);
                    this.addLinkToSelection(this.sankey.links_list[this.sankey.links_list.length - 1]);
                    this.application_data.menu_configuration.openConfigMenuElementsLinks();
                }
                // In case we get there still deref ghost link
                this._ghost_link.delete();
                this._ghost_link = null;
                this.application_data.menu_configuration.updateAllComponentsRelatedToNodes();
                this.application_data.menu_configuration.updateAllComponentsRelatedToLinks();
            }
        }
        else if (this.isInSelectionMode() && event.button == 0) {
            if ((!event.shiftKey) && (!event.ctrlKey)) {
                this.purgeSelection();
            }
            // Select element inside the selection zone & reset it (hide the zone)
            this._selection_zone.selectElementsInside();
            this._selection_zone.reset();
        }
    }
    /**
     * Define event when mouse moves over drawing area
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventMouseOver(_event) {
        // TODO Definir
    }
    /**
     * Define event when mouse moves out of drawing area
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventMouseOut(_event) {
        // TODO definir
    }
    /**
     * Define event when mouse moves in drawing area
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventMouseMove(event) {
        // Save pointer pos for external access
        this.pointer_pos = [event.pageX, event.pageY];
        // EDITION MODE =============================================================
        if (this.isInEditionMode()) {
            // When we are creating a link with LMB
            if (this._ghost_link !== null) {
                // Get relative mouse position
                const mouse_position = d3.pointer(event);
                mouse_position[0] = mouse_position[0] - this._elements_d3_groups_shift_x;
                mouse_position[1] = mouse_position[1] - this._elements_d3_groups_shift_y;
                // Move ghost target
                const target = this._ghost_link.target;
                target.setPosXY(mouse_position[0] - (target.getShapeWidthToUse() / 2), mouse_position[1] - (target.getShapeHeightToUse() / 2));
                this.checkAndUpdateAreaSize();
            }
        }
        else if (this.isInSelectionMode()) {
            if (this._selection_zone.is_visible) {
                // Get relative mouse position
                const mouse_position = d3.pointer(event);
                mouse_position[0] = mouse_position[0] - this._elements_d3_groups_shift_x;
                mouse_position[1] = mouse_position[1] - this._elements_d3_groups_shift_y;
                // Variable that can be modifier if we move the selection zone above or at the left of it starting point
                let new_x = this.selection_zone.starting_x_point, new_y = this.selection_zone.starting_y_point;
                if (mouse_position[0] > this._selection_zone.position_x) {
                    this.selection_zone.width = mouse_position[0] - this._selection_zone.position_x;
                }
                else {
                    this.selection_zone.width = Math.abs(mouse_position[0] - this._selection_zone.starting_x_point);
                    new_x = mouse_position[0];
                }
                if (mouse_position[1] > this._selection_zone.starting_y_point) {
                    this.selection_zone.height = mouse_position[1] - this._selection_zone.starting_y_point;
                }
                else {
                    this.selection_zone.height = Math.abs(this._selection_zone.starting_y_point - mouse_position[1]);
                    new_y = mouse_position[1];
                }
                // Update shape on drawing area
                this.selection_zone.setPosXY(new_x, new_y);
                this._selection_zone.setSize();
            }
        }
    }
    /**
     * Define event when mouse scrolls in drawing area
     * Note : Under the hood, this calls eventZoom method throught this.zoomListener
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_DrawingArea
     */
    eventMouseScroll(event) {
        if (this.d3_selection_zoom_area) {
            // Zoom in / out
            if (event.ctrlKey) {
                // Avoid CTRL + Scroll default behavior in Browser
                event.preventDefault();
                // Get Scrolling factor ; either 1.1 or 0.9
                const scale = 1 + (event.deltaY / Math.abs(event.deltaY)) / 10;
                // Apply scaling
                this.zoomListener.scaleBy(this.d3_selection_zoom_area, scale, [event.x, event.y]);
            }
            // Horizontal displacement
            else if (event.shiftKey) {
                this.zoomListener.translateBy(this.d3_selection_zoom_area, event.deltaY, 0);
            }
            // Vertical displacement
            else {
                this.zoomListener.translateBy(this.d3_selection_zoom_area, 0, event.deltaY);
            }
        }
    }
    /**
     * Define behavior when we scroll in drawing area (or scroll zone around)
     * && when we drag mouse middle button in drawing area (or scroll zone around)
     *
     * @private
     * @param {*} e
     * @memberof ClassTemplate_DrawingArea
     */
    eventZoom(event) {
        if (this.d3_selection) {
            // Apply translation
            this.d3_selection
                .attr('transform', event.transform.toString());
        }
    }
    // GETTERS / SETTERS ==================================================================
    // Mode
    isInSelectionMode() { return this._mode === 'selection'; }
    setSelectionMode() { this._mode = 'selection'; this.drawCursor(); }
    isInEditionMode() { return this._mode === 'edition'; }
    setEditionMode() { this._mode = 'edition'; this.drawCursor(); }
    switchMode() {
        if (this.isInEditionMode())
            this.setSelectionMode();
        else if (this.isInSelectionMode())
            this.setEditionMode();
        this.sankey.visible_nodes_list.forEach(n => n.setEventsListeners()); // drag event is disabled in edition mode so we have to reset eventListener when we switch mode
        this._legend.setEventsListeners();
        this.application_data.menu_configuration.updateAllComponentsRelatedToToolbar();
    }
    setToModeEdition(_) {
        if (_) {
            this.setEditionMode();
        }
        else {
            this.setSelectionMode();
        }
    }
    /**
     * Technically don't draw a cursor but add a class & then css use it to modify cursor
     *
     * @memberof ClassTemplate_DrawingArea
     */
    drawCursor() {
        var _a, _b;
        const mode_edition = this.isInEditionMode();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.classed('edition_mode', mode_edition);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.classed('selection_mode', !mode_edition);
    }
    /**
     * return sankey
     *
     * @readonly
     * @return {ClassTemplate_Sankey<any, Type_GenericNodeElement, Type_GenericLinkElement>}
     * @memberof ClassTemplate_DrawingArea
     */
    get sankey() { return this._sankey; }
    // Legend
    get legend() { return this._legend; }
    set legend(value) { this._legend = value; }
    // Ghost link
    get ghost_link() { return this._ghost_link; }
    set ghost_link(value) { this._ghost_link = value; }
    // Selections
    get selected_nodes_list() {
        return Object.values(this._selection)
            .filter(element => element instanceof ClassTemplate_NodeElement);
    }
    get selected_nodes_list_sorted() {
        return this.selected_nodes_list
            .sort((a, b) => sortNodesElements(a, b));
    }
    get visible_and_selected_nodes_list() {
        return this.selected_nodes_list
            .filter(node => node.is_visible);
    }
    get visible_and_selected_nodes_list_sorted() {
        return this.visible_and_selected_nodes_list
            .sort((a, b) => sortNodesElements(a, b));
    }
    get selected_links_list() {
        return Object.values(this._selection)
            .filter(element => element instanceof ClassTemplate_LinkElement);
    }
    get selected_links_list_sorted() {
        return this.selected_links_list
            .sort((a, b) => sortLinksElementsByIds(a, b));
    }
    get visible_and_selected_links_list() {
        return this.selected_links_list
            .filter(link => link.is_visible);
    }
    get visible_and_selected_links_list_sorted() {
        return this.visible_and_selected_links_list
            .sort((a, b) => sortLinksElementsByIds(a, b));
    }
    // Size
    get width() { return this._width; }
    set width(_) { this._width = _; this.drawBackground(); this.drawGrid(); }
    get height() { return this._height; }
    set height(_) { this._height = _; this.drawBackground(); this.drawGrid(); }
    get window_fitting_height() { return window.innerHeight - this._fit_margin - this.getNavBarHeight() - this.getBottomBarHeight(); }
    get window_fitting_width() { return window.innerWidth - this._fit_margin - this.getSideBarWidth(); }
    // Number of element
    get number_of_element() { return this._number_of_elements; }
    /**
     * Return height of the top nav bar
     *
     * @return {*}
     * @memberof ClassTemplate_DrawingArea
     */
    getNavBarHeight() {
        var _a, _b;
        return (_b = ((_a = document.getElementsByClassName('TopMenu')[0]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height)) !== null && _b !== void 0 ? _b : 6 * 16; // 16 because we set size in rem in css 1 rem = 16px
    }
    /**
     * Return height of the top nav bar
     *
     * @return {*}
     * @memberof ClassTemplate_DrawingArea
     */
    getBottomBarHeight() {
        var _a, _b;
        return (_b = ((_a = document.getElementsByClassName('BottomMenu')[0]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height)) !== null && _b !== void 0 ? _b : 2 * 16; // 16 because we set size in rem in css 1 rem = 16px
    }
    /**
     * Return height of the top nav bar
     *
     * @return {*}
     * @memberof ClassTemplate_DrawingArea
     */
    getSideBarWidth() {
        var _a, _b;
        return (_b = ((_a = document.getElementsByClassName('openMenu')[0]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width)) !== null && _b !== void 0 ? _b : 50;
    }
    // Color
    get color() { return this._color; }
    set color(_) { this._color = _; this.drawBackground(); } // TODO add regular expression check here
    // Scale
    get scale() {
        return this._scale;
    }
    set scale(value) {
        if (value > 0) {
            this._scale = value;
            this._scaleValueToPx.domain([0, value]);
            this.application_data.menu_configuration.updateComponentRelatedToLayoutApparence();
            this.drawElements();
            this.areaAutoFit();
        }
    }
    // Grid color
    get grid_color() { return this._grid_color; }
    set grid_color(_) { this._grid_color = _; this.drawGrid(); }
    // Grid visibility
    get grid_visible() { return this._grid_visible; }
    set grid_visible(_) { this._grid_visible = _; this.drawGrid(); }
    setGridVisible() { this.grid_visible = true; this.drawGrid(); }
    setGridInvisible() { this.grid_visible = false; this.drawGrid(); }
    // Grid size
    get grid_size() { return this._grid_size; }
    set grid_size(_) { this._grid_size = _; this.drawGrid(); }
    // Horizontal spacing
    get horizontal_spacing() { return this._horizontal_spacing; }
    set horizontal_spacing(_) { this._horizontal_spacing = _; }
    get vertical_spacing() { return this._vertical_spacing; }
    set vertical_spacing(_) { this._vertical_spacing = _; }
    get selection_zone() { return this._selection_zone; }
    // Node Context menu
    get node_contextualised() { return this._node_contextualied; }
    set node_contextualised(value) { this._node_contextualied = value; }
    // Link Context menu
    get link_contextualised() { return this._link_contextualied; }
    set link_contextualised(value) { this._link_contextualied = value; }
    // Mouve pos when we right click an element
    get pointer_pos() { return this._pointer_pos; }
    set pointer_pos(value) { this._pointer_pos = value; }
    get is_drawing_area_contextualised() { return this._is_drawing_area_contextualised; }
    set is_drawing_area_contextualised(value) { this._is_drawing_area_contextualised = value; }
    get maximum_flux() { return this._maximum_flux; }
    set maximum_flux(value) {
        if (value === undefined || value > 0) {
            this._maximum_flux = value;
            this.drawElements();
        }
    }
    get minimum_flux() { return this._minimum_flux; }
    set minimum_flux(value) {
        if (value === undefined || value > 0) {
            this._minimum_flux = value;
            this.drawElements();
        }
    }
    get scaleValueToPx() { return this._scaleValueToPx; }
    get filter_label() { return this._filter_label; }
    set filter_label(value) { this._filter_label = value; }
    get show_structure() { return this._show_structure; }
    set show_structure(value) { this._show_structure = value; }
    get filter_link_value() { return this._filter_link_value; }
    set filter_link_value(value) { this._filter_link_value = value; }
}
