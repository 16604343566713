// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import React, { useRef } from 'react';
import LZString from 'lz-string';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import FileSaver from 'file-saver';
import { useToast } from '@chakra-ui/react';
import { ClassAbstract_ApplicationData } from '../types/Abstract';
import { randomId } from './Utils';
import { JSONtoExcel, retrieveExcelResults } from '../components/dialogs/SankeyPersistence';
import { Class_ApplicationHistory } from './ApplicationHistory';
// SPECIFIC CONSTANTS ******************************************************************/
export const default_save_only_visible_elements = false;
export const default_save_with_values = true;
export const default_save_JSON_options = { mode_save: default_save_with_values };
const default_toast_duration = 1000; // 1sec
const default_toast_waiting_delay = 500; // 500ms
const toast_bypass = false;
// CLASS APPLICATION DATA **************************************************************/
/**
 * Class that contains all elements to make the application work
 *
 * @class ClassTemplate_ApplicationData
 */
export class ClassTemplate_ApplicationData extends ClassAbstract_ApplicationData {
    // CONSTRUCTOR ========================================================================
    /**
      * Creates an instance of ClassTemplate_ApplicationData.
      * @param {boolean} published_mode
      * @memberof ClassTemplate_ApplicationData
      */
    constructor(published_mode, options = {}) {
        super();
        // PUBLIC ATTRIBUTES =================================================================
        // App
        this.version = '0.91';
        this.static_path = '/static/sankeytools/';
        this.options = {};
        // Save JSON options
        this.options_save_json = default_save_JSON_options;
        // Attributes to transfer between sankeys
        this.data_var_to_update = React.useRef([]);
        /**
         * All possible attr to update in copyFrom
         * @protected
         * @type {string[]}
         * @memberof ClassTemplate_ApplicationData
         */
        this._transform_layout_all_attr = [
            'addNode',
            'addFlux',
            'removeNode',
            'removeFlux',
            'posNode',
            'Values',
            'attrNode',
            'posFlux',
            'attrFlux',
            'tagNode',
            'tagFlux',
            'tagData',
            'tagLevel',
            'attrDrawingArea'
        ];
        /**
         * All item selectable in SankeyMenuPreference
         * @protected
         * @type {string[]}
         * @memberof ClassTemplate_ApplicationData
         */
        this._preference_menu_all_item = [];
        // PRIVATE ATTRIBUTES =================================================================
        /**
         * Traduction function
         * @private
         * @type {TFunction}
         * @memberof ClassTemplate_ApplicationData
         */
        this._t = useTranslation('translation', { useSuspense: false }).t; //traductor
        /**
         * i18n saved
         * @private
         * @memberof ClassTemplate_ApplicationData
         */
        this._i18n = useTranslation('translation', { useSuspense: false }).i18n; //traductor
        /**
         * Width of logo
         * @private
         * @type {number}
         * @memberof ClassTemplate_ApplicationData
         */
        this._logo_width = 100;
        /**
         * Application name
         * @private
         * @type {string}
         * @memberof ClassTemplate_ApplicationData
         */
        this._app_name = 'SankeySuite';
        /**
         * Path prefix for backend server requests
         * @private
         * @type {string}
         * @memberof ClassTemplate_ApplicationData
         */
        this._url_prefix = '/opensankey/';
        /**
         * Variable to modify node name label displayed,
         * it can contain separator (special caracter) that split label between what we want tot display and what not
         * @private
         * @memberof ClassTemplate_ApplicationData
         */
        this._node_label_separator = '-';
        /**
         * Variable to modify node name label displayed,
         * it can contain separator (special caracter) that split label between what we want tot display and what not
         * @private
         * @type {('before' | 'after')}
         * @memberof ClassTemplate_ApplicationData
         */
        this._node_label_separator_part = 'before';
        /**
         * Ref to checkbox of displayed menu in SankeyMenuPreference
         * @private
         * @type {{ [_: string]: RefObject<HTMLInputElement> }}
         * @memberof ClassTemplate_ApplicationData
         */
        this._checkbox_refs = {};
        /**
         * Ref to launch _function_on_wait & create a _toast with a spinner to show we have to wait
         * @private
         * @memberof ClassTemplate_ApplicationData
         */
        this._toast = useToast();
        /**
         * Queue of waiting processes for toast
         * @private
         * @type {string[]}
         * @memberof ClassTemplate_ApplicationData
         */
        this._toast_processes = [];
        /**
         * Force bypassing waiting toast
         * @private
         * @type {boolean}
         * @memberof ClassTemplate_ApplicationData
         */
        this._toast_bypass = toast_bypass;
        /**
         * Guided visite steps to show app
         * @private
         * @type {StepType[]}
         * @memberof ClassTemplate_ApplicationData
         */
        this._steps = [];
        this._show_documentation = false;
        // Options for application
        this.options = options;
        // Deals with UI menu updates / each modifications
        this._menu_configuration = this.createNewMenuConfiguration();
        // Init history
        this._history = new Class_ApplicationHistory(this._menu_configuration);
        // Contains all drawn objects
        this._drawing_area = this.createNewDrawingArea();
        // For published mode only
        this.drawing_area.static = published_mode;
        this.fit_screen = published_mode;
        // Get OpenSankey logo
        this._logo_opensankey = 'logos/logo_opensankey.png';
        // Get TerriFlux logo
        this._logo_terriflux = 'logos/logo_terriflux.png';
        // Default logo for app
        this._logo = this._logo_opensankey;
        // Excel processing function
        this._processFunction = {
            ref_processing: useRef(false),
            ref_setter_processing: useRef(() => null),
            failure: useRef(false),
            not_started: useRef(true),
            ref_result: useRef(() => null),
            path: useRef(''),
            retrieveExcelResults,
            launch: (cur_path) => {
                this._processFunction.path.current = cur_path;
                this.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_excel_reading_process.current(true);
                this._processFunction.ref_setter_processing.current(true);
                this._processFunction.failure.current = true;
                this._processFunction.not_started.current = false;
                this._processFunction.ref_result.current('');
            }
        };
        // Link keyboard listener with app key down detection
        document.onkeydown = this._keyboardEventListener(this);
    }
    // CLEANING METHODS ===================================================================
    /**
     * Reset drawing area -> clean data & undraw
     * Use a waiting spinner
     * @memberof ClassTemplate_ApplicationData
     */
    reset() {
        this.sendWaitingToast(() => {
            // Reset
            this._reset();
        }, {
            success: {
                title: this.t('toast.reset.success.title'),
                desc: this.t('toast.reset.success.desc')
            },
            loading: {
                title: this.t('toast.reset.loading.title'),
                desc: this.t('toast.reset.loading.desc')
            }
        });
    }
    /**
     * Reset drawing area -> clean data & undraw
     * @protected
     * @memberof ClassTemplate_ApplicationData
     */
    _reset() {
        // Reset drawing area
        const by_pass_redraw = this._drawing_area.bypass_redraws;
        // Undraw and create new DA
        this._drawing_area.unDraw();
        this._drawing_area = this.createNewDrawingArea();
        this._drawing_area.bypass_redraws = by_pass_redraw;
        this._node_label_separator = '-';
        this._node_label_separator_part = 'before';
        // Update menus
        this.menu_configuration.updateAllMenuComponents();
    }
    // SAVING METHODS =====================================================================
    /**
     * Save in JSON in browser cache
     *
     * /!\ Add to waiting spinner queue
     *
     * @memberof ClassTemplate_ApplicationData
     */
    saveInCache() {
        this.sendWaitingToast(() => {
            // Read json file
            this._saveInCache();
        }, {
            success: {
                title: this.t('toast.save_in_cache.success.title')
            },
            loading: {
                title: this.t('toast.save_in_cache.loading.title')
            },
            error: {
                title: this.t('toast.save_in_cache.error.title')
            }
        });
    }
    /**
     * Save as JSON in browser cache
     * @protected
     * @memberof ClassTemplate_ApplicationData
     */
    _saveInCache() {
        // Push to storage
        localStorage.setItem('data', LZString.compress(JSON.stringify(this._toJSON())));
        localStorage.setItem('last_save', 'true');
        // Update logo save in cache
        this.menu_configuration.ref_to_save_in_cache_indicator.current(true);
    }
    /**
     * save to JSON format
     *
     * /!\ Add to waiting spinner queue
     *
     * @memberof ClassTemplate_ApplicationData
     */
    saveToJSON() {
        this.sendWaitingToast(() => {
            this._saveToJSON();
        }, {
            success: {
                title: this.t('toast.save_as_json.success.title')
            },
            loading: {
                title: this.t('toast.save_as_json.loading.title')
            },
            error: {
                title: this.t('toast.save_as_json.error.title')
            }
        });
    }
    /**
     * Save to JSON format
     * @protected
     * @memberof ClassTemplate_ApplicationData
     */
    _saveToJSON() {
        // Convert all datas as JSON
        const json_data = this._toJSON();
        // Prepare JSON for saving
        const json_data_str = JSON.stringify(json_data, null, 2);
        const blob = new Blob([json_data_str], { type: 'text/plain;charset=utf-8' });
        // Set name for file to download
        const dataAsSuite = json_data;
        let name = 'Diagramme de Sankey';
        if (dataAsSuite.view &&
            dataAsSuite.view.length > 0 &&
            !dataAsSuite.is_catalog) {
            name = 'Diagramme de Sankey avec vues';
        }
        else if (dataAsSuite.is_catalog === true) {
            name = 'Catalogue de vues de diagrammes de Sankey';
        }
        // Trigger file download
        FileSaver.saveAs(blob, name + '.json');
    }
    /**
     * Save as Excel format
     *
     * /!\ Add to waiting spinner queue
     *
     * @param {string} url_prefix
     * @param {string} [file_name='sankey']
     * @memberof ClassTemplate_ApplicationData
     */
    saveToExcel(url_prefix, file_name = 'sankey') {
        this.sendWaitingToast(() => {
            this._saveToExcel(url_prefix, file_name);
        }, {
            success: {
                title: this.t('toast.save_as_excel.success.title')
            },
            loading: {
                title: this.t('toast.save_as_excel.loading.title')
            },
            error: {
                title: this.t('toast.save_as_excel.error.title')
            }
        });
    }
    /**
     * Save to Excel format
     * @protected
     * @param {string} url_prefix
     * @param {string} [file_name='sankey']
     * @memberof ClassTemplate_ApplicationData
     */
    _saveToExcel(url_prefix, file_name = 'sankey') {
        JSONtoExcel(this._toJSON(), url_prefix, file_name);
    }
    /**
     * Create json file that contains all application datas
     * @memberof ClassTemplate_ApplicationData
     */
    _toJSON() {
        var _a, _b, _c, _d;
        // Create json struct
        const json_object = {};
        // App language
        if (this._language !== undefined)
            json_object['language'] = this._language;
        // Node label separator attribute
        json_object['node_label_separator'] = this._node_label_separator;
        json_object['node_label_separator_part'] = this._node_label_separator_part;
        // Dump with drawing area & its content in json struct
        return Object.assign(Object.assign({}, json_object), this.drawing_area.toJSON((_b = (_a = this.options_save_json) === null || _a === void 0 ? void 0 : _a.mode_visible_element) !== null && _b !== void 0 ? _b : default_save_only_visible_elements, (_d = (_c = this.options_save_json) === null || _c === void 0 ? void 0 : _c.mode_save) !== null && _d !== void 0 ? _d : default_save_with_values));
    }
    /**
     * Reset value of drawing_area and substructur with data from JSON
     * then assign newly created drawing_area as ClassTemplate_ApplicationData currentdrawing_area attribute
     *
     * /!\ Add to waiting spinner queue
     *
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_ApplicationData
     */
    fromJSON(json_object, draw = true) {
        this.sendWaitingToast(() => {
            // Always bypass redrawings
            this._drawing_area.bypass_redraws = true;
            // Reset everything
            this._reset();
            // Read json file
            this._fromJSON(json_object);
            // Post processing & menu updating
            this._afterFromJSON();
            // Then draw if asked
            if (draw) {
                this._drawing_area.draw();
                this._drawing_area.legend.posIfFromLegacy(); // Function do something only if JSON was from legacy
            }
        });
    }
    /**
     * Overridable method to read JSON
     * @protected
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_ApplicationData
     */
    _fromJSON(json_object) {
        // Update drawing area
        this._drawing_area.fromJSON(json_object);
    }
    /**
     * Postprocessing drawing area after JSON affectation
     * @protected
     * @memberof ClassTemplate_ApplicationData
     */
    _afterFromJSON() {
        this._drawing_area.setToModeEdition(false); // Default mode after reading json is Selection
        this._drawing_area.arrangeTrade(false);
        if (this._language !== undefined && i18next.language !== this.language)
            i18next.changeLanguage(this.language);
        this.menu_configuration.updateAllMenuComponents();
    }
    /**
     * Update current drawing area data from a json_object
     *
     * /!\ Add to waiting spinner queue
     *
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_ApplicationData
     */
    updateFromJSON(json_object) {
        this.sendWaitingToast(() => {
            // Processing
            this._updateFromJSON(json_object);
        });
    }
    /**
     * Update current drawing area data from a json_object
     * @param {Type_JSON} json_object
     * @memberof ClassTemplate_ApplicationData
     */
    _updateFromJSON(json_object) {
        if (json_object['layout'] !== undefined) {
            const json_layout = json_object['layout'];
            const drawing_area_from_layout = this.createNewDrawingArea();
            drawing_area_from_layout.bypass_redraws = true;
            drawing_area_from_layout.fromJSON(json_layout);
            this.drawing_area.updateFrom(drawing_area_from_layout, ['attrDrawingArea', 'posNode', 'posFlux', 'attrNode', 'attrFlux', 'attrGeneral', 'freeLabels', 'Views', 'tagNode', 'tagFlux', /*'tagLevel',*/ 'icon_catalog']);
        }
    }
    // PUBLIC METHODS =====================================================================
    draw() {
        this.sendWaitingToast(() => {
            this._drawing_area.draw();
            this._drawing_area.legend.posIfFromLegacy(); // Function do something only if JSON was from legacy
        }, {
            success: {
                title: this.t('toast.draw.success.title'),
                desc: this.t('toast.draw.success.desc')
            },
            loading: {
                title: this.t('toast.draw.loading.title'),
                desc: this.t('toast.draw.loading.desc')
            }
        });
    }
    /**
     * Compute the position of everything that define the sankey diagram
     *
     * /!\ Add to waiting spinner queue
     *
     * @memberof ClassTemplate_DrawingArea
     */
    computeAutoFullSankey() {
        this.sendWaitingToast(() => {
            this.drawing_area.computeAutoSankey(true);
        }, {
            success: {
                title: this.t('toast.compute_auto_sankey.success.title')
            },
            loading: {
                title: this.t('toast.compute_auto_sankey.loading.title')
            }
        });
    }
    /**
     * Create a waiting toast and add function to waiting queue.
     * @param {() => void} funct
     * @param {Type_TextForToastPromise} [intake] Info text for loading, success or error
     * @memberof ClassTemplate_ApplicationData
     */
    sendWaitingToast(funct, intake) {
        // Create and save process id
        const funct_id = randomId();
        this._toast_processes.push(funct_id);
        // Add to the processing queue
        if (this._toast_bypass)
            funct();
        else
            this._sendWaitingToast(funct, funct_id, intake);
    }
    pre_process_export_svg() {
        var _a, _b;
        const d3_select = this._pre_process_export_svg();
        const scale_da = this.drawing_area.getZoomScale();
        const legend_w = !this.drawing_area.legend.masked ? this.drawing_area.legend.width : 0;
        const svg_with_header = '<svg version="1.1" ' +
            ' height=\'' + (this.drawing_area.height * scale_da + 5).toString() + '\'' +
            ' width=\'' + ((this.drawing_area.width * scale_da) + legend_w + 5).toString() + '\'' +
            ' xmlns="http://www.w3.org/2000/svg">' +
            ((_b = (_a = d3_select === null || d3_select === void 0 ? void 0 : d3_select.node()) === null || _a === void 0 ? void 0 : _a.innerHTML) !== null && _b !== void 0 ? _b : '') +
            '</svg>';
        d3_select === null || d3_select === void 0 ? void 0 : d3_select.remove();
        return svg_with_header;
    }
    setSteps() {
        this._steps.splice(0, this._steps.length); // Reset list
        const steps = [
            {
                selector: '#g_drawing',
                content: this.t('guide.drawing_area'),
            },
            {
                selector: '.sideToolBar',
                content: this.t('guide.toolbar'),
                actionAfter: () => {
                    var _a;
                    (_a = this.menu_configuration.ref_to_btn_toogle_menu.current) === null || _a === void 0 ? void 0 : _a.click();
                    setTimeout(() => { }, 500);
                }
            },
            {
                selector: '.drawer_menu_config ',
                content: this.t('guide.menu_config'),
                actionAfter: () => { var _a; return (_a = this.menu_configuration.ref_to_btn_toogle_menu.current) === null || _a === void 0 ? void 0 : _a.click(); }
            },
            {
                selector: '.menutop_button_save_in_cache',
                content: this.t('guide.save_in_cache'),
            },
            {
                selector: '.TopMenuNav',
                content: this.t('guide.nav_menu'),
            },
            {
                selector: '.settings_button',
                content: this.t('guide.settings_button'),
                action: () => { var _a; return (_a = this.menu_configuration.refs_to_btn_toogle_top_menus['file'].current) === null || _a === void 0 ? void 0 : _a.click(); },
            },
            {
                selector: '.tutorials_button',
                content: this.t('guide.tutorials_button'),
                action: () => { var _a; return (_a = this.menu_configuration.refs_to_btn_toogle_top_menus['aide'].current) === null || _a === void 0 ? void 0 : _a.click(); },
            },
        ];
        steps.forEach(step => this._steps.push(step));
    }
    /**
     * Generatric function used to save undo/redo of some basic attribute mutation
     * (exemple : the color of the DA background),
     * it generate types of key, value and func according to model passed has parameter
     *
     * @template TModel
     * @template TKey
     * @param {TModel} model
     * @param {TKey} key
     * @param {TModel[TKey]} value
     * @param {(_:TModel[TKey])=>void} func
     * @memberof ClassTemplate_ApplicationData
     */
    setValueAndSaveHistory(model, key, value, func) {
        const old_val = model[key];
        this._history.saveUndo(() => { func(old_val); });
        this._history.saveRedo(() => { func(value); });
        func(value);
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Function to create custom application behavior when we press a key,
     *
     * Note : even if this is a class method we have to ref the curr class in parametter because 'this' take another scope when it is called in onkeydown
     *
     * @protected
     * @param {ClassTemplate_ApplicationData} app_ref
     * @return {*}
     * @memberof ClassTemplate_ApplicationData
     */
    _keyboardEventListener(app_ref) {
        return (evt) => { this._keyboardEventProcessing(evt, app_ref); };
    }
    /**
     * Process all keyboard events on application
     * @param evt
     * @param app_ref
     */
    _keyboardEventProcessing(evt, app_ref) {
        var _a, _b;
        // Events booleans ----------------------------------------------------------------
        const evtOnDrawingArea = this._isDrawingAreaActive(); // Avoid using hotkeys in text-inputs
        const evtCtrl = (evt.ctrlKey || evt.metaKey) && (!evt.shiftKey) && (!evt.altKey);
        const evtCtrlShift = (evt.ctrlKey || evt.metaKey) && (evt.shiftKey) && (!evt.altKey);
        const evtCtrlAlt = (evt.ctrlKey || evt.metaKey) && (!evt.shiftKey) && (evt.altKey);
        const evtKeyTab = (evt.key === 'Tab') && evtOnDrawingArea;
        const evtKeyDel = (evt.key === 'Delete') && evtOnDrawingArea;
        const evtKeyEsc = (evt.key === 'Escape'); // Allow escape event even when focused on input so we can close menus
        const evtKeyEnter = (evt.key === 'Enter');
        const evtKeyA = ((evt.key === 'a') || (evt.key === 'A')) && evtOnDrawingArea;
        const evtKeyS = ((evt.key === 's') || (evt.key === 'S')) && evtOnDrawingArea;
        const evtKeyF = ((evt.key === 'f') || (evt.key === 'F')) && evtOnDrawingArea;
        const evtCtrlA = evtCtrl && evtKeyA;
        const evtCtrlS = evtCtrl && evtKeyS;
        const evtCtrlShiftS = evtCtrlShift && evtKeyS;
        const evtCtrlAltS = evtCtrlAlt && evtKeyS;
        const evtCtrlF = evtCtrl && evtKeyF;
        // Event to move all selected nodes with keyboard arrows --------------------------
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(evt.key) &&
            evtOnDrawingArea // Avoid using this hotkey in text-inputs
        ) {
            // Deplace les noeuds sélectionné avec les flèches du clavier
            if (evt.key == 'ArrowUp') {
                app_ref.drawing_area.selected_nodes_list.forEach(node => {
                    node.position_y -= app_ref.drawing_area.grid_size;
                    node.draw();
                });
            }
            else if (evt.key == 'ArrowDown') {
                app_ref.drawing_area.selected_nodes_list.forEach(node => {
                    node.position_y += app_ref.drawing_area.grid_size;
                    node.draw();
                });
            }
            else if (evt.key == 'ArrowLeft') {
                app_ref.drawing_area.selected_nodes_list.forEach(node => {
                    node.position_x -= app_ref.drawing_area.grid_size;
                    node.draw();
                });
            }
            else if (evt.key == 'ArrowRight') {
                app_ref.drawing_area.selected_nodes_list.forEach(node => {
                    node.position_x += app_ref.drawing_area.grid_size;
                    node.draw();
                });
            }
            // Update drawing area size so none of elements are outside the DA
            this.drawing_area.checkAndUpdateAreaSize();
        }
        // Open config menu ---------------------------------------------------------------
        else if (evtKeyTab) {
            (_a = app_ref.menu_configuration.ref_to_btn_toogle_menu.current) === null || _a === void 0 ? void 0 : _a.click();
        }
        // Event to restore application display as neutral --------------------------------
        else if (evtKeyEsc) {
            // Set app in selection mode
            if (app_ref.drawing_area.isInEditionMode())
                app_ref.drawing_area.switchMode();
            // Deselect all element
            app_ref.drawing_area.purgeSelection();
            // Close all menus
            app_ref.menu_configuration.closeAllMenus();
            app_ref.drawing_area.closeAllContextMenus();
        }
        // Event to delete all selected elements ------------------------------------------
        else if (evtKeyDel) {
            // Delete selected elements
            app_ref.drawing_area.deleteSelection();
        }
        // Event to blur the input we are currently focused on ----------------------------
        // (It's in adequation with event on input that update drawing area when we blur input)
        // TODO surement à supprimer lorsque les inputs se feront avec menuConfigurationTextInput && menuConfigurationNumberInput
        else if ((evtKeyEnter) &&
            (((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.tagName) == 'INPUT') &&
            (['form-control', 'chakra-numberinput__field', 'chakra-input', 'name_label_input'].some(r => { var _a; return (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.className.includes(r); }))) {
            document.activeElement.blur();
        }
        // Event to select all visible elements -------------------------------------------
        else if (evtCtrlA) {
            // Prevent default event on ctrl + a
            evt.preventDefault();
            // Select all node & links
            app_ref.drawing_area.addAllVisibleNodesToSelection();
            app_ref.drawing_area.addAllVisibleLinksToSelection();
            app_ref.drawing_area.addLegendToSelection();
        }
        // Event to save current diagram in cache -----------------------------------------
        else if (evtCtrlS) {
            // Prevent default event on ctrl + s
            evt.preventDefault();
            // Save in cache
            app_ref.saveInCache();
        }
        // event to download current sankey in JSON --------------------------------------
        else if (evtCtrlShiftS) {
            // Prevent default event on ctrl + shift + s
            evt.preventDefault();
            // Trigger saving via JSON saving button
            app_ref.options_save_json = default_save_JSON_options;
            app_ref.saveToJSON();
        }
        // event to download current sankey in Excel -------------------------------------
        else if (evtCtrlAltS) {
            // Prevent default event on ctrl + shift + s
            evt.preventDefault();
            // Trigger saving via Excel saving button
            this.saveToExcel('/opensankey/');
        }
        // Fullscreen --------------------------------------------------------------------
        else if (evtCtrlF) {
            // Prevent default event
            evt.preventDefault();
            // Toggle fullscreen
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            }
            else if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }
    /**
     * Check if focus is on drawing area or not.
     * Avoid colisions between text inputs in menu & keyboard events on drawing area
     * @returns
     */
    _isDrawingAreaActive() {
        const inputs = ['input', 'textarea'];
        if (document.activeElement &&
            inputs.indexOf(document.activeElement.tagName.toLowerCase()) !== -1) {
            return false;
        }
        return true;
    }
    /**
     * Allows to create a waiting toast for given function.
     * Use a functions queue to ensure that all function that call always run in the calling order.
     *
     * @protected
     * @param {() => void} funct
     * @param {string} funct_id
     * @param {Type_TextForToastPromise} [intake]
     * @memberof ClassTemplate_ApplicationData
     */
    _sendWaitingToast(funct, funct_id, intake) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        // Check if process has to wait
        if (this._toast_processes[0] !== funct_id) {
            // Create a recursive timeout as delaying method to ensure that
            // all functions are called with respect to their creation order
            setTimeout(() => this._sendWaitingToast(funct, funct_id, intake), default_toast_waiting_delay);
        }
        // Otherwise send
        else {
            this._toast.promise(new Promise((resolve) => {
                setTimeout(() => {
                    funct(); // run
                    this._toast_processes.splice(0, 1); // pop process from processes list
                    resolve(200); // end
                }, 500); // Leave 500ms of delay in order to give enough time to load spinner component
            }), {
                success: {
                    title: (_b = (_a = intake === null || intake === void 0 ? void 0 : intake.success) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : this.t('toast.default.success.title'),
                    description: (_d = (_c = intake === null || intake === void 0 ? void 0 : intake.success) === null || _c === void 0 ? void 0 : _c.desc) !== null && _d !== void 0 ? _d : this.t('toast.default.success.desc'),
                    duration: default_toast_duration
                },
                loading: {
                    title: (_f = (_e = intake === null || intake === void 0 ? void 0 : intake.loading) === null || _e === void 0 ? void 0 : _e.title) !== null && _f !== void 0 ? _f : this.t('toast.default.loading.title'),
                    description: (_h = (_g = intake === null || intake === void 0 ? void 0 : intake.loading) === null || _g === void 0 ? void 0 : _g.desc) !== null && _h !== void 0 ? _h : this.t('toast.default.loading.desc'),
                    duration: default_toast_duration
                },
                error: {
                    title: (_k = (_j = intake === null || intake === void 0 ? void 0 : intake.error) === null || _j === void 0 ? void 0 : _j.title) !== null && _k !== void 0 ? _k : this.t('toast.default.error.title'),
                    description: (_m = (_l = intake === null || intake === void 0 ? void 0 : intake.error) === null || _l === void 0 ? void 0 : _l.desc) !== null && _m !== void 0 ? _m : this.t('toast.default.error.desc'),
                    duration: default_toast_duration
                },
            });
        }
    }
    /**
     * Some pre-process to correct html we will send to converter
     * because there is some difference between what our code produce
     * & what the converter wait to correctly produce an image
     *
     * @protected
     * @return {*}
     * @memberof Class_ApplicationData
     */
    _pre_process_export_svg() {
        this.drawing_area.purgeSelection();
        this.drawing_area.areaAutoFit();
        const svg = this.drawing_area.d3_selection_zoom_area;
        const svg_clone = svg === null || svg === void 0 ? void 0 : svg.clone(true); // clone so next instructions don't change displayed svg
        const scale_da = this.drawing_area.getZoomScale();
        // Legend width (if present)
        const legend_w = !this.drawing_area.legend.masked ? this.drawing_area.legend.width : 0;
        svg_clone === null || svg_clone === void 0 ? void 0 : svg_clone.select('#g_drawing').attr('transform', 'translate(' + legend_w + ',0' + ') scale(' + scale_da + ')');
        svg_clone === null || svg_clone === void 0 ? void 0 : svg_clone.select('#grp_legend .gg_legend').attr('transform', 'translate(0,0)');
        svg_clone === null || svg_clone === void 0 ? void 0 : svg_clone.selectAll('input').remove();
        // For some reason when attr 'dominant-baseline' is 'text-after-edge',
        // at the export to image the text is shifted to the bottom by half the font size of the text.
        // So before the convertion to image modify the svg clone to correct the error
        svg_clone === null || svg_clone === void 0 ? void 0 : svg_clone.selectAll('.name_label_text').nodes().forEach(el => {
            if (d3.select(el).attr('dominant-baseline') == 'text-after-edge') {
                const fontSize = +d3.select(el).attr('font-size').replace('px', '');
                const yPos = +d3.select(el).attr('y').replace('px', '');
                d3.select(el).attr('y', yPos - (fontSize / 2));
            }
        });
        return svg_clone;
    }
    // GETTERS / SETTERS ==================================================================
    get t() { return this._t; }
    get is_static() { return this._drawing_area.static; }
    get history() { return this._history; }
    get steps() { return this._steps; }
    get show_documentation() { return this._show_documentation; }
    set show_documentation(_) { this._show_documentation = _; }
    get drawing_area() { return this._drawing_area; }
    set drawing_area(value) { this._drawing_area = value; } // Only extended ClassTemplate_ApplicationData instance can modify these parameter (for sub-module)
    get menu_configuration() { return this._menu_configuration; }
    set menu_configuration(value) { this._menu_configuration = value; } // Only extended ClassTemplate_ApplicationData instance can modify these parameter (for sub-module)
    get url_prefix() { return this._url_prefix; }
    get logo() { return this._logo_opensankey; }
    get logo_opensankey() { return this._logo_opensankey; }
    get logo_terriflux() { return this._logo_terriflux; }
    get logo_width() { return this._logo_width; }
    set logo_width(value) { this._logo_width = value; }
    get app_name() { return this._app_name; }
    set app_name(value) { this._app_name = value; }
    get node_label_separator() { return this._node_label_separator; }
    set node_label_separator(_) { this._node_label_separator = _; }
    get node_label_separator_part() { return this._node_label_separator_part; }
    set node_label_separator_part(_) { this._node_label_separator_part = _; }
    get processFunction() { return this._processFunction; }
    get transform_layout_all_attr() { return this._transform_layout_all_attr; }
    get checkbox_refs() { return this._checkbox_refs; }
    get preference_menu_all_item() { return this._preference_menu_all_item; }
    get language() { return this._language; }
    set language(value) { this._language = value; }
    get i18n() { return this._i18n; }
}
