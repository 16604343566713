import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { FaMinus, FaPlus, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Box, Button, InputGroup, InputLeftAddon, Select, Tab, TabList, TabPanels, Tabs, } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { OSTooltip } from '../../types/Utils';
import { SankeyWrapperConfigInModalOrMenu } from './SankeyMenuConfigurationNodesAttributes';
/*************************************************************************************************/
const SankeyMenuConfigurationLinks = ({ new_data, menu_config_link_data, menu_config_link_attr, additionalMenus }) => {
    // Data -------------------------------------------------------------------------------
    // Traduction
    const { t } = new_data;
    // Links to display in selection menus ------------------------------------------------
    let links;
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        links = new_data.drawing_area.sankey.links_list;
        selected_links = new_data.drawing_area.selected_links_list;
    }
    else {
        // Only visible links
        links = new_data.drawing_area.sankey.visible_links_list;
        selected_links = new_data.drawing_area.visible_and_selected_links_list;
    }
    const entries_for_links = links.map((d) => { return { 'label': d.name, 'value': d.id }; });
    const entries_for_selected_links = selected_links.map((d) => { return { 'label': d.name, 'value': d.id }; });
    // Nodes to display in selection menus ------------------------------------------------
    const nodes = new_data.drawing_area.sankey.nodes_list;
    const addDropSource = () => {
        if (nodes.length >= 2) {
            return (React.createElement(React.Fragment, null,
                React.createElement("option", { hidden: true, key: 'no_target', value: '' }, " "),
                nodes.map((n, i) => React.createElement("option", { key: i, value: n.id }, n.name))));
        }
    };
    const addDropTarget = () => {
        if (nodes.length >= 2) {
            return (React.createElement(React.Fragment, null,
                React.createElement("option", { hidden: true, key: 'no_cible', value: '' }, " "),
                nodes.map((n, i) => React.createElement("option", { key: i, value: n.id }, n.name))));
        }
    };
    // Components updaters ----------------------------------------------------------------
    // Boolean used to force this component to reload
    const [, setCount] = useState(0);
    // Link this menu's update function
    new_data.menu_configuration.ref_to_menu_config_links_selection_updater.current = () => setCount(a => a + 1);
    // Function used to reset menu UI -----------------------------------------------------
    const refreshThisAndToggleSaving = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Refresh this menu
        setCount(a => a + 1);
    };
    const refreshThisAndUpdateRelatedComponents = () => {
        // Update values displayed in menus for link's configuration
        new_data.menu_configuration.updateAllComponentsRelatedToLinksConfig();
        // Update and update saving indicator
        refreshThisAndToggleSaving();
    };
    // Sub-menus --------------------------------------------------------------------------
    const ui = Object.assign({ 'Flux.data.données': React.createElement(SankeyWrapperConfigInModalOrMenu, { menu_to_wrap: menu_config_link_data, for_modal: false, idTab: 'link_data_tab_id' }), 'Flux.apparence.apparence': React.createElement(SankeyWrapperConfigInModalOrMenu, { menu_to_wrap: menu_config_link_attr, for_modal: false, idTab: 'link_attr_tab_id' }) }, additionalMenus.additional_menu_configuration_links);
    /**
     * Create new link
     *
     */
    const addLinkConfig = () => {
        const _addLinkConfig = () => {
            const new_link = new_data.drawing_area.addNewDefaultLinkToSankey();
            //Deselect previously selected links
            new_data.drawing_area.purgeSelectionOfLinks();
            // Add link to selection
            new_data.drawing_area.addLinkToSelection(new_link);
            // Toogle saving indicator
            refreshThisAndUpdateRelatedComponents();
        };
        const inv_addLinkConfig = () => {
            new_data.drawing_area.deleteLink(new_data.drawing_area.sankey.links_list[new_data.drawing_area.sankey.links_list.length - 1]);
            // Toogle saving indicator
            refreshThisAndUpdateRelatedComponents();
        };
        new_data.history.saveUndo(inv_addLinkConfig);
        new_data.history.saveRedo(_addLinkConfig);
        _addLinkConfig();
    };
    // Selection menu for links -----------------------------------------------------------
    // Renvoie le menue déroulant pour la sélection des flux
    const dropdownMultiLinks = () => {
        const DD = (React.createElement(Box, { layerStyle: 'submenuconfig_droplist' },
            React.createElement(Box, { height: '2rem', width: '14.75rem' },
                React.createElement(MultiSelect, { valueRenderer: (entries) => {
                        return entries.filter(d => d !== undefined).length ? entries.map(({ label }) => label + ', ') : 'Aucun flux sélectionné';
                    }, labelledBy: 'TODO Change', options: entries_for_links, value: entries_for_selected_links, overrideStrings: {
                        'selectAll': 'Tout sélectionner',
                    }, onChange: (entries) => {
                        // Update selection of links
                        const entries_values = entries.map(d => d.value);
                        links.forEach(link => {
                            if (entries_values.includes(link.id)) {
                                new_data.drawing_area.addLinkToSelection(link);
                            }
                            else {
                                new_data.drawing_area.removeLinkFromSelection(link);
                            }
                        });
                        // Update all link menus
                        refreshThisAndUpdateRelatedComponents();
                    } }))));
        return DD;
    };
    // Links upper menu -------------------------------------------------------------------
    return (React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_droplist' },
            React.createElement(OSTooltip, { label: t('Menu.tooltips.flux.plus') },
                React.createElement(Button, { variant: 'menuconfigpanel_add_button', onClick: addLinkConfig },
                    React.createElement(FaPlus, null))),
            React.createElement(OSTooltip, { label: t('Menu.tooltips.flux.slct') }, dropdownMultiLinks()),
            React.createElement(OSTooltip, { label: t('Menu.tooltips.flux.rm') },
                React.createElement(Button, { variant: 'menuconfigpanel_del_button', onClick: () => {
                        // Delete all selected links
                        new_data.drawing_area.deleteSelectedLinks();
                        // Toogle saving indicator
                        refreshThisAndUpdateRelatedComponents();
                    } },
                    React.createElement(FaMinus, null))),
            React.createElement(OSTooltip, { label: t('Menu.tooltips.noeud.dns') },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        // Update UI with only visible links / all links
                        new_data.menu_configuration.toggle_selector_on_visible_links();
                    } }, new_data.menu_configuration.is_selector_only_for_visible_links ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null)))),
        React.createElement(Box, { display: 'grid', gridTemplateColumns: '9fr 1fr', gridTemplateRows: '1fr 1fr', gridColumnGap: '0.25rem', gridRowGap: '0.25rem', height: '4.25rem' },
            React.createElement(Box, { display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr 1fr', gridRowGap: '0.25rem' },
                React.createElement(OSTooltip, { label: t('Flux.tooltips.src') },
                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                        React.createElement(InputLeftAddon, { width: '5rem' }, t('Flux.src')),
                        React.createElement(Select, { variant: 'select_custom_style', disabled: selected_links.length == 0, onChange: (event) => {
                                const new_source = new_data.drawing_area.sankey.getNode(event.target.value);
                                if (new_source !== null) {
                                    // Update link's source
                                    selected_links.forEach(link => link.source = new_source);
                                    // Toogle saving indicator
                                    refreshThisAndToggleSaving();
                                }
                            }, value: (selected_links.length > 0) ? selected_links[0].source.id : '' }, addDropSource()))),
                React.createElement(OSTooltip, { label: t('Flux.tooltips.trgt') },
                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                        React.createElement(InputLeftAddon, { width: '5rem' }, t('Flux.trgt')),
                        React.createElement(Select, { variant: 'select_custom_style', disabled: selected_links.length == 0, onChange: (event) => {
                                const new_target = new_data.drawing_area.sankey.getNode(event.target.value);
                                if (new_target !== null) {
                                    // Update link's target
                                    selected_links.forEach(link => link.target = new_target);
                                    // Toogle saving indicator
                                    refreshThisAndToggleSaving();
                                }
                            }, value: (selected_links.length > 0) ? selected_links[0].target.id : '' }, addDropTarget())))),
            React.createElement(Box, null,
                React.createElement(OSTooltip, { label: t('Flux.tooltips.inv') },
                    React.createElement(Button, { height: '100%', onClick: new_data.drawing_area.inverseSelectedLinks },
                        React.createElement(FontAwesomeIcon, { style: { transform: 'rotate(90deg)' }, icon: faRotate }))))),
        (selected_links.length !== 0) ?
            React.createElement(Tabs, { isLazy: true },
                React.createElement(TabList, null, Object
                    .keys(ui)
                    .map((key, i) => {
                    return React.createElement(Tab, { key: 'submenuconfig_tab' + i },
                        React.createElement(Box, { layerStyle: 'submenuconfig_tab' }, t(key)));
                })),
                React.createElement(TabPanels, null, Object
                    .values(ui)
                    .map((c, i) => React.createElement(React.Fragment, { key: 'panel_' + i }, c)))) :
            React.createElement(React.Fragment, null)));
};
export default SankeyMenuConfigurationLinks;
