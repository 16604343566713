// Standard lib
import React from 'react';
import { Button, Box, Modal, ModalBody, ModalContent, ModalOverlay, Center, Checkbox, ModalFooter } from '@chakra-ui/react';
import { OSTooltip } from '../../types/Utils';
/**
 * Modal containing documentation
 *
 * @param {*} { new_data, additionalMenu, Reinitialization }
 * @return {*}
 */
export const ModalDocumentation = ({ show_documentation, set_show_documentation, app_data }) => {
    const { never_see_again } = app_data.menu_configuration;
    return React.createElement(Modal, { isOpen: show_documentation && never_see_again.current == false, onClose: () => null, scrollBehavior: 'inside', variant: 'modal_documentation' },
        React.createElement(ModalOverlay, null),
        React.createElement(ModalContent, null,
            React.createElement(ModalBody, null,
                React.createElement(Center, null,
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                        React.createElement(Box, { layerStyle: 'options_2cols', height: 'fit-content', gridColumnGap: '0.5rem' },
                            React.createElement(OSTooltip, { placement: 'left-end', label: app_data.t('tooltip.start'), isAlwaysOpen: show_documentation },
                                React.createElement(Button, { variant: 'btn_documentation', onClick: () => {
                                        set_show_documentation(false);
                                        app_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_templates_lib.current(true);
                                    } }, app_data.t('start'))),
                            React.createElement(OSTooltip, { placement: 'right-end', label: app_data.t('tooltip.diaporama'), isAlwaysOpen: show_documentation },
                                React.createElement(Button, { variant: 'btn_documentation', onClick: () => {
                                        app_data.menu_configuration.dict_setter_show_dialog.ref_setter_show_modal_welcome.current(true);
                                        set_show_documentation(false);
                                    } }, app_data.t('diaporama'))))))),
            React.createElement(ModalFooter, null,
                React.createElement(Checkbox, { variant: 'checkbox_dont_show_again', isChecked: never_see_again.current, onChange: evt => {
                        never_see_again.current = evt.target.checked;
                        localStorage.setItem('dontSeeAggainWelcome', '1');
                        set_show_documentation(false);
                    } },
                    React.createElement(OSTooltip, { placement: 'bottom', label: app_data.t('tooltip.dontSeeAgain') }, app_data.t('dontSeeAgain'))))));
};
