// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 03/09/2024
// All rights reserved for TerriFlux SARL
//
// This file is used to avoid cycling dependancies inside each Class definition files.
// ==================================================================================================
import { ClassTemplate_Element } from '../Elements/Element';
export class ClassAbstract_NodeElement extends ClassTemplate_Element {
}
export class ClassAbstract_NodeDimension {
}
export class ClassAbstract_NodeStyle {
}
