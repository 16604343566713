// External imports
import React, { useRef, useState } from 'react';
import { Box, Button, Checkbox, Select } from '@chakra-ui/react';
import i18next from 'i18next';
// Internal components / functions
import { OSTooltip } from '../../types/Utils';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
import { ConfigMenuTextInput } from '../configmenus/SankeyMenuConfiguration';
// COMPONENTS ===========================================================================
export const ModalPreference = ({ new_data, additionalMenus }) => {
    // Data -------------------------------------------------------------------------------
    const { t, preference_menu_all_item, checkbox_refs } = new_data;
    // Component updater ------------------------------------------------------------------
    const [, setUpdate] = useState(0);
    //const menus = ['MEP', 'EN', 'EF', 'ED', 'EL', 'LL', 'Vis']
    //const checkbox_refs: { [_: string]: RefObject<HTMLInputElement> } = {}
    const menus = preference_menu_all_item;
    menus.forEach(menu => checkbox_refs[menu] = useRef(null));
    const update_checkboxes = (menu_to_show) => {
        menus.forEach(menu => {
            var _a, _b, _c;
            const checkbox_ref = (_b = (_a = checkbox_refs[menu]) === null || _a === void 0 ? void 0 : _a.current) !== null && _b !== void 0 ? _b : undefined;
            const checkbox_checked = (_c = checkbox_ref === null || checkbox_ref === void 0 ? void 0 : checkbox_ref.checked) !== null && _c !== void 0 ? _c : undefined;
            if ((checkbox_checked !== undefined) &&
                (checkbox_checked !== menu_to_show.includes(menu))) {
                checkbox_ref === null || checkbox_ref === void 0 ? void 0 : checkbox_ref.click();
            }
        });
    };
    new_data.menu_configuration.ref_to_modal_pref_updater.current = () => {
        setUpdate(a => a + 1);
    };
    const ref_set_text_value_input = useRef((_) => null);
    // Update input data value
    ref_set_text_value_input.current(new_data.node_label_separator);
    // JSX Component ----------------------------------------------------------------------
    const node_label_sep = React.createElement(OSTooltip, { label: t('Menu.tooltips.node_label_sep') },
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.node_label_sep')),
            React.createElement(ConfigMenuTextInput, { ref_to_set_value: ref_set_text_value_input, function_get_value: () => { return new_data.node_label_separator; }, function_on_blur: (_) => {
                    const tmp = _ ? _ : '';
                    new_data.node_label_separator = tmp;
                    new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw());
                } })));
    const node_label_sep_pos = React.createElement(OSTooltip, { label: t('Menu.tooltips.node_label_sep_pos') },
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.node_label_sep_pos')),
            React.createElement(Box, { layerStyle: 'options_2cols' },
                React.createElement(Button, { variant: new_data.node_label_separator_part == 'before' ? 'menuconfigpanel_option_button_activated_left' : 'menuconfigpanel_option_button_left', onClick: () => {
                        new_data.node_label_separator_part = 'before';
                        new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw());
                        setUpdate(a => a + 1);
                    } }, t('Menu.before')),
                React.createElement(Button, { variant: new_data.node_label_separator_part == 'after' ? 'menuconfigpanel_option_button_activated_right' : 'menuconfigpanel_option_button_right', onClick: () => {
                        new_data.node_label_separator_part = 'after';
                        new_data.drawing_area.sankey.visible_nodes_list.forEach(node => node.draw());
                        setUpdate(a => a + 1);
                    } }, t('Menu.after')))));
    const ui = {
        'lang': React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.lang')),
            React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: i18next.language, onChange: evt => {
                    new_data.language = evt.target.value;
                    new_data.saveInCache(); // Save data in cache because change language re-render the app from index
                    new_data.i18n.changeLanguage(evt.target.value);
                    new_data.menu_configuration.updateAllMenuComponents();
                    new_data.draw();
                } },
                React.createElement("option", { key: 'francais', value: 'fr' }, "Fran\u00E7ais"),
                React.createElement("option", { key: 'english', value: 'en' }, "English"))),
        'form': [
            React.createElement(Box, { key: 1 }, t('Menu.pref_title_sub_menu')),
            React.createElement(Box, { key: 2, layerStyle: 'menuconfigpanel_grid' },
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', onClick: () => {
                            sessionStorage.removeItem('modepref');
                            update_checkboxes([]);
                        } }, "Mode Simple"),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', onClick: () => {
                            sessionStorage.setItem('modepref', 'expert');
                            update_checkboxes(preference_menu_all_item);
                        } }, "Mode Expert"))),
            React.createElement(Checkbox, { isDisabled: true, variant: 'menuconfigpanel_option_checkbox', isChecked: true }, t('Menu.MEP')),
            React.createElement(Checkbox, { key: 4, variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.Noeuds')),
            React.createElement(Checkbox, { key: 6, variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.flux')),
            additionalMenus.additional_preferences
        ],
        'node_label_sep': React.createElement(React.Fragment, null,
            node_label_sep,
            node_label_sep_pos),
    };
    const content = React.createElement(React.Fragment, null, Object.values(ui).map((d, i) => {
        return React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, { key: i }, d),
            React.createElement("hr", { style: {
                    borderStyle: 'none',
                    margin: '10px',
                    color: 'grey',
                    backgroundColor: 'grey',
                    height: 1
                } }));
    }));
    return React.createElement(MenuDraggable, { dict_hook_ref_setter_show_dialog_components: new_data.menu_configuration.dict_setter_show_dialog, dialog_name: 'ref_setter_show_modal_preference', content: content, title: t('Menu.title_pref') });
};
