// External imports
import React, { useState } from 'react';
import { Box, Checkbox, Select, TabPanel, } from '@chakra-ui/react';
import { OSTooltip } from '../../deps/OpenSankey/types/Utils';
// Component definition =================================================================
/**
 * Tab that handle tag association to nodes, a nodes can have tags from the same grouptag or from different group
 * To visaulize nodes according to their tag associated, the groupTags must be at least have it banner in mode one or mutliple
 * then in the nodes filter button, select the groupTag you want to apply and in the dropdown select the node/nodes you want to see
 *
 * @type {*}
 */
export const SankeyMenuConfigurationNodesTags = ({ new_data, menu_for_modal }) => {
    // Data ------------------------------------------------------------------------------
    const { t } = new_data;
    // Node tags groups ------------------------------------------------------------------
    const list_node_taggs = new_data.drawing_area.sankey.node_taggs_list;
    const has_node_taggs = list_node_taggs.length > 0;
    const [node_tagg_entry_index, setNodeTaggEntryIndex] = useState(0);
    const node_tagg_entry = list_node_taggs[node_tagg_entry_index];
    // Selected nodes ---------------------------------------------------------------------
    let selected_nodes;
    if (!new_data.menu_configuration.is_selector_only_for_visible_nodes) {
        // All availables nodes
        selected_nodes = new_data.drawing_area.selected_nodes_list_sorted;
    }
    else {
        // Only visible nodes
        selected_nodes = new_data.drawing_area.visible_and_selected_nodes_list_sorted;
    }
    // Menu updaters ----------------------------------------------------------------------
    const [, setCount] = useState(0);
    const updateThis = () => {
        // Can just use simple refresh if node_tagg entry exists
        if (new_data.drawing_area.sankey.node_taggs_list[node_tagg_entry_index])
            setCount(a => a + 1);
        // If not, reset entry index
        else
            setNodeTaggEntryIndex(0);
    };
    new_data.menu_configuration.ref_to_menu_config_nodes_tags_updater.current = updateThis;
    /**
     * Function used to reset menu UI
     */
    const refreshThisAndUpdateRelatedComponents = () => {
        // Whatever is done, set saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        setCount(a => a + 1);
    };
    // Utils functions --------------------------------------------------------------------
    /**
     * Check if all selected nodes are related to the given tag
     * @param {Class_Tag} tag
     * @return [allTrue: boolean, allFalse: boolean]
     */
    const haveAllSelectedNodesGivenTag = (tag) => {
        let allTrue = true;
        let allFalse = true;
        selected_nodes
            .forEach(node => {
            const test = node.hasGivenTag(tag);
            allTrue = allTrue && (test === true);
            allFalse = allFalse && (test === false);
        });
        return [allTrue, allFalse];
    };
    // JSX content ------------------------------------------------------------------------
    const content = React.createElement(React.Fragment, null,
        " ",
        (selected_nodes.length > 0) ?
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.EN')),
                React.createElement(Select, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_option_select', value: node_tagg_entry_index, onChange: (evt) => setNodeTaggEntryIndex(Number(evt.target.value)) }, list_node_taggs
                    .map((node_tagg, node_tagg_index) => React.createElement("option", { key: node_tagg.id, value: node_tagg_index }, node_tagg.name))),
                has_node_taggs ? React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, node_tagg_entry.tags_list
                    .map(node_tag => {
                    const [allTrue, allFalse] = haveAllSelectedNodesGivenTag(node_tag);
                    return React.createElement(Checkbox, { isDisabled: !new_data.has_sankey_plus, variant: 'menuconfigpanel_tag_checkbox', isIndeterminate: (selected_nodes.length > 1) &&
                            (!allTrue) &&
                            (!allFalse), isChecked: allTrue, onChange: (evt) => {
                            const visible = evt.target.checked;
                            selected_nodes.forEach(node => {
                                if (visible) {
                                    node.addTag(node_tag);
                                }
                                else {
                                    node.removeTag(node_tag);
                                }
                            });
                            // Full update
                            refreshThisAndUpdateRelatedComponents();
                        } }, node_tag.name);
                })) : React.createElement(React.Fragment, null))
            :
                React.createElement(React.Fragment, null),
        " ");
    return React.createElement(OSTooltip, { label: new_data.has_sankey_plus ? '' : t('Menu.sankeyOSPDisabled') }, menu_for_modal ?
        content :
        React.createElement(TabPanel, null, content));
};
