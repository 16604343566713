// External imports
import React, { useRef, useState } from 'react';
import { Box, Select, } from '@chakra-ui/react';
// Local components or functions
import { OSTooltip } from '../../types/Utils';
import { default_link_value_label_unit } from '../../Elements/LinkAttributes';
import { ConfigMenuNumberInput, ConfigMenuTextInput } from './SankeyMenuConfiguration';
/*************************************************************************************************/
export const MenuConfigurationLinksData = ({ new_data, contextual }) => {
    var _a, _b, _c, _d;
    // Traduction
    const { t } = new_data;
    // Selected links --------------------------------------------------------------------
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    // Data tags and values --------------------------------------------------------------
    const list_data_taggs = new_data.drawing_area.sankey.data_taggs_list;
    const value = (_a = selected_links[0]) === null || _a === void 0 ? void 0 : _a.value;
    // Components updaters ---------------------------------------------------------------
    // Refs used to trigger refreshing of number & text inputs
    const ref_set_data_value_input = useRef((_) => null);
    const ref_set_text_value_input = useRef((_) => null);
    const updateInputsValues = () => {
        var _a, _b, _c;
        // Recreate a updated_selected_links list in the function because it can be called before re-rendering <MenuConfigurationLinksData/>
        // so selected_links can have the list of previous selected links wich can lead to incorrect links value
        const updated_selected_links = !new_data.menu_configuration.is_selector_only_for_visible_links ?
            new_data.drawing_area.selected_links_list_sorted : new_data.drawing_area.visible_and_selected_links_list_sorted;
        const value_update = (_a = updated_selected_links[0]) === null || _a === void 0 ? void 0 : _a.value;
        // Update input data value
        ref_set_data_value_input.current(String((_b = value_update === null || value_update === void 0 ? void 0 : value_update.data_value) !== null && _b !== void 0 ? _b : ''));
        // Update input text value
        ref_set_text_value_input.current(String((_c = value_update === null || value_update === void 0 ? void 0 : value_update.text_value) !== null && _c !== void 0 ? _c : ''));
    };
    // Function used to force this component to reload
    const [, setCount] = useState(0);
    const refreshThis = () => {
        updateInputsValues();
        setCount(a => a + 1);
    };
    // Link this menu's update function
    const refreshThisAndUpdateRelatedComponents = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // And update this menu also
        new_data.menu_configuration.updateComponentRelatedToLinksData();
    };
    if (contextual) {
        new_data.menu_configuration.ref_to_menu_contextual_config_links_data_updater.current = refreshThis;
    }
    else {
        new_data.menu_configuration.ref_to_menu_config_links_data_updater.current = refreshThis;
    }
    /**
     * Method to mutate link value & save it's undoing in data history
     *
     * @param {(number | null | undefined)} _
     */
    const updateValueAndHistory = (_) => {
        // Save old values in dict so the undo reset value for previous value of each link
        const dict_old_val = {};
        selected_links.forEach(l => dict_old_val[l.id] = l.data_value);
        // Undo link value
        const inv_updateDataLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.data_value = dict_old_val[link.id];
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        // Mutate link value 
        const _updateDataLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.data_value = (_ !== null && _ !== void 0 ? _ : null);
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        // Save undo/redo in data history
        new_data.history.saveUndo(inv_updateDataLinks);
        new_data.history.saveRedo(_updateDataLinks);
        // Execute original attr mutation
        _updateDataLinks();
    };
    /**
     * Method to mutate link text & save it's undoing in data history
     *
     * @param {(number | null | undefined)} _
     */
    const updateTextLinks = (_) => {
        // Save old values in dict so the undo reset value for previous value of each link
        const dict_old_val = {};
        selected_links.forEach(l => dict_old_val[l.id] = l.text_value);
        // Undo link value
        const inv_updateTextLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.text_value = dict_old_val[link.id];
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        // Mutate link value 
        const _updateTextLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.text_value = (_ !== null && _ !== void 0 ? _ : '');
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        // Save undo/redo in data history
        new_data.history.saveUndo(inv_updateTextLinks);
        new_data.history.saveRedo(_updateTextLinks);
        // Execute original attr mutation
        _updateTextLinks();
    };
    // JSX -------------------------------------------------------------------------------
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        // Définition des valeurs selon les paramètre dataTags
        list_data_taggs.map(data_tagg => {
            if (data_tagg.has_tags) {
                // Only one dataTag / group can be selected
                let selected_data_tags = data_tagg.selected_tags_list;
                if (selected_data_tags.length === 0) {
                    data_tagg.tags_list[0].setSelected();
                    selected_data_tags = data_tagg.selected_tags_list;
                }
                else if (selected_data_tags.length > 1) {
                    const data_tags_to_unselect = selected_data_tags.splice(1);
                    data_tags_to_unselect.forEach(tag => tag.setUnSelected());
                }
                // Retrun selection box
                return (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, data_tagg.name),
                    React.createElement(Select, { name: data_tagg.id, variant: 'menuconfigpanel_option_select', value: selected_data_tags[0].id, onChange: (evt) => {
                            // Update selected attributes for tags
                            data_tagg.selectTagsFromId(evt.target.value);
                            // Update this menu
                            refreshThisAndUpdateRelatedComponents();
                        } }, data_tagg.tags_list.map(tag => {
                        return React.createElement("option", { key: tag.id, value: tag.id }, tag.name);
                    }))));
            }
        }),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.vpp') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.vpp')),
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_data_value_input, default_value: value === null || value === void 0 ? void 0 : value.data_value, fixed_dec: 0, function_on_blur: updateValueAndHistory, minimum_value: 0, stepper: true, step: 1, unit_text: (((_b = selected_links[0]) === null || _b === void 0 ? void 0 : _b.value_label_unit_visible) &&
                        ((_c = selected_links[0]) === null || _c === void 0 ? void 0 : _c.value_label_unit) !== default_link_value_label_unit) ?
                        (_d = selected_links[0]) === null || _d === void 0 ? void 0 : _d.value_label_unit :
                        undefined }))),
        React.createElement(OSTooltip, { label: t('Flux.data.tooltips.affichage') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Flux.data.affichage')),
                React.createElement(ConfigMenuTextInput, { ref_to_set_value: ref_set_text_value_input, function_get_value: () => { return value === null || value === void 0 ? void 0 : value.text_value; }, function_on_blur: updateTextLinks }))));
    // Return JSX component
    return content;
};
/*************************************************************************************************/
/**
 * Component developped for number input of the link data config menu
 * @param {new_data}
 * @return {JSX.Elmement}
 */
export const MenuContextLinksData = ({ new_data, }) => {
    // Selected links --------------------------------------------------------------------
    var _a, _b, _c, _d, _e;
    let selected_links;
    if (!new_data.menu_configuration.is_selector_only_for_visible_links) {
        // All availables links
        selected_links = new_data.drawing_area.selected_links_list_sorted;
    }
    else {
        // Only visible links
        selected_links = new_data.drawing_area.visible_and_selected_links_list_sorted;
    }
    const value = (_a = selected_links[0]) === null || _a === void 0 ? void 0 : _a.value;
    // Components updaters ---------------------------------------------------------------
    // Refs used to trigger refreshing of number & text inputs
    const ref_set_data_value_input = useRef((_) => null);
    const updateInputsValues = () => {
        var _a;
        // Update input data value
        ref_set_data_value_input.current(String((_a = value === null || value === void 0 ? void 0 : value.data_value) !== null && _a !== void 0 ? _a : ''));
    };
    // Function used to force this component to reload
    const [, setCount] = useState(0);
    const refreshThisAndUpdateRelatedComponents = () => {
        // Toogle saving indicator
        new_data.menu_configuration.ref_to_save_in_cache_indicator.current(false);
        // Update data menu for link
        new_data.menu_configuration.updateComponentRelatedToLinksData();
        new_data.menu_configuration.ref_to_spreadsheet.current();
        // And update this menu also
        setCount(a => a + 1);
        updateInputsValues();
    };
    const updateDataLinks = (_) => {
        // Save old values in dict so the undo reset value for previous value of each link
        const dict_old_val = {};
        selected_links.forEach(l => dict_old_val[l.id] = l.data_value);
        // Undo link value
        const inv_updateDataLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.data_value = dict_old_val[link.id];
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        const _updateDataLinks = () => {
            // Update data for links
            selected_links.forEach(link => {
                link.data_value = (_ !== null && _ !== void 0 ? _ : null);
            });
            // Update scaling if only one link
            new_data.drawing_area.updateScaleAtLinkValueSetting();
            // Update this menu
            refreshThisAndUpdateRelatedComponents();
        };
        // Save undo/redo in data history
        new_data.history.saveUndo(inv_updateDataLinks);
        new_data.history.saveRedo(_updateDataLinks);
        // Execute original attr mutation
        _updateDataLinks();
    };
    return React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_data_value_input, default_value: (_b = value === null || value === void 0 ? void 0 : value.data_value) !== null && _b !== void 0 ? _b : null, function_on_blur: updateDataLinks, minimum_value: 0, stepper: true, step: 1, unit_text: (((_c = selected_links[0]) === null || _c === void 0 ? void 0 : _c.value_label_unit_visible) &&
            ((_d = selected_links[0]) === null || _d === void 0 ? void 0 : _d.value_label_unit) !== default_link_value_label_unit) ?
            (_e = selected_links[0]) === null || _e === void 0 ? void 0 : _e.value_label_unit :
            undefined });
};
