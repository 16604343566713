// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// CSS ==============================================================================================
import './deps/OpenSankey+/deps/OpenSankey/css/main.css';
import './deps/OpenSankey+/css/main.css';
import './css/main.css';
import './css/colors/red.css';
import './css/style_elements_sankey.css';
// External imports =================================================================================
import React from 'react';
import { createRoot } from 'react-dom/client';
// Local imports ====================================================================================
import './traductions/traduction';
import { MFASankeyApp } from './AppAFM';
import { Class_ApplicationDataMFA } from './types/TypesMFA';
window.React = React;
const container = document.getElementById('react-container');
const root = createRoot(container);
let initialRender = true;
let dataApp;
const App = () => {
    if (initialRender) {
        initialRender = false;
        dataApp = new Class_ApplicationDataMFA(false);
    }
    return React.createElement(MFASankeyApp, { new_data_app: dataApp });
};
const renderPage = () => {
    root.render(React.createElement(App, null));
};
renderPage();
