import { Box, Button, Checkbox, Input, Select, } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useRef } from 'react';
import { FaAlignLeft, FaAlignCenter, FaAlignRight, FaBold, FaItalic } from 'react-icons/fa';
import { ClassTemplate_LinkElement } from '../../Elements/Link';
import { Class_LinkStyle } from '../../Elements/LinkAttributes';
import { CustomFaEyeCheckIcon, OSTooltip, TooltipValueSurcharge, font_families } from '../../types/Utils';
import { ConfigMenuNumberInput } from './SankeyMenuConfiguration';
import { svg_label_upper } from './SankeyMenuConfigurationNodesAttributes';
import { ClassTemplate_NodeElement } from '../../Elements/Node';
import { Class_NodeStyle, default_node_value_label_bold, default_node_value_label_color, default_node_value_label_custom_digit, default_node_value_label_font_family, default_node_value_label_font_size, default_node_value_label_horiz, default_node_value_label_italic, default_node_value_label_nb_digit, default_node_value_label_unit, default_node_value_label_unit_factor, default_node_value_label_unit_visible, default_node_value_label_uppercase, default_node_value_label_vert, } from '../../Elements/NodeAttributes';
const svg_label_top = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,0H4.5c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5h7.247c-.143,.042-.278,.12-.391,.234l-5.087,5.191c-.574,.581-.167,1.575,.644,1.575h3.587v12.5c0,.829,.671,1.5,1.5,1.5s1.5-.671,1.5-1.5V10h3.587c.811,0,1.218-.994,.644-1.575L12.644,3.234c-.113-.114-.248-.192-.391-.234h7.247c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
const svg_label_bottom = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M19.5,21h-7.247c.143-.042,.278-.12,.391-.234l5.087-5.191c.574-.581,.167-1.575-.644-1.575h-3.587V1.5c0-.829-.672-1.5-1.5-1.5s-1.5,.671-1.5,1.5V14h-3.587c-.811,0-1.218,.994-.644,1.575l5.087,5.191c.113,.114,.248,.192,.391,.234H4.5c-.828,0-1.5,.671-1.5,1.5s.672,1.5,1.5,1.5h15c.828,0,1.5-.671,1.5-1.5s-.672-1.5-1.5-1.5Z" }));
const svg_label_center = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 24 24', width: "12", height: "12" },
    React.createElement("path", { d: "M24,12c0,.553-.448,1-1,1H1c-.552,0-1-.447-1-1s.448-1,1-1H23c.552,0,1,.447,1,1Zm-13.414-3.586c.39,.39,.902,.585,1.414,.585s1.024-.195,1.414-.585l3.293-3.293c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.293,2.293V1c0-.553-.448-1-1-1s-1,.447-1,1V6l-2.293-2.293c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l3.293,3.293Zm2.828,7.172c-.779-.779-2.049-.779-2.828,0l-3.293,3.293c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l2.293-2.293v5c0,.553,.448,1,1,1s1-.447,1-1v-5l2.293,2.293c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-3.293-3.293Z" }));
/**
 * Check if element attribute is from local attr
 *
 * @param {(Type_GenericLinkElement[] | Type_GenericNodeElement[])} elements
 * @param {possibleDecoratorName} attr
 * @return {boolean}
 */
function isElementAttributeOverloaded(elements, attr) {
    let overloaded = false;
    elements.forEach(el => overloaded = (overloaded || el.isAttributeOverloaded(attr)));
    return overloaded;
}
/**
 * Generic function to call getter decorator of model
 *
 * @template TModel
 * @template TKey
 * @param {TModel} model
 * @param {TKey} key
 * @return {TModel[TKey]}
 */
function getValueWithDecoratorRetriever(model, key) {
    return model[key];
}
/**
 * Generic function to call setter decorator of model
 *
 * @template TModel
 * @template TKey
 * @template Tvalue
 * @param {TModel} model
 * @param {TKey} key
 * @param {Tvalue} value
 */
function setValueWithDecoratorRetriever(model, key, value) {
    model[key] = value;
}
/**
 * Upate attribute value via it's decorator & save it's possible undoing in data history
 *
 * @param {Type_GenericApplicationData} data
 * @param {elementsType[]} elements
 * @param {(labelValueAttribute | labelAttributeType)} _dict_decorator_name
 * @param {keyof labelValueAttribute} k
 * @param {valueElementsType} val
 * @param {() => void} refreshParentComponent
 */
const updateElements = (data, elements, _dict_decorator_name, // declare var can be both type so we can use the function in SankeyMenuLabelComponent & SankeyMenuValueLabelComponent 
k, // key of labelValueAttribute also contain key of labelAttributeType (since labelValueAttribute is a composite type with labelAttributeType)
val, refreshParentComponent) => {
    const dict_decorator_name = _dict_decorator_name;
    // Create a dict of old val for each elements 
    const dict_old_val = {};
    elements.forEach(element => dict_old_val[element.id] = getValueWithDecoratorRetriever(element, dict_decorator_name[k]));
    // Original function
    const _updateElements = () => {
        elements.forEach(element => setValueWithDecoratorRetriever(element, dict_decorator_name[k], val));
        refreshParentComponent();
    };
    // Undo function
    const inv_updateElements = () => {
        elements.forEach(element => setValueWithDecoratorRetriever(element, dict_decorator_name[k], dict_old_val[element.id]));
        refreshParentComponent();
    };
    data.history.saveUndo(inv_updateElements); //save undo
    data.history.saveRedo(_updateElements); //save original func for a redo
    _updateElements(); // execute function
};
export const SankeyMenuLabelComponent = ({ new_data, elements, selectedElements, refreshParentComponent, dict_decorator_name }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const menu_for_style = elements.length > 0 && (elements[0] instanceof Class_NodeStyle || elements[0] instanceof Class_LinkStyle);
    const check_indeterminate = (curr) => {
        const ref_element = selectedElements[0];
        if (curr instanceof ClassTemplate_LinkElement && ref_element instanceof ClassTemplate_LinkElement) {
            return (ref_element.isEqual(curr));
        }
        else if (curr instanceof ClassTemplate_NodeElement && ref_element instanceof ClassTemplate_NodeElement) {
            return (ref_element.isEqual(curr));
        }
        else {
            return false;
        }
    };
    const is_indeterminate = !selectedElements.every(check_indeterminate);
    // Declare var used to set default attribute value in inputs 
    let get_label_horiz = default_node_value_label_horiz;
    let get_label_vert = default_node_value_label_vert;
    let get_label_font_size = default_node_value_label_font_size;
    let get_label_color = default_node_value_label_color;
    let get_label_bold = default_node_value_label_bold;
    let get_label_italic = default_node_value_label_italic;
    let get_label_uppercase = default_node_value_label_uppercase;
    let get_label_font_family = default_node_value_label_font_family;
    // If elements selected set displayed value with first selected element
    if (elements.length > 0) {
        const element_ref = elements[0];
        // Since element_ref can be LinkAttributes | Type_GenericLinkElement | Type_GenericNodeElement | Class_NodeStyle
        // we use a function to use correct decorator 'getter' to get attribute of either name label or value label depending on what we used in dict_decorator_name
        get_label_horiz = ((_a = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_horiz'])) !== null && _a !== void 0 ? _a : default_node_value_label_horiz);
        get_label_vert = ((_b = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_vert'])) !== null && _b !== void 0 ? _b : default_node_value_label_vert);
        get_label_font_size = ((_c = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_font_size'])) !== null && _c !== void 0 ? _c : default_node_value_label_font_size);
        get_label_color = ((_d = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_color'])) !== null && _d !== void 0 ? _d : default_node_value_label_color);
        get_label_font_family = ((_e = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_font_family'])) !== null && _e !== void 0 ? _e : default_node_value_label_font_family);
        get_label_bold = (_f = (getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_bold']))) !== null && _f !== void 0 ? _f : default_node_value_label_bold;
        get_label_italic = (_g = (getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_italic']))) !== null && _g !== void 0 ? _g : default_node_value_label_italic;
        get_label_uppercase = (_h = (getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_uppercase']))) !== null && _h !== void 0 ? _h : default_node_value_label_uppercase;
    }
    // Link to ConfigMenuNumberInput state variable
    const number_of_input = 3;
    const ref_set_number_inputs = [];
    for (let i = 0; i < number_of_input; i++)
        ref_set_number_inputs.push(useRef((_) => null));
    ref_set_number_inputs[0].current(String(get_label_font_size));
    return React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.edition')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                    t('Flux.apparence.couleur'),
                    (!menu_for_style) &&
                        isElementAttributeOverloaded(selectedElements, 'value_label_color') ?
                        React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                        React.createElement(React.Fragment, null)),
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: get_label_color, onChange: evt => {
                        updateElements(new_data, elements, dict_decorator_name, 'label_color', evt.target.value, refreshParentComponent);
                    } })),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, "Police"),
            React.createElement(Box, { layerStyle: 'options_3cols' },
                React.createElement(Box, { layerStyle: 'options_3cols' },
                    React.createElement(Button, { variant: get_label_bold ?
                            'menuconfigpanel_option_button_activated_left' :
                            'menuconfigpanel_option_button_left', paddingStart: '0', paddingEnd: '0', minWidth: '0', onClick: () => {
                            updateElements(new_data, elements, dict_decorator_name, 'label_bold', !get_label_bold, refreshParentComponent);
                        } },
                        React.createElement(FaBold, null)),
                    React.createElement(Button, { variant: get_label_uppercase ?
                            'menuconfigpanel_option_button_activated_center' :
                            'menuconfigpanel_option_button_center', paddingStart: '0', paddingEnd: '0', minWidth: '0', onClick: () => {
                            updateElements(new_data, elements, dict_decorator_name, 'label_uppercase', !get_label_uppercase, refreshParentComponent);
                        } }, svg_label_upper),
                    React.createElement(Button, { variant: get_label_italic ?
                            'menuconfigpanel_option_button_activated_right' :
                            'menuconfigpanel_option_button_right', paddingStart: '0', paddingEnd: '0', minWidth: '0', onClick: () => {
                            updateElements(new_data, elements, dict_decorator_name, 'label_italic', !get_label_italic, refreshParentComponent);
                        } },
                        React.createElement(FaItalic, null))),
                React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: get_label_font_family, onChange: (evt) => {
                        updateElements(new_data, elements, dict_decorator_name, 'label_font_family', evt.target.value, refreshParentComponent);
                    } }, font_families
                    .map((d) => {
                    return React.createElement("option", { style: { fontFamily: d }, key: 'ff-' + d, value: d }, d);
                })),
                React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[0], default_value: get_label_font_size, menu_for_style: menu_for_style, minimum_value: 11, stepper: true, unit_text: 'pixels', function_on_blur: (value) => {
                        updateElements(new_data, elements, dict_decorator_name, 'label_font_size', value !== null && value !== void 0 ? value : undefined, refreshParentComponent);
                    } })),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, "Position"),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                    t('Flux.label.pos'),
                    (!menu_for_style) &&
                        isElementAttributeOverloaded(selectedElements, 'value_label_horiz') ?
                        React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                        React.createElement(React.Fragment, null)),
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Box, { layerStyle: 'options_3cols' },
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.deb') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (get_label_horiz === 'left')) ?
                                    'menuconfigpanel_option_button_activated_left' :
                                    'menuconfigpanel_option_button_left', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_horiz', 'left', refreshParentComponent);
                                } },
                                React.createElement(FaAlignLeft, null))),
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.milieu_h') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (get_label_horiz === 'middle')) ?
                                    'menuconfigpanel_option_button_activated_center' :
                                    'menuconfigpanel_option_button_center', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_horiz', 'middle', refreshParentComponent);
                                } },
                                React.createElement(FaAlignCenter, null))),
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.fin') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate && (get_label_horiz === 'right')) ?
                                    'menuconfigpanel_option_button_activated_right' :
                                    'menuconfigpanel_option_button_right', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_horiz', 'right', refreshParentComponent);
                                } },
                                React.createElement(FaAlignRight, null)))),
                    React.createElement(Box, { layerStyle: 'options_3cols' },
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.dessous') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate &&
                                    (get_label_vert === 'bottom')) ?
                                    'menuconfigpanel_option_button_activated_left' :
                                    'menuconfigpanel_option_button_left', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_vert', 'bottom', refreshParentComponent);
                                } }, svg_label_bottom)),
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.milieu_v') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate &&
                                    (get_label_vert === 'middle')) ?
                                    'menuconfigpanel_option_button_activated_center' :
                                    'menuconfigpanel_option_button_center', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_vert', 'middle', refreshParentComponent);
                                } }, svg_label_center)),
                        React.createElement(OSTooltip, { label: t('Flux.label.tooltips.dessus') },
                            React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', variant: (!is_indeterminate &&
                                    (get_label_vert === 'top')) ?
                                    'menuconfigpanel_option_button_activated_right' :
                                    'menuconfigpanel_option_button_right', onClick: () => {
                                    updateElements(new_data, elements, dict_decorator_name, 'label_vert', 'top', refreshParentComponent);
                                } }, svg_label_top)))))));
};
export const SankeyMenuValueLabelComponent = ({ new_data, elements, selectedElements, refreshParentComponent, dict_decorator_name }) => {
    var _a, _b, _c, _d, _e;
    const menu_for_style = elements.length > 0 && (elements[0] instanceof Class_NodeStyle || elements[0] instanceof Class_LinkStyle);
    const check_indeterminate = (curr) => {
        const ref_element = selectedElements[0];
        if (curr instanceof ClassTemplate_LinkElement && ref_element instanceof ClassTemplate_LinkElement) {
            return (ref_element.isEqual(curr));
        }
        else if (curr instanceof ClassTemplate_NodeElement && ref_element instanceof ClassTemplate_NodeElement) {
            return (ref_element.isEqual(curr));
        }
        else {
            return false;
        }
    };
    const is_indeterminate = !selectedElements.every(check_indeterminate);
    // Declare var used to set default attribute value in inputs 
    let get_label_unit_visible = default_node_value_label_unit_visible;
    let get_label_unit = default_node_value_label_unit;
    let get_label_unit_factor = default_node_value_label_unit_factor;
    let get_label_custom_digit = default_node_value_label_custom_digit;
    let get_label_nb_digit = default_node_value_label_nb_digit;
    // If elements selected set displayed value with first selected element
    if (elements.length > 0) {
        const element_ref = elements[0];
        // Since element_ref can be LinkAttributes | Type_GenericLinkElement | Type_GenericNodeElement | Class_NodeStyle
        // we use a function to use correct decorator 'getter' to get attribute of either name label or value label depending on what we used in dict_decorator_name
        get_label_unit_visible = ((_a = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_unit_visible'])) !== null && _a !== void 0 ? _a : default_node_value_label_unit_visible);
        get_label_unit = ((_b = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_unit'])) !== null && _b !== void 0 ? _b : default_node_value_label_unit);
        get_label_unit_factor = ((_c = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_unit_factor'])) !== null && _c !== void 0 ? _c : default_node_value_label_unit_factor);
        get_label_custom_digit = ((_d = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_custom_digit'])) !== null && _d !== void 0 ? _d : default_node_value_label_custom_digit);
        get_label_nb_digit = ((_e = getValueWithDecoratorRetriever(element_ref, dict_decorator_name['label_nb_digit'])) !== null && _e !== void 0 ? _e : default_node_value_label_nb_digit);
    }
    // Link to ConfigMenuNumberInput state variable
    const number_of_input = 2;
    const ref_set_number_inputs = [];
    for (let i = 0; i < number_of_input; i++)
        ref_set_number_inputs.push(useRef((_) => null));
    ref_set_number_inputs[0].current(String(get_label_nb_digit));
    ref_set_number_inputs[1].current(String(get_label_unit_factor));
    return React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: is_indeterminate, isChecked: get_label_custom_digit, onChange: (evt) => {
                    updateElements(new_data, elements, dict_decorator_name, 'label_custom_digit', evt.target.checked, refreshParentComponent);
                } },
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.custom_digit') }, t('Flux.label.custom_digit') + ' '),
                (!menu_for_style) &&
                    isElementAttributeOverloaded(selectedElements, 'value_label_custom_digit') ?
                    TooltipValueSurcharge('link_var_', t) :
                    React.createElement(React.Fragment, null)),
            get_label_custom_digit ?
                /* Choose number of custom digit */
                React.createElement(OSTooltip, { label: t('Flux.label.tooltips.NbDigit') },
                    React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[0], default_value: get_label_nb_digit, menu_for_style: menu_for_style, minimum_value: 0, stepper: true, function_on_blur: (value) => {
                            updateElements(new_data, elements, dict_decorator_name, 'label_nb_digit', value !== null && value !== void 0 ? value : undefined, refreshParentComponent);
                        } }))
                : React.createElement(React.Fragment, null)),
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', icon: React.createElement(CustomFaEyeCheckIcon, null), isChecked: get_label_unit_visible, onChange: (evt) => {
                updateElements(new_data, elements, dict_decorator_name, 'label_unit_visible', evt.target.checked, refreshParentComponent);
            } },
            React.createElement(OSTooltip, { label: t('Flux.label.tooltips.l_u_v') }, t('Flux.label.l_u_v') + ' '),
            (!menu_for_style) &&
                isElementAttributeOverloaded(selectedElements, 'value_label_unit_visible') ?
                TooltipValueSurcharge('link_var_', t) :
                React.createElement(React.Fragment, null)),
        get_label_unit_visible ?
            React.createElement(React.Fragment, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Flux.label.l_u'),
                        (!menu_for_style) &&
                            isElementAttributeOverloaded(selectedElements, 'value_label_unit') ?
                            React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.l_u') },
                        React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: get_label_unit, onChange: evt => {
                                updateElements(new_data, elements, dict_decorator_name, 'label_unit', evt.target.value, refreshParentComponent);
                            } }))),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                        t('Flux.label.unit_factor'),
                        ((!menu_for_style) &&
                            isElementAttributeOverloaded(selectedElements, 'value_label_unit_factor')) ?
                            React.createElement(React.Fragment, null, TooltipValueSurcharge('link_var_', t)) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(OSTooltip, { label: t('Flux.label.tooltips.unit_factor') },
                        React.createElement(ConfigMenuNumberInput, { ref_to_set_value: ref_set_number_inputs[1], default_value: get_label_unit_factor, function_on_blur: (value) => {
                                updateElements(new_data, elements, dict_decorator_name, 'label_unit_factor', (value ? value : undefined), refreshParentComponent);
                            }, menu_for_style: menu_for_style, minimum_value: 1, maximum_value: get_label_unit_factor, step: 1, stepper: true })))) :
            React.createElement(React.Fragment, null),
        React.createElement(SankeyMenuLabelComponent, { new_data: new_data, elements: elements, selectedElements: selectedElements, refreshParentComponent: refreshParentComponent, dict_decorator_name: dict_decorator_name }));
};
