// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as d3 from 'd3';
// CONSTANTS ****************************************************************************
export const default_grey_color = 'grey';
export const default_black_color = 'black';
export const default_background_color = '#f2f2f2';
export const default_grid_color = '#d3d3d3';
export const default_element_color = '#a9a9a9';
export const default_font = 'Arial,sans-serif';
export const font_families = [
    'Andale Mono,monospace',
    'Apple Chancery,cursive',
    'Arial,sans-serif',
    'Avanta Garde,sans-serif',
    'Baskerville,serif',
    'Big Caslon,serif',
    'Bodoni MT,serif',
    'Book Antiqua,serif',
    'Bookman,serif',
    'Bradley Hand,cursive',
    'Brush Script MT,cursive',
    'Brush Script Std,cursive',
    'Calibri,sans-serif',
    'Calisto MT,serif',
    'Cambria,serif',
    'Candara,sans-serif',
    'Century Gothic,sans-serif',
    'Comic Sans MS,cursive',
    'Comic Sans,cursive',
    'Consolas,monospace',
    'Coronet script,cursive',
    'Courier New,monospace',
    'Courier,monospace',
    'Didot,serif',
    'Florence,cursive',
    'Franklin Gothic Medium,sans-serif',
    'Futara,sans-serif',
    'Garamond,serif',
    'Geneva,sans-serif',
    'Georgia,serif',
    'Gill Sans,sans-serif',
    'Goudy Old Style,serif',
    'Helvetica,sans-serif',
    'Hoefler Text,serif',
    'Lucida Bright,serif',
    'Lucida Console,monospace',
    'Lucida Sans Typewriter,monospace',
    'Lucida Sans,sans-serif',
    'Lucidatypewriter,monospace',
    'Monaco,monospace',
    'New Century Schoolbook,serif',
    'Noto,sans-serif',
    'Optima,sans-serif',
    'Palatino,serif',
    'Parkavenue,cursive',
    'Perpetua,serif',
    'Rockwell Extra Bold,serif',
    'Rockwell,serif',
    'Segoe UI,sans-serif',
    'Snell Roundhan,cursive',
    'Times New Roman,serif',
    'Trebuchet MS,sans-serif',
    'URW Chancery,cursive',
    'Verdana,sans-serif',
    'Zapf Chancery,cursive',
];
export const default_main_sankey_id = 'sankey_maitre';
export const const_default_position_x = 50;
export const const_default_position_y = 50;
export const default_element_position = {
    x: const_default_position_x,
    y: const_default_position_y,
    u: 0,
    v: 0
};
export const default_style_id = 'default';
export const default_style_name = 'Style par default';
// DEDICATED FUNCTIONS *******************************************************************
/**
 * Create random id
 * from https://stackoverflow.com/a/1349426
 * @param {number} length
 * @return {*}
 */
export function randomId(length = 5) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
export function makeId(name) {
    const std_name = name.toLowerCase()
        .replace(/[^a-z0-9_]/gi, '');
    return std_name + '_' + randomId();
}
export function getBooleanFromJSON(json_object, key, fallback_value) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'boolean') { // test if not undefined because json_object[key] can be false thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return fallback_value;
}
export function getBooleanOrUndefinedFromJSON(json_object, key) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'boolean') { // test if not undefined because json_object[key] can be false thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return undefined;
}
export function getNumberFromJSON(json_object, key, fallback_value) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'number') { // test if not undefined because json_object[key] can be 0 (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return fallback_value;
}
export function getNumberOrUndefinedFromJSON(json_object, key) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'number') { // test if not undefined because json_object[key] can be 0 (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return undefined;
}
export function getNumberOrNullFromJSON(json_object, key) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'number') { // test if not undefined because json_object[key] can be 0 (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return null;
}
export function getStringFromJSON(json_object, key, fallback_value) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'string') { // test if not undefined because json_object[key] can be '' (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return fallback_value;
}
export function getStringOrUndefinedFromJSON(json_object, key) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'string') { // test if not undefined because json_object[key] can be '' (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return undefined;
}
export function getStringOrNullFromJSON(json_object, key) {
    if (json_object[key] !== undefined && typeof json_object[key] === 'string') { // test if not undefined because json_object[key] can be '' (considered as false in javascript condition) thus not assiging correct value but fallback_value
        return json_object[key];
    }
    return null;
}
export function getStringListFromJSON(json_object, key, fallback_value) {
    if (json_object[key] && typeof json_object[key] === typeof fallback_value) {
        return json_object[key];
    }
    return fallback_value;
}
export function getStringListOrUndefinedFromJSON(json_object, key) {
    if (json_object[key]) {
        const _ = getStringListFromJSON(json_object, key, []);
        if (_.length > 0)
            return _;
    }
    return undefined;
}
export function getJSONFromJSON(json_object, key, fallback_value) {
    if ((json_object[key] !== undefined) &&
        (typeof json_object[key] === typeof fallback_value)) {
        return json_object[key];
    }
    return fallback_value;
}
export function getJSONOrUndefinedFromJSON(json_object, key) {
    if (json_object[key]) {
        const _ = getJSONFromJSON(json_object, key, {});
        if (Object.keys(_).length > 0)
            return _;
    }
    return undefined;
}
export const CutName = (t, n) => {
    return (t && t.length > n) ? t.slice(0, n) + '...' : t;
};
export const check_perf = (f, name) => {
    const s = performance.now();
    f();
    const e = performance.now();
    console.debug(`Execution ${name} took  ${e - s} ms`);
};
// Tooltipe added to input in menu when add a local value (for nodes & links local attributes)
export const TooltipValueSurcharge = (k, t) => {
    return React.createElement(OSTooltip, { label: t('Menu.overcharge_style_value') },
        React.createElement(FontAwesomeIcon, { style: { color: '#6cc3d5', height: '12', width: '12', float: 'right' }, icon: faCircleInfo }));
};
export const OSTooltip = ({ label, delay = 500, placement = 'top', isAlwaysOpen = false, children }) => {
    if (label === undefined) {
        return React.createElement(React.Fragment, null, children);
    }
    const element_key = label.split(' ').join('_');
    if (isAlwaysOpen) {
        return React.createElement(Tooltip, { key: element_key, openDelay: delay, placement: placement, label: label, closeDelay: 100, isOpen: true, hasArrow: true }, children);
    }
    else {
        return React.createElement(Tooltip, { key: element_key, openDelay: delay, placement: placement, label: label, closeDelay: 100 }, children);
    }
};
export const CustomFaEyeCheckIcon = (props) => {
    const { isChecked } = props;
    return isChecked ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null);
};
export const GetRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};
export const list_palette_color = [d3.interpolateBlues, d3.interpolateBrBG, d3.interpolateBuGn, d3.interpolatePiYG, d3.interpolatePuOr,
    d3.interpolatePuBu, d3.interpolateRdBu, d3.interpolateRdGy, d3.interpolateRdYlBu, d3.interpolateRdYlGn, d3.interpolateSpectral,
    d3.interpolateTurbo, d3.interpolateViridis, d3.interpolateInferno, d3.interpolateMagma, d3.interpolatePlasma, d3.interpolateCividis,
    d3.interpolateWarm, d3.interpolateCool, d3.interpolateCubehelixDefault, d3.interpolateRainbow, d3.interpolateSinebow];
// COMPONENTS ===========================================================================
// ! Won't work with locales using characters different than Arabic numerals (e.g. *Eastern* Arabic numerals: ١٢٣٬٤٥٦٫٧٨٩)
// TODO: If possible add support for locales using characters different than Arabic numerals
const getLocaleSeparators = (locale) => {
    const testNumber = 123456.789;
    const localeFormattedNumber = Intl.NumberFormat(locale).format(testNumber);
    // Get the thousands separator of the locale
    const thousandsSeparator = localeFormattedNumber.split('123')[1][0];
    // Get the decimal separator of the locale
    const decimalSeparator = localeFormattedNumber.split('123')[1][4];
    return { thousandsSeparator, decimalSeparator };
};
export const parseLocaleNumber = (stringNumber, locale = navigator.language) => {
    if (!stringNumber.trim())
        return NaN;
    const { thousandsSeparator, decimalSeparator } = getLocaleSeparators(locale);
    const normalizedStringNumber = stringNumber.replace(/\u00A0/g, ' '); // Replace non-breaking space with normal space
    const numberString = normalizedStringNumber
        .replace(new RegExp(`[${thousandsSeparator}\\s]`, 'g'), '') // Replace thousands separator and white-space
        .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.'); // Replace decimal separator
    const trimmedNumberString = numberString.replace(/^(?!-)\D+|\D+$/g, ''); // Remove characters before first and after last number, but keep negative sign
    if (trimmedNumberString === null || trimmedNumberString.trim().length === 0) {
        return NaN;
    }
    return Number(trimmedNumberString);
};
