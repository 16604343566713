// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Local imports
import { ClassAbstract_ApplicationDataMFA } from './AbstractMFA';
import { Class_MenuConfigMFA } from './MenuConfigMFA';
// CLASS APPLICATION DATA PLUS **********************************************************
/**
 * Override some Class_ApplicationData behaviors for OpenSankey+
 * @export
 * @class ClassTemplate_ApplicationDataMFA
 * @extends {Class_ApplicationData}
 */
export class ClassTemplate_ApplicationDataMFA extends ClassAbstract_ApplicationDataMFA {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_ApplicationDataMFA.
     * @param {boolean} published_mode
     * @memberof ClassTemplate_ApplicationDataMFA
     */
    constructor(published_mode) {
        // Inherited contruction
        super(published_mode);
        // PUBLIC ATTRIBUTES =================================================================
        // Static path
        this.static_path = 'static/afmsankey';
        this._has_mfa = true; // token for mfa app (if user is connected with an account)
        // PRIVATE ATTRIBUTES =================================================================
        this._logo_mfa = '';
        // Override menu
        this._menu_configuration = new Class_MenuConfigMFA();
        // Get MFA logo
        this._logo_mfa = 'logos/logo_OSS.png';
        this._logo = this._logo_mfa;
    }
    // PROTECTED METHODS =====================================================================
    // GETTERS / SETTERS ==================================================================
    get logo() { return this._logo_mfa; }
    get logo_mfa() { return this._logo_mfa; }
    get has_mfa() { return this._has_mfa; }
    // Override getter & setter so we can get new type
    get menu_configuration() { return this._menu_configuration; }
    set menu_configuration(_) { this._menu_configuration = _; }
}
