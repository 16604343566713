// ==================================================================================================
// Author : Vincent LE DOZE & Vincent CLAVEL for TerriFlux SARL
// Date : 29/05/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { ClassAbstract_LinkElement, ClassAbstract_LinkValue } from '../types/AbstractLink';
import { ClassTemplate_Handler } from './Handler';
import { default_style_id, getJSONFromJSON, getJSONOrUndefinedFromJSON, getNumberFromJSON, getNumberOrNullFromJSON, getNumberOrUndefinedFromJSON, getStringFromJSON, getStringOrNullFromJSON, makeId, } from '../types/Utils';
import { Class_LinkAttribute, default_shape_arrow_size, default_shape_color, default_shape_curvature, default_shape_ending_curve, default_shape_ending_tangeant, default_shape_is_arrow, default_shape_is_curved, default_shape_is_dashed, default_shape_is_recycling, default_shape_is_structure, default_shape_middle_recyling, default_shape_opacity, default_shape_orientation, default_shape_starting_curve, default_shape_starting_tangeant, default_link_value_label_horiz, default_link_name_label_horiz, default_link_name_label_vert, default_link_name_label_visible, default_link_value_label_color, default_link_value_label_custom_digit, default_link_value_label_font_family, default_link_value_label_font_size, default_link_value_label_is_visible, default_link_value_label_nb_digit, default_link_value_label_nb_significant_digits, default_link_value_label_on_path, default_link_value_label_pos_auto, default_link_value_label_scientific_notation, default_link_value_label_significant_digits, default_link_value_label_unit, default_link_value_label_unit_factor, default_link_value_label_unit_visible, default_link_value_label_vert, default_link_value_label_uppercase, default_link_name_label_color, default_link_name_label_bold, default_link_name_label_font_family, default_link_name_label_font_size, default_link_name_label_italic, default_link_name_label_uppercase, default_link_value_label_bold, default_link_value_label_italic } from './LinkAttributes';
const side_order = {
    'right': 0,
    'bottom': 1,
    'left': 2,
    'top': 3
};
// SPECIFIC FUNCTIONS ********************************************************************
export function defaultLinkId(source, target) {
    // TODO ajouter makeID pour crer id unique
    return source.name + ' --> ' + target.name;
}
/**
 * Allows to sort links alphabethically per id
 * @export
 * @param {(Type_AnyLinkElement | Class_LinkStyle)} a
 * @param {(Type_AnyLinkElement | Class_LinkStyle)} b
 * @return {*}
 */
export function sortLinksElementsByIds(a, b) {
    if (a.id > b.id)
        return 1;
    else if (a.id < b.id)
        return -1;
    else
        return 0;
}
/**
 * Allow to sort links by their z-ordre on the drawing area
 * @export
 * @param {Type_AnyLinkElement} a
 * @param {Type_AnyLinkElement} b
 * @return {*}
 */
export function sortLinksElementsByDisplayingOrders(a, b) {
    if (a.displaying_order > b.displaying_order)
        return 1;
    else if (a.displaying_order < b.displaying_order)
        return -1;
    else
        return 0;
}
/**
 * Allows to sort links of a given node by comparing their source / target relatives positions
 * @export
 * @param {Type_AnyLinkElement} link_a
 * @param {Type_AnyLinkElement} link_b
 * @param {Type_AnyAbstractNodeElement} node
 * @return {*}
 */
export function sortLinksElementsByRelativeNodesPositions(link_a, link_b, node) {
    // Check relation between reference node and the two links
    const is_node_source_for_link_a = (link_a.source === node);
    const is_node_target_for_link_a = (link_a.target === node);
    const is_node_source_for_link_b = (link_b.source === node);
    const is_node_target_for_link_b = (link_b.target === node);
    // Failsafe
    if ((!is_node_source_for_link_a && !is_node_target_for_link_a) ||
        (!is_node_source_for_link_b && !is_node_target_for_link_b))
        return 0; // Dont move - somethings is wrong
    // Get nodes that we need to compare
    let node_a;
    let node_b;
    let side_a;
    let side_b;
    if (is_node_source_for_link_a) {
        node_a = link_a.target;
        side_a = link_a.source_side;
    }
    else {
        node_a = link_a.source;
        side_a = link_a.target_side;
    }
    if (is_node_source_for_link_b) {
        node_b = link_b.target;
        side_b = link_b.source_side;
    }
    else {
        node_b = link_b.source;
        side_b = link_b.target_side;
    }
    // Side check : Node position comparaison if links are on the same side ?
    if (side_a === side_b) {
        // For "horizontal" sides
        if (side_a === 'right' || side_a === 'left') {
            if (node_a.position_y > node_b.position_y)
                return 1;
            else if (node_a.position_y < node_b.position_y)
                return -1;
            else
                return 0;
        }
        // For "vertical" sides
        else {
            if (node_a.position_x > node_b.position_x)
                return 1;
            else if (node_a.position_x < node_b.position_x)
                return -1;
            else
                return 0;
        }
    }
    // Otherwise, use side "priority"
    else {
        if (side_order[side_a] < side_order[side_b])
            return -1;
        else
            return 1;
    }
}
/**
 * Check if given attribute is overloaded in at least one link
 * @export
 * @param {Type_AnyLinkElement[]} links
 * @param {keyof Class_LinkAttribute} attr
 * @return {*}
 */
export function isAttributeOverloaded(links, attr) {
    let overloaded = false;
    links.forEach(link => overloaded = (overloaded || link.isAttributeOverloaded(attr)));
    return overloaded;
}
// CLASS LINK ELEMENT ********************************************************************
/**
 * Class that define how to display a link element and how to interact with it
 *
 * @class ClassTemplate_LinkElement
 */
export class ClassTemplate_LinkElement extends ClassAbstract_LinkElement {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_LinkElement.
     * @param {string} id
     * @param {Type_GenericDrawingArea} drawing_area
     * @memberof ClassTemplate_LinkElement
     */
    constructor(id, source, target, drawing_area, menu_config) {
        // Init parent class attributes
        super(id, menu_config, 'g_links');
        this._are_source_and_target_displayed = undefined;
        // Visibility memorized - flux tags
        this._flux_tags_fingerprint = '';
        this._are_related_flux_tags_selected = undefined;
        // Visibility memorized - values
        this._datatags_fingerprint = '';
        this._is_not_null = undefined;
        /**
         * Value of tooltip text associated to link
         * @private
         * @type {string}
         * @memberof ClassTemplate_LinkElement
         */
        this._tooltip_text = '';
        // Boolean var only used when enlarging thickness when mouse hovering link
        this._artifical_enlargement = false;
        /**
         * _scaleValueToPx transform a value to a proportional size in px according to data scale
         *
         * @private
         * @memberof ClassTemplate_DrawingArea
         */
        this._scaleValueToPx = d3.scaleLinear()
            .domain([0, 1])
            .range([0, 100]);
        // Add control points
        this._control_points = this.initControlPoints(drawing_area);
        // Values
        this._values = new Class_LinkValue(this);
        drawing_area.sankey.data_taggs_list
            .forEach(data_tagg => {
            this._values = this._values.expand(data_tagg);
        });
        // Source
        this._source = source;
        this._source_visibility_fingerprint = source.visibility_fingerprint;
        this._target = target;
        this._target_visibility_fingerprint = target.visibility_fingerprint;
    }
    initControlPoints(drawing_area) {
        return {
            starting_curve_point: new ClassTemplate_Handler('cp_start_' + this.id, drawing_area, this.menu_config, this, this.dragHandleStart(), this.startCurvePointDragEvent(), this.dragHandleEnd(), { class: 'cp_start' }),
            ending_curve_point: new ClassTemplate_Handler('cp_end_' + this.id, drawing_area, this.menu_config, this, this.dragHandleStart(), this.endCurvePointDragEvent(), this.dragHandleEnd(), { class: 'cp_end' }),
            starting_bezier_point: new ClassTemplate_Handler('bz_start_' + this.id, drawing_area, this.menu_config, this, this.dragHandleStart(), this.startTangeantDragEvent(), this.dragHandleEnd(), { class: 'bz_start' }),
            ending_bezier_point: new ClassTemplate_Handler('bz_end_' + this.id, drawing_area, this.menu_config, this, this.dragHandleStart(), this.endTangeantDragEvent(), this.dragHandleEnd(), { class: 'bz_end' }),
            middle_recycling_point: new ClassTemplate_Handler('recy_middle_' + this.id, drawing_area, this.menu_config, this, this.dragHandleStart(), this.middleRecyclingDragEvent(), this.dragHandleEnd(), { class: 'recy_middle' }),
            is_dragged: false
        };
    }
    // CLEANING ===========================================================================
    /**
     * Define deletion behavior
     * @memberof ClassTemplate_LinkElement
     */
    cleanForDeletion() {
        // Unref self from source node
        this._source.deleteOutputLink(this);
        // Unref self from target node
        this._target.deleteInputLink(this);
        // Delete control points
        this._control_points.starting_curve_point.delete();
        this._control_points.ending_curve_point.delete();
        this._control_points.starting_bezier_point.delete();
        this._control_points.ending_bezier_point.delete();
        this._control_points.middle_recycling_point.delete();
        // Unref self from styles
        this.style.removeReference(this);
        // Delete related values
        this._values.delete();
    }
    // COPY METHODS =======================================================================
    /**
     * Copy attributes from a given link
     *
     * @param {ClassTemplate_LinkElement<Type_GenericDrawingArea, Type_GenericSankey, Type_GenericNodeElement>} link_to_copy
     * @memberof ClassTemplate_LinkElement
     */
    copyAttrFrom(_) {
        super._copyFrom(_);
        // Update style
        if (this._display.style.id !== _._display.style.id) {
            let style = this._display.sankey.link_styles_dict[_._display.style.id];
            if (style === undefined) {
                style = this.sankey.addNewLinkStyle(_._display.style.id, _._display.style.name);
                style.copyFrom(_._display.style);
            }
            this.style = style;
        }
        // Local attributes
        this._display.attributes.copyFrom(_._display.attributes);
        // Display
        this._display.displaying_order = _._display.displaying_order;
        this._display.position_starting = structuredClone(_._display.position_starting);
        this._display.position_ending = structuredClone(_._display.position_ending);
        this._display.position_x_value = _._display.position_x_value;
        this._display.position_y_value = _._display.position_y_value;
        this._display.position_offset_value = _._display.position_offset_value;
        this._display.position_x_name = _._display.position_x_name;
        this._display.position_y_name = _._display.position_y_name;
        this._display.position_offset_name = _._display.position_offset_name;
        // Tooltips
        this._tooltip_text = _._tooltip_text;
    }
    _copyFrom(_) {
        // Source relations
        if (this._source.id !== _._source.id) {
            let source = this._display.sankey.nodes_dict[_._source.id];
            if (source === undefined) {
                source = this._display.sankey.addNewNode(_._source.id, _._source.name);
                // source.copyFrom(_._source)
            }
            this.source = source;
        }
        // target relations
        if (this._target.id !== _._target.id) {
            let target = this._display.sankey.nodes_dict[_._target.id];
            if (target === undefined) {
                target = this._display.sankey.addNewNode(_._target.id, _._target.name);
                // target.copyFrom(_._target)
            }
            this.target = target;
        }
        // Update style
        if (this._display.style.id !== _._display.style.id) {
            let style = this._display.sankey.link_styles_dict[_._display.style.id];
            if (style === undefined) {
                style = this.sankey.addNewLinkStyle(_._display.style.id, _._display.style.name);
                style.copyFrom(_._display.style);
            }
            this.style = style;
        }
        // Local attributes
        this.copyAttrFrom(_);
        // Values
        if (_._values instanceof Class_LinkValue) {
            this._values = new Class_LinkValue(this);
            this._values.copyFrom(_._values);
        }
        else if (_._values instanceof Class_LinkValueTree) {
            const first_data_tag_group = this.sankey.data_taggs_dict[_._values.data_tag_group.id];
            if (first_data_tag_group) {
                this._values = new Class_LinkValueTree(this, first_data_tag_group);
                this._values.copyFrom(_._values);
            }
        }
    }
    _toJSON(json_object, kwargs) {
        super._toJSON(json_object, kwargs);
        // Related nodes
        json_object['idSource'] = this._source.id;
        json_object['idTarget'] = this._target.id;
        // Fill style & local attributes
        json_object['style'] = this.style.id;
        json_object['local'] = this._display.attributes.toJSON();
        // Fill positions attributes
        json_object['displaying_order'] = this._display.displaying_order;
        json_object['position_starting_x'] = this._display.position_starting.x;
        json_object['position_starting_y'] = this._display.position_starting.y;
        json_object['position_ending_x'] = this._display.position_ending.x;
        json_object['position_ending_y'] = this._display.position_ending.y;
        if (this._display.position_offset_value !== undefined)
            json_object['position_offset_label'] = this._display.position_offset_value;
        if (this._display.position_offset_name !== undefined)
            json_object['position_offset_label'] = this._display.position_offset_name;
        if (this._display.position_x_value !== undefined)
            json_object['position_x_label'] = this._display.position_x_value;
        if (this._display.position_y_value !== undefined)
            json_object['position_y_label'] = this._display.position_y_value;
        if (this._display.position_x_name !== undefined)
            json_object['position_x_name'] = this._display.position_x_name;
        if (this._display.position_y_name !== undefined)
            json_object['position_y_name'] = this._display.position_y_name;
        // Tooltips
        json_object['tooltip_text'] = this._tooltip_text;
        // Values
        if (!(kwargs && kwargs['without_values']))
            json_object['value'] = this._values.toJSON();
        // Out
        return json_object;
    }
    /**
     * Possible kwargs :
     * - matching_nodes_id: { [_: string]: string } as "id in JSON" -> "id in model"
     * - matching_taggs_id: { [_: string]: string } as "id in JSON" -> "id in model"
     * - matching_tags_id: { [_: string]: { [_: string]: string } }  as "id in JSON" -> "id in model", sorted per "group id in JOSN"
     * @protected
     * @param {Type_JSON} json_object
     * @param {Type_JSON} [kwargs]
     * @memberof ClassTemplate_LinkElement
     */
    _fromJSON(json_object, kwargs) {
        // Root attributes
        super._fromJSON(json_object);
        // Matching names if needed
        const matching_taggs_id = (kwargs && kwargs['matching_taggs_id']) ? kwargs['matching_taggs_id'] : {};
        const matching_tags_id = (kwargs && kwargs['matching_tags_id']) ? kwargs['matching_tags_id'] : {};
        // Get style & local attributes
        const style_id = getStringFromJSON(json_object, 'style', default_style_id);
        this._display.style = this.sankey.link_styles_dict[style_id];
        const json_local_object = getJSONOrUndefinedFromJSON(json_object, 'local');
        if (json_local_object) {
            this._display.attributes.fromJSON(json_local_object);
            // If local attribute have key local_scale then update local scale domain
            if ('local_link_scale' in this._display.attributes)
                this.setDomainLocalScale(this._display.attributes.local_link_scale);
        }
        // Get positions infos
        this._display.displaying_order = getNumberFromJSON(json_object, 'displaying_order', this._display.displaying_order);
        this._display.position_starting.x = getNumberFromJSON(json_object, 'position_starting_x', this._display.position_starting.x);
        this._display.position_starting.y = getNumberFromJSON(json_object, 'position_starting_y', this._display.position_starting.y);
        this._display.position_ending.x = getNumberFromJSON(json_object, 'position_ending_x', this._display.position_starting.x);
        this._display.position_ending.y = getNumberFromJSON(json_object, 'position_ending_y', this._display.position_starting.y);
        this._display.position_offset_value = getNumberOrUndefinedFromJSON(json_object, 'position_offset_label');
        this._display.position_offset_name = getNumberOrUndefinedFromJSON(json_object, 'position_offset_name');
        this._display.position_x_value = getNumberOrUndefinedFromJSON(json_object, 'position_x_label');
        this._display.position_y_value = getNumberOrUndefinedFromJSON(json_object, 'position_y_label');
        this._display.position_x_name = getNumberOrUndefinedFromJSON(json_object, 'position_x_name');
        this._display.position_y_name = getNumberOrUndefinedFromJSON(json_object, 'position_y_name');
        // Get value
        this._values.fromJSON(getJSONFromJSON(json_object, 'value', {}), matching_taggs_id, matching_tags_id);
    }
    // PUBLIC METHODS =====================================================================
    unDraw() {
        super.unDraw();
        this._arrow_shape = undefined; // reset shape also
    }
    drawPath() {
        this._process_or_bypass(() => { this._drawPath(); this._orderD3Elements(); });
    }
    drawArrow() {
        this._process_or_bypass(() => { this._drawArrow(); this._orderD3Elements(); });
    }
    drawValue() {
        this._process_or_bypass(() => { this._drawValue(); this._orderD3Elements(); });
    }
    drawLabel() {
        this._process_or_bypass(() => {
            this._drawLabel();
            this._orderD3Elements();
        });
    }
    drawWithNodes() {
        if (this.source && this.target) {
            this.source.draw();
            this.target.draw();
        }
    }
    drawAsSelected() {
        this.drawControlPoint();
    }
    drawElements() {
        this._process_or_bypass(() => this._drawElements());
    }
    /**
     * Reset all attributes as defined by style
     * @memberof ClassTemplate_LinkElement
     */
    resetAttributes() {
        this._display.attributes = new Class_LinkAttribute();
        // Need to redraw from nodes
        this.drawWithNodes();
    }
    /**
     * Reverse source with target
     * @memberof ClassTemplate_LinkElement
     */
    inverse() {
        // Save prev source & target + remove link/nodes relationships
        const tmp_target = this._target;
        tmp_target.removeInputLink(this); // remove link from curr IO dict
        const tmp_source = this._source;
        tmp_source.removeOutputLink(this); // remove link from curr IO dict
        // Set source & target attributes
        this._source = tmp_target;
        this._target = tmp_source;
        // Set to recompute visibility from nodes after
        this._are_source_and_target_displayed = undefined;
        // add link to corresponding IO
        this._source.addOutputLink(this);
        this._target.addInputLink(this);
        // Draw
        this.drawElements();
    }
    increaseDisplayOrder() {
        this._display.displaying_order = this._display.displaying_order + 3;
        this.draw();
    }
    decreaseDisplayOrder() {
        this._display.displaying_order = this._display.displaying_order - 3;
        this.draw();
    }
    setTopDisplayOrder() {
        this._display.displaying_order = this._display.drawing_area.addElement();
        this.draw();
    }
    setDownDisplayOrder() {
        this._display.displaying_order = -1;
        this.draw();
    }
    deleteDraggedValuePos() {
        delete this._display.position_x_value;
        delete this._display.position_y_value;
        delete this._display.position_offset_value;
    }
    deleteDraggedLabelPos() {
        delete this._display.position_x_name;
        delete this._display.position_y_name;
        delete this._display.position_offset_name;
    }
    /**
     * Check if given tag is referenced by link's data
     * @param {Class_Tag} tag
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    hasGivenTag(tag) {
        const value = this.value;
        if (value)
            return value.hasGivenTag(tag);
        return false;
    }
    tagsUpdated() {
        this._are_related_flux_tags_selected = undefined;
    }
    /**
     * Add and cross-reference a Tag with a link
     * @param {Class_Tag} tag
     * @memberof ClassTemplate_LinkElement
     */
    addTag(tag) {
        const value = this.value;
        if (value) {
            value.addTag(tag);
            // Set to recompute visibility from tags after
            this.tagsUpdated();
        }
    }
    /**
     * Remove given tag and cross-reference from link
     * @param {Class_Tag} tag
     * @memberof ClassTemplate_LinkElement
     */
    removeTag(tag) {
        const value = this.value;
        if (value) {
            value.removeTag(tag);
            // Set to recompute visibility from tags after
            this.tagsUpdated();
        }
    }
    addDataTagGroup(tagg) {
        // Expand values tree
        this._values = this._values.expand(tagg);
        // Set to recompute visibility from tags after -> new data tagg = new values = new flux tags
        this.tagsUpdated();
    }
    removeDataTagGroup(tagg) {
        if (this._values instanceof Class_LinkValueTree) {
            // Prune values tree
            this._values = this._values.prune(tagg);
            // Set to recompute visibility from tags after -> less data tagg = differents values = different flux tags
            this.tagsUpdated();
        }
    }
    addDataTag(tag) {
        if (this._values instanceof Class_LinkValueTree) {
            // Extend current value tree branch
            this._values.extend(tag);
            // Set to recompute visibility from tags after -> new data tag = new value = new flux tags
            this.tagsUpdated();
        }
    }
    removeDataTag(tag) {
        if (this._values instanceof Class_LinkValueTree) {
            // reduce current value tree branch
            this._values.reduce(tag);
            // Set to recompute visibility from tags after -> less data tag = differente value = different flux tags
            this.tagsUpdated();
        }
    }
    useDefaultStyle() {
        this.style = this.sankey.default_link_style;
        this.drawElements();
    }
    isAttributeOverloaded(attr) {
        return this._display.attributes[attr] !== undefined;
    }
    isEqual(_) {
        if (this.shape_orientation !== _.shape_orientation) {
            return false;
        }
        if (this.shape_starting_curve !== _.shape_starting_curve) {
            return false;
        }
        if (this.shape_ending_curve !== _.shape_ending_curve) {
            return false;
        }
        if (this.shape_curvature !== _.shape_curvature) {
            return false;
        }
        if (this.shape_is_curved !== _.shape_is_curved) {
            return false;
        }
        if (this.shape_is_recycling !== _.shape_is_recycling) {
            return false;
        }
        if (this.shape_arrow_size !== _.shape_arrow_size) {
            return false;
        }
        if (this.value_label_horiz !== _.value_label_horiz) {
            return false;
        }
        if (this.value_label_vert !== _.value_label_vert) {
            return false;
        }
        if (this.value_label_on_path !== _.value_label_on_path) {
            return false;
        }
        if (this.value_label_pos_auto !== _.value_label_pos_auto) {
            return false;
        }
        if (this.shape_is_arrow !== _.shape_is_arrow) {
            return false;
        }
        if (this.shape_color !== _.shape_color) {
            return false;
        }
        if (this.shape_opacity !== _.shape_opacity) {
            return false;
        }
        if (this.shape_is_dashed !== _.shape_is_dashed) {
            return false;
        }
        if (this.value_label_is_visible !== _.value_label_is_visible) {
            return false;
        }
        if (this.value_label_font_size !== _.value_label_font_size) {
            return false;
        }
        if (this.value_label_color !== _.value_label_color) {
            return false;
        }
        if (this.value_label_custom_digit !== _.value_label_custom_digit) {
            return false;
        }
        if (this.value_label_nb_digit !== _.value_label_nb_digit) {
            return false;
        }
        if (this.value_label_significant_digits !== _.value_label_significant_digits) {
            return false;
        }
        if (this.value_label_nb_significant_digits !== _.value_label_nb_significant_digits) {
            return false;
        }
        if (this.value_label_scientific_notation !== _.value_label_scientific_notation) {
            return false;
        }
        if (this.value_label_font_family !== _.value_label_font_family) {
            return false;
        }
        if (this.value_label_unit_visible !== _.value_label_unit_visible) {
            return false;
        }
        if (this.value_label_unit !== _.value_label_unit) {
            return false;
        }
        return true;
    }
    getPathColorToUse() {
        // TODO revoir : la couleur d'un flux devrait aussi être definie par la couleur de ses noeuds sources / target
        // Default color
        let shape_color = this.shape_color;
        const dataTagColorActivated = this.sankey.selected_data_tags_list.filter(tag => tag.group.show_legend);
        // Do we apply color of flux tags ?
        const flux_taggs_activated = this.flux_taggs_list
            .filter(tagg => tagg.show_legend);
        if (flux_taggs_activated.length > 0) {
            const tagg_for_colormap = flux_taggs_activated[0];
            const tags_for_colormap = this.flux_tags_list
                .filter(tag => (tag.group === tagg_for_colormap))
                .filter(tag => tag.is_selected);
            if (tags_for_colormap.length > 0)
                shape_color = tags_for_colormap[0].color;
        }
        else if (dataTagColorActivated.length > 0) {
            // Do we apply colors of data tags ?
            dataTagColorActivated
                .forEach(tag => shape_color = tag.color);
        }
        else {
            const src_taggs_activated = this._source.taggs_list
                .filter(tagg => tagg.show_legend).filter(grp => {
                return this._source.grouped_taggs_dict[grp.id].filter(tag => tag.is_selected).length > 0;
            }).length > 0;
            const trgt_taggs_activated = this._target.taggs_list
                .filter(tagg => tagg.show_legend).filter(grp => {
                var _a;
                return ((_a = this._target.grouped_taggs_dict[grp.id]) !== null && _a !== void 0 ? _a : []).filter(tag => tag.is_selected).length > 0;
            }).length > 0;
            // Do we apply colors of node source/target tags ?
            const tagg_activated = this.sankey.node_taggs_list
                .filter(tagg => tagg.show_legend);
            const trgt_node_type = this._target.grouped_taggs_dict['type de noeud'];
            const src_node_type = this._source.grouped_taggs_dict['type de noeud'];
            // The first common tag is used to défine the color. The code after would take the first tag
            // of one of the two nodes source or target which is not the same as the first of the common tag
            if (tagg_activated.length > 0 && this._source.grouped_taggs_dict[tagg_activated[0].id]) {
                const group_id = tagg_activated[0].id;
                const common_tags = this._source.grouped_taggs_dict[group_id].filter(t => this._target.grouped_taggs_dict[group_id] && this._target.grouped_taggs_dict[group_id].includes(t));
                if (common_tags.length > 0) {
                    shape_color = common_tags[0].color;
                    return shape_color;
                }
            }
            // If we apply color from tag then take by prio : src/product > trgt/product > src > trgt
            if (src_node_type && src_node_type.filter(tag => tag.name == 'produit').length == 1 && src_taggs_activated) {
                shape_color = this._source.getShapeColorToUse();
            }
            else if (trgt_node_type && trgt_node_type.filter(tag => tag.name == 'produit').length == 1 && trgt_taggs_activated) {
                shape_color = this._target.getShapeColorToUse();
            }
            else {
                if (trgt_taggs_activated) {
                    // If target has a tag from a group of which we display the palette
                    shape_color = this._target.getShapeColorToUse();
                }
                else if (src_taggs_activated) {
                    // If source has a tag from a group of which we display the palette
                    shape_color = this._source.getShapeColorToUse();
                }
            }
        }
        return shape_color;
    }
    getArrowColorToUse() {
        return this.getPathColorToUse();
    }
    /**
     * Return maximum value possible for this link
     *
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    getMaxValue() {
        return this._values.getMaxValue();
    }
    getAllValues() {
        return this._values.getAllValues();
    }
    setDomainLocalScale(_) {
        if (_ !== undefined) {
            this._scaleValueToPx.domain([0, _]);
        }
    }
    /**
     * Compute position of these points :
     * - Starting tangeant first & second point
     * - Ending tangeant first & second point
     * @memberof ClassTemplate_LinkElement
     */
    computeControlPoints() {
        this.computeStartingCurvePoint();
        this.computeEndingCurvePoint();
        this.computeStartingBezierPoint();
        this.computeEndingBezierPoint();
        if (this.shape_is_recycling)
            this.computeMiddleRecyclingPoint();
    }
    // PROTECTED METHODS ==================================================================
    /**
     * Set up element on d3 svg area
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    _draw() {
        // Heritance
        super._draw();
        // Get starting point
        const starting_point = this.source.getOutputLinkStartingPoint(this);
        if (starting_point) {
            this._display.position_starting.x = starting_point.x;
            this._display.position_starting.y = starting_point.y;
        }
        // Get ending point
        const ending_point = this.target.getInputLinkEndingPoint(this);
        if (ending_point) {
            this._display.position_ending.x = ending_point.x;
            this._display.position_ending.y = ending_point.y;
        }
        // Draw only if we have starting & ending points
        if (starting_point && ending_point) {
            // Setup order
            this.drawing_area.orderElements();
            // Draw elements
            this._drawElements();
        }
    }
    _initDraw() {
        var _a;
        super._initDraw();
        // Update class attributes
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.attr('class', 'gg_links').datum(this);
    }
    /**
     * Draw link shape on d3 svg
     * @protected
     * @memberof ClassTemplate_LinkElement
     */
    _drawPath() {
        var _a, _b, _c, _d, _e, _f, _g;
        // Speed-up computing
        if (!this.d3_selection)
            return;
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_path').remove();
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.selectAll('.link_shape').remove();
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.selectAll('.link_shape').remove();
        // Failsafe
        if (this._source && this._target) {
            // Avoid recomputations
            const thickness = this.thickness;
            const shape_color = this.getPathColorToUse();
            const shape_opacity = this.shape_opacity;
            // Check to choose how to draw
            const show_as_dash = this.shape_is_dashed || this.data_value == null || this.shape_is_structure;
            const x0 = this.position_x_start;
            const y0 = this.position_y_start;
            const xf = this.position_x_end;
            const yf = this.position_y_end;
            const dist = Math.sqrt((xf - x0) * (xf - x0) + (yf - y0) * (yf - y0));
            const show_as_path = show_as_dash || ((dist / thickness) > 2) || this.shape_is_recycling;
            // Add new path
            (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.append('path').classed('link', true).classed('link_path', true).attr('d', () => this.getBezierPath());
            // Apply properties
            (_e = this.d3_selection) === null || _e === void 0 ? void 0 : _e.selectAll('.link_path').attr('id', this.id).attr('fill', 'none').attr('stroke', show_as_path ? shape_color : 'none').attr('stroke-opacity', show_as_path ? shape_opacity : '0').attr('stroke-width', show_as_path ? thickness : '0').attr('stroke-dasharray', show_as_dash ? '10,2' : '');
            // Show as full shape for specific shapes
            if (!show_as_path) {
                (_f = this.d3_selection) === null || _f === void 0 ? void 0 : _f.append('path').classed('link', true).classed('link_shape', true).attr('d', () => this.getBezierShape());
                // Apply properties
                (_g = this.d3_selection) === null || _g === void 0 ? void 0 : _g.selectAll('.link_shape').attr('id', this.id + '_shape').attr('fill', shape_color).attr('opacity', shape_opacity).attr('stroke', 'none').attr('stroke-opacity', '0').attr('stroke-width', '0');
            }
        }
    }
    /**
     * Draw arrow shape on d3
     * @protected
     * @memberof ClassTemplate_LinkElement
     */
    _drawArrow() {
        var _a, _b;
        // Speed-up computing
        if (!this.d3_selection)
            return;
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_arrow').remove();
        // draw arrow if needed
        if (this.shape_is_arrow && this.is_visible) {
            if (this._arrow_shape === undefined) {
                this.target.drawLinksArrow();
            }
            else {
                const arrow_color = this.getArrowColorToUse(); // Avoid recomputing
                (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('path').attr('class', 'link_arrow').attr('d', this._arrow_shape).attr('fill', arrow_color).attr('fill-opacity', this.shape_opacity).attr('stroke', arrow_color).attr('stroke-width', 0.1);
            }
        }
    }
    /**
     * Draw link label on d3 svg
     * @protected
     * @memberof ClassTemplate_LinkElement
     */
    _drawValue() {
        var _a, _b;
        // Speed-up computing
        if (!this.d3_selection)
            return;
        // Clean previous label
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_value').remove();
        // Add value label
        const link_val = this.data_value;
        // =======================DRAW VALUE LABEL ============================
        if ((this.drawing_area.show_structure !== 'structure') &&
            (this.value_label_is_visible) &&
            ((link_val !== null && link_val !== void 0 ? link_val : 0) >= this.drawing_area.filter_label)) {
            // Failsafe
            if (this._source && this._target) {
                // Compute label to display
                let label_to_display = link_val;
                // If label is undefined or null, do nothing
                if (label_to_display) {
                    // Create text object
                    const d3_text_selection = (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('text').classed('link', true).classed('link_value', true).classed('link_value_text', true).attr('id', 'value_text_' + this.id);
                    d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.style('font-size', String(this.value_label_font_size) + 'px').style('font-family', this.value_label_font_family).attr('fill', this.value_label_color).attr('font-weight', this.value_label_bold ? 'bold' : 'normal').attr('font-style', this.value_label_italic ? 'italic' : 'normal').style('text-transform', this.value_label_uppercase ? 'uppercase' : 'none');
                    // Compute text position
                    if (this.value_label_on_path) {
                        // Create text on path
                        const d3_textpath_selection = d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.append('textPath').classed('link', true).classed('link_value', true).classed('link_value_textpath', true).attr('id', 'value_textpath_' + this.id).attr('href', '#' + this.id).attr('side', this.getTextPathSide());
                        // Add text directly on textpath object
                        d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.text(this.data_label).attr('spacing', 'exact').attr('method', 'align');
                        // Add styling text attributes directly on text object
                        // Relative position from starting point of path
                        this.updateValueTextPathOffset();
                        if (!this.drawing_area.static) {
                            d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragValuePathStart(ev))
                                .on('drag', ev => this.dragValuePathMove(ev))
                                .on('end', ev => this.dragValuePathEnd(ev)));
                        }
                    }
                    else {
                        this.updateValueXYPosition();
                        d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.text(label_to_display).attr('spacing', 'exact').attr('method', 'align');
                        if (!this.drawing_area.static) {
                            d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragValueStart(ev))
                                .on('drag', ev => this.dragValueMove(ev))
                                .on('end', ev => this.dragValueEnd(ev)));
                        }
                    }
                }
            }
        }
    }
    _drawLabel() {
        var _a, _b;
        // Speed-up computing
        if (!this.d3_selection)
            return;
        // Clean previous label
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_label').remove();
        const link_text = this.text_value;
        // =======================DRAW TEXT LABEL ============================
        if ((this.drawing_area.show_structure !== 'structure') &&
            (this.name_label_is_visible) &&
            ((link_text !== null && link_text !== void 0 ? link_text : '') !== '')) {
            if (this._source && this._target) {
                // Compute label to display
                const label_to_display = link_text;
                // If label is undefined or null, do nothing
                if (label_to_display) {
                    // Create text object
                    const d3_text_selection = (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('text').classed('link', true).classed('link_label', true).classed('link_label_text', true).attr('id', 'label_text_' + this.id);
                    d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.style('font-size', String(this.name_label_font_size) + 'px').style('font-family', this.name_label_font_family).attr('fill', this.name_label_color).attr('font-weight', this.name_label_bold ? 'bold' : 'normal').attr('font-style', this.name_label_italic ? 'italic' : 'normal').style('text-transform', this.name_label_uppercase ? 'uppercase' : 'none');
                    // Compute text position
                    if (this.name_label_on_path) {
                        // Create text on path
                        const d3_textpath_selection = d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.append('textPath').classed('link', true).classed('link_label', true).classed('link_label_textpath', true).attr('id', 'label_textpath_' + this.id).attr('href', '#' + this.id).attr('side', this.getTextPathSide());
                        // Add text directly on textpath object
                        d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.text(label_to_display).attr('spacing', 'exact').attr('method', 'align');
                        // Add styling text attributes directly on text object
                        // Relative position from starting point of path
                        this.updateLabelTextPathOffset();
                        if (!this.drawing_area.static) {
                            d3_textpath_selection === null || d3_textpath_selection === void 0 ? void 0 : d3_textpath_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragTextPathStart(ev))
                                .on('drag', ev => this.dragTextPathMove(ev))
                                .on('end', ev => this.dragTextPathEnd(ev)));
                        }
                    }
                    else {
                        this.updateTextXYPosition();
                        d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.text(label_to_display).attr('spacing', 'exact').attr('method', 'align');
                        if (!this.drawing_area.static) {
                            d3_text_selection === null || d3_text_selection === void 0 ? void 0 : d3_text_selection.call(d3.drag()
                                .filter(evt => (evt.which == 1) && this.drawing_area.isInSelectionMode()) // only trigger drag when LMB drag & DA is in mode selection
                                .on('start', ev => this.dragTextStart(ev))
                                .on('drag', ev => this.dragTextMove(ev))
                                .on('end', ev => this.dragTextEnd(ev)));
                        }
                    }
                }
            }
        }
    }
    /**
     * Draw all d3 elements on link d3 selection
     * @protected
     * @memberof ClassTemplate_LinkElement
     */
    _drawElements() {
        this._drawPath();
        this._drawArrow();
        this._drawValue();
        this._drawLabel();
    }
    /**
     * Put d3 elements in correct display order
     * @protected
     * @memberof ClassTemplate_NodeElement
     */
    _orderD3Elements() {
        var _a, _b, _c, _d, _e;
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_shape').raise();
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.selectAll('.link_path').raise();
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.selectAll('.link_arrow').raise();
        (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.selectAll('.link_label').raise();
        (_e = this.d3_selection) === null || _e === void 0 ? void 0 : _e.selectAll('.link_value').raise();
    }
    /**
     * Deal with simple left Mouse Button (LMB) click on given element
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof Class_Link
     */
    eventSimpleLMBCLick(event) {
        // Apply parent behavior first
        super.eventSimpleLMBCLick(event);
        // Get related drawing area
        const drawing_area = this.drawing_area;
        // EDITION MODE ===========================================================
        if (drawing_area.isInEditionMode()) {
            // Purge selection list
            drawing_area.purgeSelection();
            // Close all menus
            drawing_area.closeAllMenus();
        }
        // SELECTION MODE =========================================================
        else if (drawing_area.isInSelectionMode()) {
            // SHIFT
            if (event.shiftKey) {
                this.addOrRemoveLinkFromSelection();
                // Open related menu
                this.menu_config.openConfigMenuElementsLinks();
                // Update components related to link edition
                this.menu_config.updateAllComponentsRelatedToLinks();
            }
            // CTRL
            else if (event.ctrlKey) {
                this.addOrRemoveLinkFromSelection();
                // Update components related to link edition
                this.menu_config.updateAllComponentsRelatedToLinks();
            }
            // OTHERS
            else {
                // If we're here then it's a simple click (no ctrl,alt or shift key pressed) - purge
                // Purge selection list
                drawing_area.purgeSelection();
                // Add link to selection
                drawing_area.addLinkToSelection(this);
            }
        }
    }
    eventSimpleRMBCLick(event) {
        // Apply parent behavior first
        super.eventSimpleRMBCLick(event);
        // SELECTION MODE =========================================================
        if (this.drawing_area.isInSelectionMode()) {
            event.preventDefault();
            this.drawing_area.pointer_pos = [event.pageX, event.pageY];
            if (!this.drawing_area.selected_links_list.includes(this)) {
                this.drawing_area.addLinkToSelection(this);
            }
            this.menu_config.updateAllComponentsRelatedToLinks();
            this.drawing_area.link_contextualised = this;
            this.menu_config.ref_to_menu_context_links_updater.current();
        }
    }
    addOrRemoveLinkFromSelection() {
        if (this.drawing_area.selected_links_list.includes(this)) {
            // Remove link from selection
            this.drawing_area.removeLinkFromSelection(this);
        }
        else {
            // Add link to selection
            this.drawing_area.addLinkToSelection(this);
        }
    }
    /**
     * Define event when mouse moves over element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseOver(event) {
        var _a, _b;
        // Apply parent behavior first
        super.eventMouseOver(event);
        // ALT
        if (event.altKey) {
            // Show tooltip
            this.drawTooltip();
            (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.classed('tooltip_shown', true);
        }
        else if (this.thickness < 15) {
            this._artifical_enlargement = true;
            // Artificially enlarge link thickness if too thin
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_path').attr('stroke-width', 15);
        }
    }
    /**
   * Define event when mouse moves in the element
   *
   * @protected
   * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
   * @memberof ClassTemplate_LinkElement
   */
    eventMouseMove(event) {
        super.eventMouseMove(event);
        if (event.altKey) {
            this.moveTooltip(event);
        }
    }
    /**
     * Define event when mouse move out of element
     * @protected
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_Element
     */
    eventMouseOut(event) {
        var _a, _b;
        super.eventMouseOut(event);
        // Clear tooltip
        d3.selectAll('.sankey-tooltip').remove();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.classed('tooltip_shown', false);
        // reset link thickness
        if (this._artifical_enlargement) {
            this._artifical_enlargement = false;
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_path').attr('stroke-width', this.thickness);
        }
    }
    scaleValueToPx(_) {
        if (this.local_link_scale !== undefined) {
            return this._scaleValueToPx(_);
        }
        else {
            return this.drawing_area.scaleValueToPx(_);
        }
    }
    // PRIVATE METHODS ====================================================================
    //================= Functions for link label if it is a TextPath  =================
    /**
     * Function used to set link label offset on DA & other attribute linkd to it
     *
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    updateValueTextPathOffset() {
        var _a, _b, _c, _d;
        const [label_position, label_anchor, label_ortho_position, label_dominant_baseline] = this.getValueTextPathOffset();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_value_textpath').attr('text-anchor', label_anchor);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_value_textpath').attr('startOffset', label_position + '%');
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_value_text').attr('dy', label_ortho_position);
        (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.select('.link_value_textpath').attr('dominant-baseline', label_dominant_baseline);
    }
    /**
   * Function used to set link label offset on DA & other attribute linkd to it
   *
   * @private
   * @memberof ClassTemplate_LinkElement
   */
    updateLabelTextPathOffset() {
        var _a, _b, _c, _d;
        const [label_position, label_anchor, label_ortho_position, label_dominant_baseline] = this.getLabelTextPathOffset();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_label_textpath').attr('text-anchor', label_anchor);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_label_textpath').attr('startOffset', label_position + '%');
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_label_text').attr('dy', label_ortho_position);
        (_d = this.d3_selection) === null || _d === void 0 ? void 0 : _d.select('.link_label_textpath').attr('dominant-baseline', label_dominant_baseline);
    }
    /**
     * Function used to return link value offset on DA & other attribute linkd to it
     *
     * @private
     * @return {*}  {[number, string, number, string]}
     * @memberof ClassTemplate_LinkElement
     */
    getValueTextPathOffset() {
        // Initialize value as if it link attributes were :
        // - value_label_horiz : 'start'
        // - value_label_vert : 'above'
        // Offset positions
        let label_anchor = 'start';
        let label_position = 1;
        // Ortogonal position from path
        let label_ortho_position = 0;
        let label_dominant_baseline = 'text-after-edge';
        if (this._display.position_offset_value !== undefined) {
            const offset = this._display.position_offset_value;
            label_position = offset;
        }
        else {
            // offset attributes
            if (this.value_label_horiz === 'middle') {
                label_anchor = 'middle';
                label_position = 50;
            }
            else if (this.value_label_horiz === 'right') {
                label_anchor = 'end';
                label_position = 99;
            }
        }
        if (this.value_label_vert === 'top' || (this.value_label_pos_auto && this.value_label_font_size > this.thickness)) {
            label_ortho_position = -this.thickness / 2;
        }
        // orthogonal attributes
        else if (this.value_label_vert === 'middle') {
            label_ortho_position = 0;
            label_dominant_baseline = 'middle';
        }
        else if (this.value_label_vert === 'bottom') {
            label_ortho_position = this.thickness / 2 + this.value_label_font_size;
            label_dominant_baseline = 'text-top';
        }
        return [label_position, label_anchor, label_ortho_position, label_dominant_baseline];
    }
    /**
   * Function used to return link label offset on DA & other attribute linkd to it
   *
   * @private
   * @return {*}  {[number, string, number, string]}
   * @memberof ClassTemplate_LinkElement
   */
    getLabelTextPathOffset() {
        // Initialize value as if it link attributes were :
        // - name_label_horiz : 'start'
        // - name_label_vert : 'above'
        // Offset positions
        let label_anchor = 'start';
        let label_position = 1;
        // Ortogonal position from path
        let label_ortho_position = 0;
        let label_dominant_baseline = 'text-after-edge';
        if (this._display.position_offset_name !== undefined) {
            const offset = this._display.position_offset_name;
            label_position = offset;
        }
        else {
            // offset attributes
            if (this.name_label_horiz === 'middle') {
                label_anchor = 'middle';
                label_position = 50;
            }
            else if (this.name_label_horiz === 'right') {
                label_anchor = 'end';
                label_position = 99;
            }
        }
        if (this.name_label_vert === 'top' || (this.name_label_pos_auto && this.name_label_font_size > this.thickness)) {
            label_ortho_position = -this.thickness / 2;
        }
        // orthogonal attributes
        else if (this.name_label_vert === 'middle') {
            label_ortho_position = 0;
            label_dominant_baseline = 'middle';
        }
        else if (this.name_label_vert === 'bottom') {
            label_ortho_position = this.thickness / 2 + this.name_label_font_size;
            label_dominant_baseline = 'text-top';
        }
        return [label_position, label_anchor, label_ortho_position, label_dominant_baseline];
    }
    getTextPathSide() {
        if ((this.source.position_x > this.target.position_x)) {
            return 'right';
        }
        return 'left';
    }
    //================= Functions for link label if it is a simple text  =================
    /**
     * Set the position of the label of the link when it doesn't follow the path
     *
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    updateTextXYPosition() {
        var _a, _b, _c;
        const [label_pos, label_ortho_pos, label_anchor] = this.getTextXYPos();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_label_text').attr('y', label_ortho_pos);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_label_text').attr('x', label_pos);
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_label_text').attr('text-anchor', label_anchor);
    }
    updateValueXYPosition() {
        var _a, _b, _c;
        const [label_pos, label_ortho_pos, label_anchor] = this.getValueXYPos();
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.select('.link_value_text').attr('y', label_ortho_pos);
        (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.select('.link_value_text').attr('x', label_pos);
        (_c = this.d3_selection) === null || _c === void 0 ? void 0 : _c.select('.link_value_text').attr('text-anchor', label_anchor);
    }
    /**
     * Return position value of the link value label when it doesn't follow the link path,
     * return [pos_x,pos_y,text-anchor]
     *
     * @private
     * @return {*}  {[number, number, string]}
     * @memberof ClassTemplate_LinkElement
     */
    getValueXYPos() {
        // Initialize value as if it link attributes were :
        // - value_label_horiz : 'start'
        // - value_label_vert : 'above'
        let label_ortho_pos = this.position_y_start;
        let label_pos = this.position_x_start;
        let label_anchor = 'start';
        // The process of the y position of the label depend of the x position :
        // - if the label is at the start of the link path then we take position_y_start as the reference
        // - if the label is at the middle of the link path then we take the center point as the reference
        // - if the label is at the middle of the link path then we take the position_y_end as the reference
        if (this._display.position_x_value !== undefined) { //dragged
            label_pos = this._display.position_x_value;
        }
        else {
            if (this.value_label_horiz === 'middle') {
                label_anchor = 'middle';
                label_pos = (this._control_points.starting_bezier_point.position_x + this._control_points.ending_bezier_point.position_x) / 2;
                label_ortho_pos = (this._control_points.starting_bezier_point.position_y + this._control_points.ending_bezier_point.position_y) / 2;
            }
            else if (this.value_label_horiz === 'right') {
                label_anchor = 'end';
                label_pos = this.position_x_end;
                label_ortho_pos = this.position_y_end;
            }
        }
        if (this._display.position_y_value !== undefined) { //dragged
            label_ortho_pos = this._display.position_y_value;
        }
        else {
            // Then we apply a relative vertical shift depending of the value_label_vert
            if (this.value_label_vert === 'top' || (this.value_label_pos_auto && this.value_label_font_size > this.thickness)) {
                label_ortho_pos -= (this.value_label_font_size / 2) + this.thickness / 2;
            }
            else if (this.value_label_vert === 'middle') {
                label_ortho_pos += (this.value_label_font_size / 3);
            }
            else if (this.value_label_vert === 'bottom') {
                label_ortho_pos += this.value_label_font_size + this.thickness / 2;
            }
        }
        return [label_pos, label_ortho_pos, label_anchor];
    }
    /**
   * Return position value of the link name label when it doesn't follow the link path,
   * return [pos_x,pos_y,text-anchor]
   *
   * @private
   * @return {*}  {[number, number, string]}
   * @memberof ClassTemplate_LinkElement
   */
    getTextXYPos() {
        // Initialize value as if it link attributes were :
        // - name_label_horiz : 'start'
        // - name_label_vert : 'above'
        let label_ortho_pos = this.position_y_start;
        let label_pos = this.position_x_start;
        let label_anchor = 'start';
        // The process of the y position of the label depend of the x position :
        // - if the label is at the start of the link path then we take position_y_start as the reference
        // - if the label is at the middle of the link path then we take the center point as the reference
        // - if the label is at the middle of the link path then we take the position_y_end as the reference
        if (this._display.position_x_name !== undefined) { //dragged
            label_pos = this._display.position_x_name;
        }
        else {
            if (this.name_label_horiz === 'middle') {
                label_anchor = 'middle';
                label_pos = (this._control_points.starting_bezier_point.position_x + this._control_points.ending_bezier_point.position_x) / 2;
                label_ortho_pos = (this._control_points.starting_bezier_point.position_y + this._control_points.ending_bezier_point.position_y) / 2;
            }
            else if (this.name_label_horiz === 'right') {
                label_anchor = 'end';
                label_pos = this.position_x_end;
                label_ortho_pos = this.position_y_end;
            }
        }
        if (this._display.position_y_name !== undefined) { //dragged
            label_ortho_pos = this._display.position_y_name;
        }
        else {
            // Then we apply a relative vertical shift depending of the name_label_vert
            if (this.name_label_vert === 'top' || (this.name_label_pos_auto && this.name_label_font_size > this.thickness)) {
                label_ortho_pos -= (this.name_label_font_size / 2) + this.thickness / 2;
            }
            else if (this.name_label_vert === 'middle') {
                label_ortho_pos += (this.name_label_font_size / 3);
            }
            else if (this.name_label_vert === 'bottom') {
                label_ortho_pos += this.name_label_font_size + this.thickness / 2;
            }
        }
        return [label_pos, label_ortho_pos, label_anchor];
    }
    /**
     * Function triggered when we start dragging link value label, it initialise relative position if undefined
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextElement,unknown,unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragValueStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        const [label_pos, label_ortho_pos,] = this.getValueXYPos();
        if (this._display.position_x_value === undefined) {
            this._display.position_x_value = label_pos;
            this.value_label_horiz = 'dragged';
        }
        if (this._display.position_y_value === undefined) {
            this._display.position_y_value = label_ortho_pos;
            this.value_label_vert = 'dragged';
        }
    }
    /**
     * Function triggered when we move the link value label, it update relative node position & redraw the value label
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextElement,ClassTemplate_LinkElement,ClassTemplate_LinkElement>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragValueMove(event) {
        this._display.position_x_value = ((this._display.position_x_value !== undefined) ? this._display.position_x_value : 0) + event.dx;
        this._display.position_y_value = ((this._display.position_y_value !== undefined) ? this._display.position_y_value : 0) + event.dy;
        this.updateValueXYPosition();
    }
    dragValueEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    /**
   * Function triggered when we start dragging node name label, it initialise relative position if undefined
   *
   * @private
   * @param {d3.D3DragEvent<SVGTextElement,unknown,unknown>} event
   * @memberof ClassTemplate_LinkElement
   */
    dragTextStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        const [label_pos, label_ortho_pos,] = this.getTextXYPos();
        if (this._display.position_x_name === undefined) {
            this._display.position_x_name = label_pos;
            this.name_label_horiz = 'dragged';
        }
        if (this._display.position_y_name === undefined) {
            this._display.position_y_name = label_ortho_pos;
            this.name_label_vert = 'dragged';
        }
    }
    /**
     * Function triggered when we move the node name label, it update relative node position & redraw the name slabel
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextElement,ClassTemplate_LinkElement,ClassTemplate_LinkElement>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragTextMove(event) {
        this._display.position_x_name = ((this._display.position_x_name !== undefined) ? this._display.position_x_name : 0) + event.dx;
        this._display.position_y_name = ((this._display.position_y_name !== undefined) ? this._display.position_y_name : 0) + event.dy;
        this.updateTextXYPosition();
    }
    dragTextEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    /**
     * Display the tooltip on drawing area
     *
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    drawTooltip() {
        // Clean previous label
        d3.selectAll('.sankey-tooltip').remove();
        d3.select('body')
            .append('div')
            .attr('class', 'sankey-tooltip')
            .style('opacity', 1)
            .style('top', (this.source.position_y + this.target.position_y) / 2 + 'px')
            .style('left', (this.source.position_x + this.target.position_x) / 2 + 'px')
            .html(this.tooltip_html);
    }
    /**
     * Event when we move the mouse over the link and the tooltip is shown,
     * we simply move the tooltip to current cursor location
     *
     * @private
     * @param {React.MouseEvent<HTMLButtonElement, React.MouseEvent>} event
     * @memberof ClassTemplate_LinkElement
     */
    moveTooltip(event) {
        d3.selectAll('.sankey-tooltip')
            .style('top', event.pageY + 'px')
            .style('left', event.pageX + 'px');
    }
    drawControlPoint() {
        var _a, _b;
        // Speed-up computing
        if (!this.d3_selection)
            return;
        // Draw control handler
        this._control_points.starting_curve_point.draw();
        this._control_points.ending_curve_point.draw();
        this._control_points.starting_curve_point.draw();
        this._control_points.ending_curve_point.draw();
        //If the shape is curved set visible tangeant points else set them invissible
        if (this.shape_is_curved) {
            this._control_points.starting_bezier_point.setVisible();
            this._control_points.ending_bezier_point.setVisible();
        }
        else {
            this._control_points.starting_bezier_point.setInvisible();
            this._control_points.ending_bezier_point.setInvisible();
        }
        // Recyling handler
        if (this.shape_is_recycling)
            this._control_points.middle_recycling_point.setVisible();
        else
            this._control_points.middle_recycling_point.setInvisible();
        // Clean previous shape
        (_a = this.d3_selection) === null || _a === void 0 ? void 0 : _a.selectAll('.link_control_path').remove();
        if (this._control_points.is_dragged) {
            // Get control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x5 = this._control_points.ending_curve_point.position_x;
            const y5 = this._control_points.ending_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            const x4 = this._control_points.ending_bezier_point.position_x;
            const y4 = this._control_points.ending_bezier_point.position_y;
            // Compute path
            let path;
            // Normal mode
            if (!this.shape_is_recycling) {
                //If the shape is curved use tangeant points
                if (this.shape_is_curved) {
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
                }
                else {
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x5 + ',' + y5;
                }
            }
            else {
                const xmid = this._control_points.middle_recycling_point.position_x;
                const ymid = this._control_points.middle_recycling_point.position_y;
                if (this.is_horizontal)
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + x2 + ',' + ymid
                        + ' L ' + x4 + ',' + ymid
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
                else if (this.is_vertical)
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + xmid + ',' + y2
                        + ' L ' + xmid + ',' + y4
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
                else
                    path = 'M ' + x1 + ',' + y1
                        + ' L ' + x2 + ',' + y2
                        + ' L ' + xmid + ',' + ymid
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5;
            }
            (_b = this.d3_selection) === null || _b === void 0 ? void 0 : _b.append('path').classed('link', true).classed('link_control_path', true).attr('d', path).attr('fill', 'none').attr('stroke', 'red').attr('stroke-opacity', 0.75).attr('stroke-width', 1);
        }
    }
    /**
     * Return a svg path for link path drawing
     * @private
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    getBezierPath() {
        // Update control points
        this.computeControlPoints();
        // Normal mode
        if (!this.shape_is_recycling) {
            // Get starting and ending position per type of shape
            const x0 = this.position_x_start; // Shorter to write
            const y0 = this.position_y_start; // ...
            const x6 = this.position_x_end;
            const y6 = this.position_y_end;
            // Get control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            const x4 = this._control_points.ending_bezier_point.position_x;
            const y4 = this._control_points.ending_bezier_point.position_y;
            const x5 = this._control_points.ending_curve_point.position_x;
            const y5 = this._control_points.ending_curve_point.position_y;
            // Center point
            // let k = 1
            // if (Math.abs(x4 - x5) > 0) {
            //   k = Math.abs((x2 - x1)/(x4 - x5))
            //   k = k / (1 + k)
            // }
            // const x3 = x2 + k*(x4 - x2)
            // const y3 = y2 + k*(y4 - y2)
            const x3 = (x2 + x4) / 2;
            const y3 = (y2 + y4) / 2;
            // Return paths
            if (!this.shape_is_curved) {
                return 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' L ' + x5 + ',' + y5
                    + ' L ' + x6 + ',' + y6;
            }
            else {
                return 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' Q ' + x2 + ',' + y2 + ' ' + x3 + ',' + y3
                    + ' Q ' + x4 + ',' + y4 + ' ' + x5 + ',' + y5
                    + ' L ' + x6 + ',' + y6;
            }
        }
        // Recycling mode
        else {
            // Get starting and ending position per type of shape
            const x0 = this.position_x_start; // Shorter to write
            const y0 = this.position_y_start; // ...
            const xf = this.position_x_end;
            const yf = this.position_y_end;
            // Get middle point coordinates
            const x_mid = this._control_points.middle_recycling_point.position_x;
            const y_mid = this._control_points.middle_recycling_point.position_y;
            // Get starting control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            // First curve
            let x3, y3;
            let x4, y4;
            let x5, y5;
            if (this.is_horizontal) {
                x4 = x2;
                y4 = y_mid;
                x3 = x4;
                y3 = (y4 + y2) / 2;
                x5 = x1;
                y5 = y4;
            }
            else if (this.is_vertical) {
                x4 = x_mid;
                y4 = y2;
                x3 = (x4 + x2) / 2;
                y3 = y4;
                x5 = x4;
                y5 = y1;
            }
            else {
                x4 = x_mid;
                y4 = y_mid;
                x3 = (x4 + x2) / 2;
                y3 = (y4 + y2) / 2;
            }
            // Get ending control points coordinates
            const x9 = this._control_points.ending_bezier_point.position_x;
            const y9 = this._control_points.ending_bezier_point.position_y;
            const x10 = this._control_points.ending_curve_point.position_x;
            const y10 = this._control_points.ending_curve_point.position_y;
            // End curve
            let x6, y6;
            let x7, y7;
            let x8, y8;
            if (this.is_horizontal) {
                x7 = x9;
                y7 = y_mid;
                x8 = x9;
                y8 = (y7 + y9) / 2;
                x6 = x10;
                y6 = y7;
            }
            else if (this.is_vertical) {
                x7 = x_mid;
                y7 = y9;
                x8 = (x7 + x9) / 2;
                y8 = y7;
                x6 = x7;
                y6 = y10;
            }
            else {
                x7 = x_mid;
                y7 = y_mid;
                x8 = (x7 + x9) / 2;
                y8 = (y7 + y9) / 2;
            }
            // Return paths
            if (!this.shape_is_curved) {
                let path = 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' L ' + x2 + ',' + y2
                    + ' L ' + x3 + ',' + y3;
                if (this.is_vertical || this.is_horizontal)
                    path = path
                        + ' L ' + x4 + ',' + y4
                        + ' L ' + x5 + ',' + y5
                        + ' L ' + x5 + ',' + y5
                        + ' L ' + x6 + ',' + y6;
                path = path
                    + ' L ' + x7 + ',' + y7
                    + ' L ' + x8 + ',' + y8
                    + ' L ' + x9 + ',' + y9
                    + ' L ' + x10 + ',' + y10
                    + ' L ' + xf + ',' + yf;
                return path;
            }
            else {
                let path = 'M ' + x0 + ',' + y0
                    + ' L ' + x1 + ',' + y1
                    + ' Q ' + x2 + ',' + y2 + ' ' + x3 + ',' + y3;
                if (this.is_vertical || this.is_horizontal)
                    path = path
                        + ' Q ' + x4 + ',' + y4 + ' ' + x5 + ',' + y5
                        + ' L ' + x6 + ',' + y6;
                path = path
                    + ' Q ' + x7 + ',' + y7 + ' ' + x8 + ',' + y8
                    + ' Q ' + x9 + ',' + y9 + ' ' + x10 + ',' + y10
                    + ' L ' + xf + ',' + yf;
                return path;
            }
        }
    }
    /**
     * Return a svg path for link path drawing
     * @private
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    getBezierShape() {
        // Update control points
        this.computeControlPoints();
        // Normal mode
        if (!this.shape_is_recycling) {
            // Get starting and ending position per type of shape
            const x0 = this.position_x_start;
            const y0 = this.position_y_start;
            const x6 = this.position_x_end;
            const y6 = this.position_y_end;
            // Get control points coordinates
            const x1 = this._control_points.starting_curve_point.position_x;
            const y1 = this._control_points.starting_curve_point.position_y;
            const x2 = this._control_points.starting_bezier_point.position_x;
            const y2 = this._control_points.starting_bezier_point.position_y;
            const x4 = this._control_points.ending_bezier_point.position_x;
            const y4 = this._control_points.ending_bezier_point.position_y;
            const x5 = this._control_points.ending_curve_point.position_x;
            const y5 = this._control_points.ending_curve_point.position_y;
            // Coefs to help tranform path -> shape
            const half_thickness = this.thickness / 2;
            const dx = x5 - x1;
            const dy = y5 - y1;
            let ang;
            let v_axe, v_ortho;
            let dx_fwd, dy_fwd;
            // Clamping function
            function clamp(p, v, pmin, pmax) {
                const dmin = p - pmin;
                const dmax = p - pmax;
                const toward_min = (Math.sign(dmin) !== Math.sign(v));
                const toward_max = (Math.sign(dmax) !== Math.sign(v));
                const keep_above_min = (!toward_min) || ((toward_min) && (Math.abs(v) < Math.abs(dmin)));
                const keep_below_max = (!toward_max) || ((toward_max) && (Math.abs(v) < Math.abs(dmax)));
                if (keep_above_min && keep_below_max)
                    return p + v;
                else {
                    if ((toward_min) && (!keep_above_min)) {
                        return pmin;
                    }
                    if ((toward_max) && (!keep_below_max)) {
                        return pmax;
                    }
                    // Should never happen
                    return p;
                }
            }
            // Upper part of shape
            let x0_fwd, y0_fwd;
            let x1_fwd, y1_fwd;
            let x2_fwd, y2_fwd;
            let x4_fwd, y4_fwd;
            let x5_fwd, y5_fwd;
            let x6_fwd, y6_fwd;
            // First part of path
            if (this.is_horizontal || this.is_horizontal_vertical) {
                // Coefs to help tranform path -> shape
                ang = Math.atan2(dy, dx); // Mean angle of curve
                v_axe = -half_thickness * Math.tan(ang / 2); // Advance in curve axe to cross lines
                v_ortho = -half_thickness; // Orthogonal vector to v_axe
                if (dx < 0) { // Inverse vector for inversed direction
                    v_axe = -half_thickness * Math.tan((Math.PI - ang) / 2);
                    v_ortho = half_thickness;
                }
                dx_fwd = -v_ortho * Math.sin(ang) + v_axe * Math.cos(ang); // Centre displacement fwd
                dy_fwd = v_ortho * Math.cos(ang) + v_axe * Math.sin(ang); // ...
                // Starting point
                x0_fwd = x0;
                y0_fwd = y0 - half_thickness;
                // Bezier start
                x1_fwd = clamp(x1, dx_fwd, x0, x5);
                y1_fwd = y0_fwd;
                // Bezier first tangent dir
                x2_fwd = clamp(x2, dx_fwd, x1, x6);
                y2_fwd = y0_fwd;
            }
            else {
                // Coefs to help tranform path -> shape
                ang = Math.atan2(dy, dx); // Mean angle of curve
                if (dy > 0 && dx > 0) {
                    v_axe = half_thickness * Math.tan((Math.PI / 2 - ang) / 2); // Advance in curve axe to cross lines
                    v_ortho = -half_thickness; // Orthogonal vector to v_axe
                }
                else if (dy < 0 && dx > 0) {
                    v_axe = half_thickness * Math.tan(ang / 2);
                    v_ortho = -half_thickness;
                }
                else if (dy < 0 && dx < 0) {
                    v_axe = -half_thickness * Math.tan(ang / 2);
                    v_ortho = half_thickness;
                }
                else {
                    v_axe = -half_thickness * Math.tan((Math.PI / 2 - ang) / 2);
                    v_ortho = half_thickness;
                }
                dx_fwd = -v_ortho * Math.sin(ang) + v_axe * Math.cos(ang); // Centre displacement fwd
                dy_fwd = v_ortho * Math.cos(ang) + v_axe * Math.sin(ang); // ...
                // Starting point
                x0_fwd = x0 + Math.sign(dx) * Math.sign(dy) * half_thickness;
                y0_fwd = y0;
                // Bezier start
                x1_fwd = x0_fwd;
                y1_fwd = clamp(y1, dy_fwd, y0, y5);
                // Bezier first tangent dir
                x2_fwd = x0_fwd;
                y2_fwd = clamp(y2, dy_fwd, y1, y6);
            }
            // Second part of path
            if (this.is_horizontal || this.is_vertical_horizontal) {
                // Coefs to help tranform path -> shape
                ang = Math.atan2(dy, dx); // Mean angle of curve
                v_axe = -half_thickness * Math.tan(ang / 2); // Advance in curve axe to cross lines
                v_ortho = -half_thickness; // Orthogonal vector to v_axe
                if (dx < 0) { // Inverse vector for inversed direction
                    v_axe = -half_thickness * Math.tan((Math.PI - ang) / 2);
                    v_ortho = half_thickness;
                }
                dx_fwd = -v_ortho * Math.sin(ang) + v_axe * Math.cos(ang); // Centre displacement fwd
                dy_fwd = v_ortho * Math.cos(ang) + v_axe * Math.sin(ang); // ...
                // End point
                x6_fwd = x6;
                y6_fwd = y6 - half_thickness;
                // Bezier end
                x5_fwd = clamp(x5, dx_fwd, x1, x6);
                y5_fwd = y6_fwd;
                // Bezier second tangent dir
                x4_fwd = clamp(x4, dx_fwd, x0, x5);
                y4_fwd = y6_fwd;
            }
            else {
                // Coefs to help tranform path -> shape
                ang = Math.atan2(dy, dx); // Mean angle of curve
                if (dy > 0 && dx > 0) {
                    v_axe = half_thickness * Math.tan((Math.PI / 2 - ang) / 2); // Advance in curve axe to cross lines
                    v_ortho = -half_thickness; // Orthogonal vector to v_axe
                }
                else if (dy < 0 && dx > 0) {
                    v_axe = half_thickness * Math.tan(ang / 2);
                    v_ortho = -half_thickness;
                }
                else if (dy < 0 && dx < 0) {
                    v_axe = -half_thickness * Math.tan(ang / 2);
                    v_ortho = half_thickness;
                }
                else {
                    v_axe = -half_thickness * Math.tan((Math.PI / 2 - ang) / 2);
                    v_ortho = half_thickness;
                }
                dx_fwd = -v_ortho * Math.sin(ang) + v_axe * Math.cos(ang); // Centre displacement fwd
                dy_fwd = v_ortho * Math.cos(ang) + v_axe * Math.sin(ang); // ...
                // End point
                x6_fwd = x6 + Math.sign(dx) * Math.sign(dy) * half_thickness;
                y6_fwd = y6;
                // Bezier end
                x5_fwd = x6_fwd;
                y5_fwd = clamp(y5, dy_fwd, y1, y6);
                // Bezier second tangent dir
                x4_fwd = x6_fwd;
                y4_fwd = clamp(y4, dy_fwd, y0, y5);
            }
            // Rotating function
            function rotx(p, pc) {
                const v = p - pc;
                return p - 2 * v;
            }
            function roty(p, pc) {
                const v = p - pc;
                return p - 2 * v;
            }
            // Lower part of shape
            let x0_bwd, y0_bwd;
            let x1_bwd, y1_bwd;
            let x2_bwd, y2_bwd;
            let x4_bwd, y4_bwd;
            let x5_bwd, y5_bwd;
            let x6_bwd, y6_bwd;
            if (this.is_horizontal || this.is_vertical) {
                const xcentre = (x0 + x6) / 2;
                const ycentre = (y0 + y6) / 2;
                x0_bwd = rotx(x0_fwd, xcentre);
                y0_bwd = roty(y0_fwd, ycentre);
                x1_bwd = rotx(x1_fwd, xcentre);
                y1_bwd = roty(y1_fwd, ycentre);
                x2_bwd = rotx(x2_fwd, xcentre);
                y2_bwd = roty(y2_fwd, ycentre);
                x6_bwd = rotx(x6_fwd, xcentre);
                y6_bwd = roty(y6_fwd, ycentre);
                x5_bwd = rotx(x5_fwd, xcentre);
                y5_bwd = roty(y5_fwd, ycentre);
                x4_bwd = rotx(x4_fwd, xcentre);
                y4_bwd = roty(y4_fwd, ycentre);
            }
            else {
                x0_bwd = rotx(x6_fwd, x6);
                y0_bwd = roty(y6_fwd, y6);
                x1_bwd = rotx(x5_fwd, x5);
                y1_bwd = roty(y5_fwd, y5);
                x2_bwd = rotx(x4_fwd, x4);
                y2_bwd = roty(y4_fwd, y4);
                x6_bwd = rotx(x0_fwd, x0);
                y6_bwd = roty(y0_fwd, y0);
                x5_bwd = rotx(x1_fwd, x1);
                y5_bwd = roty(y1_fwd, y1);
                x4_bwd = rotx(x2_fwd, x2);
                y4_bwd = roty(y2_fwd, y2);
            }
            // Center point
            const k_fwd = 0.5;
            // Experimental code - kept for memory
            // if (Math.abs(x4_fwd - x5_fwd) > 0) {
            //   k_fwd = Math.abs((x2_fwd - x1_fwd)/(x4_fwd - x5_fwd))
            //   k_fwd = k_fwd / (1 + k_fwd)
            // }
            const k_bwd = 0.5;
            // Experimental code - kept for memory
            // if (Math.abs(x4_bwd - x5_bwd) > 0) {
            //   k_bwd = Math.abs((x2_bwd - x1_bwd)/(x4_bwd - x5_bwd))
            //   k_bwd = k_bwd / (1 + k_bwd)
            // }
            const x3_fwd = x2_fwd + k_fwd * (x4_fwd - x2_fwd);
            const x3_bwd = x2_bwd + k_bwd * (x4_bwd - x2_bwd);
            const y3_fwd = y2_fwd + k_fwd * (y4_fwd - y2_fwd);
            const y3_bwd = y2_bwd + k_bwd * (y4_bwd - y2_bwd);
            // Return paths
            if (!this.shape_is_curved) {
                return 'M ' + x0_fwd + ',' + y0_fwd
                    + ' L ' + x1_fwd + ',' + y1_fwd
                    + ' L ' + x5_fwd + ',' + y5_fwd
                    + ' L ' + x6_fwd + ',' + y6_fwd
                    + ' L ' + x0_bwd + ',' + y0_bwd
                    + ' L ' + x1_bwd + ',' + y1_bwd
                    + ' L ' + x5_bwd + ',' + y5_bwd
                    + ' L ' + x6_bwd + ',' + y6_bwd;
            }
            else {
                return 'M ' + x0_fwd + ',' + y0_fwd
                    + ' L ' + x1_fwd + ',' + y1_fwd
                    + ' Q ' + x2_fwd + ',' + y2_fwd + ' ' + x3_fwd + ',' + y3_fwd
                    + ' Q ' + x4_fwd + ',' + y4_fwd + ' ' + x5_fwd + ',' + y5_fwd
                    + ' L ' + x6_fwd + ',' + y6_fwd
                    + ' L ' + x0_bwd + ',' + y0_bwd
                    + ' L ' + x1_bwd + ',' + y1_bwd
                    + ' Q ' + x2_bwd + ',' + y2_bwd + ' ' + x3_bwd + ',' + y3_bwd
                    + ' Q ' + x4_bwd + ',' + y4_bwd + ' ' + x5_bwd + ',' + y5_bwd
                    + ' L ' + x6_bwd + ',' + y6_bwd;
            }
        }
        else {
            return '';
        }
    }
    // =========== Method about control points ==============
    /**
     * Function used to update starting curve point position value
     *
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    computeStartingCurvePoint() {
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const x6 = this.position_x_end;
        const y6 = this.position_y_end;
        const starting_shift = this.shape_starting_curve;
        const horizontal_direction = Math.sign(x6 - x0); // +1 / -1
        const vertical_direction = Math.sign(y6 - y0); // +1 / -1
        let x1, y1;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x1 = x0 + horizontal_direction * Math.abs(this.position_x_start - this.position_x_end) * starting_shift;
                y1 = y0;
            }
            else {
                x1 = x0;
                y1 = y0 + vertical_direction * Math.abs(this.position_y_start - this.position_y_end) * starting_shift;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x1 = x0 - horizontal_direction * Math.abs(this.position_x_start - this.position_x_end) * starting_shift;
                y1 = y0;
            }
            else {
                x1 = x0;
                y1 = y0 - vertical_direction * Math.abs(this.position_y_start - this.position_y_end) * starting_shift;
            }
        }
        this._control_points.starting_curve_point.setPosXY(x1, y1);
    }
    /**
    * Function used to update ending curve point position value
    *
    * @private
    * @memberof ClassTemplate_LinkElement
    */
    computeEndingCurvePoint() {
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const x6 = this.position_x_end;
        const y6 = this.position_y_end;
        // Shifts
        const horizontal_direction = Math.sign(x6 - x0); // +1 / -1
        const vertical_direction = Math.sign(y6 - y0); // +1 / -1
        let x5, y5;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x5 = x6 - horizontal_direction * Math.abs(this.position_x_start - this.position_x_end) * this.shape_ending_curve;
                y5 = y6;
            }
            else {
                x5 = x6;
                y5 = y6 - vertical_direction * Math.abs(this.position_y_start - this.position_y_end) * this.shape_ending_curve;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x5 = x6 + horizontal_direction * Math.abs(this.position_x_start - this.position_x_end) * this.shape_ending_curve;
                y5 = y6;
            }
            else {
                x5 = x6;
                y5 = y6 + vertical_direction * Math.abs(this.position_y_start - this.position_y_end) * this.shape_ending_curve;
            }
        }
        this._control_points.ending_curve_point.setPosXY(x5, y5);
    }
    /**
    * Function used to update starting tangeant point position value
    *
    * @private
    * @memberof ClassTemplate_LinkElement
    */
    computeStartingBezierPoint() {
        const x1 = this._control_points.starting_curve_point.position_x;
        const y1 = this._control_points.starting_curve_point.position_y;
        const x5 = this._control_points.ending_curve_point.position_x;
        const y5 = this._control_points.ending_curve_point.position_y;
        let x2, y2;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x2 = x1 + (x5 - x1) * this.shape_starting_tangeant;
                y2 = y1;
            }
            else {
                x2 = x1;
                y2 = y1 + (y5 - y1) * this.shape_starting_tangeant;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                x2 = x1 - (x5 - x1) * this.shape_starting_tangeant;
                y2 = y1;
            }
            else {
                x2 = x1;
                y2 = y1 - (y5 - y1) * this.shape_starting_tangeant;
            }
        }
        this._control_points.starting_bezier_point.setPosXY(x2, y2);
    }
    /**
    * Function used to update ending tangeant point position value
    *
    * @private
    * @memberof ClassTemplate_LinkElement
    */
    computeEndingBezierPoint() {
        const x1 = this._control_points.starting_curve_point.position_x;
        const y1 = this._control_points.starting_curve_point.position_y;
        const x5 = this._control_points.ending_curve_point.position_x;
        const y5 = this._control_points.ending_curve_point.position_y;
        let x4, y4;
        // Normal mode
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x4 = x5 + (x1 - x5) * this.shape_ending_tangeant;
                y4 = y5;
            }
            else {
                x4 = x5;
                y4 = y5 + (y1 - y5) * this.shape_ending_tangeant;
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                x4 = x5 - (x1 - x5) * this.shape_ending_tangeant;
                y4 = y5;
            }
            else {
                x4 = x5;
                y4 = y5 - (y1 - y5) * this.shape_ending_tangeant;
            }
        }
        // Update point
        this._control_points.ending_bezier_point.setPosXY(x4, y4);
    }
    computeMiddleRecyclingPoint() {
        // Get starting & ending position
        const x0 = this.position_x_start; // Shorter to write
        const y0 = this.position_y_start; // ...
        const xf = this.position_x_end;
        const yf = this.position_y_end;
        // Compute ref points
        const x_ref = (x0 + xf) / 2;
        const y_ref = (y0 + yf) / 2;
        // Compute point
        let x_mid, y_mid;
        if (this.is_horizontal) {
            x_mid = x_ref;
            y_mid = y_ref + this.shape_middle_recycling;
        }
        else if (this.is_vertical) {
            x_mid = x_ref + this.shape_middle_recycling;
            y_mid = y_ref;
        }
        else {
            const vx = (xf - x0);
            const vy = (yf - y0);
            const vx_ortho = -vy;
            const vy_ortho = vx;
            const d = Math.sqrt(vx * vx + vy * vy);
            const scale_norm = this.shape_middle_recycling / Math.sqrt(2);
            x_mid = x_ref + scale_norm * (vx_ortho / d);
            y_mid = y_ref + scale_norm * (vy_ortho / d);
        }
        // Update point
        this._control_points.middle_recycling_point.setPosXY(x_mid, y_mid);
    }
    // =========== Method about drag event ==============
    /**
     * Activate the control points alignement guide
     *
     * @private
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    dragHandleStart() {
        return () => {
            this._control_points.is_dragged = true;
            // Save current attribute val before mutating them in dragHandlers events
            const ghost = {
                'shape_starting_curve': this.shape_starting_curve,
                'shape_ending_curve': this.shape_ending_curve,
                'shape_starting_tangeant': this.shape_starting_tangeant,
                'shape_ending_tangeant': this.shape_ending_tangeant,
            };
            // Save undo to reposition handler to save pos
            this.display.drawing_area.application_data.history.saveUndo(() => {
                this.shape_starting_curve = ghost['shape_starting_curve'];
                this.shape_ending_curve = ghost['shape_ending_curve'];
                this.shape_starting_tangeant = ghost['shape_starting_tangeant'];
                this.shape_ending_tangeant = ghost['shape_ending_tangeant'];
            });
        };
    }
    /**
     * Deactivate the control points alignement guide
     * @private
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    dragHandleEnd() {
        return () => {
            this._control_points.is_dragged = false;
            // Save current attribute val after mutating them in dragHandlers events
            const ghost = {
                'shape_starting_curve': this.shape_starting_curve,
                'shape_ending_curve': this.shape_ending_curve,
                'shape_starting_tangeant': this.shape_starting_tangeant,
                'shape_ending_tangeant': this.shape_ending_tangeant,
            };
            // Save redo to reposition handler to current pos
            this.display.drawing_area.application_data.history.saveRedo(() => {
                this._display.attributes.shape_starting_curve = ghost['shape_starting_curve'];
                this._display.attributes.shape_ending_curve = ghost['shape_ending_curve'];
                this._display.attributes.shape_starting_tangeant = ghost['shape_starting_tangeant'];
                this._display.attributes.shape_ending_tangeant = ghost['shape_ending_tangeant'];
            });
            this.drawControlPoint();
            this.menu_config.updateComponentRelatedToLinksApparence();
            this.drawing_area.checkAndUpdateAreaSize();
        };
    }
    /**
     * Function called when we drag the starting curve point, it update variable shape_starting_curve
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    startCurvePointDragEvent() {
        return (event) => {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.starting_curve_point.position_x + event.dx;
                const x0 = this.position_x_start;
                const x6 = this.position_x_end;
                // Compute starting curve point coef based on new handle pos
                const dx6x0 = Math.abs(x6 - x0);
                if (dx6x0 >= 0) // Avoid NaN
                    this.shape_starting_curve = Math.abs(handle_new_pos_x - x0) / dx6x0;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.starting_curve_point.position_y + event.dy;
                const y0 = this.position_y_start;
                const y6 = this.position_y_end;
                // Compute starting curve point coef based on new handle pos
                const dy6y0 = Math.abs(y6 - y0);
                if (dy6y0 >= 0) // Avoid NaN
                    this.shape_starting_curve = Math.abs(handle_new_pos_y - y0) / dy6y0;
            }
        };
    }
    /**
     * Function called when we drag the ending curve point, it update variable shape_ending_curve
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    endCurvePointDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_vertical_horizontal) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.ending_curve_point.position_x + event.dx;
                const x0 = this.position_x_start;
                const x6 = this.position_x_end;
                // Compute ending curve point coef based on new handle pos
                const dx6x0 = Math.abs(x6 - x0);
                if (dx6x0 >= 0) // Avoid NaN
                    this.shape_ending_curve = Math.abs(handle_new_pos_x - x6) / dx6x0;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.ending_curve_point.position_y + event.dy;
                const y0 = this.position_y_start;
                const y6 = this.position_y_end;
                // Compute ending curve point coef based on new handle pos
                const dy6y0 = Math.abs(y6 - y0);
                if (dy6y0 >= 0) // Avoid NaN
                    this.shape_ending_curve = Math.abs(handle_new_pos_y - y6) / dy6y0;
            }
            this._control_points.is_dragged = false;
        };
    }
    /**
     * Function called when we drag the starting tangeant point, it update variable shape_starting_tangeant
     *
     * @private
     * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    startTangeantDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_horizontal_vertical) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.starting_bezier_point.position_x + event.dx;
                const x1 = this._control_points.starting_curve_point.position_x;
                const x5 = this._control_points.ending_curve_point.position_x;
                // Compute starting tangeant point coef based on new handle pos
                const dx1x5 = Math.abs(x5 - x1);
                if (dx1x5 > 0) // Avoid NaN
                    this.shape_starting_tangeant = Math.abs(handle_new_pos_x - x1) / dx1x5;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.starting_bezier_point.position_y + event.dy;
                const y1 = this._control_points.starting_curve_point.position_y;
                const y5 = this._control_points.ending_curve_point.position_y;
                // Compute starting tangeant point coef based on new handle pos
                const dy1y5 = Math.abs(y5 - y1);
                if (dy1y5 > 0) // Avoid NaN
                    this.shape_starting_tangeant = Math.abs(handle_new_pos_y - y1) / dy1y5;
            }
            this._control_points.is_dragged = false;
        };
    }
    /**
    * Function called when we drag the ending tangeant point, it update variable shape_ending_tangeant
    *
    * @private
    * @param {d3.D3DragEvent<SVGGElement, unknown, unknown>} event
    * @memberof ClassTemplate_LinkElement
    */
    endTangeantDragEvent() {
        return (event) => {
            this._control_points.is_dragged = true;
            if (this.is_horizontal || this.is_vertical_horizontal) {
                // Compute new handle position
                const handle_new_pos_x = this._control_points.ending_bezier_point.position_x + event.dx;
                const x1 = this._control_points.starting_curve_point.position_x;
                const x5 = this._control_points.ending_curve_point.position_x;
                // Compute starting tangeant point coef based on new handle pos
                const dx1x5 = Math.abs(x5 - x1);
                if (dx1x5 > 0) // Avoid NaN
                    this.shape_ending_tangeant = Math.abs(handle_new_pos_x - x5) / dx1x5;
            }
            else {
                // Compute new handle position
                const handle_new_pos_y = this._control_points.ending_bezier_point.position_y + event.dy;
                const y1 = this._control_points.starting_curve_point.position_y;
                const y5 = this._control_points.ending_curve_point.position_y;
                // Compute starting tangeant point coef based on new handle pos
                const dy1y5 = Math.abs(y5 - y1);
                if (dy1y5 > 0) // Avoid NaN
                    this.shape_ending_tangeant = Math.abs(handle_new_pos_y - y5) / dy1y5;
            }
            this._control_points.is_dragged = false;
        };
    }
    middleRecyclingDragEvent() {
        return (event) => {
            // Only in recylcing
            if (this.shape_is_recycling) {
                if (this.is_horizontal) {
                    const handle_new_pos_y = this._control_points.middle_recycling_point.position_y + event.dy;
                    const y0 = this.position_y_start;
                    const yf = this.position_y_end;
                    this.shape_middle_recycling = handle_new_pos_y - (y0 + yf) / 2;
                }
                else if (this.is_vertical) {
                    const handle_new_pos_x = this._control_points.middle_recycling_point.position_x + event.dx;
                    const x0 = this.position_x_start;
                    const xf = this.position_x_end;
                    this.shape_middle_recycling = handle_new_pos_x - (x0 + xf) / 2;
                }
                else {
                    // Starting & Ending positions
                    const x0 = this.position_x_start;
                    const xf = this.position_x_end;
                    const y0 = this.position_y_start;
                    const yf = this.position_y_end;
                    // Vector start->end
                    const vx = (xf - x0);
                    const vy = (yf - y0);
                    // Middle recyling is at given distance
                    const sign = Math.sign(vx * event.dy - vy * event.dx); // Produit vectoriel
                    const d = Math.sqrt(event.dx * event.dx + event.dy * event.dy);
                    this.shape_middle_recycling = this.shape_middle_recycling + sign * d;
                }
            }
        };
    }
    redrawNodesSourceTarget() {
        this.source.draw();
        this.target.draw();
        if (this.source.position_type == 'parametric') {
            // if the positioning mode of source is parametric we need to reposition all nodes below
            const same_source_u = this.sankey.visible_nodes_list.filter(n => n.position_u == this.source.position_u && n.position_v > this.source.position_v);
            same_source_u.forEach(n => n.draw());
        }
        if (this.target.position_type == 'parametric') {
            // if the positioning mode of target is parametric we need to reposition all nodes below
            const same_target_u = this.sankey.visible_nodes_list.filter(n => n.position_u == this.target.position_u && n.position_v > this.target.position_v);
            same_target_u.forEach(n => n.draw());
        }
    }
    /**
     * Function triggered when we start dragging link value label when it follow the link path, it initialise relative position if undefined
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,Unknown,Unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragValuePathStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        if (this._display.position_offset_value === undefined) {
            const [label_offset,] = this.getValueTextPathOffset();
            this._display.position_offset_value = label_offset;
            this.value_label_horiz = 'dragged';
        }
    }
    /**
     * Function triggered when we move the link value label when it follow the link path, it update relative node position & redraw the name slabel
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,unknown,unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragValuePathMove(event) {
        this._display.position_offset_value = ((this._display.position_offset_value !== undefined) ? this._display.position_offset_value : 0) + event.dx;
        if (this._display.position_offset_value < 0)
            this._display.position_offset_value = 0;
        else if (this._display.position_offset_value > 100)
            this._display.position_offset_value = 100;
        this.updateValueTextPathOffset();
    }
    dragValuePathEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    /**
     * Function triggered when we start dragging node name label when it follow the link path, it initialise relative position if undefined
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,Unknown,Unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragTextPathStart(_event) {
        //if position_x_label is undefined init position_x_label pos whith current fixed x position value
        if (this._display.position_offset_name === undefined) {
            const [label_offset,] = this.getLabelTextPathOffset();
            this._display.position_offset_name = label_offset;
            this.name_label_horiz = 'dragged';
        }
    }
    /**
     * Function triggered when we move the node name label when it follow the link path, it update relative node position & redraw the name slabel
     *
     * @private
     * @param {d3.D3DragEvent<SVGTextPathElement,unknown,unknown>} event
     * @memberof ClassTemplate_LinkElement
     */
    dragTextPathMove(event) {
        this._display.position_offset_name = ((this._display.position_offset_name !== undefined) ? this._display.position_offset_name : 0) + event.dx;
        if (this._display.position_offset_name < 0)
            this._display.position_offset_name = 0;
        else if (this._display.position_offset_name > 100)
            this._display.position_offset_name = 100;
        this.updateLabelTextPathOffset();
    }
    dragTextPathEnd(_event) {
        this.menu_config.updateAllComponentsRelatedToLinks();
    }
    // GETTERS / SETTERS ==================================================================
    /**
     * Get name of link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get name() {
        return defaultLinkId(this._source, this._target);
    }
    get is_visible() {
        return (super.is_visible &&
            this.are_source_and_target_displayed &&
            this.are_related_flux_tags_selected &&
            this.is_not_null);
    }
    get display() {
        return this._display;
    }
    /**
     * displaying order on drawing area
     * @memberof ClassTemplate_LinkElement
     */
    get displaying_order() {
        return this._display.displaying_order;
    }
    set displaying_order(_) {
        this._display.displaying_order = _;
    }
    /**
     * Get source node
     * @memberof ClassTemplate_LinkElement
     */
    get source() {
        return this._source;
    }
    /**
     * set source node
     * @memberof ClassTemplate_LinkElement
     */
    set source(_) {
        if (this.source !== _) {
            // Memorize old source
            const old_source = this._source;
            // Set source attr
            this._source = _;
            // Set to recompute visibility from nodes after
            this._are_source_and_target_displayed = undefined;
            // Clean old source
            old_source.swapOutputLink(this, _);
            // If we set a source from himself then make the link a recycing one
            if (this.target === this.source) {
                this.shape_is_recycling = true;
            }
        }
    }
    /**
     * Get starting node side for link
     * @readonly
     * @type {Type_Side}
     * @memberof ClassTemplate_LinkElement
     */
    get source_side() {
        // Failsafe : because of constructor
        if (this.source === undefined || this.target === undefined) {
            return 'right';
        }
        // Normal behavior
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                if (this.source.position_x <= this.target.position_x)
                    return 'right';
                else
                    return 'left';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'bottom';
                else
                    return 'top';
            }
        }
        // Recylcing mode
        else {
            if (this.is_horizontal || this.is_horizontal_vertical) {
                if (this.source.position_x <= this.target.position_x)
                    return 'left';
                else
                    return 'right';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'top';
                else
                    return 'bottom';
            }
        }
    }
    /**
     * get destination node
     * @memberof ClassTemplate_LinkElement
     */
    get target() {
        return this._target;
    }
    /**
     * Set destination node
     * @memberof ClassTemplate_LinkElement
     */
    set target(_) {
        if (this.target !== _) {
            // Memorize old target for swapping
            const old_target = this._target;
            // Assign target attribute
            this._target = _;
            // Set to recompute visibility from nodes after
            this._are_source_and_target_displayed = undefined;
            // Clean old source
            old_target.swapInputLink(this, _);
            // If we set a target to himself then make the link a recycing one
            if (this.target === this.source) {
                this.shape_is_recycling = true;
            }
        }
    }
    /**
     * Get starting node side for link
     * @readonly
     * @type {Type_Side}
     * @memberof ClassTemplate_LinkElement
     */
    get target_side() {
        // Failsafe : because of constructor
        if (this.source === undefined || this.target === undefined) {
            return 'left';
        }
        // Normal behavior
        if (!this.shape_is_recycling) {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                if (this.source.position_x <= this.target.position_x)
                    return 'left';
                else
                    return 'right';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'top';
                else
                    return 'bottom';
            }
        }
        // Recycling mode
        else {
            if (this.is_horizontal || this.is_vertical_horizontal) {
                if (this.source.position_x <= this.target.position_x)
                    return 'right';
                else
                    return 'left';
            }
            else {
                if (this.source.position_y <= this.target.position_y)
                    return 'bottom';
                else
                    return 'top';
            }
        }
    }
    /**
     * Get value object.
     * Either search correct current value with data_taggs,
     * or return directly the value when there is no data_taggs
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get value() {
        if (this._values instanceof Class_LinkValue)
            return this._values;
        else
            return this._values.getValueForDataTags(this.sankey.selected_data_tags_list);
    }
    /**
     * Either search correct current value with data_taggs,
     *  or return directly the value when there is no data_taggs
     * @memberof ClassTemplate_LinkElement
     */
    get data_value() {
        if (this.drawing_area.show_structure === 'structure')
            return null;
        if (this.drawing_area.show_structure === 'data') {
            const value = this.value;
            // Cast as number
            if (value !== null && value.result_value)
                return value.data_value;
            else
                return null;
        }
        if (this.drawing_area.show_structure === 'free_interval') {
            const value = this.value;
            // Cast as number
            if (value !== null && value.result_value)
                return value.result_value;
            if (value !== null && value.data_value)
                return value.data_value;
            else
                return null;
        }
        if (this.drawing_area.show_structure === 'free_value') {
            const value = this.value;
            // Cast as number
            if (value !== null && value.result_value)
                return value.result_value;
            if (value !== null && value.data_value)
                return value.data_value;
            else
                return null;
        }
        if (this.drawing_area.show_structure === 'reconciled') {
            const value = this.value;
            // Cast as number
            if (value !== null && value.result_value)
                return value.result_value;
            if (value !== null && value.data_value)
                return value.data_value;
            else
                return null;
        }
        return null;
    }
    /**
     * Either set correct current value with data_taggs,
     *  or set directly the value when there is no data_taggs
     * @memberof ClassTemplate_LinkElement
     */
    set data_value(_) {
        const value = this.value;
        // Cast as number
        if (value !== null) {
            value.data_value = _;
            this.redrawNodesSourceTarget();
        }
    }
    /**
     * Either search correct current value with data_taggs,
     *  or return directly the value when there is no data_taggs
     * @return string
     * @memberof ClassTemplate_LinkElement
     */
    get text_value() {
        const value = this.value;
        // Cast as string
        if (value !== null && value.text_value !== null)
            return value.text_value;
        else
            return '';
    }
    /**
     * Either set correct current value with data_taggs,
     *  or set directly the value when there is no data_taggs
     * @memberof ClassTemplate_LinkElement
     */
    set text_value(_) {
        const value = this.value;
        // Cast as number
        if (value !== null) {
            value.text_value = _;
            this.drawLabel();
        }
    }
    get data_label() {
        // Init
        if (this.drawing_area.show_structure === 'free_interval') {
            if (this.value.free_mini != undefined) {
                return '[' + this.value.free_mini + ',' + this.value.free_maxi + ']';
            }
        }
        let data_value = this.data_value;
        let text_value = '-';
        // Create data label
        if (data_value !== null) {
            // If value has a unit & it's factor is superior to 1 then divide data_value label by unit factor
            if (this.value_label_unit_visible && this.value_label_unit != '' && this.value_label_unit_factor > 1) {
                data_value /= this.value_label_unit_factor;
            }
            // Convert
            if (this.value_label_scientific_notation) {
                // 12345.67 avec nb_sign = 4 devient 1,234*e+04
                if (this.value_label_significant_digits) {
                    text_value = data_value.toExponential(this.value_label_nb_significant_digits - 1);
                }
                else {
                    text_value = data_value.toExponential();
                }
            }
            // Do we need to keep only N significant numbers ?
            else if (this.value_label_significant_digits == true) {
                // 12345.67 avec nb_sign = 4 devient 12340
                text_value = String(parseFloat(data_value.toPrecision(this.value_label_nb_significant_digits)));
                if (text_value[text_value.length - 1] == '0' && text_value.length == this.value_label_nb_significant_digits && text_value == String(this.data_value)) {
                    text_value += '.';
                }
            }
            else if (this.value_label_custom_digit) {
                text_value = String(parseFloat(data_value.toFixed(this.value_label_nb_digit)));
            }
            else {
                text_value = String(data_value);
            }
            text_value = text_value.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ');
            // Add unit suffix
            if (text_value && this.value_label_unit_visible)
                text_value = text_value + ' ' + this.value_label_unit;
        }
        return text_value;
    }
    /**
     * Dict as [id: tag] of tags related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_tags_dict() {
        const value = this.value;
        if (value)
            return this.value.flux_tags_dict;
        return {};
    }
    /**
     * Array of tags related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_tags_list() {
        const value = this.value;
        if (value)
            return this.value.flux_tags_list;
        return [];
    }
    /**
     * Dict as [id: tag group] of tag groups related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_taggs_dict() {
        const value = this.value;
        if (value)
            return this.value.flux_taggs_dict;
        return {};
    }
    /**
     * Array of tag groups related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_taggs_list() {
        return Object.values(this.flux_taggs_dict);
    }
    /**
     * Set tooltip text
     * @memberof ClassTemplate_LinkElement
     */
    get tooltip_text() { return this._tooltip_text; }
    /**
     * Get tooltip text
     * @memberof ClassTemplate_LinkElement
     */
    set tooltip_text(_) {
        this._tooltip_text = _;
        // TODO redraw ?
    }
    /**
     * Get style key of node
     * @return {string}
     * @memberof Class_Node
     */
    get style() {
        return this._display.style;
    }
    /**
    * Set style key of node
    * @memberof Class_Node
    */
    set style(_) {
        if (!_)
            return;
        this._display.style.removeReference(this);
        this._display.style = _;
        _.addReference(this);
        this.drawElements();
    }
    /**
     * Get thickness of stroke shape
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get thickness() {
        var _a;
        if (this.drawing_area.show_structure === 'reconciled') {
            if (((_a = this.value) === null || _a === void 0 ? void 0 : _a.free_mini) != undefined) {
                return 2;
            }
        }
        // Get link value for current dataTaggs selected
        const data_value = this.data_value;
        // Scale this value for the drawing area
        const linkValueInPx = (data_value !== null && (!this.shape_is_structure)) ? this.scaleValueToPx(data_value) : 2;
        // If link processed size is inferior to min. limit return min. limit
        if (this.drawing_area.minimum_flux && linkValueInPx < this.drawing_area.minimum_flux) {
            return this.drawing_area.minimum_flux;
        }
        // If link processed size is superior to max. limit return max. limit
        if (this.drawing_area.maximum_flux && linkValueInPx > this.drawing_area.maximum_flux) {
            return this.drawing_area.maximum_flux;
        }
        return Math.max(2, linkValueInPx);
    }
    get position_x_start() {
        return this._display.position_starting.x;
    }
    get position_y_start() {
        return this._display.position_starting.y;
    }
    get position_x_end() {
        // If we draw an arrow for the link then we need to create a space between the node and the end of the link path (this space correspond to the size of the arrow)
        let shifting_end_point_x = 0;
        if (this.shape_is_arrow) {
            const is_horizontal_at_target = this.is_horizontal || this.is_vertical_horizontal;
            const is_revert = (is_horizontal_at_target && this.target_side == 'right') || (!is_horizontal_at_target && this.target_side == 'bottom');
            const sign_shifting_end_point = (is_revert) ? -1 : 1;
            shifting_end_point_x = (this.is_horizontal || this.is_vertical_horizontal) ? this.shape_arrow_size * sign_shifting_end_point : 0;
        }
        return this._display.position_ending.x - shifting_end_point_x;
    }
    get position_y_end() {
        // If we draw an arrow for the link then we need to create a space between the node and the end of the link path (this space correspond to the size of the arrow)
        let shifting_end_point_y = 0;
        if (this.shape_is_arrow) {
            const is_horizontal_at_target = this.is_horizontal || this.is_vertical_horizontal;
            const is_revert = (is_horizontal_at_target && this.target_side == 'right') || (!is_horizontal_at_target && this.target_side == 'bottom');
            const sign_shifting_end_point = (is_revert) ? -1 : 1;
            shifting_end_point_y = (this.is_vertical || this.is_horizontal_vertical) ? this.shape_arrow_size * sign_shifting_end_point : 0;
        }
        return this._display.position_ending.y - shifting_end_point_y;
    }
    get local_link_scale() {
        if ('_local_link_scale' in this._display.attributes) {
            return this._display.attributes.local_link_scale;
        }
        else {
            return this._display.style.local_link_scale;
        }
    }
    set local_link_scale(value) {
        this._display.attributes.local_link_scale = value;
        this.setDomainLocalScale(value);
        this.redrawNodesSourceTarget();
    }
    get control_points_position() {
        return {
            'starting_curve': [this._control_points.starting_curve_point.display.position.x, this._control_points.starting_curve_point.display.position.y],
            'ending_curve': [this._control_points.ending_curve_point.display.position.x, this._control_points.ending_curve_point.display.position.y],
            'starting_bezier': [this._control_points.starting_bezier_point.display.position.x, this._control_points.starting_bezier_point.display.position.y],
            'ending_bezier': [this._control_points.ending_bezier_point.display.position.x, this._control_points.ending_bezier_point.display.position.y],
            'middle_recycling': [this._control_points.middle_recycling_point.display.position.x, this._control_points.middle_recycling_point.display.position.y],
        };
    }
    // ------------ Decorator about shape attribute -------------
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_orientation() {
        if (this._display.attributes.shape_orientation !== undefined) {
            return this._display.attributes.shape_orientation;
        }
        else if (this._display.style.shape_orientation !== undefined) {
            return this._display.style.shape_orientation;
        }
        return default_shape_orientation;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_orientation(_) {
        if ((!this.shape_is_recycling) && (((this.is_vertical_horizontal) || (this.is_horizontal_vertical)) &&
            ((_ === 'hh') || (_ === 'vv')))) {
            // In 'hh' or 'vv' : ending + starting <= 1
            // In 'hv' or 'vh' : ending <= 1 & starting <= 1
            // So we need to divide these values per 2 here to avoid bricking link
            this.shape_starting_curve = this.shape_starting_curve / 2;
            this.shape_starting_curve = this.shape_ending_curve / 2;
        }
        this._display.attributes.shape_orientation = _;
        // Need to redraw from nodes
        this.drawWithNodes();
    }
    // Orientation
    get is_horizontal() { return this.shape_orientation === 'hh'; }
    get is_vertical() { return this.shape_orientation === 'vv'; }
    get is_horizontal_vertical() { return this.shape_orientation === 'hv'; }
    get is_vertical_horizontal() { return this.shape_orientation === 'vh'; }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_starting_curve() {
        if (this._display.attributes.shape_starting_curve !== undefined) {
            return this._display.attributes.shape_starting_curve;
        }
        else if (this._display.style.shape_starting_curve !== undefined) {
            return this._display.style.shape_starting_curve;
        }
        return default_shape_starting_curve;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_starting_curve(_) {
        if (_ >= 0) {
            // For non recycling shape we have upper bound on starting
            if (!this.shape_is_recycling) {
                // Specific case for horizontal-vertical links : starting = [0; 1]
                if ((this.is_horizontal_vertical) ||
                    (this.is_vertical_horizontal)) {
                    if (_ <= 1.0)
                        this._display.attributes.shape_starting_curve = _;
                    else
                        this._display.attributes.shape_starting_curve = 1.0;
                }
                // Otherwise for rectiligne links : starting = [0; 1 - ending]
                else {
                    if ((_ + this.shape_ending_curve) <= 1.0)
                        this._display.attributes.shape_starting_curve = _;
                    else
                        this._display.attributes.shape_starting_curve = 1.0 - this.shape_ending_curve;
                }
            }
            // For recycling shapes we don't have upper bounds on starting
            else {
                this._display.attributes.shape_starting_curve = _;
            }
            this.drawElements();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_ending_curve() {
        if (this._display.attributes.shape_ending_curve !== undefined) {
            return this._display.attributes.shape_ending_curve;
        }
        else if (this._display.style.shape_ending_curve !== undefined) {
            return this._display.style.shape_ending_curve;
        }
        return default_shape_ending_curve;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_ending_curve(_) {
        if (_ >= 0) {
            // For non recycling shape we have upper bound on ending
            if (!this.shape_is_recycling) {
                // Specific case for horizontal-vertical links : ending = [0; 1]
                if ((this.is_horizontal_vertical) ||
                    (this.is_vertical_horizontal)) {
                    if (_ <= 1.0)
                        this._display.attributes.shape_ending_curve = _;
                    else
                        this._display.attributes.shape_ending_curve = 1.0;
                }
                // Otherwise for rectiligne links : ending = [0; 1 - starting]
                else {
                    if ((_ + this.shape_starting_curve) <= 1.0)
                        this._display.attributes.shape_ending_curve = _;
                    else
                        this._display.attributes.shape_ending_curve = 1.0 - this.shape_starting_curve;
                }
            }
            // For recycling shapes we don't have upper bounds on ending
            else {
                this._display.attributes.shape_ending_curve = _;
            }
            this.drawElements();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_starting_tangeant() {
        if (this._display.attributes.shape_starting_tangeant !== undefined) {
            return this._display.attributes.shape_starting_tangeant;
        }
        else if (this._display.style.shape_starting_tangeant !== undefined) {
            return this._display.style.shape_starting_tangeant;
        }
        return default_shape_starting_tangeant;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_starting_tangeant(_) {
        if (_ > 0) {
            this._display.attributes.shape_starting_tangeant = _;
            this.drawElements();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_ending_tangeant() {
        if (this._display.attributes.shape_ending_tangeant !== undefined) {
            return this._display.attributes.shape_ending_tangeant;
        }
        else if (this._display.style.shape_ending_tangeant !== undefined) {
            return this._display.style.shape_ending_tangeant;
        }
        return default_shape_ending_tangeant;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_ending_tangeant(_) {
        if (_ > 0) {
            this._display.attributes.shape_ending_tangeant = _;
            this.drawElements();
            this.drawControlPoint();
        }
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_middle_recycling() {
        if (this._display.attributes.shape_middle_recycling !== undefined) {
            return this._display.attributes.shape_middle_recycling;
        }
        else if (this._display.style.shape_middle_recycling !== undefined) {
            return this._display.style.shape_middle_recycling;
        }
        return default_shape_middle_recyling;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_middle_recycling(_) {
        this._display.attributes.shape_middle_recycling = _;
        this.drawElements();
        this.drawControlPoint();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_curvature() {
        if (this._display.attributes.shape_curvature !== undefined) {
            return this._display.attributes.shape_curvature;
        }
        else if (this._display.style.shape_curvature !== undefined) {
            return this._display.style.shape_curvature;
        }
        return default_shape_curvature;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_curvature(_) { this._display.attributes.shape_curvature = _; this.drawElements(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_is_curved() {
        if (this._display.attributes.shape_is_curved !== undefined) {
            return this._display.attributes.shape_is_curved;
        }
        else if (this._display.style.shape_is_curved !== undefined) {
            return this._display.style.shape_is_curved;
        }
        return default_shape_is_curved;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_is_curved(_) { this._display.attributes.shape_is_curved = _; this.drawElements(); this.drawControlPoint(); }
    get shape_is_structure() {
        var _a;
        if (this.sankey.drawing_area.show_structure == 'reconciled') {
            if (((_a = this.value) === null || _a === void 0 ? void 0 : _a.free_mini) != undefined) {
                return true;
            }
        }
        if (this._display.attributes.shape_is_structure !== undefined) {
            return this._display.attributes.shape_is_structure;
        }
        else if (this._display.style.shape_is_structure !== undefined) {
            return this._display.style.shape_is_structure;
        }
        return default_shape_is_structure;
    }
    set shape_is_structure(_) { this._display.attributes.shape_is_structure = _; this.drawWithNodes(); this.drawControlPoint(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_is_recycling() {
        if (this._display.attributes.shape_is_recycling !== undefined) {
            return this._display.attributes.shape_is_recycling;
        }
        else if (this._display.style.shape_is_recycling !== undefined) {
            return this._display.style.shape_is_recycling;
        }
        return default_shape_is_recycling;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_is_recycling(_) {
        // In recylcing mode we dont have upperbound for starting & ending
        // But in normal mode we have upper bounds, so we need to add upper bound
        // to avoid bricking link path
        if (!_ && this._display.attributes.shape_is_recycling) {
            this.shape_starting_curve = Math.min(this.shape_starting_curve, 0.25);
            this.shape_ending_curve = Math.min(this.shape_ending_curve, 0.25);
        }
        this._display.attributes.shape_is_recycling = _;
        // Need to redraw from nodes
        this.drawWithNodes();
        this.drawControlPoint();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_arrow_size() {
        if (this._display.attributes.shape_arrow_size !== undefined) {
            return this._display.attributes.shape_arrow_size;
        }
        else if (this._display.style.shape_arrow_size !== undefined) {
            return this._display.style.shape_arrow_size;
        }
        return default_shape_arrow_size;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_arrow_size(_) { this._display.attributes.shape_arrow_size = _; this.drawElements(); }
    /**
     * Set and redraw d3 path for link arrow
     * @memberof ClassTemplate_LinkElement
     */
    set shape_arrow_path(_) {
        this._arrow_shape = _;
        this.drawArrow();
    }
    /**
   * TODO Description
   * @memberof ClassTemplate_LinkElement
   */
    get shape_is_arrow() {
        if (this._display.attributes.shape_is_arrow !== undefined) {
            return this._display.attributes.shape_is_arrow;
        }
        else if (this._display.style.shape_is_arrow !== undefined) {
            return this._display.style.shape_is_arrow;
        }
        return default_shape_is_arrow;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_is_arrow(_) { this._display.attributes.shape_is_arrow = _; this.drawElements(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_color() {
        if (this._display.attributes.shape_color !== undefined) {
            return this._display.attributes.shape_color;
        }
        else if (this._display.style.shape_color !== undefined) {
            return this._display.style.shape_color;
        }
        return default_shape_color;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_color(_) { this._display.attributes.shape_color = _; this.drawElements(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_opacity() {
        if (this._display.attributes.shape_opacity !== undefined) {
            return this._display.attributes.shape_opacity;
        }
        else if (this._display.style.shape_opacity !== undefined) {
            return this._display.style.shape_opacity;
        }
        return default_shape_opacity;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_opacity(_) { this._display.attributes.shape_opacity = _; this.drawElements(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get shape_is_dashed() {
        if (this._display.attributes.shape_is_dashed !== undefined) {
            return this._display.attributes.shape_is_dashed;
        }
        else if (this._display.style.shape_is_dashed !== undefined) {
            return this._display.style.shape_is_dashed;
        }
        return default_shape_is_dashed;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set shape_is_dashed(_) { this._display.attributes.shape_is_dashed = _; this.drawElements(); }
    // ------------ Decorator about value label attribute -------------
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_horiz() {
        if (this._display.attributes.value_label_horiz !== undefined) {
            return this._display.attributes.value_label_horiz;
        }
        else if (this._display.style.value_label_horiz !== undefined) {
            return this._display.style.value_label_horiz;
        }
        return default_link_value_label_horiz;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_horiz(_) {
        this.value_label_pos_auto = false;
        if (_ !== 'dragged')
            this.deleteDraggedValuePos();
        this._display.attributes.value_label_horiz = _;
        this.drawValue();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_vert() {
        if (this._display.attributes.value_label_vert !== undefined) {
            return this._display.attributes.value_label_vert;
        }
        else if (this._display.style.value_label_vert !== undefined) {
            return this._display.style.value_label_vert;
        }
        return default_link_value_label_vert;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_vert(_) {
        if (_ !== 'dragged')
            this.deleteDraggedValuePos();
        this.value_label_pos_auto = false;
        this._display.attributes.value_label_vert = _;
        this.drawValue();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_on_path() {
        if (this._display.attributes.value_label_on_path !== undefined) {
            return this._display.attributes.value_label_on_path;
        }
        else if (this._display.style.value_label_on_path !== undefined) {
            return this._display.style.value_label_on_path;
        }
        return default_link_value_label_on_path;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_on_path(_) {
        this._display.attributes.value_label_on_path = _;
        if (_) {
            const lab_pos = this._display.attributes.value_label_horiz;
            const lab_orth_pos = this._display.attributes.value_label_vert;
            this._display.attributes.value_label_horiz = (lab_pos == 'dragged') ? 'middle' : lab_pos;
            this._display.attributes.value_label_vert = (lab_orth_pos == 'dragged' ? 'middle' : lab_orth_pos);
        }
        this.drawValue();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_pos_auto() {
        if (this._display.attributes.value_label_pos_auto !== undefined) {
            return this._display.attributes.value_label_pos_auto;
        }
        else if (this._display.style.value_label_pos_auto !== undefined) {
            return this._display.style.value_label_pos_auto;
        }
        return default_link_value_label_pos_auto;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_pos_auto(_) {
        this._display.attributes.value_label_pos_auto = _;
        this._display.attributes.value_label_vert = (this._display.attributes.value_label_vert === 'dragged') ? 'middle' : this._display.attributes.value_label_vert;
        this.drawValue();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_is_visible() {
        if (this._display.attributes.value_label_is_visible !== undefined) {
            return this._display.attributes.value_label_is_visible;
        }
        else if (this._display.style.value_label_is_visible !== undefined) {
            return this._display.style.value_label_is_visible;
        }
        return default_link_value_label_is_visible;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_is_visible(_) { this._display.attributes.value_label_is_visible = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_font_size() {
        if (this._display.attributes.value_label_font_size !== undefined) {
            return this._display.attributes.value_label_font_size;
        }
        else if (this._display.style.value_label_font_size !== undefined) {
            return this._display.style.value_label_font_size;
        }
        return default_link_value_label_font_size;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_font_size(_) { this._display.attributes.value_label_font_size = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_color() {
        if (this._display.attributes.value_label_color !== undefined) {
            return this._display.attributes.value_label_color;
        }
        else if (this._display.style.value_label_color !== undefined) {
            return this._display.style.value_label_color;
        }
        return default_link_value_label_color;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_color(_) { this._display.attributes.value_label_color = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_scientific_notation() {
        if (this._display.attributes.value_label_scientific_notation !== undefined) {
            return this._display.attributes.value_label_scientific_notation;
        }
        else if (this._display.style.value_label_scientific_notation !== undefined) {
            return this._display.style.value_label_scientific_notation;
        }
        return default_link_value_label_scientific_notation;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_scientific_notation(_) { this._display.attributes.value_label_scientific_notation = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_significant_digits() {
        if (this._display.attributes.value_label_significant_digits !== undefined) {
            return this._display.attributes.value_label_significant_digits;
        }
        else if (this._display.style.value_label_significant_digits !== undefined) {
            return this._display.style.value_label_significant_digits;
        }
        return default_link_value_label_significant_digits;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_nb_significant_digits() {
        if (this._display.attributes.value_label_nb_significant_digits !== undefined) {
            return this._display.attributes.value_label_nb_significant_digits;
        }
        else if (this._display.style.value_label_nb_significant_digits !== undefined) {
            return this._display.style.value_label_nb_significant_digits;
        }
        return default_link_value_label_nb_significant_digits;
    }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_significant_digits(_) { this._display.attributes.value_label_significant_digits = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof Class_LinkElement
     */
    set value_label_nb_significant_digits(_) { this._display.attributes.value_label_nb_significant_digits = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_font_family() {
        if (this._display.attributes.value_label_font_family !== undefined) {
            return this._display.attributes.value_label_font_family;
        }
        else if (this._display.style.value_label_font_family !== undefined) {
            return this._display.style.value_label_font_family;
        }
        return default_link_value_label_font_family;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_font_family(_) { this._display.attributes.value_label_font_family = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_unit_visible() {
        if (this._display.attributes.value_label_unit_visible !== undefined) {
            return this._display.attributes.value_label_unit_visible;
        }
        else if (this._display.style.value_label_unit_visible !== undefined) {
            return this._display.style.value_label_unit_visible;
        }
        return default_link_value_label_unit_visible;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_unit_visible(_) { this._display.attributes.value_label_unit_visible = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_unit() {
        if (this._display.attributes.value_label_unit !== undefined) {
            return this._display.attributes.value_label_unit;
        }
        else if (this._display.style.value_label_unit !== undefined) {
            return this._display.style.value_label_unit;
        }
        return default_link_value_label_unit;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_unit(_) { this._display.attributes.value_label_unit = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_unit_factor() {
        if (this._display.attributes.value_label_unit_factor !== undefined) {
            return this._display.attributes.value_label_unit_factor;
        }
        else if (this._display.style.value_label_unit_factor !== undefined) {
            return this._display.style.value_label_unit_factor;
        }
        return default_link_value_label_unit_factor;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_unit_factor(_) { this._display.attributes.value_label_unit_factor = _; this.drawValue(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_custom_digit() {
        if (this._display.attributes.value_label_custom_digit !== undefined) {
            return this._display.attributes.value_label_custom_digit;
        }
        else if (this._display.style.value_label_custom_digit !== undefined) {
            return this._display.style.value_label_custom_digit;
        }
        return default_link_value_label_custom_digit;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_custom_digit(_) {
        this._display.attributes.value_label_custom_digit = _;
        if (_) {
            this.value_label_scientific_notation = false;
            this.value_label_significant_digits = false;
        }
        this.drawValue();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    get value_label_nb_digit() {
        if (this._display.attributes.value_label_nb_digit !== undefined) {
            return this._display.attributes.value_label_nb_digit;
        }
        else if (this._display.style.value_label_nb_digit !== undefined) {
            return this._display.style.value_label_nb_digit;
        }
        return default_link_value_label_nb_digit;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_LinkElement
     */
    set value_label_nb_digit(_) { this._display.attributes.value_label_nb_digit = _; this.drawValue(); }
    get value_label_uppercase() {
        if (this._display.attributes.value_label_uppercase !== undefined) {
            return this._display.attributes.value_label_uppercase;
        }
        else if (this._display.style.value_label_uppercase !== undefined) {
            return this._display.style.value_label_uppercase;
        }
        return default_link_value_label_uppercase;
    }
    get value_label_bold() {
        if (this._display.attributes.value_label_bold !== undefined) {
            return this._display.attributes.value_label_bold;
        }
        else if (this._display.style.value_label_bold !== undefined) {
            return this._display.style.value_label_bold;
        }
        return default_link_value_label_bold;
    }
    get value_label_italic() {
        if (this._display.attributes.value_label_italic !== undefined) {
            return this._display.attributes.value_label_italic;
        }
        else if (this._display.style.value_label_italic !== undefined) {
            return this._display.style.value_label_italic;
        }
        return default_link_value_label_italic;
    }
    set value_label_uppercase(_) { this._display.attributes.value_label_uppercase = _; this.drawValue(); }
    set value_label_bold(_) { this._display.attributes.value_label_bold = _; this.drawValue(); }
    set value_label_italic(_) { this._display.attributes.value_label_italic = _; this.drawValue(); }
    // ------------ Decorator about name label attribute -------------
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_is_visible() {
        if (this._display.attributes.name_label_is_visible !== undefined) {
            return this._display.attributes.name_label_is_visible;
        }
        else if (this._display.style.name_label_is_visible !== undefined) {
            return this._display.style.name_label_is_visible;
        }
        return default_link_name_label_visible;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_is_visible(_) { this._display.attributes.name_label_is_visible = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_font_family() {
        if (this._display.attributes.name_label_font_family !== undefined) {
            return this._display.attributes.name_label_font_family;
        }
        else if (this._display.style.name_label_font_family !== undefined) {
            return this._display.style.name_label_font_family;
        }
        return default_link_name_label_font_family;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_font_family(_) { this._display.attributes.name_label_font_family = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_font_size() {
        if (this._display.attributes.name_label_font_size !== undefined) {
            return this._display.attributes.name_label_font_size;
        }
        else if (this._display.style.name_label_font_size !== undefined) {
            return this._display.style.name_label_font_size;
        }
        return default_link_name_label_font_size;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_font_size(_) { this._display.attributes.name_label_font_size = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_uppercase() {
        if (this._display.attributes.name_label_uppercase !== undefined) {
            return this._display.attributes.name_label_uppercase;
        }
        else if (this._display.style.name_label_uppercase !== undefined) {
            return this._display.style.name_label_uppercase;
        }
        return default_link_name_label_uppercase;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_uppercase(_) { this._display.attributes.name_label_uppercase = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_bold() {
        if (this._display.attributes.name_label_bold !== undefined) {
            return this._display.attributes.name_label_bold;
        }
        else if (this._display.style.name_label_bold !== undefined) {
            return this._display.style.name_label_bold;
        }
        return default_link_name_label_bold;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_bold(_) { this._display.attributes.name_label_bold = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_italic() {
        if (this._display.attributes.name_label_italic !== undefined) {
            return this._display.attributes.name_label_italic;
        }
        else if (this._display.style.name_label_italic !== undefined) {
            return this._display.style.name_label_italic;
        }
        return default_link_name_label_italic;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_italic(_) { this._display.attributes.name_label_italic = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_color() {
        if (this._display.attributes.name_label_color !== undefined) {
            return this._display.attributes.name_label_color;
        }
        else if (this._display.style.name_label_color !== undefined) {
            return this._display.style.name_label_color;
        }
        return default_link_name_label_color;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_color(_) { this._display.attributes.name_label_color = _; this.drawLabel(); }
    /**
   * TODO Description
   * @memberof ClassTemplate_NodeElement
   */
    get name_label_pos_auto() {
        if (this._display.attributes.name_label_pos_auto !== undefined) {
            return this._display.attributes.name_label_pos_auto;
        }
        else if (this._display.style.name_label_pos_auto !== undefined) {
            return this._display.style.name_label_pos_auto;
        }
        return default_link_value_label_pos_auto;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_pos_auto(_) {
        this._display.attributes.name_label_pos_auto = _;
        const orth_pos = this.name_label_vert;
        this._display.attributes.name_label_vert = (orth_pos === 'dragged') ? 'middle' : orth_pos;
        this.drawLabel();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_on_path() {
        if (this._display.attributes.name_label_on_path !== undefined) {
            return this._display.attributes.name_label_on_path;
        }
        else if (this._display.style.name_label_on_path !== undefined) {
            return this._display.style.name_label_on_path;
        }
        return default_link_value_label_on_path;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_on_path(_) {
        this._display.attributes.name_label_on_path = _;
        if (_) {
            const lab_pos = this.name_label_horiz;
            const lab_orth_pos = this.name_label_vert;
            this._display.attributes.name_label_horiz = (lab_pos == 'dragged') ? 'middle' : lab_pos;
            this._display.attributes.name_label_vert = (lab_orth_pos == 'dragged' ? 'middle' : lab_orth_pos);
        }
        this.drawLabel();
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_vert() {
        if (this._display.attributes.name_label_vert !== undefined) {
            return this._display.attributes.name_label_vert;
        }
        else if (this._display.style.name_label_vert !== undefined) {
            return this._display.style.name_label_vert;
        }
        return default_link_name_label_vert;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_vert(_) { if (_ !== 'dragged')
        this.deleteDraggedLabelPos(); this._display.attributes.name_label_vert = _; this.drawLabel(); }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    get name_label_horiz() {
        if (this._display.attributes.name_label_horiz !== undefined) {
            return this._display.attributes.name_label_horiz;
        }
        else if (this._display.style.name_label_horiz !== undefined) {
            return this._display.style.name_label_horiz;
        }
        return default_link_name_label_horiz;
    }
    /**
     * TODO Description
     * @memberof ClassTemplate_NodeElement
     */
    set name_label_horiz(_) { if (_ !== 'dragged')
        this.deleteDraggedLabelPos(); this._display.attributes.name_label_horiz = _; this.drawLabel(); }
    // ------------ Other Decorators  -------------
    get datatags_fingerprint() { return this._datatags_fingerprint; }
    /**
     * If link has tags :
     * - check for each tag group if the flow has at least one selected tag that isn't filtered out
     * else if the link doesn't have tag it isn't filtered by them
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get are_related_flux_tags_selected() {
        var _a, _b;
        if ((this._are_related_flux_tags_selected === undefined) ||
            (this.sankey.flux_tags_fingerprint !== this._flux_tags_fingerprint)) {
            // Recompute visibility value
            let are_related_flux_tags_selected;
            const list_tag = this.flux_taggs_list;
            if (list_tag.length > 0) {
                let display = true;
                // Check if at least one flux tag is selected in each group = ok to display
                Object.values((_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.taggs_dict) !== null && _b !== void 0 ? _b : {})
                    .forEach(tag_list => {
                    display = (tag_list.filter(tag => tag.is_selected).length > 0) ? display : false;
                });
                are_related_flux_tags_selected = display;
            }
            else {
                are_related_flux_tags_selected = true; // if no tag associated to flux then ok to display
            }
            // Update  fingerprint if needed
            // -> This condition allows to avoid unecessary visibility recomputing on related elements
            //    that check this link's visibility fingerprint
            if (are_related_flux_tags_selected !== this._are_related_flux_tags_selected) {
                this.updateVisibilityFingerprint();
            }
            // Update memorized values
            this._are_related_flux_tags_selected = are_related_flux_tags_selected;
            this._flux_tags_fingerprint = this.sankey.flux_tags_fingerprint;
        }
        return this._are_related_flux_tags_selected;
    }
    /**
     * If link value for current dataTagg parameter is different of 0 then pass the check,
     *
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get is_not_null() {
        if ((this._is_not_null === undefined) ||
            (this._datatags_fingerprint !== this.sankey.data_tags_fingerprint)) {
            // Recompute visibility value
            const is_not_null = (this.data_value !== 0);
            // Update  fingerprint if needed
            // -> This condition allows to avoid unecessary visibility recomputing on related elements
            //    that check this link's visibility fingerprint
            if (is_not_null !== this._is_not_null) {
                this.updateVisibilityFingerprint();
            }
            // Update memorized values
            this._is_not_null = is_not_null;
            this._datatags_fingerprint = this.sankey.data_tags_fingerprint;
        }
        return this._is_not_null;
    }
    // PRIVATE GETTER / SETTER =============================================================
    /**
     * Check if node source and node target are displayed,
     * if one of them is not then we don't display the link
     *
     * @private
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    get are_source_and_target_displayed() {
        var _a, _b, _c, _d;
        if ((this._are_source_and_target_displayed === undefined) ||
            (this._source.visibility_fingerprint !== this._source_visibility_fingerprint) ||
            (this._target.visibility_fingerprint !== this._target_visibility_fingerprint)) {
            // Recompute visibility value
            const are_source_and_target_displayed = (((_b = (_a = this._source) === null || _a === void 0 ? void 0 : _a.is_visible) !== null && _b !== void 0 ? _b : false) &&
                ((_d = (_c = this._target) === null || _c === void 0 ? void 0 : _c.is_visible) !== null && _d !== void 0 ? _d : false));
            // Update  fingerprint if needed
            // -> This condition allows to avoid unecessary visibility recomputing on related elements
            //    that check this link's visibility fingerprint
            if (are_source_and_target_displayed !== this._are_source_and_target_displayed) {
                this.updateVisibilityFingerprint();
            }
            // Update memorized values
            this._are_source_and_target_displayed = are_source_and_target_displayed;
            this._source_visibility_fingerprint = this._source.visibility_fingerprint;
            this._target_visibility_fingerprint = this._target.visibility_fingerprint;
        }
        return this._are_source_and_target_displayed;
    }
    /**
     * If drawing area has filter_link_value above 0:
     * - check if the link value is superior to the filter
     *   if not don't display the link
     * @readonly
     * @private
     * @memberof ClassTemplate_LinkElement
     */
    get is_value_above_threshold() {
        if (this.drawing_area.filter_link_value == 0) {
            return true;
        }
        else {
            return Number(this.data_value) >= this.drawing_area.filter_link_value;
        }
    }
    get tooltip_html() {
        // Title
        let tooltip_html = '<p class="title" style="margin-bottom: 5px;">' +
            this.source.name.split('\\n').join(' ') +
            ' → ' +
            this.target.name.split('\\n').join(' ') +
            '</p>';
        // Subtitle
        if (this.tooltip_text) {
            tooltip_html += '<p class="subtitle" style="	margin-bottom: 5px;">' +
                this.tooltip_text.split('\n').join('</br>') +
                '</p>';
        }
        // Create table
        tooltip_html += '<div style="padding-left :5px;padding-right :5px">';
        tooltip_html += '<table class="table" style="margin-bottom: 5px;">';
        tooltip_html += '  <tbody>';
        // Show data
        tooltip_html += '    <tr>';
        tooltip_html += '      <th>' + this.drawing_area.application_data.t('Noeud.drawing_area_tooltip.val') + '</th>';
        tooltip_html += '      <td>' + this.data_label + '</td>';
        tooltip_html += '    </tr>';
        // Show flux tags
        const flux_tags = this.flux_tags_list; // avoid hidden recomputing
        this.flux_taggs_list
            .forEach(tagg => {
            const flux_tags_names = flux_tags
                .filter(tag => tag.group === tagg)
                .map(tag => tag.name);
            tooltip_html += '    <tr>';
            tooltip_html += '      <th> ' + tagg.name + ' </th>';
            tooltip_html += '      <td>' + flux_tags_names.join() + '</td>';
            tooltip_html += '    </tr>';
        });
        tooltip_html += '  </tbody>';
        tooltip_html += '</table>';
        tooltip_html += '</div>';
        return tooltip_html;
    }
}
// CLASS LINK TREE VALUE ****************************************************************
/**
 * Define a node for value
 * @export
 * @class Class_LinkValueTree
 * @implements {TreeNodeInterface}
 */
export class Class_LinkValueTree {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_LinkValueTree.
     * @param {(Class_LinkValueTree | ClassTemplate_LinkElement)} parent
     * @param {Class_DataTagGroup} tag_group
     * @memberof Class_LinkValueTree
     */
    constructor(parent, data_tag_group) {
        // PRIVATE ATTRIBUTES =================================================================
        this._is_currently_deleted = false;
        // Instanciate parent
        this.parent = parent;
        // Instanciate taggroup
        this.data_tag_group = data_tag_group;
        // Instanciate children
        this.children = {};
        data_tag_group.tags_list.forEach(tag => {
            this.children[tag.id] = new Class_LinkValue(this);
        });
    }
    // CLEANING METHODS ====================================================================
    /**
     * Define deletion behavior
     * - Remove self from parent
     * - Delete childrens
     * @memberof Class_LinkValueTree
     */
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Delete children
            Object.keys(this.children)
                .forEach(id => {
                this.children[id].delete();
            });
            this.children = {};
            // Unref from parent
            if (this.parent instanceof Class_LinkValueTree)
                this.parent.removeChild(this);
            // // Unref taggroup
            // this.data_tag_group = null
        }
    }
    // COPY METHODS =======================================================================
    copyFrom(element) {
        // Check types of children
        const [allValues, allTrees] = element.kindOfChildren();
        // Clean children
        Object.values(this.children)
            .forEach(child => child.delete());
        // Copy children recursively
        Object.keys(element.children)
            .forEach(tag_id => {
            var _a, _b;
            const child_to_copy = element.children[tag_id];
            if ((child_to_copy instanceof Class_LinkValueTree) && (allTrees)) {
                const new_child = new Class_LinkValueTree(this, (_b = (_a = this.link) === null || _a === void 0 ? void 0 : _a.sankey.data_taggs_dict[child_to_copy.data_tag_group.id]) !== null && _b !== void 0 ? _b : child_to_copy.data_tag_group); // Fallback should never happen !!
                this.children[tag_id] = new_child;
                new_child.copyFrom(child_to_copy);
            }
            else if ((child_to_copy instanceof Class_LinkValue) && allValues) {
                const new_child = new Class_LinkValue(this);
                this.children[tag_id] = new_child;
                new_child.copyFrom(child_to_copy);
            }
        });
    }
    toJSON() {
        const json_object = {};
        json_object['datatag_group'] = this.data_tag_group.id;
        Object.entries(this.children)
            .forEach(([id, child]) => {
            json_object[id] = child.toJSON();
        });
        return json_object;
    }
    fromJSON(json_object, matching_taggs_id = {}, matching_tags_id = {}) {
        // All parentality relations are sets via sankey struct with fromJSON + addDataTag
        // So it is not necessary to read datatag group -> it should be the same as in JSON
        // if (this.data_tag_group.id !== json_object['datatag_group'])
        //   console.error('Erreur lecture valeur dans JSON : datatag group are not matching')
        // else {
        Object.entries(json_object)
            .filter(([id,]) => id !== 'datatag_group') // Skip this entry in JSON
            .forEach(([id, sub_json_object]) => {
            var _a;
            if (typeof sub_json_object === 'object')
                (_a = this.children[id]) === null || _a === void 0 ? void 0 : _a.fromJSON(sub_json_object, matching_taggs_id, matching_tags_id);
        });
        //}
    }
    // PUBLIC METHODS =====================================================================
    /**
     * Add new children related to new tagGroup
     * Always add in the bottom of the tree
     * @param {Class_DataTagGroup} data_tag_group
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    expand(data_tag_group) {
        if (this.data_tag_group !== data_tag_group) // Protection against tag group already present
            Object.keys(this.children)
                .forEach(id => {
                this.children[id] = this.children[id].expand(data_tag_group);
            });
        return this;
    }
    /**
     * Remove all children related to given tag group
     * - Either prune bottom of tree (simple case)
     * - Or slice tree to keep sub-combinations of tags
     * @param {Class_DataTagGroup} data_tag_group
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    prune(data_tag_group) {
        // If data_tag_group correspond to this tree's tag group - do the pruning process
        if (this.data_tag_group === data_tag_group) {
            // Keep parent ref in memory
            const parent = this.parent;
            // Keep first child ref in memory
            const id = Object.keys(this.children)[0];
            const child = this.children[id];
            // Delete ref to first child
            delete this.children[id];
            // Re-attach tree together
            if (parent instanceof Class_LinkValueTree) {
                // When pruning this, first child is preserve because ref has been deleted from children table
                parent.removeAndReplaceChild(this, child);
                return parent;
            }
            else {
                // Parent is LinkElement
                return child;
            }
        }
        // If data_tag_group is different than the one used by
        else {
            // Recurse, only if children are also trees
            Object.keys(this.children)
                .forEach(id => {
                const child = this.children[id];
                if (child instanceof Class_LinkValueTree)
                    child.prune(data_tag_group);
            });
            return this;
        }
    }
    /**
     * Add new child from given data_tag
     * @param {Class_Tag} data_tag
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    extend(data_tag) {
        // What kind of children
        const [allValues, allTrees] = this.kindOfChildren();
        // Case 1 : Last node tree before values
        if (allValues && (!allTrees)) {
            // Tag must be from this tree's data_tag group
            if (data_tag.group === this.data_tag_group) {
                // If not already existing, create a new child // given data_tag
                if (!this.children[data_tag.id]) {
                    const _ = new Class_LinkValue(this);
                    this.children[data_tag.id] = _;
                }
                // Return child // given data_tag
                return this.children[data_tag.id];
            }
        }
        // Case 2 : Current children's are also tree
        else if ((!allValues) && allTrees) {
            // If data_tag's group correspond to this tree's data_tag group - add new child
            if (data_tag.group === this.data_tag_group) {
                // If not already existing, create a new child // given data_tag
                if (!this.children[data_tag.id]) {
                    const ref_child = Object.values(this.children)[0]; // Never undefined beacause of test on (!allValues && AllTrees)
                    if (ref_child instanceof Class_LinkValueTree) {
                        // Create and reference
                        const _ = new Class_LinkValueTree(this, ref_child.data_tag_group);
                        this.children[data_tag.id] = _;
                        // Recursivly copy values / sub-trees
                        _.copyFrom(ref_child);
                    }
                }
                // Return child // given data_tag
                return this.children[data_tag.id];
            }
            // Tag group is different than the one used
            else {
                // Go deeper recursivley
                let output = undefined;
                Object.values(this.children)
                    .forEach(child => {
                    // Child can only be Class_LinkValueTree because of test on (!allValues && AllTrees)
                    const _ = child.extend(data_tag);
                    // Return something not undefined if possible
                    if (_ && (!output))
                        output = _;
                });
                return output;
            }
        }
        return undefined;
    }
    /**
     * Remove child related to given dataTag
     * @param {Class_Tag} data_tag
     * @memberof Class_LinkValueTree
     */
    reduce(data_tag) {
        // Tag is from correct data_tag group
        if (data_tag.group === this.data_tag_group) {
            this.removeChildFromDataTagId(data_tag.id);
        }
        // Recursive call
        else {
            Object.values(this.children)
                .forEach(child => {
                if (child instanceof Class_LinkValueTree)
                    child.reduce(data_tag);
            });
        }
    }
    /**
     * Remove given child from children (ie prune tree)
     * @private
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @memberof Class_LinkValueTree
     */
    removeChild(child) {
        // Get child's id
        const id = this.getDataTagIdFromChild(child);
        // Remove it
        if (id)
            this.removeChildFromDataTagId(id);
    }
    getValueForDataTags(data_tags) {
        // Failsafe
        if (data_tags.length === 0)
            return null;
        // Get value recursively
        const matching_tags = data_tags.filter(tag => (tag.group === this.data_tag_group));
        const remaining_tags = data_tags.filter(tag => (tag.group !== this.data_tag_group));
        // Failsafe
        if (matching_tags.length !== 1)
            return null;
        // Recursive
        const child = this.children[matching_tags[0].id];
        if (child !== undefined) {
            if (child instanceof Class_LinkValue)
                return child;
            else
                return child.getValueForDataTags(remaining_tags);
        }
        else {
            return null;
        }
    }
    setDataValueForDataTags(data_tags, val) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            value.data_value = val;
        }
    }
    getDataValueForDataTags(data_tags) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            return value.data_value;
        }
        else {
            return null;
        }
    }
    setTextValueForDataTags(data_tags, val) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            value.text_value = val;
        }
    }
    getTextValueForDataTags(data_tags) {
        const value = this.getValueForDataTags(data_tags);
        if (value !== null) {
            return value.text_value;
        }
        else {
            return null;
        }
    }
    /**
     * Find corresponding id for given child
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @memberof Class_LinkValueTree
     */
    getDataTagIdFromChild(child) {
        let id = undefined;
        Object.keys(this.children)
            .forEach(tag_id => {
            if (this.children[tag_id] === child) {
                id = tag_id;
            }
        });
        return id;
    }
    /**
     * Return combinason of datatags if to reach given child
     * @param {(Class_LinkValue | Class_LinkValueTree)} child
     * @return {*}  {string[]}
     * @memberof Class_LinkValueTree
     */
    getDataTagsIdCombination(child) {
        const id = this.getDataTagIdFromChild(child);
        if (id) {
            if (this.parent instanceof Class_LinkValueTree) {
                const prev_id = this.parent.getDataTagsIdCombination(this);
                prev_id.push(id);
                return prev_id;
            }
            else
                return [id];
        }
        return [];
    }
    /**
     * Browse children & search for the maximum value among them
     *
     * @return {*}
     * @memberof Class_LinkValueTree
     */
    getMaxValue() {
        let max = null;
        Object.entries(this.children)
            .forEach(child => {
            const _ = child[1].getMaxValue();
            max = ((max !== null && max !== void 0 ? max : 0) <= _ ? _ : max);
        });
        return max;
    }
    getAllValues() {
        let out = {};
        Object.values(this.children)
            .forEach(child => {
            const _ = child.getAllValues();
            out = Object.assign(Object.assign({}, out), _);
        });
        Object.values(out)
            .forEach(_ => {
            if (_[1] && this.data_tag)
                _[1].push(this.data_tag);
        });
        return out;
    }
    // PRIVATE METHODS ====================================================================
    kindOfChildren() {
        let allLinkValue = true;
        let allLinkValueTree = true;
        Object.values(this.children)
            .forEach(child => {
            allLinkValue = allLinkValue && (child instanceof Class_LinkValue);
            allLinkValueTree = allLinkValueTree && (child instanceof Class_LinkValueTree);
        });
        return [allLinkValue, allLinkValueTree];
    }
    removeAndReplaceChild(child, new_child) {
        // Get current child id
        const id = this.getDataTagIdFromChild(child);
        // Delete current child
        if (id) {
            this.removeChildFromDataTagId(id);
            // Replace and update cross refs
            this.children[id] = new_child;
            new_child.parent = this;
        }
    }
    removeChildFromDataTagId(id) {
        if (this.children[id]) {
            this.children[id].delete();
            delete this.children[id];
        }
    }
    // GETTERS / SETTERS ==================================================================
    get link() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.link;
        else
            return this.parent;
    }
    get data_tag() {
        var _a, _b;
        if (this.parent instanceof Class_LinkValueTree)
            return (_b = this.parent.data_tag_group.tags_dict[(_a = this.parent.getDataTagIdFromChild(this)) !== null && _a !== void 0 ? _a : '']) !== null && _b !== void 0 ? _b : null;
        else
            return null;
    }
}
// CLASS LINK VALUE *********************************************************************
/**
 * Define a link value object
 *
 * @export
 * @class Class_LinkValue
 */
export class Class_LinkValue extends ClassAbstract_LinkValue {
    // CONSTRUCTOR ========================================================================
    constructor(parent) {
        var _a, _b;
        super();
        this.data_value = null;
        this.text_value = null;
        // TODO moved in a derived class in MFASankey
        this.result_value = null;
        this.free_mini = null;
        this.free_maxi = null;
        /**
         * FluxTags
         * @private
         * @type {{ [_: string]: Class_Tag }}
         * @memberof ClassTemplate_LinkElement
         */
        this._flux_tags = [];
        // Sorted tag by group
        this._taggs_dict = {};
        this._is_currently_deleted = false;
        // Parents / Children relations
        this.parent = parent;
        // Id
        const name = ((_b = (_a = this.link) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '') + '_value_';
        this.data_tags_id
            .forEach(tag_id => name + '_' + tag_id);
        this._id = makeId(name);
    }
    // CLEANING METHODS ===================================================================
    delete() {
        if (!this._is_currently_deleted) {
            // Set as currently deleted
            this._is_currently_deleted = true;
            // Unref from parent
            if (this.parent instanceof Class_LinkValueTree)
                this.parent.removeChild(this);
            // Remove reference of self in related tags
            this.flux_tags_list.forEach(tag => tag.removeReference(this));
            this._flux_tags = [];
            this._taggs_dict = {};
        }
    }
    // COPY METHODS =======================================================================
    copyFrom(element) {
        this.data_value = element.data_value;
        this.text_value = element.text_value;
        // Tags - Cleaning
        this.flux_tags_list.forEach(tag => tag.removeReference(this));
        this._flux_tags = [];
        this._taggs_dict = {};
        // Re-associating
        element.flux_tags_list
            .forEach(flux_tag => {
            flux_tag.addReference(this);
        });
    }
    /**
     * Extract this link value as JSON
     *
     * @return {*}
     * @memberof Class_LinkValue
     */
    toJSON() {
        // Init output JSON
        const json_object = {};
        json_object['id'] = this._id;
        // Fill data
        json_object['id'] = this._id;
        if (this.data_value)
            json_object['data_value'] = this.data_value;
        if (this.text_value)
            json_object['text_value'] = this.text_value;
        json_object['tags'] = Object.fromEntries(this.flux_taggs_list
            .map(tagg => [
            tagg.id,
            this.flux_tags_list
                .filter(tag => (tag.group === tagg))
                .map(tag => tag.id)
        ]));
        // Output
        return json_object;
    }
    fromJSONLegacy(json_object) {
        this.data_value = getNumberOrNullFromJSON(json_object, 'value');
        this.text_value = getStringOrNullFromJSON(json_object, 'display_value');
    }
    /**
     * Read this link value from JSON
     *
     * @param {Type_JSON} json_object
     * @memberof Class_LinkValue
     */
    fromJSON(json_object, matching_taggs_id = {}, matching_tags_id = {}) {
        var _a, _b, _c;
        this._id = getStringFromJSON(json_object, 'id', this._id);
        // Update attributes
        if (Object.prototype.hasOwnProperty.call(json_object, 'value')) { // Value key => Legacy JSON
            this.fromJSONLegacy(json_object);
        }
        else {
            if ('extension' in json_object && json_object['extension'].data_value) {
                console.log('tatatat');
                this.data_value = getNumberOrNullFromJSON(json_object['extension'], 'data_value');
                this.result_value = getNumberOrNullFromJSON(json_object, 'data_value');
            }
            else if ('extension' in json_object && json_object['extension'].free_mini !== undefined) {
                console.log('tututu');
                this.free_mini = getNumberOrNullFromJSON(json_object['extension'], 'free_mini');
                this.free_maxi = getNumberOrNullFromJSON(json_object['extension'], 'free_maxi');
                this.result_value = getNumberOrNullFromJSON(json_object, 'data_value');
            }
            else {
                console.log('titititi');
                this.data_value = getNumberOrNullFromJSON(json_object, 'data_value');
            }
            this.text_value = getStringOrNullFromJSON(json_object, 'text_value');
        }
        // Get Flux tags
        // In JSON here are how supposed tags var is :
        // tags: {key_grp_tag: [key_tag, ...] }
        // where 'key_grp_tag' represent the id of a flux tag group
        // &  '[key_tag, ...]' represent the array of id of tag selected
        // for that flux tag group
        const flux_taggs_dict = ((_b = ((_a = this.link) === null || _a === void 0 ? void 0 : _a.drawing_area).sankey.flux_taggs_dict) !== null && _b !== void 0 ? _b : {});
        Object.entries((_c = json_object['tags']) !== null && _c !== void 0 ? _c : {})
            .filter(([_id_tagg, list]) => {
            var _a;
            if (matching_tags_id[_id_tagg] === undefined) //Sanity check, it is possible that json_object link have ref to tag that fluxTags doesn't have (it can occurs with legecy view)
                return false;
            const tagg_id = (_a = matching_taggs_id[_id_tagg]) !== null && _a !== void 0 ? _a : _id_tagg;
            const tag_ids = list.map(_ => { var _a; return ((_a = matching_tags_id[_id_tagg][_]) !== null && _a !== void 0 ? _a : _); });
            return ((tagg_id in flux_taggs_dict) &&
                (tag_ids.length > 0));
        })
            .forEach(([id, list]) => {
            var _a;
            const tagg_id = (_a = matching_taggs_id[id]) !== null && _a !== void 0 ? _a : id;
            const tagg = flux_taggs_dict[tagg_id];
            const tag_ids = list.map(_ => { var _a; return (_a = matching_tags_id[id][_]) !== null && _a !== void 0 ? _a : _; });
            tagg.tags_list
                .filter(tag => tag_ids.includes(tag.id))
                .forEach(tag => this.addTag(tag));
        });
    }
    // PUBLIC METHODS =====================================================================
    draw() {
        var _a;
        (_a = this.link) === null || _a === void 0 ? void 0 : _a.draw();
    }
    expand(data_tag_group) {
        const new_parent = new Class_LinkValueTree(this.parent, data_tag_group);
        // Copy values from child in grandchildren
        data_tag_group.tags_list.forEach(tag => {
            const _ = new_parent.extend(tag);
            if (_ instanceof Class_LinkValue) // Should always be the case here, but needed
                _.copyFrom(this);
        });
        // Clean self
        this.delete();
        // Return new parent
        return new_parent;
    }
    /**
     * Check if given flux tag is referenced by value
     * @param {Class_Tag} tag
     * @return {*}
     * @memberof ClassTemplate_LinkElement
     */
    hasGivenTag(tag) {
        return this._flux_tags.includes(tag);
    }
    /**
     * Add and cross-reference a Flux tag with this value
     * @param {Class_Tag} tag
     * @memberof ClassTemplate_LinkElement
     */
    addTag(tag) {
        if (!this.hasGivenTag(tag)) {
            this._flux_tags.push(tag);
            this.addTagToGroupTagDict(tag);
            tag.addReference(this);
            this.draw();
        }
    }
    /**
     * Remove given tag and cross-reference from link
     * @param {Class_Tag} tag
     * @memberof ClassTemplate_LinkElement
     */
    removeTag(tag) {
        if (this.hasGivenTag(tag)) {
            const idx = this._flux_tags.indexOf(tag);
            this._flux_tags.splice(idx, 1);
            this.removeTagToGroupTagDict(tag);
            tag.removeReference(this);
            this.draw();
        }
    }
    /**
     * Function that can be used instead of the one in Class_linkValueTree so the recursive function stop & return a value
     *
     * @return {*}
     * @memberof Class_LinkValue
     */
    getMaxValue() {
        return this.data_value;
    }
    getAllValues() {
        const tmp = {};
        if (this.data_tag)
            tmp[this.id] = [this, [this.data_tag]];
        else
            tmp[this.id] = [this, undefined];
        return tmp;
    }
    // PRIVATE ===================================================
    /**
     * Add tag to dict of tag sorted by group
     *
     * @private
     * @param {Class_Tag} tag
     * @memberof Class_LinkValue
     */
    addTagToGroupTagDict(tag) {
        const grp_id = tag.group.id;
        if (grp_id in this._taggs_dict) {
            if (!(this._taggs_dict[grp_id].includes(tag)))
                this._taggs_dict[grp_id].push(tag);
        }
        else {
            this._taggs_dict[grp_id] = [tag];
        }
    }
    /**
     * Remove tag from dict of tag sorted by group
     *
     * @private
     * @param {Class_Tag} tag
     * @memberof Class_LinkValue
     */
    removeTagToGroupTagDict(tag) {
        const grp_id = tag.group.id;
        if (grp_id in this._taggs_dict) {
            const idx = this._taggs_dict[grp_id].indexOf(tag);
            this._taggs_dict[grp_id].splice(idx, 1);
            // After removing a tag check if the flow has other tag from the group,
            //  if not remove tag group entries from flow so are_related_flux_tags_selected don't take into account groupTag not linked to flow
            if (Object.values(this._taggs_dict[grp_id]).length == 0) {
                delete this._taggs_dict[grp_id];
            }
        }
    }
    // GETTERS / SETTERS ==================================================================
    /**
     * Id of value
     *
     * @readonly
     * @memberof Class_LinkValue
     */
    get id() { return this._id; }
    /**
     * Related link of value
     *
     * @readonly
     * @type {(ClassTemplate_LinkElement | null)}
     * @memberof Class_LinkValue
     */
    get link() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.link;
        else
            return this.parent;
    }
    /**
     * Dict as [id: tag] of flux tags related to this value
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_tags_dict() {
        return this._flux_tags;
    }
    /**
     * Array of flux tags related to this value
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_tags_list() {
        return Object.values(this._flux_tags);
    }
    /**
     * Dict as [id: tag group] of tag groups related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_taggs_dict() {
        const taggs = {};
        this.flux_tags_list
            .forEach(tag => {
            if (!taggs[tag.group.id])
                taggs[tag.group.id] = tag.group;
        });
        return taggs;
    }
    get taggs_dict() {
        return this._taggs_dict;
    }
    /**
     * Array of tag groups related to link
     * @readonly
     * @memberof ClassTemplate_LinkElement
     */
    get flux_taggs_list() {
        return Object.values(this.flux_taggs_dict);
    }
    get data_tags_id() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.getDataTagsIdCombination(this);
        else
            return [];
    }
    get data_tagg() {
        if (this.parent instanceof Class_LinkValueTree)
            return this.parent.data_tag_group;
        else
            return null;
    }
    get data_tag() {
        var _a, _b, _c;
        if (this.parent instanceof Class_LinkValueTree)
            return (_c = (_a = this.data_tagg) === null || _a === void 0 ? void 0 : _a.tags_dict[(_b = this.parent.getDataTagIdFromChild(this)) !== null && _b !== void 0 ? _b : '']) !== null && _c !== void 0 ? _c : null;
        else
            return null;
    }
}
// CLASS GHOST LINK *********************************************************************
export class ClassTemplate_GhostLinkElement extends ClassTemplate_LinkElement {
    constructor(id, source, target, drawing_area, menu_config) {
        super(id, source, target, drawing_area, menu_config);
        // Display
        this._display = {
            drawing_area: drawing_area,
            sankey: drawing_area.sankey,
            displaying_order: drawing_area.addElement(),
            position_starting: {
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            position_ending: {
                x: 0,
                y: 0,
                u: 0,
                v: 0
            },
            style: drawing_area.sankey.default_link_style,
            attributes: new Class_LinkAttribute()
        };
        // Link with style
        this._display.style.addReference(this);
        this.source.addOutputLink(this);
        this.target.addInputLink(this); // Target
        // Instanciate display on svg
        this.computeControlPoints();
    }
    // GETTER / SETTER ====================================================================
    get is_visible() { return (this._is_visible && this.sankey.is_visible); }
}
