// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// OpenSankey imports
import { default_main_sankey_id } from '../deps/OpenSankey+/deps/OpenSankey/types/Utils';
import { ClassAbstract_SankeyMFA } from './AbstractMFA';
// CLASS SANKEY PLUS *********************************************************************
/**
 * Contains all necessary elements to draw a Sankey
 *
 * @export
 * @class ClassTemplate_SankeyMFA
 */
export class ClassTemplate_SankeyMFA extends ClassAbstract_SankeyMFA {
    // PRIVATE ATTRIBUTES =================================================================
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_SankeyMFA.
     * @param {Type_GenericDrawingArea} drawing_area
     * @memberof ClassTemplate_SankeyMFA
     */
    constructor(drawing_area, menu_config, id = default_main_sankey_id) {
        // Heritance
        super(drawing_area, menu_config, id);
        // Overrides
        this._menu_config = menu_config;
    }
}
