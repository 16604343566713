export const resources_template = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      templates: {
        themes: {
          energy: 'Energy',
          enviro: 'Environment',
          mat: 'Materials',
          industry: 'Industry',
          economy: 'Economics',
          ress: 'Ressources',
          commercial: 'Commercial',
          supply: 'Supply chain',
          agri: 'Agriculture'
        },
        difficulties: {
          basic: 'Essential',
          intermediary: 'Advanced',
          advanced: 'Expert',
        },
        ids: {
          power_grid: 'Power grid with productions & uses disagregation',
          waste_recycling: 'Household packaging waste recycling',
          water_usage: 'Water ressource systeme',
          personnal_budget: 'Personal expenses',
          business_simple: 'Simple business accounting',
          commercial_pipe: 'Business pipe',
          production_of_boats: 'Sailboats production'
        },
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      templates:{
        themes: {
          energy: 'Energie',
          enviro: 'Environnement',
          mat: 'Matières',
          industry: 'Industrie',
          economy: 'Economique',
          ress: 'Ressources',
          commercial: 'Commercial',
          supply: 'Logistique',
          agri: 'Agriculture'
        },
        difficulties: {
          basic: 'Basique',
          intermediary: 'Avancé',
          advanced: 'Expert',
        },
        ids: {
          power_grid: 'Réseau électrique avec désagrégation des productions et des utilisations',
          waste_recycling: 'Recyclage des déchets d\'emballages',
          water_usage: 'Usage des ressources en eau',
          personnal_budget: 'Dépenses personnelles',
          business_simple: 'Bilan comptable simple',
          commercial_pipe: 'Pipe commercial',
          production_of_boats: 'Production'
        },
      },
    }
  }
}
