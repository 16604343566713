// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// OpenSankey imports
import { default_main_sankey_id } from '../deps/OpenSankey+/deps/OpenSankey/types/Utils';
// Local imports
import { ClassAbstract_DrawingAreaMFA } from './AbstractMFA';
// CLASS DRAWING AREA PLUS **************************************************************
/**
 * Override OpenSankey's class to take in account specifities of OpenSankey+ app
 *
 * @export
 * @class ClassTemplate_DrawingAreaMFA
 * @extends {Class_DrawingArea}
 */
export class ClassTemplate_DrawingAreaMFA extends ClassAbstract_DrawingAreaMFA {
    // PROTECTED ATTRIBUTES ===============================================================
    // PRIVATE ATTRIBUTES =================================================================
    // Objects containeds in drawing area -------------------------------------------------
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_DrawingAreaMFA.
     * @param {number} height
     * @param {number} width
     * @param {
     *  ClassAbstract_ApplicationDataMFA<
          ClassTemplate_DrawingAreaMFA<Type_GenericSankey, Type_GenericNodeElement, Type_GenericLinkElement>,
          Type_GenericSankey,
          Type_GenericNodeElement,
          Type_GenericLinkElement>} application_data
     * @memberof ClassTemplate_DrawingAreaMFA
     */
    constructor(application_data, id = default_main_sankey_id) {
        // Heritance
        super(application_data, id);
        // Overrides
        this.application_data = application_data;
    }
}
