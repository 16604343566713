// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import { useRef } from 'react';
// OpenSankey+ imports
import { Class_MenuConfigOSP } from '../deps/OpenSankey+/types/MenuConfigOSP';
// CLASS MENU CONFIG PLUS ***************************************************************
/**
 * Override OpenSankey+'s Class_MenuConfigOSP to take in account specifities of OpenSankey++ app
 *
 * @export
 * @class Class_MenuConfigMFA
 * @extends {Class_MenuConfigOSP}
 */
export class Class_MenuConfigMFA extends Class_MenuConfigOSP {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_MenuConfigMFA.
     * @memberof Class_MenuConfigMFA
     */
    constructor() {
        // Init parent class
        super();
        // Init new attr
        this._action_type = 'optim';
        this._dict_setter_show_dialog_afm = {
            ref_setter_show_split_trade: useRef(() => null),
            ref_setter_show_reconciliation: useRef(() => null)
        };
    }
    // PUBLIC METHODS ====================================================================
    // PROTECTED METHODS ==================================================================
    // GETTERS / SETTERS ==================================================================
    get action_type() { return this._action_type; }
    set action_type(_) { this._action_type = _; }
    get dict_setter_show_dialog_afm() { return this._dict_setter_show_dialog_afm; }
}
