// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// Local imports
import { ClassAbstract_NodeElementMFA } from './AbstractMFA';
// CLASS NODE ELEMENT PLUS **************************************************************
/**
 * Override OpenSankey's class to take in account specifities of OpenSankey+ app
 * @export
 * @class ClassTemplate_NodeElementMFA
 * @extends {ClassAbstract_NodeElementMFA}
 */
export class ClassTemplate_NodeElementMFA extends ClassAbstract_NodeElementMFA {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_NodeElementMFA.
     * @param {string} id
     * @param {string} name
     * @param {Type_GenericDrawingArea} drawing_area
     * @param {Class_MenuConfigMFA} menu_config
     * @memberof ClassTemplate_NodeElementMFA
     */
    constructor(id, name, drawing_area, menu_config) {
        // Heritance
        super(id, name, drawing_area, menu_config);
        // Overrides
        this._menu_config = menu_config;
    }
    // ABSTRACT METHODS ===================================================================
    // Nothing ...
    // PUBLIC METHOD ======================================================================
    // Nothing ...
    // PROTECTED METHODS ====================================================================
    // Nothing ...
    // PRIVATE METHODS ====================================================================
    // Nothing ...
    // GETTERS / SETTERS ==================================================================
    // Get application config menu
    get menu_config() { return this._menu_config; }
}
